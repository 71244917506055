import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR, resetStoreHOR } from './hors.js'

const t = makeTypes('menuderecha')
const ASSIGN = t('assign').single()
const RESETSTORE = 'reset'

export const abrirMenuDerecha = mac(ASSIGN, 'payload')

const initialState = {
    data: false,
}

const abrirReducer = createReducer(initialState, assignHOR({ ASSIGN }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(abrirReducer, resetStoreReducer)

