import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, selectHOR, addFetch, delFetch, resetStoreHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'
import { fetchPaises } from './Paises.js'
import { asignarMensaje } from './Mensajes'

const t = makeTypes('workingaccounts')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETSTORE = 'reset'
 
const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const selectRango = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT} ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, deleteReducer, resetStoreReducer)

export const fetchWorkingaccounts = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/workingaccounts?country=${values ? (values.country ? values.country : "") : ""}&receiver=${values ? (values.receiver ? values.receiver : "") : ""}&active=${values ? (values.active ? values.active : "") : ""}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const searchWorkingaccounts = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/workingaccounts/search?country_id=${values}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}


export const editWorkingaccount = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/workingaccounts`, values.id, values)
        dispatch(editActions.success(data.data))
        dispatch(asignarMensaje("La cuenta fue editada"))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    } 
}

export const crearWorkingaccount = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/workingaccounts`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const borrarWorkingaccount = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    try {
        const data = await deleteRequest(`/workingaccounts`, id)
        dispatch(deleteActions.success(data.data.id))
        dispatch(asignarMensaje("La cuenta fue borrada"))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}