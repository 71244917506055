import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Box, Grid, Paper, Typography } from '@material-ui/core'
import TextField from './TextField';
import Checkbox from './Checkbox';
import SelectSearch from './SelectSearch2';
import { currency2decimal } from '../services/utils'

const validate = (values, props) => {
  const errors = {}

  const requiredFields = [
    'origen',
    'destino',
    'pais_origen',
    'pais_destino',
    'tasa',
    'codigo'
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  return errors
}

const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '').replace(/\-/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}

const normalizeAmount = country => {
  console.log(country)
  if(country){
    if(country.nombre === "Panama") return numeroDecimal
    else if(country.nombre === "Venezuela") return numeroDecimal
    else return numero
  }else{
    return numero
  }
}

const numeroDecimal = value => {
  let valor
  valor = value.replace(/\./g, '').replace(/\-/g, '')
  let partes = valor.split(",");
  let decimal = partes.length > 1 && partes[1].substring(0,5)
  valor = decimal ? partes[0]+","+decimal : valor
  valor = valor.replace(/\,/g, '.')
  return valor
}

const selector = formValueSelector('calculadora-manual')

let FormCalculadora = props => {
    const { handleSubmit, change, paises } = props
    const pais_origen = useSelector(state => selector(state, 'pais_origen'))
    const pais_destino = useSelector(state => selector(state, 'pais_destino'))
    const proporcional = useSelector(state => selector(state, 'proporcional'))
    const origen = useSelector(state => selector(state, 'origen'))
    const destino = useSelector(state => selector(state, 'destino'))
    const tasa = useSelector(state => selector(state, 'tasa'))

    const handleChange = (e, value) => {
        if(e.target.name === "destino"){
            change('origen', tasa ? Math.ceil(proporcional ? (value / tasa ) * 100 : (value * tasa) * 100 ) / 100 : "" )
        }else if(e.target.name === "origen"){
            change('destino', tasa ? Math.floor(proporcional ? (value * tasa ) * 100 : (value / tasa) * 100 ) / 100 : "" )
        }else if(e.target.name === "tasa"){
            change('destino', origen ? Math.floor(proporcional ? (origen * value ) * 100 : (origen / value) * 100 ) / 100 : "" )
        }else if(e.target.name === "proporcional"){
            change('destino', origen ? Math.floor(value ? (origen * tasa ) * 100 : (origen / tasa) * 100 ) / 100 : "" )
        }
    }

    return(
        <Paper >
            <Box p={4}>
                <Grid container justify="center" alignItems="center" style={{ marginBottom: '20px'}} >
                    <Typography variant="h6">Calcula tu envío</Typography>
                </Grid>
                <form noValidate onSubmit={handleSubmit} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                name="pais_origen"
                                label="Desde"
                                disableClearable={true}
                                etiqueta="origen"
                                options={paises}
                                component={SelectSearch}/>
                                
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Field
                                name="origen"
                                label="Tu envías"
                                autoComplete='off'
                                format={currency2decimal}
                                normalize={normalizeAmount(pais_origen)}
                                onChange={(e,value) => handleChange(e,value)}
                                component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Field
                        name="tasa"
                        label="Tasa"
                        autoComplete='off'
                        format={currency2decimal}
                        normalize={numeroDecimal}
                        onChange={(e,value) => handleChange(e,value)}
                        component={TextField}
                    />
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                name="pais_destino"
                                label="Hacia"
                                etiqueta="destino"
                                disableClearable={true}
                                options={paises}
                                component={SelectSearch}/>

                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Field
                                name="destino"
                                label="Tu destinatario recibe"
                                autoComplete='off'
                                format={currency2decimal}
                                normalize={normalizeAmount(pais_destino)}
                                onChange={(e,value) => handleChange(e,value)}
                                component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Field 
                        name="proporcional" 
                        component={Checkbox} 
                        label="Proporcional" 
                        onChange={(e,value) => handleChange(e,value)}
                    />

                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Button variant="contained" color="primary" type="submit">
                                Enviar Dinero
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Paper>
    )
}

export default FormCalculadora = reduxForm({
  form: 'calculadora-manual',
  enableReinitialize : true,
  validate,
})(FormCalculadora);