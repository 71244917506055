import React from 'react'
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { currency } from '../services/utils'


function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
}

const RenderSelect = ({
    input,
    label,
    options,
    etiqueta,
    defaultValue,
    handleOnChange,
    meta: { touched, error },
    clear,
    disableClearable,
    change,
    ...custom
  }) => 
  {
    const handleChange = (e, newValue)=> {
        switch (etiqueta) {
            case "codigo":
                if(newValue){
                    change(input.name, newValue.phone)
                }else{
                    change(input.name, "")
                }
                break;
            case "tipo_cuenta":
                if(newValue){
                    change(input.name, newValue.nombre)
                }else{
                    change(input.name, "")
                }
                break;
            case "origen":
                if(newValue){
                    change(input.name, newValue.origen_id)
                }else{
                    change(input.name, "")
                }
                break;
            case "destino":
                if(newValue){
                    change(input.name, newValue.destino_id)
                }else{
                    change(input.name, "")
                }
                break;
            default:
                if(newValue){
                    change(input.name, newValue.id)
                    if(etiqueta === "banco"){
                        change("codigo", newValue.id)}
                    }else{
                        change(input.name, "")
                    if(etiqueta === "banco"){
                        change("codigo", "")
                    }
                }
                break;
        }        
    }
  
    return(
      <Autocomplete
        disableClearable={disableClearable}
        defaultValue={defaultValue}
        clearOnEscape
        options={options}
        disabled={custom.disabled ? true : false}
        style={custom.style ? custom.style : {} }
        onChange={handleChange}
        getOptionLabel={ options => {
            switch (etiqueta) {
                case "codigo":
                    return options ? "("+options.code+")" + "+" + options.phone : ""
                case "rango":
                    return options ? currency(options.minimo) + "-" + currency(options.maximo) : ""
                case "origen":
                    return options ? options.origen.nombre : ""
                case "destino":
                    return options ? options.destino.nombre : ""
                default:
                    return options.nombre
            }
        }}
        renderOption={ (options, status) => {
            switch (etiqueta) {
                case "codigo":
                    return (options ? 
                        <React.Fragment>
                            <span>{countryToFlag(options.code)}</span>
                            ({options.code}) +{options.phone}
                    </React.Fragment> : "")
                case "rango":
                    return options ? currency(options.minimo) + "-" + currency(options.maximo) : ""
                case "origen":
                    return options ? options.origen.nombre: ""
                case "destino":
                    return options ? options.destino.nombre: ""
                default:
                    return options.nombre
            }
        }}
        renderInput={params => {return(<TextField 
          variant="outlined"
          { ...params}
          error={touched && (error ? true : false)}
          helperText={touched && ((error && <span>{error}</span>))}
          required={custom.required}
          label={label}
          fullWidth
      />)}} />
    )
}

export default RenderSelect
