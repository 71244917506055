import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: '20px'
    }
}));

export default function HorizontalLabelPositionBelowStepper(props) {
    const { pasos } = props
    const classes = useStyles();
    const pasoActivo = useSelector(state => state.Pasos.data)

    return (
        <div className={classes.root}>
            <Stepper activeStep={pasoActivo} alternativeLabel>
            {pasos && pasos.map(label => (
                <Step key={label}>
                <StepLabel>{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
        </div>
    );
}