import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, Grid, Typography, Button } from '@material-ui/core'

import Layout from '../../components/Layout'
import Steeper from '../../components/Stepper'
import MenuNav from './menuSolicitudesMayorista'
import FormCalculadoraManual from '../../components/FormCalculadoraManual'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import ElegirCliente from './elegirClienteMayorista'
import { fetchPaises } from '../../ducks/Paises'
import { asignarPaso } from '../../ducks/Pasos'
import ElegirBeneficiario from "./elegirBeneficiario";
import Resumen from "./resumen";
import { asignarDetalle } from "../../ducks/DetallesCambio";
import { agregarSolicitudDivisas, resetError } from "../../ducks/Transacciones";

const AgregarSolicitudMayorista = props => {
    const didMountRef = useRef(false);
    const dispatch = useDispatch()
    let pasos = [
        "Calcula la Solicitud",
        "Elige Cliente",
        "Elige Cuenta Receptora",
        "Confirma tu Solicitud",
    ]
    
    const [ mensaje, setMensaje ] = useState("")
    const paises = useSelector(state => state.Paises.data)
    const loading = useSelector(state => state.Paises.fetching || state.Transacciones.adding)
    const pasoActivo = useSelector(state => state.Pasos.data)
    const usuario = useSelector(state => state.Usuarios.data.find(us => us.selected))
    const beneficiario = useSelector(state => state.Beneficiarios.data.find(benef => benef.selected) )
    const detalle = useSelector(state => state.DetalleCambio.data)
    const transaccionAdded = useSelector(state => state.Transacciones.added)
    const transaccionAdding = useSelector(state => state.Transacciones.adding)
    const error = useSelector(state => state.Transacciones.error)

    const handleContinue = values => {
        dispatch(asignarDetalle(values))
        dispatch(asignarPaso(pasoActivo +1))
    }

    const agregarSolicitud = () => {
        dispatch(agregarSolicitudDivisas({ usuario, beneficiario, detalle }))
    }

    useEffect(() => {
        const fetchData = () => {
            if(paises.length ===  0){
                dispatch(fetchPaises())
            }
        }
        fetchData()
        return () => dispatch(asignarPaso(0))
    },[])

    useEffect(() => {
        if(transaccionAdded && didMountRef.current){
            dispatch(asignarPaso(0))
            setMensaje("La solicitud fue registrada!")
        }else{
            didMountRef.current = true
        }
        return () => setMensaje("")
    },[transaccionAdding])

    return(
        <Layout menu>
            <Hidden smDown>
                <MenuNav />
            </Hidden>

            <Grid container justify="center" alignItems="center">
              <Typography variant="h5">Realiza tu Solicitud</Typography>
            </Grid>

            <Hidden smDown>
                <Steeper 
                    pasoActivo={pasoActivo}
                    pasos={pasos}
                />
            </Hidden>

            {pasoActivo === 0 &&
                <Grid container justify="center" alignContent="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <FormCalculadoraManual 
                            paises={paises}
                            initialValues={{
                                pais_origen: paises.find(x => x.nombre === "Chile"),
                                pais_destino: paises.find(x => x.nombre === "Venezuela"),
                                proporcional: true,
                            }}
                            onSubmit={values => handleContinue(values)}
                        />
                    </Grid>
                </Grid>
            }

            {pasoActivo === 1 &&
                <ElegirCliente />
            }
            {pasoActivo === 2 &&
                <ElegirBeneficiario />
            }
            {pasoActivo === 3 &&
                <Resumen />
            }
            
            {pasoActivo !== 0 && (
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => dispatch(asignarPaso(pasoActivo - 1 ))}>Volver</Button>
                </Grid>
                <Grid item>
                    {pasoActivo === (pasos.length - 1) && <Button variant="contained" color="primary" onClick={agregarSolicitud}>Confirmar Transacción</Button>}
                </Grid>
            </Grid>
            )}

            <Backdrop open={!!loading} />
            <Snackbar 
                open={!!mensaje}
                handleClose={() => setMensaje("") }
                message={mensaje}
            />
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetError()) }
                message={error && error.map( er => er)}
            />

        </Layout>
    )
}


export default AgregarSolicitudMayorista