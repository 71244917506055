import React from 'react'
import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, selectHOR, addFetch, delFetch, resetStoreHOR } from './hors.js'
import { getRequest, putRequest, postImageRequest, deleteRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'

const t = makeTypes('empresas')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const selectEstado = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT} ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, deleteReducer, resetStoreReducer )

export const crearEmpresa = values => async (dispatch, getState) => {
    try {
        const response = await postImageRequest(`/companies`, { data: values, image: values.images })
        return response
    } catch (error) {
        throw error
    }
}
export const editarEmpresa = values => async (dispatch, getState) => {
    try {
        const response = await postImageRequest(`/companies/edit-company`, { data: values, image: values.images })
        return response
    } catch (error) {
        throw error
    }
}

