import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Paper, Box } from '@material-ui/core'

import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import TarjetaCuentas from '../../components/TarjetaCuentas/TarjetaCuentas'
import FormularioBeneficiariosVzla from '../../components/FormularioBeneficiariosVzla'
import FormBeneficiarioChile from '../../components/FormBeneficiarioChile'
import FormBeneficiarioPnm from '../../components/FormBeneficiarioPnm'
import TextField from '../../components/TextField'
import { asignarPaso } from '../../ducks/Pasos'
import { fetchBeneficiariosUsuarios, fetchBeneficiarios, resetErrorBeneficiario, agregarBeneficiarioAUsuario, seleccionarBeneficario, vincularBenefUsuario } from '../../ducks/Beneficiarios'

const ElegirBeneficiario = props => {
    const { cliente, multiple } = props
    const [ filtroBeneficiario, setFiltroBeneficiario ] = useState('')
    const [ mensaje, setMensaje ] = useState('')
    const dispatch = useDispatch()
    const usuario = useSelector( state => {
        if(cliente){
            return state.Clientes.data.find(cliente => cliente.selected)
        }else{
            return state.Usuarios.data.find(us => us.selected)
        }
    })
    const beneficiarios = useSelector( state => state.Beneficiarios )
    const paises = useSelector( state => state.Paises.data )
    const loading = useSelector( state => state.Beneficiarios.fetching || state.Beneficiarios.adding || state.Beneficiarios.editing || state.Beneficiarios.editing )
    const paisSeleccionado = useSelector(state => state.DetalleCambio.data.pais_destino)
    const pasoActivo = useSelector(state => state.Pasos.data)
    const error = useSelector( state => state.Beneficiarios.error )
    const beneficiariosFiltrados = beneficiarios.data
        .filter(benef => ((benef.nombre && benef.nombre.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) 
            || (benef.cuenta && benef.cuenta.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) 
            || (benef.idNumero && benef.idNumero.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) ))
        .filter((benef, index, self) => index === self.findIndex( benefrep => benefrep.nombre === benef.nombre))


    const handleContinue = values => {
        if(!values.users.find(us => us.id === usuario.id)){
            dispatch(vincularBenefUsuario({ account: values.id, user: usuario.id}))
        }
        dispatch(seleccionarBeneficario(values.id))
        dispatch(asignarPaso(pasoActivo +1))
    }
    
    let formularioBeneficiario
    
    switch (paisSeleccionado?.nombre) {
        case "Venezuela":
            formularioBeneficiario = 
                <FormularioBeneficiariosVzla
                    paisId={paisSeleccionado.id}
                    busquedaAdmin={true}
                    onSubmit={(values) => {
                        if(beneficiarios.data.find(benef => benef.cuenta === values.codigo + values.cuenta)){
                            setMensaje('Este beneficiario ya se encuentra en su lista')
                        }else{
                            dispatch(
                                agregarBeneficiarioAUsuario(
                                    {
                                    ...values,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo + values.cuenta,
                                    country_id: paisSeleccionado.id,
                                    },
                                    paisSeleccionado.nombre,
                                    usuario.id
                                )
                            )
                        }
                    }}
                />
            break
        case "Chile":
            formularioBeneficiario =                                         
                <FormBeneficiarioChile
                    paisId={paisSeleccionado.id}
                    busquedaAdmin={true}
                    onSubmit={(values) => {
                        if(beneficiarios.data.find(benef => benef.cuenta === values.cuenta)){
                            setMensaje('Este beneficiario ya se encuentra en su lista')
                        }else{
                        
                        }
                        dispatch(
                            agregarBeneficiarioAUsuario(
                                {
                                    ...values,
                                    idNumero: values.rut,
                                    idTipo: "Rut",
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                },
                                paisSeleccionado.nombre,
                                usuario.id
                            )
                        )
                    }}
                />
            break

        case "Panama":
            formularioBeneficiario =                                         
                <FormBeneficiarioPnm
                    paisId={paisSeleccionado.id}
                    busquedaAdmin={true}
                    onSubmit={(values) => {
                        if(beneficiarios.data.find(benef => benef.cuente === values.codigo + values.cuenta)){
                            setMensaje('Este beneficiario ya se encuentra en su lista')
                        }else{
                            dispatch(
                                agregarBeneficiarioAUsuario(
                                {
                                    ...values,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                },
                                paisSeleccionado.nombre,
                                usuario.id
                                )
                            )
                        }
                    }}
                />
            break

        case "BTC":
            formularioBeneficiario = <div>En construcción</div>
            break

        default:
            formularioBeneficiario = "Aún se pueden agregar cuentas para este país"
            break
    }

    useEffect(() => {
        const fetchData = () => {
            if(cliente){
                dispatch(fetchBeneficiarios({ id: usuario.id, pais: paisSeleccionado.id }))
            }else{
                dispatch(fetchBeneficiariosUsuarios({ id: usuario.id, pais: paisSeleccionado.id }))
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        return () => dispatch(resetErrorBeneficiario())
    }, [])

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper>
                    <Box p={4}>
                        {formularioBeneficiario}
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {beneficiarios.data.length > 0 &&
                <TextField
                    label="Buscar"
                    input={{
                        name:"filtroBeneficiario",
                        value: filtroBeneficiario,
                        onChange: value => setFiltroBeneficiario(value.target.value),
                        onBlur: () => {},
                    }}
                    meta={{
                        error: false,
                        touched: false,
                    }}
                />
                }
                {beneficiariosFiltrados.length > 0 ? beneficiariosFiltrados.map((x,id) => 
                    <Box key={id} pb={1}>
                        <TarjetaCuentas
                            paises={paises}
                            cuenta={x}
                            acciones={[
                                {}
                            ]}
                            handleClick={() => handleContinue(x)}
                        />
                    </Box>
                ) : <Typography variant="body1" style={{ textAlign: "center", margin: 10 }}>No se han encontrado cuentas, puedes buscar o agregar alguna</Typography>}
            </Grid>

            <Backdrop open={!!loading} />
            <Snackbar 
                open={!!mensaje}
                handleClose={() => setMensaje('') }
                message={mensaje}
            />
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorBeneficiario()) }
                message={error && error.map( er => er)}
            />
        </Grid>
    )
}

export default ElegirBeneficiario