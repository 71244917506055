import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import TextField from './TextField';
import SelectField from './SelectField';

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'descripcion',
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  return errors
}

let FormPaises = props => {
    const { handleSubmit, paisSeleccionado } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                type="text"
                name="nombre"
                label="Nombre"
                component={TextField}
            />

            <Field
                type="text"
                name="descripcion"
                label="Descripción"
                component={TextField}
            />

            <Field
                type="text"
                name="origin"
                label="Origen"
                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                options={[{id:true, nombre: "SI"},{id:false, nombre: "NO"}]}
                component={SelectField}
            />

            <Field
                type="text"
                name="destiny"
                label="Destino"
                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                options={[{id:true, nombre: "SI"},{id:false, nombre: "NO"}]}
                component={SelectField}
            />

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    {paisSeleccionado ? 
                    <Button variant="contained" color="primary" type="submit">
                        Editar País
                    </Button>
                    :
                    <Button variant="contained" color="primary" type="submit">
                        Agregar País
                    </Button>}
                </Grid>
            </Grid>
        </form>
    )
}

FormPaises = reduxForm({
  form: 'paises',
  enableReinitialize : true,
  validate,
})(FormPaises);

export default FormPaises
