import imageCompression from 'browser-image-compression';

export const getQueryVariable = variable => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return false
}

export function Banco(codigo){
    const options = [  
            {id: "0156", nombre:"100%BANCO"},
            {id: "0196", nombre: "ABN AMRO BANK"},
            {id: "0172", nombre: "BANCAMIGA BANCO MICROFINANCIERO, C.A."},
            {id: "0171", nombre: "BANCO ACTIVO BANCO COMERCIAL, C.A."},
            {id: "0166", nombre: "BANCO AGRICOLA"},
            {id: "0175", nombre: "BANCO BICENTENARIO"},
            {id: "0128", nombre: "BANCO CARONI, C.A. BANCO UNIVERSAL"},
            {id: "0164", nombre: "BANCO DE DESARROLLO DEL MICROEMPRESARIO"},
            {id: "0102", nombre: "BANCO DE VENEZUELA S.A.I.C.A."},
            {id: "0114", nombre: "BANCO DEL CARIBE C.A."},
            {id: "0149", nombre: "BANCO DEL PUEBLO SOBERANO C.A."},
            {id: "0163", nombre: "BANCO DEL TESORO"},
            {id: "0176", nombre: "BANCO ESPIRITO SANTO, S.A."},
            {id: "0115", nombre: "BANCO EXTERIOR C.A."},
            {id: "0003", nombre: "BANCO INDUSTRIAL DE VENEZUELA."},
            {id: "0173", nombre: "BANCO INTERNACIONAL DE DESARROLLO, C.A."},
            {id: "0105", nombre: "BANCO MERCANTIL C.A."},
            {id: "0191", nombre: "BANCO NACIONAL DE CREDITO (BNC)."},
            {id: "0116", nombre: "BANCO OCCIDENTAL DE DESCUENTO (BOD)."},
            {id: "0138", nombre: "BANCO PLAZA"},
            {id: "0108", nombre: "BANCO PROVINCIAL BBVA"},
            {id: "0104", nombre: "BANCO VENEZOLANO DE CREDITO S.A."},
            {id: "0168", nombre: "BANCRECER S.A. BANCO DE DESARROLLO"},
            {id: "0134", nombre: "BANESCO BANCO UNIVERSAL"},
            {id: "0177", nombre: "BANFANB"},
            {id: "0146", nombre: "BANGENTE"},
            {id: "0174", nombre: "BANPLUS BANCO COMERCIAL C.A"},
            {id: "0190", nombre: "CITIBANK."},
            {id: "0121", nombre: "CORP BANCA."},
            {id: "0157", nombre: "DELSUR BANCO UNIVERSAL"},
            {id: "0151", nombre: "FONDO COMUN"},
            {id: "0601", nombre: "INSTITUTO MUNICIPAL DE CRÉDITO POPULAR"},
            {id: "0169", nombre: "MIBANCO BANCO DE DESARROLLO, C.A.",},
            {id: "0137", nombre: "SOFITASA"},
            {id: 'BANCO DE CHILE', nombre:'BANCO DE CHILE'},
            {id: 'BANCO INTERNACIONAL', nombre: 'BANCO INTERNACIONAL'},
            {id: 'SCOTIABANK CHILE', nombre: 'SCOTIABANK CHILE'},
            {id: 'BANCO DE CREDITO E INVERSIONES', nombre: 'BANCO DE CREDITO E INVERSIONES'},
            {id: 'CORPBANCA', nombre: 'CORPBANCA'},
            {id: 'BANCO BICE', nombre: 'BANCO BICE'},
            {id: 'HSBC BANK (CHILE)', nombre: 'HSBC BANK (CHILE)'},
            {id: 'BANCO SANTANDER-CHILE', nombre: 'BANCO SANTANDER-CHILE'},
            {id: 'BANCO ITAÚ CHILE', nombre: 'BANCO ITAÚ CHILE'},
            {id: 'BANCO SECURITY', nombre: 'BANCO SECURITY'},
            {id: 'BANCO FALABELLA', nombre: 'BANCO FALABELLA'},
            {id: 'DEUTSCHE BANK (CHILE)', nombre: 'DEUTSCHE BANK (CHILE)'},
            {id: 'BANCO RIPLEY', nombre: 'BANCO RIPLEY'},
            {id: 'RABOBANK CHILE', nombre: 'RABOBANK CHILE'},
            {id: 'BANCO CONSORCIO', nombre: 'BANCO CONSORCIO'},
            {id: 'BANCO PENTA', nombre: 'BANCO PENTA'},
            {id: 'BANCO PARIS', nombre: 'BANCO PARIS'},
            {id: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA)', nombre: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA)'},
            {id: 'BANCO DEL ESTADO DE CHILE', nombre: 'BANCO DEL ESTADO DE CHILE'},
            {id: 'ATLAS BANK', nombre: 'ATLAS BANK'},
            {id: 'BAC INTERNATIONAL BANK', nombre: 'BAC INTERNATIONAL BANK'},
            {id: 'BALBOA BANK & TRUST', nombre: 'BALBOA BANK & TRUST'},
            {id: 'BANCO ALIADO', nombre: 'BANCO ALIADO'},
            {id: 'BANCO AZTECA', nombre: 'BANCO AZTECA'},
            {id: 'BANCO DAVIVIENDA PANAMA. S.A.', nombre: 'BANCO DAVIVIENDA PANAMA. S.A.'},
            {id: 'BANCO DEL PACIFICO', nombre: 'BANCO DEL PACIFICO'},
            {id: 'BANCO DELTA', nombre: 'BANCO DELTA'},
            {id: 'BANCO FICOHSA PANAMA', nombre: 'BANCO FICOHSA PANAMA'},
            {id: 'BANCO GENERAL', nombre: 'BANCO GENERAL'},
            {id: 'BANCO INT. DE COSTA RICA', nombre: 'BANCO INT. DE COSTA RICA'},
            {id: 'BANCO LAFISE PANAMA. S.A.', nombre: 'BANCO LAFISE PANAMA. S.A.'},
            {id: 'BANCOLOMBIA', nombre: 'BANCOLOMBIA'},
            {id: 'BANCO NACIONAL DE PANAMA', nombre: 'BANCO NACIONAL DE PANAMA'},
            {id: 'BANCO PICHINCHA DE PANAMA', nombre: 'BANCO PICHINCHA DE PANAMA'},
            {id: 'BANESCO', nombre: 'BANESCO'},
            {id: 'BANISI. S.A.', nombre: 'BANISI. S.A.'},
            {id: 'BANISTMO. S.A.', nombre: 'BANISTMO. S.A.'},
            {id: 'BANK OF CHINA', nombre: 'BANK OF CHINA'},
            {id: 'BBP BANK. S.A.', nombre: 'BBP BANK. S.A.'},
            {id: 'BCT BANK INTERNATIONAL', nombre: 'BCT BANK INTERNATIONAL'},
            {id: 'BI BANK', nombre: 'BI BANK'},
            {id: 'CACECHI', nombre: 'CACECHI'},
            {id: 'CAJA DE AHORROS', nombre: 'CAJA DE AHORROS'},
            {id: 'CANAL BANK', nombre: 'CANAL BANK'},
            {id: 'CAPITAL BANK', nombre: 'CAPITAL BANK'},
            {id: 'CITIBANK. N.A.', nombre: 'CITIBANK. N.A.'},
            {id: 'COEDUCO', nombre: 'COEDUCO'},
            {id: 'COOESAN', nombre: 'COOESAN'},
            {id: 'COOPEDUC', nombre: 'COOPEDUC'},
            {id: 'COOPERATIVA CRISTOBAL', nombre: 'COOPERATIVA CRISTOBAL'},
            {id: 'COOPERATIVA DE SERVICIOS MULTI', nombre: 'COOPERATIVA DE SERVICIOS MULTI'},
            {id: 'COOPERATIVA PROFESIONALES R.L', nombre: 'COOPERATIVA PROFESIONALES R.L'},
            {id: 'COOPEVE', nombre: 'COOPEVE'},
            {id: 'COOPRAC, R.L.', nombre: 'COOPRAC, R.L.'},
            {id: 'CREDICORPBANK', nombre: 'CREDICORPBANK'},
            {id: 'ECASESO', nombre: 'ECASESO'},
            {id: 'GLOBAL BANK', nombre: 'GLOBAL BANK'},
            {id: 'MERCANTIL BANK', nombre: 'MERCANTIL BANK'},
            {id: 'METROBANK', nombre: 'METROBANK'},
            {id: 'MMG BANK', nombre: 'MMG BANK'},
            {id: 'MULTIBANK', nombre: 'MULTIBANK'},
            {id: 'PRIVAL BANK', nombre: 'PRIVAL BANK'},
            {id: 'PRODUBANK', nombre: 'PRODUBANK'},
            {id: 'RESERVA FEDERAL DE EUA', nombre: 'RESERVA FEDERAL DE EUA'},
            {id: 'SCOTIABANK', nombre: 'SCOTIABANK'},
            {id: 'ST GEORGES BANK', nombre: 'ST GEORGES BANK'},
            {id: 'TELERED', nombre: 'TELERED'},
            {id: 'TOWERBANK', nombre: 'TOWERBANK'},
            {id: 'UNI BANK & TRUST INC', nombre: 'UNI BANK & TRUST INC'},
        ]
    return options.find(x => codigo === x.id ) ? options.find(x => codigo === x.id ).nombre : ""
}

export const currency = value => {
    if(typeof(value) === "number"){
      value = value.toString()
    }
    let stringValue = value && value.replace(/\./g, '').replace(/\,/g, '');
    let valor = stringValue && parseFloat(stringValue);
    valor = valor && valor.toLocaleString('en-US').replace(/\,/g, '.');
    return valor ? valor : '';
}

export const addMillsSep = num => {
    var x1 = num.toString();
    x1 = x1.replace('.', ',');
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1;
};

export const currency2decimal = value => {
    if(value){
        let newValue = value.toString()
        newValue = newValue.replace(/\./g, ',')
        newValue = newValue.replace(/[^0-9\,\-]/g, '').replace(/([-+]?\d*\,\d*)(\,)(\d*)/g, '$1$2$3$4$5').replace(/^([-+]?\d+,?\d{0,5})\d*$/, '$1')
        let partes = newValue.split(",")
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;      
    }else{
        return ''
    }
};

export const currency8decimal = value => {
    if(value){
        let newValue = value.toString()
        newValue = newValue.replace(/\./g, ',')
        newValue = newValue.replace(/[^0-9\,\-]/g, '').replace(/([-+]?\d*\,\d*)(\,)(\d*)/g, '$1$2').replace(/^([-+]?\d+,?\d{0,8})\d*$/, '$1')
        let partes = newValue.split(",");
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;      
    }else{
        return ''
    }
};

export function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function comprobarArchivo(imagen){
    let comprobante
    let extension  = imagen.name.split('.').pop()
    if( ['image/jpeg','image/gif','image/png'].indexOf(imagen.type) !== -1 ){
        var blob = imagen.slice(0, imagen.size, 'image/png'); 
        var newFile = new File([blob], `Comprobante${Math.random().toString(36)}.${extension}`, {type: 'image/png'})
        if(newFile.size >= 10000000){
            throw { status: false, mensaje: "El archivo adjunto es muy pesado, se recomienda adjuntar una imagen o un pdf más liviano" }
        }else{
            comprobante = newFile
            return { status: true, comprobante }
        }
    }else if( ['application/pdf'].indexOf(imagen.type) !== -1) {
        var blob = imagen.slice(0, imagen.size, 'application/pdf'); 
        var newFile = new File([blob], `Comprobante${Math.random().toString(36)}.${extension}`, {type: 'application/pdf'})
        if(newFile.size >= 2000000){
            throw { status: false, mensaje: "El archivo adjunto es muy pesado, se recomienda adjuntar una imagen o un pdf más liviano" }
        }else{
            comprobante = newFile
            return { status: true, comprobante }
        }
    }else{
        return { status: false, mensaje: "El archivo adjunto debe ser PDF o Imagen, intente nuevamente" }
    } 
}

export async function handleImageUpload(img) {
    const imageFile = img
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile; // write your own logic
    } catch (error) {
      throw error;
    }
  }

  export const paises = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
]

export const formatoRut = rut => {
    var textoRut = rut.replace(/[\W\s\._\-a-jA-Jl-zL-Z]+/g, '');
    var fullRut = "";
    
    if (textoRut.length <= 1) {
        if (textoRut == "k") {
            fullRut = "K";
        } else {
            fullRut = textoRut;
        }
    } else {
        var cont = 0;
        for (var i = (textoRut.length - 1); i >= 0; i--) {
            if (i == (textoRut.length - 1)) {
                if (textoRut.charAt(i) == "k") {
                    fullRut = "-K";
                } else {
                    fullRut = "-" + textoRut.charAt(i);
                }
            } else {
            if (cont == 3) {
                fullRut = "." + fullRut;
                cont = 0;
            }
            fullRut = textoRut.charAt(i) + fullRut;
            cont++;
            }
        }
    }
    return fullRut
}

export const checkRut = (rut = "") =>  {
    
    // Despejar Puntos
    var valor = rut.replace(/\./g,'');
    // Despejar Guión
    valor = valor.replace(/\-/g,'');
    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
    rut = cuerpo + '-'+ dv
    
    if(cuerpo.length < 7) { 
        return false;  
    }
  
    var suma = 0;
    var multiplo = 2;
    
    // Para cada dígito del Cuerpo
    for( var i=1;i<=cuerpo.length;i++) {    
        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * valor.charAt(cuerpo.length - i);
        // Sumar al Contador General
        suma = suma + index;
        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { 
            multiplo = multiplo + 1; 
        } else { 
            multiplo = 2; 
        }
    }
    
    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);
    
    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;
    
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado != dv) { 
        return false; 
    }
  
    return true
}

export const validate = (values = {}, props) => {
    const errors = {}
    const requiredFields = [
      'nombre',
      'rut',
      'banco',
      'tipoCuenta',
      'cuenta',
      'email',
      'idTipo',
      'idNumero',
      'codigo',
    ]
    requiredFields.forEach(field => {
    if (!values[field]) {
        errors[field] = true
      }
    })
  
    if (values.nombre && values.nombre.length <= 3 ){
      errors.nombre = "Nombre inválido"
    }
    
    if (values.rut && !checkRut(values.rut) ) {
      errors.rut = "Rut Inválido"
    }
  
    if (values.cuenta){
        switch (props.pais) {
            case "Venezuela":
                if(values.cuenta.length < 16){
                    errors.cuenta = "Cuenta Inválida"
                }
                break;
            case "Chile":
                if(values.cuenta.length < 5){
                    errors.cuenta = "Cuenta Inválida"
                }
                break;
            case "Panama":
                if(values.cuenta.length < 2){
                    errors.cuenta = "Cuenta Inválida"
                }
                break;
            default:
                break;
        }
    }
  
    if (values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    return errors
}

export const numeroCuentaVzla = value => {
    let valor = value.replace(/[^0-9]+/g, "")
    if(valor.length > 16){
        return valor.substring(0,16)
    }else{
        return valor
    }
}
export const numeroCuentaChile = value => {
    let valor = value.replace(/[^0-9]+/g, "")
    if(valor.length > 20){
        return valor.substring(0,20)
    }else{
        return valor
    }
}
export const numeroCuentaPnm = value => {
    let valor = value.replace(/[^0-9]+/g, "")
    if(valor.length > 18){
        return valor.substring(0,18)
    }else{
        return valor
    }
}

export const validateFile = (imagen) => {
    if( ['image/jpeg','image/gif','image/png'].indexOf(imagen.type) !== -1 ){
        var blob = imagen.slice(0, imagen.size, 'image/png'); 
        var newFile = new File([blob], imagen.name)
        if(newFile.size >= 10000000){
            throw { data: {errors: "Una de las imagenes es muy pesada por favor intente con imagenes mas livianas" }}
        }
    }else if( ['application/pdf'].indexOf(imagen.type) !== -1) {
        var blob = imagen.slice(0, imagen.size, 'application/pdf'); 
        var newFile = new File([blob], imagen.name)
        if(newFile.size >= 2000000){
            throw { data: { errors: "Uno de los archivos es muy pesado por favor intente de nuevo con archivos mas livianos" } }
        }
    }else{
        throw { data: { errors:"El archivo adjunto debe ser PDF o Imagen, intente nuevamente" } }
    }
    return
}

export const numeroDecimal = value => value && value.replace(/\./g, '');