import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Grid, Typography, Paper, Box } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import FormBusquedaBeneficiario from '../../components/FormBusquedaBeneficiario'
import TablaBeneficiarios from './tablaBeneficiarios'
import { fetchPaises } from '../../ducks/Paises'
import { buscarBeneficiarios } from '../../ducks/Beneficiarios'

const Cuentas = props => {
    const dispatch = useDispatch()

    const cargarUsuarios = async values => {
        await dispatch(buscarBeneficiarios(values))
    }

    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Cuentas</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Box m={2}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xl={6} lg={6} sm={12}>
                        <Paper>
                            <Grid container justify="center" alignItems="center" >
                                <Typography variant="h6">Busqueda de Cuenta</Typography>
                            </Grid>
                            <Box p={2}>
                                <FormBusquedaBeneficiario onSubmit={values => cargarUsuarios(values)} />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} lg={12} xl={12}>
                    <TablaBeneficiarios
                        id={true} 
                        agregar={true} 
                        acciones={true} 
                        nombre={true}
                        banco={true}
                        pais={true}
                        activo={true}
                        cuenta={true}
                        editar={true}
                        borrar={true}
                        activar={true}
                        email={true}
                        cedula={true}/>
                </Grid>
            </Grid>
        </Box>
    )

}

export default Cuentas