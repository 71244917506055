import axios from 'axios'
import S3FileUpload from 'react-s3';
import { store } from '../index'
import { resetUsuario } from '../ducks/Usuario'

const config = {
    bucketName: 'aguacate',
    //bucketName: 'aguacate-dev',
    //dirName: 'photos', /* optional */
    region: 'sa-east-1',
    accessKeyId: 'AKIAS6UF422PJZABO3HX',
    secretAccessKey: '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',
}

const baseUrl = process.env.REACT_APP_BACKEND_URL+"/api"

async function request(url,method,data, uid_request, client_request, token_request ){
    
    const uid = uid_request ? uid_request : localStorage.getItem("uid")
    const token = token_request ? token_request :  localStorage.getItem("token");
    const client = client_request ? client_request : localStorage.getItem("client");

    try {
        const response = await axios({
            method,
            url: `${baseUrl}${url}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token-type':'Bearer',
                'client': client ? client : "",
                'uid': uid ? uid : "",
                'access-token': token ? token : "",
            },
            data: data ? data : undefined
        })
        const newToken = response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        return response
    } catch (e) {
        if(e.response.status === 401){
            store.dispatch(resetUsuario())
            throw e.response
        }
        const newToken = e.response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        throw e.response
    }
}

async function requestArchivo(url,method,data, uid_request, client_request, token_request ){
    
    const uid = uid_request ? uid_request : localStorage.getItem("uid")
    const token = token_request ? token_request :  localStorage.getItem("token");
    const client = client_request ? client_request : localStorage.getItem("client");
    var formData = new FormData()
    var images = data.images
    var length = images.length
    formData.append('transactions', JSON.stringify(data.transactions))
    for (var x = 0; x < length; x++) {
        formData.append(`images[${x}]`, images[x]);
    }

    try {
        const response = await axios({
            method,
            url: `${baseUrl}${url}`,
            headers: {
                'Accept': 'application/json',
                'token-type':'Bearer',
                'client': client ? client : "",
                'uid': uid ? uid : "",
                'access-token': token ? token : "",
            },
            data: formData ? formData : undefined
        })
        const newToken = response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        return response
    }catch(e){
        if(e.response.status === 401){
            store.dispatch(resetUsuario())
            throw e.response
        }
        const newToken = e.response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        throw e.response

    }
}

async function requestImagesRails( url,method,data, uid_request, client_request, token_request ){
    try {
        const uid = uid_request ? uid_request : localStorage.getItem("uid")
        const token = token_request ? token_request :  localStorage.getItem("token");
        const client = client_request ? client_request : localStorage.getItem("client");

        var formData = new FormData()
        var images = data.image
        var length = images.length
        
        formData.append('data', JSON.stringify(data.data))

        for (var x = 0; x < length; x++) {
            formData.append(`images[${x}]`, images[x]);
        }

        const response = await axios({
            method,
            url: `${baseUrl}${url}`,
            headers:{
                'Accept': 'application/json',
                'token-type':'Bearer',
                'client': client ? client : "",
                'uid': uid ? uid : "",
                'access-token': token ? token : "",
            },
            data: formData ? formData : undefined
        })
        const newToken = response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        return response
    } catch (e) {
        console.log(e)
        if(e.response.status === 401){
            store.dispatch(resetUsuario())
            throw e.response
        }
        const newToken = e.response.headers['access-token']
        if(newToken){
            localStorage.setItem('token', newToken)
        }
        throw e.response
    }
}

async function requestImage(data) {
    try {
        const response = await S3FileUpload.uploadFile(data.imagen, { ...config, dirName: data.id})
        return response
    } catch (error) {
        if(error.status === 403){
            throw { data: {errors:"Ocurrió un problema de permisos al subir su imagen, contacte al admin" }}
        }else{
            throw { data: {errors:"Ocurrió un problema intentando subir su archivo, intente de nuevo por favor" }}
        }
    }
}

export function postImage(data){
    return requestImage(data)
}

export function postImageRequest(url,data){
    return requestImagesRails(url, 'POST', data);
}

export function postRequest(url,data){
    return request(url, 'POST', data);
}

export function putRequest(url, id, data, uid, client, access_token){
    if (id){
        return request(`${url}/${id}`, 'PUT', data);
    }else{
        return request(`${url}`, 'PUT', data, uid, client, access_token)
    }
}

export function getRequest(url,id){
    if (id)
        return request(`${url}/${id}`)
    else
        return request(`${url}`)
}

export function deleteRequest(url, id, uid, client, token){
    if (id){
        return request (`${url}/${id}`, 'DELETE')
    }else{
        return request (`${url}`, 'DELETE', null, uid, client, token)
    }
}

export function postFile(url, data) {
    return requestArchivo(url, 'POST', data);
}
export function postRequestImage(url, data) {
    return requestArchivo(url, 'POST', data);
}