import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Box } from '@material-ui/core'

import Backdrop from '../../components/Backdrop'
import Tabla from '../../components/Tabla'
import MenuButton from '../../components/MenuButton/MenuButton'
import FormPaises from '../../components/FormPaises'
import Layout from '../../components/Layout'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'

import { fetchPaises, editPaises, borrarPais, crearPais, resetErrorPaises } from '../../ducks/Paises'

const Paises = props => {

    const [ modalCrear, setModalCrear ] = useState(false)
    const [ modalEditar, setModalEditar] = useState(false)
    const [ modalBorrar, setModalBorrar] = useState(false)
    const [ paisSeleccionado, setPaisSeleccionado ] = useState(false)

    const paises = useSelector(state => state.Paises)
    const loading = useSelector(state => state.Paises.fetching)
    const dispatch = useDispatch()

    const fetchData = () => {
        dispatch(fetchPaises())
    }
    
    useEffect(() => {
        fetchData() 
    },[])
    
    return (
        <Layout menu={false}>
            <Grid 
                container 
                justify="center"
                alignItems="center">
                <Typography variant="h5">Paises</Typography>
            </Grid>
            <Tabla
                agregar={() => {setPaisSeleccionado(false);setModalCrear(true)}}
                columnas={[
                    { title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'ID', field: 'id', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Nombre', field: 'nombre',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Descripción', field: 'descripcion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Origen', field: 'origin',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Destino', field: 'destiny',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Activo', field: 'active',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={paises.data.map(x => ({
                    ...x,
                    origin: x.origin ? "SI" : "NO",
                    destiny: x.destiny ? "SI" : "NO",
                    active: x.active ? "SI" : "NO",
                    accion: 
                        <MenuButton 
                            buttons={[
                                {
                                    name:"Editar",
                                    action: () => {setPaisSeleccionado(x); setModalEditar(true)}
                                },
                                {
                                    name: x.active ? "Desactivar" : "Activar",
                                    action: () => {dispatch(editPaises({ ...x, active: !x.active}))}
                                },
                                {
                                    name:"Borrar",
                                    action: () => {setPaisSeleccionado(x); setModalBorrar(true)}
                                }
                            ]}
                        />,
            }))} />

            <Modal
                open={modalCrear}
                title="Crear País"
                close={true}
                handleClose={() => setModalCrear(false)}>
                    <FormPaises
                        onSubmit={values => {
                            dispatch(crearPais(values))
                            setModalCrear(false)
                        }} 
                        paises={paises}/>

            </Modal>

            <Modal
                open={modalEditar}
                title="Editar País"
                close={true}
                handleClose={() => setModalEditar(false)}>
                    <FormPaises 
                        onSubmit={values => {
                            dispatch(editPaises({ id: paisSeleccionado.id , ...values}))
                            setModalEditar(false)
                        }}
                        initialValues={{
                            nombre:  paisSeleccionado && paisSeleccionado.nombre,
                            descripcion:  paisSeleccionado && paisSeleccionado.descripcion,
                            origin:  paisSeleccionado ? paisSeleccionado.origin : true,
                            destiny:  paisSeleccionado ? paisSeleccionado.destiny : true
                        }}
                        paisSeleccionado={paisSeleccionado}/>

            </Modal>

            <Modal
                open={modalBorrar}
                title="Confirmación"
                close={true}
                action={() => {dispatch(borrarPais(paisSeleccionado.id)); setModalBorrar(false)}}
                handleClose={() => setModalBorrar(false)}>
                    ¿Está seguro de que desea borrar el pais seleccionado?
            </Modal>

            <Snackbar 
                open={paises.error && paises.error.length > 0 ? true : false}
                handleClose={() => {dispatch(resetErrorPaises())}}
                message={paises.error?.map(error => error)}/>
            
            <Backdrop open={loading} />
        </Layout>
    )
}

export default Paises