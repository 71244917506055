import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { Box, Hidden, Grid, Typography, IconButton, Paper, Button } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { resetTransacciones } from '../../ducks/Transacciones'
import { fetchBalances, addBalances } from '../../ducks/Balances'
import { history } from '../../index';
import { currency2decimal, Banco } from '../../services/utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import MenuCajeros from './menuCajeros'
import FormBusquedaFecha from '../../components/FormBusquedaFecha'
import Tabla from '../../components/Tabla'
import Backdrop from '../../components/Backdrop'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(10),
          marginLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(15),
          marginLeft: theme.spacing(10),
          marginBottom: theme.spacing(5),
          marginRight: theme.spacing(5),
        }
    },
    title: {
      flex: 1,
      textAlign: 'center'
    },

  }));

const Workingaccounts = props => {
    const classes = useStyles();
    const { match } = props
    const [ permiso, setPermiso ] = useState(false)
    const [ limit, setLimit ] = useState(40)
    const [ offset, setOffset ] = useState(0)
    const workingaccounts = useSelector( state => state.Workingaccounts )
    const balances = useSelector( state => state.Balances )
    const loading = useSelector( state => state.Workingaccounts.fetching || state.Balances.fetching )
    const suma = useSelector( state => state.Balances.data.map(x => x.comision).length > 0 ? state.Balances.data.map(x => x.comision).reduce((a,b) => a+b) : 0)
    const dispatch = useDispatch()

    if(permiso){
        const cuenta = workingaccounts.data.find(x => x.id === parseInt(match.params.id)) && workingaccounts.data.find(x => x.id === parseInt(match.params.id)).active
        if( !cuenta ){
            history.push("/")
        }
    }

    const cargarMas = () => {
        dispatch(addBalances({cuenta: match.params.id, limit: limit, offset: offset }))
        setOffset(offset+40)
    }

    const fetchData = async () => {
        await dispatch(fetchWorkingaccounts({active: true}))
        dispatch(fetchBalances({cuenta: match.params.id, limit: limit, offset: 0 }))
        dispatch(resetTransacciones())
        setOffset(40)
        setPermiso(true)
    }

    useEffect(function effectFunction(){
        fetchData()
    },[])

    return(
        <Grid className={classes.root}>
            <Grid container>
                <Typography className={classes.title} variant="h5">
                    Transacciones Realizadas
                </Typography>
            </Grid>
            <Navbar />
            <Hidden smDown>
                <MenuCajeros id={match.params.id} />
            </Hidden>
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>

            <Grid container>
                <Typography className={classes.title} variant="h5">
                    Balance
                </Typography>
            </Grid>

            <Tabla 
                rowStyle={ (rowData, index) => {
                    if(index === 0) {
                        return {color: 'red'};
                    }
                    return {};
                    }}
                sorting={false}
                loading={balances.fetching}
                columnas={[
                    { title: 'Total Ganancia', field: 'ganancia', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Total', field: 'saldoTotal', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Disp.', field: 'saldoDisp', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Deudor', field: 'saldoDeudor',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Tip. Transaccion', field: 'tipoTransaccion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Monto', field: 'monto',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Monto+Com.', field: 'montoCom',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Ganancia', field: 'comision',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Id. Transacción', field: 'id',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Benef.', field: 'beneficiario',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Banco', field: 'banco',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Cuenta', field: 'cuenta',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Ref.', field: 'referencia',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Comentario', field: 'motivo',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Fecha', field: 'created_at',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={balances.data.map(x => ({
                    ...x,
                    saldoTotal: x.saldoTotal ? currency2decimal(x.saldoTotal) : "",
                    saldoDisp: x.saldoDisp ? currency2decimal(x.saldoDisp) : "",
                    saldoDeudor: x.saldoDeudor ? currency2decimal(x.saldoDeudor) : "",
                    monto: x.monto ? currency2decimal(x.monto) : "",
                    montoCom: x.montoCom ? currency2decimal(x.montoCom) : "",
                    comision: x.comision ? currency2decimal(x.comision) : "",
                    id: x.clpbstransaction ? x.clpbstransaction.id : "",
                    beneficiario: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.nombre :"") : "",
                    referencia: x.clpbstransaction ? x.clpbstransaction.referencia : "",
                    banco: x.clpbstransaction ? (x.clpbstransaction.account ? Banco(x.clpbstransaction.account.banco) :"") : "",
                    cuenta: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.cuenta :"") : "",
                    created_at: moment(x.created_at).format('DD-MM-YYYY'),
                    ganancia: currency2decimal(suma)
            }))} />

            {balances.data.length % 40 === 0 &&  
            <Grid item xs={12} lg={12} xl={12} style={{ margin: '10px' }}>
                <Grid container justify="center" alignItems="center"  >
                    <Button onClick={() => cargarMas()} variant="contained" color="primary" >
                        Mostrar más
                    </Button>
                </Grid>
            </Grid>}
            <Backdrop open={loading}/>
        </Grid>
    );
}

export default Workingaccounts