import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { delHOR, addHOR , editModalHOR, resetStoreHOR } from './hors.js'

const t = makeTypes('modales')
const ADD = t('add').single()
const DEL = t('del').single()
const EDIT = t('edit').single()
const RESETSTORE = 'reset'


export const abrirModal = values => mac(ADD, 'payload')({
    id: Math.random().toString(36),
    ...values
})
export const cerrarModal = mac(DEL, 'payload')
export const editarModal = values =>  mac(EDIT, 'payload')({
    ...values
})

const initialState = {
    data: [],
}

const addReducer = createReducer(initialState, addHOR({ ADD }))
const delReducer = createReducer(initialState, delHOR({ DEL }))
const editReducer = createReducer(initialState, editModalHOR({ EDIT }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(delReducer, addReducer, editReducer, resetStoreReducer)

