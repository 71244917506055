import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core'

import HomeLayout from '../HomeLayout/HomeLayout'
import { Titlespan } from '../common/title/index';
import { history } from '../../index';
import './Nosotros.scss';

function Nosotros(props) {
    return (
        <React.Fragment>
            <HomeLayout >
                <div id="main-content">
                    <section className={`banner-wrapper2 demo1`}>
                        <div className="banner-outer">
                            <>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <div className="banner-content2">
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="h4">¡Somos lo que necesitas!</Typography>}
                                                Name=""
                                            />
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="body1">Con más de 5 años en el mercado somos un equipo de trabajo que se ha profesionalizado en entender que para ti lo más importante es la seguridad, la confianza y la responsabilidad en los servicios que ofrecemos</Typography>}
                                                Name=""
                                            />
                                            <div className="banner-main-title2">
                                                <Titlespan
                                                    Class="banner-main-title"
                                                    Label={<Typography color='inherit' variant="body1">Conocemos tus necesidades y compromisos familiares, de negocios y ahorro; por ello trabajamos siempre por ofrecer la mayor calidad en todas las opciones que te brindamos para transferencias internacionalies y remesas</Typography>}
                                                    Name=""
                                                />
                                            </div>
                                            <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/clientes/cambio')} >Enviar Dinero</Button>
                                            {false &&<div className="banner-btn-wrapper2">

                                                <Button onClick={() => history.push("/clientes/cambio")}
                                                    variant="outlined" color="primary">
                                                    Envía con nosotros
                                                </Button>
                                            </div>}
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </section>
                </div>
            </HomeLayout>
        </React.Fragment>
        
    );
}

export default Nosotros;
