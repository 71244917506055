import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, Container } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import Loading from './Loading'
import renderCheckbox from './Checkbox'
import { addMillsSep } from '../services/utils'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'descripcion',
    'tasa',
    'origen_id',
    'destino_id',
    'rango_id',
    'profile_id',
    'tiempo'
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })


  if (values.nombre && values.nombre.length < 3 ){
    errors.nombre = "Nombre debe tener mas caracteres"
  }

  if (values.descripcion && values.descripcion.length < 3 ){
    errors.descripcion = "Descripción debe tener mas caracteres"
  }

  if (values.minimo && values.maximo && (parseInt(values.minimo) > parseInt(values.maximo) ) ){
    errors.minimo = "valor minimo no puede ser mayor que el maximo"
    errors.maximo = "valor minimo no puede ser mayor que el maximo"
  }



  return errors
}

const currency = value => {
    if(typeof(value) === "number"){
      value = value.toString()
    }
    let stringValue = value && value.replace(/\./g, '').replace(/\,/g, '');
    let valor = stringValue && parseFloat(stringValue);
    valor = valor && valor.toLocaleString('en-US').replace(/\,/g, '.');
    return valor ? valor : '';
};

const currency2decimal = value => {
    if(value){
        let newValue = value.toString()
        newValue = newValue.replace(/\./g, ',')
        newValue = newValue.replace(/[^0-9\,]/g, '').replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$2$3$4$5').replace(/^(\d+,?\d{0,5})\d*$/, '$1');
        let partes = newValue.split(",");
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;      
    }else{
        return ''
    }
};
  
const numeroDecimal = value => value && value.replace(/\./g, '');
const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}
let FormTasas = props => {
    const { handleSubmit, perfil, cargando, origen, paises, rangos, perfiles, initialValues, change, tasaSeleccionada } = props
        return(
          <Container>
                <Grid container justify="center" alignItems="center"  >
                  <Typography variant="h6">{tasaSeleccionada ? "Editar Tasa" : "Agregar Tasa"}</Typography>
                </Grid>
                <form noValidate onSubmit={handleSubmit} >
                  <Grid container >
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                type="text"
                                name="nombre"
                                label="Nombre"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                name="tasa"
                                label="Tasa"
                                format={currency2decimal}
                                normalize={numeroDecimal}
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <Grid container alignItems="center"  >
                        <Field 
                          name="efectivo" 
                          component={renderCheckbox} 
                          label="Efectivo" 
                        />
                        <Field 
                          name="proporcional" 
                          component={renderCheckbox} 
                          label="Proporcional" 
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                name="origen_id"
                                defaultValue={initialValues.origen_id}
                                disableClearable={true}
                                label="Origen"
                                options={paises.data.filter(x => x.active)}
                                component={SelectSearch}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                name="destino_id"
                                defaultValue={initialValues.destino_id}
                                disableClearable={true}
                                label="Destino"
                                options={paises.data.filter(x => x.active)}
                                component={SelectSearch}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                              <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                etiqueta="rango"
                                name="rango_id"
                                defaultValue={initialValues.rango_id}
                                disableClearable={true}
                                label="Rango"
                                options={rangos.data.filter(x => x.active).filter(x => x.country_id === (origen && (origen.id ? origen.id : origen)))}
                                component={SelectSearch}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                              <Field
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                name="profile_id"
                                defaultValue={initialValues.profile_id}
                                disableClearable={true}
                                label="Perfil"
                                options={perfiles.data.filter(x => x.active)}
                                component={SelectSearch}/>
                        </Grid>
                    </Grid>
                    {perfil && (perfil.nombre ? perfil.nombre !== "Admin" : perfiles.data.find(x => x.id === perfil).nombre !== "Admin") && 
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                type="text"
                                name="descripcion"
                                label="Descripción (equivalencia ej: 1CLP = 500Bs)"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>}
                    {perfil && (perfil.nombre ? perfil.nombre !== "Admin" : perfiles.data.find(x => x.id === perfil).nombre !== "Admin") && 
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                required
                                type="text"
                                name="tiempo"
                                label="Tiempo máximo"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>}

                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                          { cargando ? <Loading /> :
                            (tasaSeleccionada ? 
                            <Button variant="contained" color="primary" type="submit">
                                Editar Tasa
                            </Button>
                            :
                            <Button variant="contained" color="primary" type="submit">
                                Agregar Tasa
                            </Button>)}
                        </Grid>
                    </Grid>
                  </Grid>
                </form>
            </Container>
        )
    }


FormTasas = reduxForm({
  form: 'tasas',
})(FormTasas);

const selector = formValueSelector('tasas')

export default connect( state => {
    const origen = selector(state, 'origen_id')
    const perfil = selector(state, 'profile_id')
    return {
    origen,
    perfil,
    initialValues: {
      nombre:  state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).nombre,
      descripcion:  state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).descripcion,
      tiempo:  state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).tiempo,
      tasa:  state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).tasa.toString(),
      origen_id:  state.Paises.data.find(x => x.id === (state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).origen_id) ),
      destino_id:  state.Paises.data.find(x => x.id === (state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).destino_id) ),
      rango_id:  state.Rangos.data.find(x => x.id === (state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).rango_id) ),
      profile_id:  state.Profiles.data.find(x => x.id === (state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).profile_id) ),
      efectivo: state.Tasas.data.find(x => x.selected === true) && state.Tasas.data.find(x => x.selected === true).efectivo,
      proporcional: state.Tasas.data.find(x => x.selected === true) ? state.Tasas.data.find(x => x.selected === true).proporcional : true
    },
    enableReinitialize : true,
    validate,
}})(FormTasas)
