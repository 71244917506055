import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid } from '@material-ui/core'
import FormCuenta from '../components/FormCuenta'
import { searchWorkingaccounts } from '../ducks/Workingaccounts'
import { verificarComprobante } from '../ducks/Comprobante'
import { abrirModal } from '../ducks/Modales'
import { editarEstadoTransaccion, transaccionesAdmin } from '../ducks/Transacciones'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const FormularioCuenta = props => {

    const { pais, verificar, idTransaccion, cerrarModalVerificar } = props
    const workingaccounts = useSelector( state => state.Workingaccounts)
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchData(){
            await dispatch(searchWorkingaccounts(pais))
        }
        fetchData()
    },[])

    return(
        <Grid container>
            <Box p={4}>
                <FormCuenta pais={pais} workingaccounts={workingaccounts} onSubmit={async values => {
                    const comprobantes = await dispatch(verificarComprobante(values))
                    if(comprobantes.length >0){
                        dispatch(abrirModal({ title:"Coincidencias de comprobante", close:true, aceptar: "Agregar igual", action: () => {
                            if(verificar){
                                dispatch(editarEstadoTransaccion(idTransaccion,{ ...values, estado: "Pendiente" }))
                                cerrarModalVerificar()
                            }
                            else{
                                dispatch(transaccionesAdmin(values))
                            }
                        },
                            body:<div>
                                {comprobantes.map(x => 
                                    <ul key={x.id}>
                                        <li>Cliente: {x.customer ? x.customer.cliente : (x.user ? x.user.nombre+" "+x.user.apellido : "-")}</li>
                                        <li>Beneficiario: {x.account ? x.account.nombre : ""}</li>
                                        <li>CantidadPesos: {x.cantidadPesos ? x.cantidadPesos : ""}</li>
                                        <li>Fecha: {x.created_at ? moment(x.created_at).format('DD-MM-YYYY') : ""}</li>
                                    </ul>
                                )}
                                </div>
                        }))
                    }else{
                        if(verificar){
                            dispatch(editarEstadoTransaccion(idTransaccion,{ ...values, estado: "Pendiente" }))
                            cerrarModalVerificar()
                        }
                        else{
                            dispatch(transaccionesAdmin(values))
                        }
                    }
                    }} />
            </Box>
        </Grid>
    )
}

export default FormularioCuenta
