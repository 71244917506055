import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, resetStoreHOR, resetHOR, resetErrorHOR, addFetch } from './hors.js'
import { postRequest, getRequest, deleteRequest, putRequest, postImage, postImageRequest } from '../services/api'
import { abrirModal, cerrarModal } from './Modales'
import { fetchProfiles } from './Profiles'
import { asignarPaso } from './Pasos'
import { crearEmpresa, editarEmpresa } from './Empresas'
import moment from 'moment'
import 'moment/locale/es'
import { comprobarArchivo, handleImageUpload } from '../services/utils'
moment.locale('es')

const t = makeTypes('usuario')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const RESET = t('reset').single()
const RESETSTORE = 'reset'
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
export const resetStore = mac(RESETSTORE)
export const resetUsuario = mac(RESET)
export const resetErrorUsuario = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const resetUsuarioReducer = createReducer(initialState, resetHOR({ RESET }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState }))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers( fetchReducer, addReducer, editReducer, resetStoreReducer, resetUsuarioReducer, resetErrorReducer)

export const editUsuario = (values,navigate) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await postImageRequest('/users/edit-user', {data: values, image: values.images} )
        dispatch(fetchActions.success([response.data]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
} 

export const asociarEmpresa = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await dispatch(crearEmpresa(values))
        dispatch(fetchActions.success([response.data]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        dispatch(abrirModal({ title:"Notificación", body: (error.data ? (error.data.errors ? error.data.errors : "Ocurrió un error, contacte al admin o intente más tarde") : "Ocurrió un error, contacte al admin o intente más tarde"), close: true}))
    }
} 

export const editarEmpresaAsociada = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await dispatch(editarEmpresa(values))
        dispatch(fetchActions.success([response.data]))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        dispatch(abrirModal({ title:"Notificación", body: (error.data ? (error.data.errors ? error.data.errors : "Ocurrió un error, contacte al admin o intente más tarde") : "Ocurrió un error, contacte al admin o intente más tarde"), close: true}))
    }
} 

export const editUsuarioActual = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    const state= getState()
    const usuarioActual = state.Usuario.data[0]
    try {
        let imagen1
        let imagen2
        let comprobante1
        let comprobante2
        let archivo1
        let archivo2
        let response1
        let response2
        if(values.imagen2){
            imagen1 = comprobarArchivo(values.imagen1)
            imagen2 = comprobarArchivo(values.imagen2)
            if (!imagen1.status || !imagen2.status){
                dispatch(abrirModal({ title:"Notificación", close: true, body: (!imagen1.status ? imagen1.mensaje : "") + !imagen1.status ? imagen1.mensaje : ""  }))
            }else{
                if(imagen2.comprobante.type === 'image/jpeg'){
                    comprobante2 = await handleImageUpload(imagen2.comprobante)
                }else{
                    comprobante2 = imagen2.comprobante
                }
                if(imagen1.comprobante.type === 'image/jpeg'){
                    comprobante1 = await handleImageUpload(imagen1.comprobante)
                }else{
                    comprobante1 = imagen1.comprobante
                }
                archivo2 = await postImage({id: usuarioActual.id, imagen: comprobante2 })
                archivo1 = await postImage({id: usuarioActual.id, imagen: comprobante1 })
                response2 = await postRequest('/users_files', { nombre:"Identificacion cara 2", user_id: usuarioActual.id, descripcion: "Identificacion cara 2", archivo: archivo2.location })
                response1 = await postRequest('/users_files', { nombre:"Identificacion cara 1", user_id: usuarioActual.id, descripcion: "Identificacion cara 1", archivo: archivo1.location })
                const response = await putRequest('/users', usuarioActual.id, { ...values, rut: values.tipo_id === "Rut" ? values.rut : values.pasaporte } )
                dispatch(editActions.success({ ...response.data, archivo: true, profile: state.Profiles.data.find(x => x.id === response.data.profile_id) }))
                state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
            }
        }else if(values.imagen1){
            imagen1 = comprobarArchivo(values.imagen1)
            if (!imagen1.status){
                dispatch(abrirModal({ title:"Notificación", close: true, body: (!imagen1.status ? imagen1.mensaje : "") }))
            }else{
                if(imagen1.comprobante.type === 'image/jpeg'){
                    comprobante1 = await handleImageUpload(imagen1.comprobante)
                }else{
                    comprobante1 = imagen1.comprobante
                }
                archivo1 = await postImage({id: usuarioActual.id, imagen: comprobante1 })
                response1 = await postRequest('/users_files', { nombre:"Identificacion cara 1", user_id: usuarioActual.id, descripcion: "Identificacion cara 1", archivo: archivo1.location })
                const response = await putRequest('/users', usuarioActual.id, { ...values, rut: values.tipo_id === "Rut" ? values.rut : values.pasaporte } )
                dispatch(editActions.success({ ...response.data, archivo: true, profile: state.Profiles.data.find(x => x.id === response.data.profile_id) }))
                state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
            }
        }else{
            const response = await putRequest('/users', usuarioActual.id, { ...values, rut: values.tipo_id === "Rut" ? values.rut : values.pasaporte } )
            dispatch(editActions.success({ ...response.data, archivo:true, profile: state.Profiles.data.find(x => x.id === response.data.profile_id) }))
            if(!usuarioActual.first_session){
                state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
            }
        }
    } catch (error) {
        dispatch(editActions.error())
        if(error.data && error.data.errors && error.data.errors.email){
            dispatch(abrirModal({ title:"Notificación", body: "El correo ingresado ya existe", close: true }))
        }else if(error.data && error.data.errors && error.data.errors.rut ){
            dispatch(abrirModal({ title:"Notificación", body: "El rut ingresado ya existe", close:true }))
        }else if(error.mensaje){
            dispatch(abrirModal({ title:"Notificación", body: error.mensaje, close:true }))
        }else{
            dispatch(abrirModal({ title:"Notificación", body: "Ocurrió un error, contacte al administrador", close:true }))
        }
    }
}

export const getUsuario = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await getRequest('/auth/validate_token')
        const response = await getRequest('/users/get-user')
        if(response.data.active){
            await dispatch(fetchProfiles())
            const state = getState()
            dispatch(fetchActions.success([{ ...response.data, archivo: response.data.UserFile.length > 0 ? true : false, profile: state.Profiles.data.find(x => x.id === response.data.profile_id)}]))
            return response.data
        }else{
            const uid_local = localStorage.getItem("uid");
            const token_local = localStorage.getItem("token");
            const client_local = localStorage.getItem("client");
            await deleteRequest('/auth/sign_out', '', uid_local, client_local, token_local )
            localStorage.removeItem("uid");
            localStorage.removeItem("client");
            localStorage.removeItem("token");
            dispatch(abrirModal({ title:"Notificación", body:"Su usuario ha sido desactivado, contacte al administrador", close: true}))
            dispatch(fetchActions.success([]))
        }
    } catch (error) {
        dispatch(fetchActions.error())
    }
}

export const loginUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        let response = await postRequest('/auth/sign_in', values)
        localStorage.setItem('uid',response.headers['uid'])
        localStorage.setItem('token',response.headers['access-token'])
        localStorage.setItem('client',response.headers['client'])
        response = await getRequest('/users/get-user')
        if(response.data.active){
            dispatch(addActions.success(response.data))
        }else{
            dispatch(addActions.success())
        }
    } catch (error) {
        console.log(error)
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const registerUsuario = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await postRequest('/auth', { ...values, fecha_actualizacion: moment().format(), confirm_success_url: process.env.REACT_APP_BACKEND_URL+'/login' })
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors.full_messages : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const registerUsuarioMayorista = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await dispatch(fetchProfiles())
        const state = getState()
        await postRequest('/auth', { ...values, profile_id: state.Profiles.data.find(x => x.nombre === "Mayorista").id, fecha_actualizacion: moment().format(), confirm_success_url: process.env.REACT_APP_BACKEND_URL+'/login' })
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors.full_messages : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const cerrarUsuario = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    const state = getState()
    try {
        const uid_local = localStorage.getItem("uid");
        const token_local = localStorage.getItem("token");
        const client_local = localStorage.getItem("client");
        await deleteRequest('/auth/sign_out', '', uid_local, client_local, token_local )
        localStorage.removeItem("uid");
        localStorage.removeItem("client");
        localStorage.removeItem("token");
        dispatch(fetchActions.success([]))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        dispatch(resetUsuario())
        dispatch(abrirModal({ title:"Notificación", body: "Su sesión se cerró con éxito", close:true }))
    } catch (error) {
        dispatch(fetchActions.error(error))
        dispatch(abrirModal({ title:"Notificación", body: "Ocurrió un error, contacte al administrador", close:true }))
    }       
}

export const emailConfirmacion = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await postRequest('/auth/confirmation', { email: values.email, confirm_success_url: process.env.REACT_APP_BACKEND_URL })
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? [error.data.errors] : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const solicitarPassword = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await postRequest('/auth/password', { email: values.email, redirect_url: process.env.REACT_APP_BACKEND_URL+'/password-reset'})
        dispatch(fetchActions.success([]))
    } catch (error) {
        console.log(error)

        dispatch(fetchActions.error(error.data ? (error.data.errors ? [error.data.errors] : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const resetPassword = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await putRequest('/auth/password', '', { password: values.password, password_confirmation: values.password_confirmation}, values.uid, values.client, values.access_token)
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(["Ocurrió un error, si el error persiste contáctenos"]))
    }
}