import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, CardContent, Paper, Hidden, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Modal from '../Notificacion2'
import MenuButton from '../MenuButton/MenuButton'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import Colors from '../../constants/Colors'
import { Banco } from '../../services/utils';

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    contentLeft:{
		flex:0.6,
        overflow: 'hidden',
        cursor: 'pointer',
        flexDirection: 'column'
    },
    contentRight:{
		flex: 0.4,
        overflow: 'hidden',
        flexDirection: 'column',
        textAlign: 'end'
    },
    icon: {
        fontSize: 70,
    },
    iconContainer:{
        alignSelf: 'center'
    }
});

const TarjetaCuentas = props => {
	const { cuenta, buttons, handleClick } = props
	const [ detail, setDetail ] = useState(false) 
	const paises = useSelector(state => state.Paises.data)
	const pais = paises?.find(x => x.id === cuenta.country_id) ? paises?.find(x => x.id === cuenta.country_id).nombre : ""
	const classes = useStyles();

	const handleDetalle = () => {
		setDetail(true)
	}

	return (
		<>
			<Card className={classes.root}>
				<Grid container direction="row">
					<Hidden smDown>
						<CardContent className={classes.iconContainer}>
							<Paper>
								<AccountBalanceIcon style={{ color: cuenta && cuenta.active ? Colors.primary : Colors.secondary}} className={classes.icon}/>
							</Paper>
						</CardContent>
					</Hidden>
					<CardContent className={classes.contentLeft} onClick={handleClick ? handleClick : handleDetalle}>
						<Typography variant='overline' gutterBottom>
							<strong>
								{cuenta ? cuenta.nombre + (cuenta.idTipo && cuenta.idNumero ? " - " + cuenta.idTipo+"-"+cuenta.idNumero : "") : ""}
							</strong>
						</Typography>
						<Typography variant='body2' gutterBottom>
								{Banco(cuenta ? cuenta.banco : "")}
						</Typography>
						<Typography variant='body2' gutterBottom>
							{cuenta ? cuenta.cuenta : ""}
						</Typography>
					</CardContent>
					<CardContent className={classes.contentRight}>
						<Typography variant='body2' gutterBottom>
							{ pais + "-" + (cuenta ? cuenta.tipoCuenta : "")}
						</Typography>
						{buttons && buttons.length > 0 && <MenuButton buttons={buttons}/>}
					</CardContent>
				</Grid>
			</Card>
			<Modal
				open={detail}
				title="Detalle Cuenta"
				close={true}
				handleClose={() => setDetail(false)}
			>
				<Typography variant='body2' gutterBottom>Nombre: {cuenta ? cuenta.nombre + (cuenta.idTipo && cuenta.idNumero ? "  " +cuenta.idTipo+"-"+cuenta.idNumero : "") : ""}</Typography>
				<Typography variant='body2' gutterBottom>Banco: {Banco(cuenta?.banco)}</Typography>
				<Typography variant='body2' gutterBottom>Cuenta: {cuenta?.tipoCuenta+"-"+cuenta?.cuenta}</Typography>
				<Typography variant='body2' gutterBottom>País Origen/País Destino: {pais}</Typography>
				<Typography variant='body2' gutterBottom>Email: {cuenta.email ? cuenta.email : "No aplica"}</Typography>
        	</Modal>
		</>
	);
}

TarjetaCuentas.propTypes = {
	cuenta: PropTypes.object.isRequired,
	buttons: PropTypes.array,
	handleClick: PropTypes.func
}

export default memo(TarjetaCuentas)
