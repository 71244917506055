import React, {useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, Container, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TextField from './TextField';
import RadioButton from './RadioButton';
import SelectSearch from './SelectSearch'
import Loading from '../components/Loading'
import { searchWorkingaccounts } from '../ducks/Workingaccounts'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'comprobante',
    'cuenta',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  return errors
}

const selector = formValueSelector('cuenta')

let FormCuenta = props => {
      const { handleSubmit, pais, submitting, change } = props
      const workingaccounts = useSelector(state => state.Workingaccounts)
      const dispatch = useDispatch()
      const cuenta = useSelector( state => selector(state, 'cuenta'))

      const fetchData = async () => {
        await dispatch(searchWorkingaccounts(pais))
      }

      useEffect(() => {
          fetchData()
      },[])
      return(
          <Container>
                <Grid container justify="center" alignItems="center"  >
                    <Typography variant="h6">Forma de Pago</Typography>
                </Grid>
                <form noValidate onSubmit={handleSubmit} >
                <Grid container  >
                    <Field
                    required
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="text"
                    change={change}
                    name="cuenta"
                    disableClearable={true}
                    label="Cuenta"
                    options={workingaccounts.data.filter(x => x.receiver === true).filter(x => x.active)}
                    component={SelectSearch}/>

                    { workingaccounts.data.find(x => x.id === cuenta) && workingaccounts.data.find(x => x.id === cuenta).nombre !== "Efectivo" &&
                        <Field
                            required
                            type="text"
                            name="comprobante"
                            label="Comprobante"
                            component={TextField}
                        />
                    }

                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            { submitting ? <Loading /> :
                            <Button variant="contained" color="primary" type="submit">
                                Agregar Transacción
                            </Button>}
                        </Grid>
                    </Grid>
                </Grid>
                </form>
            </Container>
        )
    }


export default FormCuenta = reduxForm({
  form: 'cuenta',
  enableReinitialize: true,
  validate,
})(FormCuenta);