import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Hidden
} from "@material-ui/core";
import Stepper from "../../components/Stepper";
import Navbar from "../Navbar/Navbar";
import MenuDetalle from "../../components/Drawer";
import MenuClientes from "../../components/MenuClientes";
import Calculadora from "../Calculadora";
import Backdrop from "../../components/Backdrop";
import ContenidoMenu from "../../components/ContenidoMenu";
import TarjetaCuentas from "../../components/TarjetaCuentas/TarjetaCuentas";
import TarjetaNueva from "../../components/TarjetaNueva";
import FormCalculadora from "../../components/FormCalculadora";
import TablaBeneficiariosSugeridos from "../TablaBeneficiariosSugeridos";
import BotonFlotante from "../../components/BotonFlotante";
import FormFile from "../../components/FormFile";
import Card from "../../components/Card";
import Modal from "../../components/Notificacion2";
import Snackbar from "../../components/Snackbar";
import FormularioBeneficiariosVzla from "../../components/FormularioBeneficiariosVzla";
import FormBeneficiarioChile from "../../components/FormBeneficiarioChile";
import FormBeneficiarioPnm from "../../components/FormBeneficiarioPnm";
import { resetClientes } from "../../ducks/Clientes";
import { asignarMensaje } from "../../ducks/Mensajes";
import { resetMensaje } from "../../ducks/Mensajes";
import {
  resetBeneficiarios,
  fetchBeneficiariosUsuarios,
  agregarBeneficiarioAUsuario,
  resetErrorBeneficiario,
  editCuentas, quitarDeUsuario
} from "../../ducks/Beneficiarios";
import {
  resetBeneficiariosSugeridos,
  fetchBeneficiariosSugeridos,
} from "../../ducks/BeneficiariosSugeridos";
import { asignarPaso } from "../../ducks/Pasos";
import { transaccionClientes, resetError } from "../../ducks/Transacciones";
import { getUsuario, editUsuario, resetErrorUsuario } from "../../ducks/Usuario";
import { asignarDetalle } from "../../ducks/DetallesCambio";
import { abrirMenuDerecha }from "../../ducks/MenuDerecha";
import { fetchArchivos } from "../../ducks/Archivos";
import { resetBeneficiariosSeleccionados, borrarBeneficiarioSeleccionado, agregarBeneficiarioSeleccionado } from "../../ducks/BeneficiariosSeleccionados";
import { Banco, currency, currency2decimal, paises as paisesConst } from "../../services/utils";
import BancosChile from '../../constants/BancosChile'
import BancosPnm from '../../constants/BancosPnm'
import BancosVzla from '../../constants/BancosVzla'
import TiposCuentaPnm from '../../constants/TiposCuentaPnm'
import TiposCuentaVzla from '../../constants/TiposCuentaVzla'
import TiposCuentasChile from '../../constants/TiposCuentasChile'
import FormUsuario from "../../components/FormUsuario";
import TiposCuenta from "../../constants/TiposCuenta";
import TiposId from "../../constants/TiposId";
import TiposIdVzla from "../../constants/TiposIdVzla";
import Profesiones from "../../constants/Profesiones";
import Sexos from "../../constants/Sexos";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import "moment/locale/es";
import TarjetaDetalle from "../../components/TarjetaDetalle";
moment.locale("es");

const useStyles = makeStyles( theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
      marginLeft: theme.spacing(10),
      marginBottom: theme.spacing(5),
      marginRight: theme.spacing(5),
    }
  }
}));

const AgregarCliente = (props) => {
  const classes = useStyles();
  const [ modalDeposito, setModalDeposito ] = useState(false)
  const [ cuentaSeleccionada, setCuentaSeleccionada ] = useState(false)
  const [ modalEditarCuenta, setModalEditarCuenta ] = useState(false)
  const [ modalBorrarCuenta, setModalBorrarCuenta ] = useState(false)
  const [ modalInfo, setModalInfo ] = useState(false)
  const [ info, setInfo ] = useState("")
  const [ crearCuenta, setCrearCuenta ] = useState(false)
  const [ modalEnviar, setModalEnviar ] = useState(false)
  const [ beneficiarioSeleccionado, setBeneficiarioSeleccionado ] = useState(null)
  const [ modalBeneficiariosSugeridos, setModalBeneficiariosSugeridos ] = useState(false)
  const tasas = useSelector( state => state.Tasas)
  const pasoActivo = useSelector((state) => state.Pasos);
  const beneficiariosSugeridos = useSelector(
    (state) => state.BeneficiariosSugeridos
  );
  const minimo = useSelector(state => Math.min( ...state.Tasas.data.filter(x => x.efectivo === state.DetalleCambio.data.efectivo).filter(x => ( (x.origen_id === state.DetalleCambio.data.pais_origen.id) && (x.destino_id === state.DetalleCambio.data.pais_destino.id))).map(x => x.rango.minimo) ))
  const suma = useSelector( state => state.BeneficiariosSeleccionados.data.map(x => x.origen).length > 0 ? state.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b) : 0)
  const detalle = useSelector((state) => state.DetalleCambio);
  const transacciones = useSelector((state) => state.Transacciones);
  const beneficiarios = useSelector((state) => state.Beneficiarios);
  const usuario = useSelector((state) => state.Usuario.data[0]);
  const usuarioError = useSelector((state) => state.Usuario.error);
  const menuIzquierda = useSelector((state) => state.Menu.data);
  const menuDerecha = useSelector((state) => state.MenuDerecha.data);
  const paises = useSelector((state) => state.Paises);
  const mensajes = useSelector((state) => state.Mensajes);
  const beneficiariosSeleccionados = useSelector(
    (state) => state.BeneficiariosSeleccionados
  );
  const menuEstatus = useSelector((state) => state.MenuDerecha.data);
  const loading = useSelector((state) => (state.Usuario.fetching || state.Beneficiarios.fetching || state.BeneficiariosSugeridos.fetching || state.Transacciones.fetching ));
  const dispatch = useDispatch();

  const resetData = async () => {
    if (pasoActivo.data !== "home") {
      dispatch(asignarPaso(0));
      await dispatch(getUsuario());
      await dispatch(fetchArchivos(usuario.id));
      dispatch(resetClientes());
      dispatch(resetBeneficiarios());
      dispatch(resetBeneficiariosSugeridos());
      dispatch(resetBeneficiariosSeleccionados());
    } else {
      if( ( (!usuario.verified) && (usuario.images.length === 0) && (!usuario.archivo) ) ){
        dispatch(asignarPaso(1));
        if (usuario.first_session) {
          setModalInfo(true)
          setInfo("Bienvenid@ a Aguacatecambios! te solicitaremos verificar todos tus datos. Luego de ser aprobada tu primera transacción ciertos datos personales no podrán ser modificados")
        }
      } else {
        dispatch(asignarPaso(2));
      }
    }
  };

  const handlePrimerPaso = async (values) => {
    await dispatch(fetchBeneficiariosUsuarios({ ...usuario, pais: values.pais_destino.id }));
    dispatch(asignarDetalle(values));
    if( ( (!usuario.verified) && (usuario.images.length === 0) && (!usuario.archivo) )){
      await dispatch(fetchBeneficiariosSugeridos({ ...usuario, pais: values.pais_destino.id }));
      dispatch(asignarPaso(pasoActivo.data + 1));
      if (usuario.first_session) {
        setModalInfo(true)
        setInfo("Bienvenid@ a Aguacatecambios! te solicitaremos verificar todos tus datos. Luego de ser aprobada tu primera transacción ciertos datos personales no podrán ser modificados")
      }
    } else {
      dispatch(asignarPaso(pasoActivo.data + 2));
    }
  };
  
  let cuenta;
  let formularioCrearCuenta;
  const paisSeleccionadoOrigen = paises.data.find( x => x.id === (detalle.data.pais_origen && detalle.data.pais_origen.id))
  if(paisSeleccionadoOrigen){
    switch (paisSeleccionadoOrigen.nombre) {
      case "Chile":
        cuenta = 
          <>
            <Typography>
              <strong>
                Realizar el deposito a la siguiente cuenta:
              </strong>
            </Typography>
            <ul>
              <li>Banco Estado</li>
              <li>Vista o Chequera Electrónica </li>
              <li>Aguacate Investments SpA</li>
              <li>35771203513</li>
              <li>Rut: 77.258.223-4</li>
            </ul>
            <Typography>
              <strong>
                Debe adjuntar su comprobante de pago aquí:
              </strong>
            </Typography>
          </>
        break;
    case "Panama":
      cuenta = 
        <>
          <Typography>
            <strong>
              Realizar el deposito a la siguiente cuenta:
            </strong>
          </Typography>
          <ul>
            <li>Banco Panama</li>
            <li>Cuenta de Ahorro</li>
            <li>Jonathan Quintero</li>
            <li>Cuenta: 201001762904</li>
          </ul>
          <Typography></Typography>
          <Typography>
            <strong>
              Debe adjuntar su comprobante de pago aquí:
            </strong>
          </Typography>
        </>
      break;
    case "Venezuela":
      cuenta = 
        <>
          <Typography>
            <strong>
              Realizar el deposito a la siguiente cuenta:
            </strong>
          </Typography>
          <ul>
            <li>Banco Banesco</li>
          </ul>
          <Typography>
            <strong>
              Debe adjuntar su comprobante de pago aquí:
            </strong>
          </Typography>
        </>
        break;
      default:
        break;
    }
  }
  const paisSeleccionado = paises.data.find(
    (x) => x.id === (detalle.data.pais_destino && detalle.data.pais_destino.id)
  );
    if (paisSeleccionado) {
        switch (paisSeleccionado.nombre) {
            case "Venezuela":
                formularioCrearCuenta = 
                    <FormularioBeneficiariosVzla
                        cuentaSeleccionada={cuentaSeleccionada}
                        pais={paisSeleccionado.nombre}
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposIdVzla.find(x => x.id === cuentaSeleccionada.idTipo),
                            banco: cuentaSeleccionada &&  BancosVzla.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: cuentaSeleccionada &&  TiposCuentaVzla.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            codigo: cuentaSeleccionada && BancosVzla.find(x => x.id === cuentaSeleccionada.banco ).id,
                            cuenta: cuentaSeleccionada && cuentaSeleccionada.cuenta.substr(cuentaSeleccionada.cuenta.length - 16)
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo + values.cuenta,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                            }else{
                                await dispatch(agregarBeneficiarioAUsuario({
                                        ...values,
                                        idTipo: values.idTipo.id,
                                        tipoCuenta: values.tipoCuenta.id,
                                        banco: values.banco.id,
                                        cuenta: values.codigo + values.cuenta,
                                        country_id: paisSeleccionado.id,
                                    },paisSeleccionado.nombre)
                                )
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
            case "Chile":
                formularioCrearCuenta =
                    <FormBeneficiarioChile
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={{
                            ...cuentaSeleccionada,
                            rut: cuentaSeleccionada && cuentaSeleccionada.idNumero,
                            banco: BancosChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentasChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    idNumero: values.rut,
                                    idTipo: "Rut",
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                            }else{
                                await dispatch(agregarBeneficiarioAUsuario(
                                    {
                                    ...values,
                                    idNumero: values.rut,
                                    idTipo: "Rut",
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                    },
                                    paisSeleccionado.nombre
                                ))
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
            case "Panama":
                formularioCrearCuenta =
                    <FormBeneficiarioPnm
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposId,
                            banco: BancosPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentaPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                            }else{
                                await dispatch(agregarBeneficiarioAUsuario({
                                    ...values,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                },
                                paisSeleccionado.nombre
                                ))
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
      default:
        formularioCrearCuenta = (
          <Grid container justify="center" alignItems="center">
            <Typography variant="h6">
              Aún no podemos enviar dinero a este pais{" "}
            </Typography>
          </Grid>
        );
        break;
    }
  }

    useEffect(() => {
        resetData();
    }, []);

  return (
    <Grid className={classes.root}>
        <Grid container justify="center" alignItems="center">
            <Typography variant="h5">Realiza tu cambio</Typography>
        </Grid>
        <Navbar />
        <MenuDetalle>
            <ContenidoMenu />
        </MenuDetalle>
        <MenuDetalle>
            {menuIzquierda && <ContenidoMenu />}
            {menuDerecha &&
            beneficiariosSeleccionados.data.map((x, id) => (
                <Card
                styles={{ minHeight: "100px" }}
                title={`Transferencia ${id + 1}`}
                key={id}
                >
                <Typography color="textSecondary">
                    <strong>Nombre:</strong>
                    {x.nombre}
                </Typography>
                <Typography color="textSecondary">
                    <strong>Banco:</strong>
                    {Banco(x.banco)}
                </Typography>
                <Typography color="textSecondary">
                    <strong>Envía:</strong>
                    {currency(x.origen)}
                </Typography>
                <Typography color="textSecondary">
                    <strong>Recibe:</strong>
                    {currency(x.destino)}
                </Typography>
                <Typography color="textSecondary">
                    <strong>Eliminar:</strong>
                    <Button onClick={() => dispatch(borrarBeneficiarioSeleccionado(x.id))}>
                    <DeleteIcon />
                    </Button>
                </Typography>
                </Card>
            ))}
        </MenuDetalle>
        <Hidden smDown>
            <MenuClientes />
        </Hidden>
        <Stepper
            pasoActivo={pasoActivo}
            pasos={[
            "Calcula tu cambio",
            "Actualiza tus datos",
            "Elige los Beneficiarios",
            "Confirma tu cambio",
            ]}
        />

        {pasoActivo.data === 0 && (
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={8} xl={8}>
                    <Calculadora
                        codigoPromocional={true}  
                        perfil="Cliente"
                        descripcion={true}
                        handleSubmit={(values) => {
                            handlePrimerPaso(values);
                        }}
                    />
                </Grid>
            </Grid>
        )}

        {pasoActivo.data === 1 && (
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    >
                        <Paper style={{ maxWidth: "600px" }}>
                            <Box p={4}>
                                <FormUsuario 
                                    proceso={true}
                                    usuarioSeleccionado={usuario}
                                    initialValues={{
                                        ...usuario,
                                        sexo: Sexos.find(y => y.id === (usuario && usuario.sexo) ),
                                        pais: paisesConst.find(y => y.label === (usuario && usuario.pais) ),
                                        nacionalidad:  paisesConst.find(y => y.label === (usuario && usuario.nacionalidad) ),
                                        tipo_cuenta:  TiposCuenta.find(y => y.id === (usuario && usuario.tipo_cuenta) ),
                                        tipo_id: TiposId.find(y => y.id === (usuario && usuario.tipo_id) ),
                                        profesion: Profesiones.find(y => y.id === (usuario && usuario.profesion) ),
                                        codigo: paisesConst.find(y => y.phone === (usuario && usuario.codigo) ),
                                    }}
                                    onSubmit={async values => {
                                        await dispatch(editUsuario({
                                            ...values,
                                            sexo: values.sexo ? values.sexo.id : null,
                                            tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                                            nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                                            pais: values.pais ? values.pais.label : null,
                                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                                            profesion: values.profesion ? values.profesion.id : null,
                                            codigo: values.codigo ? values.codigo.phone : null,
                                            profile_id: values.profile_id ? values.profile_id.id : null,
                                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                                        }, true)).then(dispatch(asignarPaso(2)))
                                        if (usuario.first_session){
                                            if (beneficiariosSugeridos.data.length > 0) {
                                                setModalBeneficiariosSugeridos(true)
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )}

    {pasoActivo.data === 2 && (
    <Grid container justify="center">
        <Grid item xs={12} lg={12} xl={12}>
            <Grid style={{ margin: 10 }}>
                <TarjetaNueva 
                    title="Nueva Cuenta"
                    descripcion="Click aquí para crear una nueva cuenta"
                    handleClick={() => {setCuentaSeleccionada(null);setCrearCuenta(true)}}
                />
            </Grid>
            {beneficiarios.data.length > 0 ? beneficiarios.data.map(x => 
                <Grid key={x.id} style={{ margin: 10 }}>
                    <TarjetaCuentas
                        acciones={true}
                        cuenta={x}
                        editar={true}
                        borrar={true}
                        handleClick={() => {
                            if(beneficiariosSeleccionados.data.find(y => y.cuenta === x.cuenta)){
                                dispatch(asignarMensaje(['Ya agregó una transacción a esta cuenta, debe elegir una distinta']))
                            }else{
                                if(detalle.data.origen/(beneficiariosSeleccionados.data.length+1) >= minimo){
                                    setBeneficiarioSeleccionado(x);
                                    setModalEnviar(true)
                                }else{
                                    dispatch(asignarMensaje([`No puede agregar mas de ${beneficiariosSeleccionados.data.length} transacciones por el cambio de ${detalle.data.origen}. Verifica el carro de compras`]))
                                }   
                            }
                        }}
                        handleEditar={() => {setCuentaSeleccionada(x); setModalEditarCuenta(true)}}
                        handleBorrar={() => {setCuentaSeleccionada(x); setModalBorrarCuenta(true)}}
                    />
                </Grid>) 
                : 
                <Typography variant="body1" style={{ textAlign: "center", margin: 10 }}>No se han encontrado cuentas, puedes empezar a agregar tus beneficiarios!</Typography>
            }
        </Grid>
        {!menuDerecha && beneficiariosSeleccionados.data.length > 0 && (
        <BotonFlotante
            handleAction={() =>
            dispatch(abrirMenuDerecha(!menuEstatus))
            }
            count={beneficiariosSeleccionados.data.length}
        />
        )}
    </Grid>
    )}
    
    {pasoActivo.data === 3 &&
        beneficiariosSeleccionados.data.map((x, id) => (
            <Grid key={id}>
                <Grid>
                    <Typography variant="h6">Transacción {id+1}</Typography>
                </Grid>
                <Grid>
                    <Typography variant="body1" style={{ marginLeft: 20 }}>Beneficiario</Typography>
                </Grid>
                <Grid style={{ margin: 10 }}>
                    <TarjetaCuentas 
                        cuenta={x}
                    />
                </Grid>
                <Grid>
                    <Typography variant="body1" style={{ marginLeft: 20 }}>Detalle</Typography>
                </Grid>
                <Grid style={{ margin: 10 }}>
                    <TarjetaDetalle 
                        transaccion={x}
                    />
                </Grid>
            </Grid>
        ))
    }

      {pasoActivo.data !== 0 && (
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={() => {
                if(pasoActivo.data === 2 && ((usuario.verified) || (usuario.images.length === 0) || (!usuario.archivo)) ){
                    dispatch(asignarPaso(pasoActivo.data - 2))
                }else{
                    dispatch(asignarPaso(pasoActivo.data - 1))
                }
            }}
          >
            Volver
          </Button>
          {pasoActivo.data === 3 && (
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={() =>{
                setModalDeposito(true)
              }}
            >
              Confirmar Transacción
            </Button>
          )}
        </Grid>
      )}

        <Modal
        open={modalDeposito}
        title="Confirmación"
        close={true}
        handleClose={() => setModalDeposito(false)}
        >
            <div>
            {cuenta}
            <FormFile
                loading={transacciones.adding}
                onSubmit={ async (values) => {
                await dispatch(transaccionClientes(values))
                setModalDeposito(false)
                }}
            />
            </div>

        </Modal>

        <Modal
            open={crearCuenta}
            title="Crear Cuenta"
            close={true}
            handleClose={() => setCrearCuenta(false)}
        >
            {formularioCrearCuenta}

        </Modal>

        <Modal
            open={modalBorrarCuenta}
            title="Confirmación"
            close={true}
            handleClose={() => setModalBorrarCuenta(false)}
            action={() => {dispatch(quitarDeUsuario(cuentaSeleccionada.id));setModalBorrarCuenta(false)}}
        >
            ¿Está seguro de que desea desvincular esta cuenta?

        </Modal>

        <Modal
            open={modalEditarCuenta}
            title="Editar Cuenta"
            close={true}
            handleClose={() => setModalEditarCuenta(false)}
        >
            {formularioCrearCuenta}
        </Modal>

        <Modal
            open={modalEnviar}
            title="Confirmación"
            close={true}
            handleClose={() => setModalEnviar(false)}
        >
            <div>
                <Typography variant="body1">{` Ingresa cuanto deseas enviar a ${beneficiarioSeleccionado && beneficiarioSeleccionado.nombre} - ${Banco(beneficiarioSeleccionado && beneficiarioSeleccionado.banco)}`}</Typography>
                <Typography variant="h6"><strong>{`(Saldo Disponible: ${currency2decimal(detalle.data.origen - suma)}):`}</strong></Typography>
                <FormCalculadora 
                    limitMaximo={detalle.data.origen}
                    calculadoraSec={true}
                    paisDisabled={true}
                    initialValues={{
                        pais_origen: detalle.data.pais_origen,
                        pais_destino: detalle.data.pais_destino,
                        efectivo: detalle.data.efectivo,
                        tasa: detalle.data.tasa
                    }}
                    onSubmit={ values => {
                        dispatch(agregarBeneficiarioSeleccionado({ ...beneficiarioSeleccionado, ...values, origen: parseFloat(values.origen), destino: parseFloat(values.destino)}));
                        setModalEnviar(false)}} 
                    tasas={tasas}
                />
            </div>
        </Modal>

        <Modal
            open={modalBeneficiariosSugeridos}
            title="Agregar Beneficiarios Sugeridos"
            close={true}
            handleClose={() => {
            setModalBeneficiariosSugeridos(false)
            }}
        >
            <TablaBeneficiariosSugeridos agregarCuenta={true} />
        </Modal>

        <Modal
            open={modalInfo}
            title="Información"
            close={true}
            handleClose={() => setModalInfo(false)}
        >
            {info}
        </Modal>

        <Snackbar
            open={transacciones.error && transacciones.error.length > 0 ? true : false}
            handleClose={() => dispatch(resetError())}
            message={transacciones.error}
        />
        <Snackbar
            open={beneficiarios.error && beneficiarios.error.length > 0 ? true : false}
            handleClose={() => dispatch(resetErrorBeneficiario())}
            message={beneficiarios.error}
        />
        <Snackbar
            open={usuarioError && usuarioError.length > 0 ? true : false}
            handleClose={() => dispatch(resetErrorUsuario())}
            message={usuarioError}
        />

        <Backdrop 
            open={loading}
        />

        <Snackbar
            open={mensajes.data.length > 0 ? true : false}
            handleClose={() => dispatch(resetMensaje())}
            message={mensajes.data}
        />
    </Grid>
  );
};

export default AgregarCliente;
