import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Typography, Button, Box, IconButton } from '@material-ui/core'
import Navbar from './Navbar/Navbar'
import MenuDetalle from '../components/Drawer'
import ContenidoMenu from '../components/ContenidoMenu'
import MenuPartners from './MenuPartners'
import TablaTransacciones from './transacciones/tablaTransacciones'
import FormularioBusquedaFecha from './FormularioBusquedaFecha'
import SearchIcon from '@material-ui/icons/Search';
import * as transaccionesDuck from '../ducks/Transacciones'
import * as estadosDuck from '../ducks/Estados'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')


class Partners extends React.Component{
    
    state={
        busqueda:false,
        loading: true,
    }

    async componentDidMount(){
        const { transaccionesAsociados } = this.props
        await transaccionesAsociados({ state_id: "Notificada", estatus: "Notificada", fecha: moment().format('YYYY-MM-DD')})
        this.setState({ ...this.state, loading: false})
    }


    render(){
        const { transacciones, transaccionesAsociados } = this.props
        return (
            <Box mt={15} mb={5} ml={10} mr={5}>
                <Grid container justify="center" alignItems="center" >
                    <Typography variant="h5">Transacciones Notificadas<IconButton onClick={() => this.setState({ ...this.state, busqueda: !this.state.busqueda})}><SearchIcon /></IconButton></Typography>
                </Grid>
                {this.state.busqueda && 
                    <FormularioBusquedaFecha handleSubmit={ values => transaccionesAsociados({ state_id: "Notificada", estatus: 'Notificada', ...values})} />
                }
                <Navbar />
                <MenuDetalle>
                    <ContenidoMenu />
                </MenuDetalle>
                <MenuPartners />
                <TablaTransacciones
                    loading={this.state.loading}
                    id={true} 
                    cliente={true} 
                    beneficiario={true} 
                    fecha={true} 
                    cedula={true} 
                    banco={true} 
                    cuenta={true} 
                    archivo={true} 
                    pago={true} 
                    recibe={true} 
                    operador={true}
                    actualizacion={true}
                    transacciones={transacciones} />
            </Box>

        )
    }
}

const mapStateToProps = state => {
    return {
        transacciones: state.Transacciones,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...transaccionesDuck,
    ...estadosDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Partners)