const Actividades = [
    {id: '11111' , nombre: 'CULTIVO DE TRIGO'},
    {id: '11112' , nombre: 'CULTIVO DE MAIZ'},
    {id: '11113' , nombre: 'CULTIVO DE AVENA'},
    {id: '11114' , nombre: 'CULTIVO DE ARROZ'},
    {id: '11115' , nombre: 'CULTIVO DE CEBADA'},
    {id: '11119' , nombre: 'CULTIVO DE OTROS CEREALES'},
    {id: '11121' , nombre: 'CULTIVO FORRAJEROS EN PRADERAS NATURALES'},
    {id: '11122' , nombre: 'CULTIVO FORRAJEROS EN PRADERAS MEJORADAS O SEMBRADAS'},
    {id: '11131' , nombre: 'CULTIVO DE POROTOS O FRIJOL'},
    {id: '11132' , nombre: 'CULTIVO, PRODUCCIÓN DE LUPINO'},
    {id: '11139' , nombre: 'CULTIVO DE OTRAS LEGUMBRES'},
    {id: '11141' , nombre: 'CULTIVO DE PAPAS'},
    {id: '11142' , nombre: 'CULTIVO DE CAMOTES O BATATAS'},
    {id: '11149' , nombre: 'CULTIVO DE OTROS TUBÉRCULOS N.C.P'},
    {id: '11151' , nombre: 'CULTIVO DE RAPS'},
    {id: '11152' , nombre: 'CULTIVO DE MARAVILLA'},
    {id: '11159' , nombre: 'CULTIVO DE OTRAS OLEAGINOSAS N.C.P.'},
    {id: '11160' , nombre: 'PRODUCCIÓN DE SEMILLAS DE CEREALES, LEGUMBRES, OLEAGINOSAS'},
    {id: '11191' , nombre: 'CULTIVO DE REMOLACHA'},
    {id: '11192' , nombre: 'CULTIVO DE TABACO'},
    {id: '11193' , nombre: 'CULTIVO DE FIBRAS VEGETALES INDUSTRIALES'},
    {id: '11194' , nombre: 'CULTIVO DE PLANTAS AROMÁTICAS O MEDICINALES'},
    {id: '11199' , nombre: 'OTROS CULTIVOS N.C.P.'},
    {id: '11211' , nombre: 'CULTIVO TRADICIONAL DE HORTALIZAS FRESCAS'},
    {id: '11212' , nombre: 'CULTIVO DE HORTALIZAS EN INVERNADEROS Y CULTIVOS HIDROPONICOS'},
    {id: '11213' , nombre: 'CULTIVO ORGÁNICO DE HORTALIZAS'},
    {id: '11220' , nombre: 'CULTIVO DE PLANTAS VIVAS Y PRODUCTOS DE LA FLORICULTURA'},
    {id: '11230' , nombre: 'PRODUCCIÓN DE SEMILLAS DE FLORES, PRADOS, FRUTAS Y HORTALIZAS'},
    {id: '11240' , nombre: 'PRODUCCIÓN EN VIVEROS, EXCEPTO ESPECIES FORESTALES'},
    {id: '11250' , nombre: 'CULTIVO Y RECOLECCIÓN DE HONGOS, TRUFAS Y SAVIA, PRODUCCIÓN DE JARABE DE ARCE DE AZÚCAR Y AZÚCAR'},
    {id: '11311' , nombre: 'CULTIVO DE UVA DESTINADA A PRODUCCIÓN DE PISCO Y AGUARDIENTE'},
    {id: '11312' , nombre: 'CULTIVO DE UVA DESTINADA A PRODUCCIÓN DE VINO'},
    {id: '11313' , nombre: 'CULTIVO DE UVA DE MESA'},
    {id: '11321' , nombre: 'CULTIVO DE FRUTALES EN ÁRBOLES O ARBUSTOS CON CICLO DE VIDA MAYOR A UNA TEMPORADA'},
    {id: '11322' , nombre: 'CULTIVO DE FRUTALES MENORES EN PLANTAS CON CICLO DE VIDA DE UNA TEMPORADA'},
    {id: '11330' , nombre: 'CULTIVO DE PLANTAS CUYAS HOJAS O FRUTAS SE UTILIZAN PARA PREPARAR BEBIDAS'},
    {id: '11340' , nombre: 'CULTIVO DE ESPECIAS'},
    {id: '12111' , nombre: 'CRÍA DE GANADO BOVINO PARA LA PRODUCCIÓN LECHERA'},
    {id: '12112' , nombre: 'CRÍA DE GANADO PARA PRODUCCIÓN DE CARNE, O COMO GANADO REPRODUCTOR'},
    {id: '12120' , nombre: 'CRÍA DE GANADO OVINO Y/O EXPLOTACIÓN LANERA'},
    {id: '12130' , nombre: 'CRÍA DE EQUINOS (CABALLARES, MULARES)'},
    {id: '12210' , nombre: 'CRÍA DE PORCINOS'},
    {id: '12221' , nombre: 'CRÍA DE AVES DE CORRAL PARA LA PRODUCCIÓN DE CARNE'},
    {id: '12222' , nombre: 'CRÍA DE AVES DE CORRAL PARA LA PRODUCCIÓN DE HUEVOS'},
    {id: '12223' , nombre: 'CRÍA DE AVES FINAS O NO TRADICIONALES'},
    {id: '12230' , nombre: 'CRÍA DE ANIMALES DOMÉSTICOS, PERROS Y GATOS'},
    {id: '12240' , nombre: 'APICULTURA'},
    {id: '12250' , nombre: 'RANICULTURA, HELICICULTURA U OTRA ACTIVIDAD CON ANIMALES MENORES O INSECTOS'},
    {id: '12290' , nombre: 'OTRAS EXPLOTACIONES DE ANIMALES NO CLASIFICADOS EN OTRA PARTE, INCLUIDO SUS SUBPRODUCTOS'},
    {id: '13000' , nombre: 'EXPLOTACIÓN MIXTA'},
    {id: '14011' , nombre: 'SERVICIO DE CORTE Y ENFARDADO DE FORRAJE'},
    {id: '14012' , nombre: 'SERVICIO DE RECOLECCIÓN, EMPACADO, TRILLA, DESCASCARAMIENTO Y DESGRANE, Y SIMILARES'},
    {id: '14013' , nombre: 'SERVICIO DE ROTURACIÓN SIEMBRA Y SIMILARES'},
    {id: '14014' , nombre: 'DESTRUCCIÓN DE PLAGAS, PULVERIZACIONES, FUMIGACIONES U OTRAS'},
    {id: '14015' , nombre: 'COSECHA, PODA, AMARRE Y LABORES DE ADECUACIÓN DE LA PLANTA U OTRAS'},
    {id: '14019' , nombre: 'OTROS SERVICIOS AGRÍCOLAS N.C.P.'},
    {id: '14021' , nombre: 'SERVICIOS DE ADIESTRAMIENTO, GUARDERÍA Y CUIDADOS DE MASCOTAS, EXCEPTO ACTIVIDADES VETERINARIAS'},
    {id: '14022' , nombre: 'SERVICIOS GANADEROS, EXCEPTO ACTIVIDADES VETERINARIAS'},
    {id: '15010' , nombre: 'CAZA DE MAMÍFEROS MARINOS, EXCEPTO BALLENAS'},
    {id: '15090' , nombre: 'CAZA ORDINARIA Y MEDIANTE TRAMPAS'},
    {id: '20010' , nombre: 'EXPLOTACIÓN DE BOSQUES'},
    {id: '20020' , nombre: 'RECOLECCIÓN DE PRODUCTOS FORESTALES SILVESTRES'},
    {id: '20030' , nombre: 'EXPLOTACIÓN DE VIVEROS DE ESPECIES FORESTALES'},
    {id: '20041' , nombre: 'SERVICIOS DE FORESTACIÓN'},
    {id: '20042' , nombre: 'SERVICIOS DE CORTA DE MADERA'},
    {id: '20043' , nombre: 'SERVICIOS DE CONTROL DE INCENDIOS FORESTALES'},
    {id: '20049' , nombre: 'OTRAS ACTIVIDADES DE SERVICIOS CONEXAS A LA SILVICULTURA N.C.P.'},
    {id: '51010' , nombre: 'CULTIVO DE ESPECIES ACUÁTICAS EN CUERPO DE AGUA DULCE'},
    {id: '51020' , nombre: 'REPRODUCCIÓN Y CRIANZAS DE PECES MARINOS'},
    {id: '51030' , nombre: 'CULTIVO'},
    {id: '51040' , nombre: 'REPRODUCCIÓN Y CRÍA DE MOLUSCOS Y CRUSTACEOS.'},
    {id: '51090' , nombre: 'SERVICIOS RELACIONADOS CON LA ACUICULTURA'},
    {id: '52010' , nombre: 'PESCA INDUSTRIAL'},
    {id: '52020' , nombre: 'ACTIVIDAD PESQUERA DE BARCOS FACTORÍAS'},
    {id: '52030' , nombre: 'PESCA ARTESANAL. EXTRACCIÓN DE RECURSOS ACUÁTICOS EN GENERAL, INCLUYE BALLENAS'},
    {id: '52040' , nombre: 'RECOLECCIÓN DE PRODUCTOS MARINOS'},
    {id: '52050' , nombre: 'SERVICIOS RELACIONADOS CON LA PESCA'},
    {id: '100000' , nombre: 'EXTRACCIÓN'},
    {id: '111000' , nombre: 'EXTRACCIÓN DE PETRÓLEO CRUDO Y GAS NATURAL'},
    {id: '112000' , nombre: 'ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA EXTRACCIÓN DE PETRÓLEO Y GAS'},
    {id: '120000' , nombre: 'EXTRACCIÓN DE MINERALES DE URANIO Y TORIO'},
    {id: '131000' , nombre: 'EXTRACCIÓN DE MINERALES DE HIERRO'},
    {id: '132010' , nombre: 'EXTRACCIÓN DE ORO Y PLATA'},
    {id: '132020' , nombre: 'EXTRACCIÓN DE ZINC Y PLOMO'},
    {id: '132030' , nombre: 'EXTRACCIÓN DE MANGANESO'},
    {id: '132090' , nombre: 'EXTRACCIÓN DE OTROS MINERALES METALÍFEROS N.C.P.'},
    {id: '133000' , nombre: 'EXTRACCIÓN DE COBRE'},
    {id: '141000' , nombre: 'EXTRACCIÓN DE PIEDRA'},
    {id: '142100' , nombre: 'EXTRACCIÓN DE NITRATOS Y YODO'},
    {id: '142200' , nombre: 'EXTRACCIÓN DE SAL'},
    {id: '142300' , nombre: 'EXTRACCIÓN DE LITIO Y CLORUROS'},
    {id: '142900' , nombre: 'EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P.'},
    {id: '151110' , nombre: 'PRODUCCIÓN'},
    {id: '151120' , nombre: 'CONSERVACIÓN DE CARNES ROJAS (FRIGORÍFICOS)'},
    {id: '151130' , nombre: 'PRODUCCIÓN'},
    {id: '151140' , nombre: 'ELABORACIÓN DE CECINAS'},
    {id: '151210' , nombre: 'PRODUCCIÓN DE HARINA DE PESCADO'},
    {id: '151221' , nombre: 'FABRICACIÓN DE PRODUCTOS ENLATADOS DE PESCADO Y MARISCOS'},
    {id: '151222' , nombre: 'ELABORACIÓN DE CONGELADOS DE PESCADOS Y MARISCOS'},
    {id: '151223' , nombre: 'ELABORACIÓN DE PRODUCTOS AHUMADOS'},
    {id: '151230' , nombre: 'ELABORACIÓN DE PRODUCTOS EN BASE A VEGETALES ACUÁTICOS'},
    {id: '151300' , nombre: 'ELABORACIÓN Y CONSERVACIÓN DE FRUTAS'},
    {id: '151410' , nombre: 'ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL'},
    {id: '151420' , nombre: 'ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN ANIMAL'},
    {id: '151430' , nombre: 'ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN MARINO'},
    {id: '152010' , nombre: 'ELABORACIÓN DE LECHE'},
    {id: '152020' , nombre: 'ELABORACIÓN DE QUESOS'},
    {id: '152030' , nombre: 'FABRICACIÓN DE POSTRES A BASE DE LECHE (HELADOS'},
    {id: '153110' , nombre: 'ELABORACIÓN DE HARINAS DE TRIGO'},
    {id: '153120' , nombre: 'ACTIVIDADES DE MOLIENDA DE ARROZ'},
    {id: '153190' , nombre: 'ELABORACIÓN DE OTRAS MOLINERAS Y ALIMENTOS A BASE DE CEREALES'},
    {id: '153210' , nombre: 'ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN'},
    {id: '153220' , nombre: 'ELABORACIÓN DE GLUCOSA Y OTROS AZÚCARES DIFERENTES DE LA REMOLACHA'},
    {id: '153300' , nombre: 'ELABORACIÓN DE ALIMENTOS PREPARADOS PARA ANIMALES'},
    {id: '154110' , nombre: 'FABRICACIÓN DE PAN'},
    {id: '154120' , nombre: 'FABRICACIÓN DE GALLETAS'},
    {id: '154200' , nombre: 'ELABORACIÓN DE AZÚCAR DE REMOLACHA O CANA'},
    {id: '154310' , nombre: 'ELABORACIÓN DE CACAO Y CHOCOLATES'},
    {id: '154320' , nombre: 'FABRICACIÓN DE PRODUCTOS DE CONFITERÍA'},
    {id: '154400' , nombre: 'ELABORACIÓN DE MACARRONES'},
    {id: '154910' , nombre: 'ELABORACIÓN DE TE'},
    {id: '154920' , nombre: 'ELABORACIÓN DE LEVADURAS NATURALES O ARTIFICIALES'},
    {id: '154930' , nombre: 'ELABORACIÓN DE VINAGRES'},
    {id: '154990' , nombre: 'ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS NO CLASIFICADOS EN OTRA PARTE'},
    {id: '155110' , nombre: 'ELABORACIÓN DE PISCOS (INDUSTRIAS PISQUERAS)'},
    {id: '155120' , nombre: 'ELABORACIÓN DE BEBIDAS ALCOHÓLICAS Y DE ALCOHOL ETÍLICO A PARTIR DE SUSTANCIAS FERMENTADAS Y OTROS'},
    {id: '155200' , nombre: 'ELABORACIÓN DE VINOS'},
    {id: '155300' , nombre: 'ELABORACIÓN DE BEBIDAS MALTEADAS'},
    {id: '155410' , nombre: 'ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS'},
    {id: '155420' , nombre: 'ENVASADO DE AGUA MINERAL NATURAL'},
    {id: '155430' , nombre: 'ELABORACIÓN DE HIELO'},
    {id: '160010' , nombre: 'FABRICACIÓN DE CIGARROS Y CIGARRILLOS'},
    {id: '160090' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS DEL TABACO'},
    {id: '171100' , nombre: 'PREPARACIÓN DE HILATURA DE FIBRAS TEXTILES, TEJEDURA PROD. TEXTILES'},
    {id: '171200' , nombre: 'ACABADO DE PRODUCTOS TEXTIL'},
    {id: '172100' , nombre: 'FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIAS TEXTILES'},
    {id: '172200' , nombre: 'FABRICACIÓN DE TAPICES Y ALFOMBRA'},
    {id: '172300' , nombre: 'FABRICACIÓN DE CUERDAS'},
    {id: '172910' , nombre: 'FABRICACIÓN DE TEJIDOS DE USO INDUSTRIAL COMO TEJIDOS IMPREGNADOS'},
    {id: '172990' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P.'},
    {id: '173000' , nombre: 'FABRICACIÓN DE TEJIDOS DE PUNTO'},
    {id: '181010' , nombre: 'FABRICACIÓN DE PRENDAS DE VESTIR TEXTILES Y SIMILARES'},
    {id: '181020' , nombre: 'FABRICACIÓN DE PRENDAS DE VESTIR DE CUERO NATURAL'},
    {id: '181030' , nombre: 'FABRICACIÓN DE ACCESORIOS DE VESTIR'},
    {id: '181040' , nombre: 'FABRICACIÓN DE ROPA DE TRABAJO'},
    {id: '182000' , nombre: 'ADOBO Y TENIDOS DE PIELES, FABRICACIÓN DE ARTÍCULOS DE PIEL'},
    {id: '191100' , nombre: 'CURTIDO Y ADOBO DE CUEROS'},
    {id: '191200' , nombre: 'FABRICACIÓN DE MALETAS, BOLSOS DE MANO Y SIMILARES, ARTÍCULOS DE TALABARTERÍA Y GUARNICIONERÍA'},
    {id: '192000' , nombre: 'FABRICACIÓN DE CALZADO'},
    {id: '201000' , nombre: 'ASERRADO Y ACEPILLADURA DE MADERAS'},
    {id: '202100' , nombre: 'FABRICACIÓN DE TABLEROS'},
    {id: '202200' , nombre: 'FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES'},
    {id: '202300' , nombre: 'FABRICACIÓN DE RECIPIENTES DE MADERA'},
    {id: '202900' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS DE MADERA, ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES'},
    {id: '210110' , nombre: 'FABRICACIÓN DE CELULOSA Y OTRAS PASTAS DE MADERA'},
    {id: '210121' , nombre: 'FABRICACIÓN DE PAPEL DE PERIÓDICO'},
    {id: '210129' , nombre: 'FABRICACIÓN DE PAPEL Y CARTÓN N.C.P.'},
    {id: '210200' , nombre: 'FABRICACIÓN DE PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN'},
    {id: '210900' , nombre: 'FABRICACIÓN DE OTROS ARTÍCULOS DE PAPEL Y CARTÓN'},
    {id: '221101' , nombre: 'EDICIÓN PRINCIPALMENTE DE LIBROS'},
    {id: '221109' , nombre: 'EDICIÓN DE FOLLETOS'},
    {id: '221200' , nombre: 'EDICIÓN DE PERIÓDICOS'},
    {id: '221300' , nombre: 'EDICIÓN DE GRABACIONES'},
    {id: '221900' , nombre: 'OTRAS ACTIVIDADES DE EDICIÓN'},
    {id: '222101' , nombre: 'IMPRESIÓN PRINCIPALMENTE DE LIBROS'},
    {id: '222109' , nombre: 'OTRAS ACTIVIDADES DE IMPRESIÓN N.C.P.'},
    {id: '222200' , nombre: 'ACTIVIDADES DE SERVICIO RELACIONADA CON LA IMPRESIÓN'},
    {id: '223000' , nombre: 'REPRODUCCIÓN DE GRABACIONES'},
    {id: '231000' , nombre: 'FABRICACIÓN DE PRODUCTOS DE HORNOS COQUE'},
    {id: '232000' , nombre: 'FABRICACIÓN DE PRODUCTOS DE REFINACIÓN DE PETRÓLEO'},
    {id: '233000' , nombre: 'ELABORACIÓN DE COMBUSTIBLE NUCLEAR'},
    {id: '241110' , nombre: 'FABRICACIÓN DE CARBÓN VEGETAL'},
    {id: '241190' , nombre: 'FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS'},
    {id: '241200' , nombre: 'FABRICACIÓN DE ABONOS Y COMPUESTOS DE NITRÓGENO'},
    {id: '241300' , nombre: 'FABRICACIÓN DE PLÁSTICOS EN FORMAS PRIMARIAS Y DE CAUCHO SINTÉTICO'},
    {id: '242100' , nombre: 'FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO'},
    {id: '242200' , nombre: 'FABRICACIÓN DE PINTURAS'},
    {id: '242300' , nombre: 'FABRICACIÓN DE PRODUCTOS FARMACEUTICOS'},
    {id: '242400' , nombre: 'FABRICACIONES DE JABONES Y DETERGENTES'},
    {id: '242910' , nombre: 'FABRICACIÓN DE EXPLOSIVOS Y PRODUCTOS DE PIROTECNIA'},
    {id: '242990' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P.'},
    {id: '243000' , nombre: 'FABRICACIÓN DE FIBRAS MANUFACTURADAS'},
    {id: '251110' , nombre: 'FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO'},
    {id: '251120' , nombre: 'RECAUCHADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO'},
    {id: '251900' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO'},
    {id: '252010' , nombre: 'FABRICACIÓN DE PLANCHAS'},
    {id: '252020' , nombre: 'FABRICACIÓN DE TUBOS'},
    {id: '252090' , nombre: 'FABRICACIÓN DE OTROS ARTÍCULOS DE PLÁSTICO'},
    {id: '261010' , nombre: 'FABRICACIÓN'},
    {id: '261020' , nombre: 'FABRICACIÓN DE VIDRIO HUECO'},
    {id: '261030' , nombre: 'FABRICACIÓN DE FIBRAS DE VIDRIO'},
    {id: '261090' , nombre: 'FABRICACIÓN DE ARTÍCULOS DE VIDRIO N.C.P.'},
    {id: '269101' , nombre: 'FABRICACIÓN DE PRODUCTOS DE CERÁMICA NO REFRACTARIA PARA USO NO ESTRUCTURAL CON FINES ORNAMENTALES'},
    {id: '269109' , nombre: 'FABRICACIÓN DE PRODUCTOS DE CERÁMICA NO REFRACTARIA PARA USO NO ESTRUCTURAL N.C.P.'},
    {id: '269200' , nombre: 'FABRICACIÓN DE PRODUCTOS DE CERÁMICAS REFRACTARIA'},
    {id: '269300' , nombre: 'FABRICACIÓN DE PRODUCTOS DE ARCILLA Y CERÁMICAS NO REFRACTARIAS PARA USO ESTRUCTURAL'},
    {id: '269400' , nombre: 'FABRICACIÓN DE CEMENTO'},
    {id: '269510' , nombre: 'ELABORACIÓN DE HORMIGÓN'},
    {id: '269520' , nombre: 'FABRICACIÓN DE PRODUCTOS DE FIBROCEMENTO Y ASBESTOCEMENTO'},
    {id: '269530' , nombre: 'FABRICACIÓN DE PANELES DE YESO PARA LA CONSTRUCCIÓN'},
    {id: '269590' , nombre: 'FABRICACIÓN DE ARTÍCULOS DE CEMENTO Y YESO N.C.P.'},
    {id: '269600' , nombre: 'CORTE'},
    {id: '269910' , nombre: 'FABRICACIÓN DE MEZCLAS BITUMINOSAS A BASE DE ASFALTO'},
    {id: '269990' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P'},
    {id: '271000' , nombre: 'INDUSTRIAS BASICAS DE HIERRO Y ACERO'},
    {id: '272010' , nombre: 'ELABORACIÓN DE PRODUCTOS DE COBRE EN FORMAS PRIMARIAS.'},
    {id: '272020' , nombre: 'ELABORACIÓN DE PRODUCTOS DE ALUMINIO EN FORMAS PRIMARIAS'},
    {id: '272090' , nombre: 'FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y DE OTROS METALES NO FERROSOS N.C.P.'},
    {id: '273100' , nombre: 'FUNDICIÓN DE HIERRO Y ACERO'},
    {id: '273200' , nombre: 'FUNDICIÓN DE METALES NO FERROSOS'},
    {id: '281100' , nombre: 'FABRICACIÓN DE PRODUCTOS METÁLICOS DE USO ESTRUCTURAL'},
    {id: '281211' , nombre: 'FABRICACIÓN DE RECIPIENTES DE GAS COMPRIMIDO O LICUADO'},
    {id: '281219' , nombre: 'FABRICACIÓN DE TANQUES'},
    {id: '281280' , nombre: 'REPARACIÓN DE TANQUES'},
    {id: '281310' , nombre: 'FABRICACIÓN DE GENERADORES DE VAPOR'},
    {id: '281380' , nombre: 'REPARACIÓN DE GENERADORES DE VAPOR'},
    {id: '289100' , nombre: 'FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METAL, INCLUYE PULVIMETALURGIA'},
    {id: '289200' , nombre: 'TRATAMIENTOS Y REVESTIMIENTOS DE METALES, OBRAS DE INGENIERÍA MECÁNICA EN GENERAL'},
    {id: '289310' , nombre: 'FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA'},
    {id: '289320' , nombre: 'FABRICACIÓN DE HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA'},
    {id: '289910' , nombre: 'FABRICACIÓN DE CABLES'},
    {id: '289990' , nombre: 'FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.'},
    {id: '291110' , nombre: 'FABRICACIÓN DE MOTORES Y TURBINAS'},
    {id: '291180' , nombre: 'REPARACIÓN DE MOTORES Y TURBINAS'},
    {id: '291210' , nombre: 'FABRICACIÓN DE BOMBAS'},
    {id: '291280' , nombre: 'REPARACIÓN DE BOMBAS'},
    {id: '291310' , nombre: 'FABRICACIÓN DE COJINETES'},
    {id: '291380' , nombre: 'REPARACIÓN DE COJINETES'},
    {id: '291410' , nombre: 'FABRICACIÓN DE HORNOS'},
    {id: '291480' , nombre: 'REPARACIÓN DE HORNOS'},
    {id: '291510' , nombre: 'FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN'},
    {id: '291580' , nombre: 'REPARACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN'},
    {id: '291910' , nombre: 'FABRICACIÓN DE OTRO TIPO DE MAQUINARIAS DE USO GENERAL'},
    {id: '291980' , nombre: 'REPARACIÓN OTROS TIPOS DE MAQUINARIA Y EQUIPOS DE USO GENERAL'},
    {id: '292110' , nombre: 'FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL'},
    {id: '292180' , nombre: 'REPARACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL'},
    {id: '292210' , nombre: 'FABRICACIÓN DE MÁQUINAS HERRAMIENTAS'},
    {id: '292280' , nombre: 'REPARACIÓN DE MÁQUINAS HERRAMIENTAS'},
    {id: '292310' , nombre: 'FABRICACIÓN DE MAQUINARIA METALÚRGICA'},
    {id: '292380' , nombre: 'REPARACIÓN DE MAQUINARIA PARA LA INDUSTRIA METALÚRGICA'},
    {id: '292411' , nombre: 'FABRICACIÓN DE MAQUINARIA PARA MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN'},
    {id: '292412' , nombre: 'FABRICACIÓN DE PARTES PARA MÁQUINAS DE SONDEO O PERFORACIÓN'},
    {id: '292480' , nombre: 'REPARACIÓN DE MAQUINARIA PARA LA EXPLOTACIÓN DE PETRÓLEO'},
    {id: '292510' , nombre: 'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS'},
    {id: '292580' , nombre: 'REPARACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS'},
    {id: '292610' , nombre: 'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRENDAS TEXTILES'},
    {id: '292680' , nombre: 'REPARACIÓN DE MAQUINARIA PARA LA INDUSTRIA TEXTIL'},
    {id: '292710' , nombre: 'FABRICACIÓN DE ARMAS Y MUNICIONES'},
    {id: '292780' , nombre: 'REPARACIÓN DE ARMAS'},
    {id: '292910' , nombre: 'FABRICACIÓN DE OTROS TIPOS DE MAQUINARIAS DE USO ESPECIAL'},
    {id: '292980' , nombre: 'REPARACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL'},
    {id: '293000' , nombre: 'FABRICACIÓN DE APARATOS DE USO DOMÉSTICO N.C.P.'},
    {id: '300010' , nombre: 'FABRICACIÓN Y ARMADO DE COMPUTADORES Y HARDWARE EN GENERAL'},
    {id: '300020' , nombre: 'FABRICACIÓN DE MAQUINARIA DE OFICINA'},
    {id: '311010' , nombre: 'FABRICACIÓN DE MOTORES'},
    {id: '311080' , nombre: 'REPARACIÓN DE MOTORES'},
    {id: '312010' , nombre: 'FABRICACIÓN DE APARATOS DE DISTRIBUCIÓN Y CONTROL'},
    {id: '312080' , nombre: 'REPARACIÓN DE APARATOS DE DISTRIBUCIÓN Y CONTROL'},
    {id: '313000' , nombre: 'FABRICACIÓN DE HILOS Y CABLES AISLADOS'},
    {id: '314000' , nombre: 'FABRICACIÓN DE ACUMULADORES DE PILAS Y BATERÍAS PRIMARIAS'},
    {id: '315010' , nombre: 'FABRICACIÓN DE LÁMPARAS Y EQUIPO DE ILUMINACIÓN'},
    {id: '315080' , nombre: 'REPARACIÓN DE EQUIPO DE ILUMINACIÓN'},
    {id: '319010' , nombre: 'FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO N.C.P.'},
    {id: '319080' , nombre: 'REPARACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO N.C.P.'},
    {id: '321010' , nombre: 'FABRICACIÓN DE COMPONENTES ELECTRÓNICOS'},
    {id: '321080' , nombre: 'REPARACIÓN DE COMPONENTES ELECTRÓNICOS'},
    {id: '322010' , nombre: 'FABRICACIÓN DE TRANSMISORES DE RADIO Y TELEVISIÓN'},
    {id: '322080' , nombre: 'REPARACIÓN DE TRANSMISORES DE RADIO Y TELEVISIÓN'},
    {id: '323000' , nombre: 'FABRICACIÓN DE RECEPTORES (RADIO Y TV), APARATOS DE GRABACIÓN Y REPRODUCCIÓN (AUDIO Y VIDEO)'},
    {id: '331110' , nombre: 'FABRICACIÓN DE EQUIPO MÉDICO Y QUIRÚRGICO'},
    {id: '331120' , nombre: 'LABORATORIOS DENTALES'},
    {id: '331180' , nombre: 'REPARACIÓN DE EQUIPO MÉDICO Y QUIRÚRGICO'},
    {id: '331210' , nombre: 'FABRICACIÓN DE INSTRUMENTOS Y APARATOS PARA MEDIR'},
    {id: '331280' , nombre: 'REPARACIÓN DE INSTRUMENTOS Y APARATOS PARA MEDIR'},
    {id: '331310' , nombre: 'FABRICACIÓN DE EQUIPOS DE CONTROL DE PROCESOS INDUSTRIALES'},
    {id: '331380' , nombre: 'REPARACIÓN DE EQUIPOS DE CONTROL DE PROCESOS INDUSTRIALES'},
    {id: '332010' , nombre: 'FABRICACIÓN Y/O REPARACIÓN DE LENTES Y ARTÍCULOS OFTALMOLÓGICOS'},
    {id: '332020' , nombre: 'FABRICACIÓN DE INSTRUMENTOS DE OPTICA N.C.P. Y EQUIPOS FOTOGRÁFICOS'},
    {id: '332080' , nombre: 'REPARACIÓN DE INSTRUMENTOS DE OPTICA N.C.P Y EQUIPO FOTOGRÁFICOS'},
    {id: '333000' , nombre: 'FABRICACIÓN DE RELOJES'},
    {id: '341000' , nombre: 'FABRICACIÓN DE VEHÍCULOS AUTOMOTORES'},
    {id: '342000' , nombre: 'FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES, FABRICACIÓN DE REMOLQUES Y SEMI REMOLQUES'},
    {id: '343000' , nombre: 'FABRICACIÓN DE PARTES Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES Y SUS MOTORES'},
    {id: '351110' , nombre: 'CONSTRUCCIÓN Y REPARACIÓN DE BUQUES, ASTILLEROS'},
    {id: '351120' , nombre: 'CONSTRUCCIÓN DE EMBARCACIONES MENORES'},
    {id: '351180' , nombre: 'REPARACIÓN DE EMBARCACIONES MENORES'},
    {id: '351210' , nombre: 'CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE'},
    {id: '351280' , nombre: 'REPARACIÓN DE EMBARCACIONES DE RECREO Y DEPORTES'},
    {id: '352000' , nombre: 'FABRICACIÓN DE LOCOMOTORAS Y DE MATERIAL RODANTE PARA FERROCARRILES Y TRANVÍAS'},
    {id: '353010' , nombre: 'FABRICACIÓN DE AERONAVES Y NAVES ESPACIALES'},
    {id: '353080' , nombre: 'REPARACIÓN DE AERONAVES Y NAVES ESPACIALES'},
    {id: '359100' , nombre: 'FABRICACIÓN DE MOTOCICLETAS'},
    {id: '359200' , nombre: 'FABRICACIÓN DE BICICLETAS Y DE SILLONES DE RUEDAS PARA INVALIDOS'},
    {id: '359900' , nombre: 'FABRICACIÓN DE OTROS EQUIPOS DE TRANSPORTE N.C.P.'},
    {id: '361010' , nombre: 'FABRICACIÓN DE MUEBLES PRINCIPALMENTE DE MADERA'},
    {id: '361020' , nombre: 'FABRICACIÓN DE OTROS MUEBLES N.C.P.'},
    {id: '369100' , nombre: 'FABRICACIÓN DE JOYAS Y PRODUCTOS CONEXOS'},
    {id: '369200' , nombre: 'FABRICACIÓN DE INSTRUMENTOS DE MÚSICA'},
    {id: '369300' , nombre: 'FABRICACIÓN DE ARTÍCULOS DE DEPORTE'},
    {id: '369400' , nombre: 'FABRICACIÓN DE JUEGOS Y JUGUETES'},
    {id: '369910' , nombre: 'FABRICACIÓN DE PLUMAS Y LÁPICES DE TODA CLASE Y ARTÍCULOS DE ESCRITORIO EN GENERAL'},
    {id: '369920' , nombre: 'FABRICACIÓN DE BROCHAS'},
    {id: '369930' , nombre: 'FABRICACIÓN DE FÓSFOROS'},
    {id: '369990' , nombre: 'FABRICACIÓN DE ARTÍCULOS DE OTRAS INDUSTRIAS N.C.P.'},
    {id: '371000' , nombre: 'RECICLAMIENTO DE DESPERDICIOS Y DESECHOS METÁLICOS'},
    {id: '372010' , nombre: 'RECICLAMIENTO DE PAPEL'},
    {id: '372020' , nombre: 'RECICLAMIENTO DE VIDRIO'},
    {id: '372090' , nombre: 'RECICLAMIENTO DE OTROS DESPERDICIOS Y DESECHOS N.C.P.'},
    {id: '401011' , nombre: 'GENERACIÓN HIDROELÉCTRICA'},
    {id: '401012' , nombre: 'GENERACIÓN EN CENTRALES TERMOELÉCTRICA DE CICLOS COMBINADOS'},
    {id: '401013' , nombre: 'GENERACIÓN EN OTRAS CENTRALES TERMOELÉCTRICAS'},
    {id: '401019' , nombre: 'GENERACIÓN EN OTRAS CENTRALES N.C.P.'},
    {id: '401020' , nombre: 'TRANSMISIÓN DE ENERGÍA ELÉCTRICA'},
    {id: '401030' , nombre: 'DISTRIBUCIÓN DE ENERGIA ELÉCTRICA'},
    {id: '402000' , nombre: 'FABRICACIÓN DE GAS, DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS'},
    {id: '403000' , nombre: 'SUMINISTRO DE VAPOR Y AGUA CALIENTE'},
    {id: '410000' , nombre: 'CAPTACIÓN'},
    {id: '451010' , nombre: 'PREPARACIÓN DEL TERRENO'},
    {id: '451020' , nombre: 'SERVICIOS DE DEMOLICIÓN Y EL DERRIBO DE EDIFICIOS Y OTRAS ESTRUCTURAS'},
    {id: '452010' , nombre: 'CONSTRUCCIÓN DE EDIFICIOS COMPLETOS O DE PARTES DE EDIFICIOS'},
    {id: '452020' , nombre: 'OBRAS DE INGENIERÍA'},
    {id: '453000' , nombre: 'ACONDICIONAMIENTO DE EDIFICIOS'},
    {id: '454000' , nombre: 'OBRAS MENORES EN CONSTRUCCIÓN (CONTRATISTAS'},
    {id: '455000' , nombre: 'ALQUILER DE EQUIPO DE CONSTRUCCIÓN O DEMOLICIÓN DOTADO DE OPERARIOS'},
    {id: '501010' , nombre: 'VENTA AL POR MAYOR DE VEHÍCULOS AUTOMOTORES (IMPORTACIÓN'},
    {id: '501020' , nombre: 'VENTA O COMPRAVENTA AL POR MENOR DE VEHÍCULOS AUTOMOTORES NUEVOS O USADOS, EXCEPTO MOTOCICLETAS'},
    {id: '502010' , nombre: 'SERVICIO DE LAVADO DE VEHÍCULOS AUTOMOTORES'},
    {id: '502020' , nombre: 'SERVICIOS DE REMOLQUE DE VEHÍCULOS (GRUAS)'},
    {id: '502080' , nombre: 'MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES'},
    {id: '503000' , nombre: 'VENTA DE PARTES'},
    {id: '504010' , nombre: 'VENTA DE MOTOCICLETAS'},
    {id: '504020' , nombre: 'VENTA DE PIEZAS Y ACCESORIOS DE MOTOCICLETAS'},
    {id: '504080' , nombre: 'REPARACIÓN DE MOTOCICLETAS'},
    {id: '505000' , nombre: 'VENTA AL POR MENOR DE COMBUSTIBLE PARA AUTOMOTORES'},
    {id: '511010' , nombre: 'CORRETAJE DE PRODUCTOS AGRÍCOLAS'},
    {id: '511020' , nombre: 'CORRETAJE DE GANADO (FERIAS DE GANADO)'},
    {id: '511030' , nombre: 'OTROS TIPOS DE CORRETAJES O REMATES N.C.P. (NO INCLUYE SERVICIOS DE MARTILLERO)'},
    {id: '512110' , nombre: 'VENTA AL POR MAYOR DE ANIMALES VIVOS'},
    {id: '512120' , nombre: 'VENTA AL POR MAYOR DE PRODUCTOS PECUARIOS (LANAS, PIELES, CUEROS SIN PROCESAR), EXCEPTO ALIMENTOS'},
    {id: '512130' , nombre: 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGRÍCOLAS'},
    {id: '512210' , nombre: 'MAYORISTA DE FRUTAS Y VERDURAS'},
    {id: '512220' , nombre: 'MAYORISTAS DE CARNES'},
    {id: '512230' , nombre: 'MAYORISTAS DE PRODUCTOS DEL MAR (PESCADO'},
    {id: '512240' , nombre: 'MAYORISTAS DE VINOS Y BEBIDAS ALCOHÓLICAS Y DE FANTASÍA'},
    {id: '512250' , nombre: 'VENTA AL POR MAYOR DE CONFITES'},
    {id: '512260' , nombre: 'VENTA AL POR MAYOR DE TABACO Y PRODUCTOS DERIVADOS'},
    {id: '512290' , nombre: 'VENTA AL POR MAYOR DE HUEVOS'},
    {id: '513100' , nombre: 'VENTA AL POR MAYOR DE PRODUCTOS TEXTILES'},
    {id: '513910' , nombre: 'VENTA AL POR MAYOR DE MUEBLES'},
    {id: '513920' , nombre: 'VENTA AL POR MAYOR DE ARTÍCULOS ELÉCTRICOS Y ELECTRÓNICOS PARA EL HOGAR'},
    {id: '513930' , nombre: 'VENTA AL POR MAYOR DE ARTÍCULOS DE PERFUMERÍA'},
    {id: '513940' , nombre: 'VENTA AL POR MAYOR DE PAPEL Y CARTÓN'},
    {id: '513951' , nombre: 'VENTA AL POR MAYOR DE LIBROS'},
    {id: '513952' , nombre: 'VENTA AL POR MAYOR DE REVISTAS Y PERIÓDICOS'},
    {id: '513960' , nombre: 'VENTA AL POR MAYOR DE PRODUCTOS FARMACEUTICOS'},
    {id: '513970' , nombre: 'VENTA AL POR MAYOR DE INSTRUMENTOS CIENTÍFICOS Y QUIRÚRGICOS'},
    {id: '513990' , nombre: 'VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS N.C.P.'},
    {id: '514110' , nombre: 'VENTA AL POR MAYOR DE COMBUSTIBLES LÍQUIDOS'},
    {id: '514120' , nombre: 'VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS'},
    {id: '514130' , nombre: 'VENTA AL POR MAYOR DE COMBUSTIBLES GASEOSOS'},
    {id: '514140' , nombre: 'VENTA AL POR MAYOR DE PRODUCTOS CONEXOS A LOS COMBUSTIBLES'},
    {id: '514200' , nombre: 'VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS'},
    {id: '514310' , nombre: 'VENTA AL POR MAYOR DE MADERA NO TRABAJADA Y PRODUCTOS RESULTANTES DE SU ELABORACIÓN PRIMARIA'},
    {id: '514320' , nombre: 'VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN'},
    {id: '514910' , nombre: 'VENTA AL POR MAYOR DE PRODUCTOS QUÍMICOS'},
    {id: '514920' , nombre: 'VENTA AL POR MAYOR DE DESECHOS METÁLICOS (CHATARRA)'},
    {id: '514930' , nombre: 'VENTA AL POR MAYOR DE INSUMOS VETERINARIOS'},
    {id: '514990' , nombre: 'VENTA AL POR MAYOR DE OTROS PRODUCTOS INTERMEDIOS'},
    {id: '515001' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA AGRÍCOLA Y FORESTAL'},
    {id: '515002' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA METALÚRGICA'},
    {id: '515003' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA MINERÍA'},
    {id: '515004' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA CONSTRUCCIÓN'},
    {id: '515005' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS'},
    {id: '515006' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA PARA TEXTILES Y CUEROS'},
    {id: '515007' , nombre: 'VENTA AL POR MAYOR DE MÁQUINAS Y EQUIPOS DE OFICINA, INCLUYE MATERIALES CONEXOS'},
    {id: '515008' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA Y EQUIPO DE TRANSPORTE EXCEPTO VEHÍCULOS AUTOMOTORES'},
    {id: '515009' , nombre: 'VENTA AL POR MAYOR DE MAQUINARIA'},
    {id: '519000' , nombre: 'VENTA AL POR MAYOR DE OTROS PRODUCTOS N.C.P.'},
    {id: '521111' , nombre: 'GRANDES ESTABLECIMIENTOS (VENTA DE ALIMENTOS), HIPERMERCADOS'},
    {id: '521112' , nombre: 'ALMACENES MEDIANOS (VENTA DE ALIMENTOS), SUPERMERCADOS, MINIMARKETS'},
    {id: '521120' , nombre: 'ALMACENES PEQUENOS (VENTA DE ALIMENTOS)'},
    {id: '521200' , nombre: 'GRANDES TIENDAS - PRODUCTOS DE FERRETERÍA Y PARA EL HOGAR'},
    {id: '521300' , nombre: 'GRANDES TIENDAS - VESTUARIO Y PRODUCTOS PARA EL HOGAR'},
    {id: '521900' , nombre: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN PEQUENOS ALMACENES NO ESPECIALIZADOS'},
    {id: '522010' , nombre: 'VENTA AL POR MENOR DE BEBIDAS Y LICORES (BOTILLERÍAS)'},
    {id: '522020' , nombre: 'VENTA AL POR MENOR DE CARNES (ROJAS'},
    {id: '522030' , nombre: 'COMERCIO AL POR MENOR DE VERDURAS Y FRUTAS (VERDULERÍA)'},
    {id: '522040' , nombre: 'VENTA AL POR MENOR DE PESCADOS'},
    {id: '522050' , nombre: 'VENTA AL POR MENOR DE PRODUCTOS DE PANADERÍA Y PASTELERÍA'},
    {id: '522060' , nombre: 'VENTA AL POR MENOR DE ALIMENTOS PARA MASCOTAS Y ANIMALES EN GENERAL'},
    {id: '522070' , nombre: 'VENTA AL POR MENOR DE AVES Y HUEVOS'},
    {id: '522090' , nombre: 'VENTA AL POR MENOR DE PRODUCTOS DE CONFITERÍAS'},
    {id: '523111' , nombre: 'FARMACIAS - PERTENECIENTES A CADENA DE ESTABLECIMIENTOS'},
    {id: '523112' , nombre: 'FARMACIAS INDEPENDIENTES'},
    {id: '523120' , nombre: 'VENTA AL POR MENOR DE PRODUCTOS MEDICINALES'},
    {id: '523130' , nombre: 'VENTA AL POR MENOR DE ARTÍCULOS ORTOPÉDICOS'},
    {id: '523140' , nombre: 'VENTA AL POR MENOR DE ARTÍCULOS DE TOCADOR Y COSMÉTICOS'},
    {id: '523210' , nombre: 'VENTA AL POR MENOR DE CALZADO'},
    {id: '523220' , nombre: 'VENTA AL POR MENOR DE PRENDAS DE VESTIR EN GENERAL'},
    {id: '523230' , nombre: 'VENTA AL POR MENOR DE LANAS'},
    {id: '523240' , nombre: 'VENTA AL POR MENOR DE MALETERÍAS'},
    {id: '523250' , nombre: 'VENTA AL POR MENOR DE ROPA INTERIOR Y PRENDAS DE USO PERSONAL'},
    {id: '523290' , nombre: 'COMERCIO AL POR MENOR DE TEXTILES PARA EL HOGAR Y OTROS PRODUCTOS TEXTILES N.C.P.'},
    {id: '523310' , nombre: 'VENTA AL POR MENOR DE ARTÍCULOS ELECTRODOMÉSTICOS Y ELECTRÓNICOS PARA EL HOGAR'},
    {id: '523320' , nombre: 'VENTA AL POR MENOR DE CRISTALES'},
    {id: '523330' , nombre: 'VENTA AL POR MENOR DE MUEBLES, INCLUYE COLCHONES'},
    {id: '523340' , nombre: 'VENTA AL POR MENOR DE INSTRUMENTOS MUSICALES (CASA DE MÚSICA)'},
    {id: '523350' , nombre: 'VENTA AL POR MENOR DE DISCOS'},
    {id: '523360' , nombre: 'VENTA AL POR MENOR DE LÁMPARAS'},
    {id: '523390' , nombre: 'VENTA AL POR MENOR DE APARATOS'},
    {id: '523410' , nombre: 'VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA Y MATERIALES DE CONSTRUCCIÓN'},
    {id: '523420' , nombre: 'VENTA AL POR MENOR DE PINTURAS'},
    {id: '523430' , nombre: 'COMERCIO AL POR MENOR DE PRODUCTOS DE VIDRIO'},
    {id: '523911' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS FOTOGRÁFICOS'},
    {id: '523912' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS ÓPTICOS'},
    {id: '523921' , nombre: 'COMERCIO POR MENOR DE JUGUETES'},
    {id: '523922' , nombre: 'COMERCIO AL POR MENOR DE LIBROS'},
    {id: '523923' , nombre: 'COMERCIO AL POR MENOR DE REVISTAS Y DIARIOS'},
    {id: '523924' , nombre: 'COMERCIO DE ARTÍCULOS DE SUMINISTROS DE OFICINAS Y ARTÍCULOS DE ESCRITORIO EN GENERAL'},
    {id: '523930' , nombre: 'COMERCIO AL POR MENOR DE COMPUTADORAS'},
    {id: '523941' , nombre: 'COMERCIO AL POR MENOR DE ARMERÍAS'},
    {id: '523942' , nombre: 'COMERCIO AL POR MENOR DE BICICLETAS Y SUS REPUESTOS'},
    {id: '523943' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS DEPORTIVOS'},
    {id: '523950' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS DE JOYERÍA'},
    {id: '523961' , nombre: 'VENTA AL POR MENOR DE GAS LICUADO EN BOMBONAS'},
    {id: '523969' , nombre: 'VENTA AL POR MENOR DE CARBÓN'},
    {id: '523991' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS TÍPICOS (ARTESANÍAS)'},
    {id: '523992' , nombre: 'VENTA AL POR MENOR DE FLORES'},
    {id: '523993' , nombre: 'VENTA AL POR MENOR DE MASCOTAS Y ACCESORIOS'},
    {id: '523999' , nombre: 'VENTAS AL POR MENOR DE OTROS PRODUCTOS EN ALMACENES ESPECIALIZADOS N.C.P.'},
    {id: '524010' , nombre: 'COMERCIO AL POR MENOR DE ANTIGUEDADES'},
    {id: '524020' , nombre: 'COMERCIO AL POR MENOR DE ROPA USADA'},
    {id: '524090' , nombre: 'COMERCIO AL POR MENOR DE ARTÍCULOS Y ARTEFACTOS USADOS N.C.P.'},
    {id: '525110' , nombre: 'VENTA AL POR MENOR EN EMPRESAS DE VENTA A DISTANCIA POR CORREO'},
    {id: '525120' , nombre: 'VENTA AL POR MENOR EN EMPRESAS DE VENTA A DISTANCIA VÍA TELEFÓNICA'},
    {id: '525130' , nombre: 'VENTA AL POR MENOR EN EMPRESAS DE VENTA A DISTANCIA VÍA INTERNET, COMERCIO ELECTRÓNICO'},
    {id: '525200' , nombre: 'VENTA AL POR MENOR EN PUESTOS DE VENTA Y MERCADOS'},
    {id: '525911' , nombre: 'VENTA AL POR MENOR REALIZADA POR INDEPENDIENTES EN TRANSPORTE PÚBLICO (LEY 20.388)'},
    {id: '525919' , nombre: 'VENTA AL POR MENOR NO REALIZADA EN ALMACENES DE PRODUCTOS PROPIOS N.C.P.'},
    {id: '525920' , nombre: 'MÁQUINAS EXPENDEDORAS'},
    {id: '525930' , nombre: 'VENTA AL POR MENOR A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA'},
    {id: '525990' , nombre: 'OTROS TIPOS DE VENTA AL POR MENOR NO REALIZADA EN ALMACENES N.C.P.'},
    {id: '526010' , nombre: 'REPARACIÓN DE CALZADO Y OTROS ARTÍCULOS DE CUERO'},
    {id: '526020' , nombre: 'REPARACIONES ELÉCTRICAS Y ELECTRÓNICAS'},
    {id: '526030' , nombre: 'REPARACIÓN DE RELOJES Y JOYAS'},
    {id: '526090' , nombre: 'OTRAS REPARACIONES DE EFECTOS PERSONALES Y ENSERES DOMÉSTICOS N.C.P.'},
    {id: '551010' , nombre: 'HOTELES'},
    {id: '551020' , nombre: 'MOTELES'},
    {id: '551030' , nombre: 'RESIDENCIALES'},
    {id: '551090' , nombre: 'OTROS TIPOS DE HOSPEDAJE TEMPORAL COMO CAMPING'},
    {id: '552010' , nombre: 'RESTAURANTES'},
    {id: '552020' , nombre: 'ESTABLECIMIENTOS DE COMIDA RÁPIDA (BARES'},
    {id: '552030' , nombre: 'CASINOS Y CLUBES SOCIALES'},
    {id: '552040' , nombre: 'SERVICIOS DE COMIDA PREPARADA EN FORMA INDUSTRIAL'},
    {id: '552050' , nombre: 'SERVICIOS DE BANQUETES'},
    {id: '552090' , nombre: 'SERVICIOS DE OTROS ESTABLECIMIENTOS QUE EXPENDEN COMIDAS Y BEBIDAS'},
    {id: '601001' , nombre: 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRILES'},
    {id: '601002' , nombre: 'TRANSPORTE DE CARGA POR FERROCARRILES'},
    {id: '602110' , nombre: 'TRANSPORTE URBANO DE PASAJEROS VÍA FERROCARRIL (INCLUYE METRO)'},
    {id: '602120' , nombre: 'TRANSPORTE URBANO DE PASAJEROS VÍA AUTOBUS (LOCOMOCIÓN COLECTIVA)'},
    {id: '602130' , nombre: 'TRANSPORTE INTERURBANO DE PASAJEROS VÍA AUTOBUS'},
    {id: '602140' , nombre: 'TRANSPORTE URBANO DE PASAJEROS VÍA TAXI COLECTIVO'},
    {id: '602150' , nombre: 'SERVICIOS DE TRANSPORTE ESCOLAR'},
    {id: '602160' , nombre: 'SERVICIOS DE TRANSPORTE DE TRABAJADORES'},
    {id: '602190' , nombre: 'OTROS TIPOS DE TRANSPORTE REGULAR DE PASAJEROS POR VÍA TERRESTRE N.C.P.'},
    {id: '602210' , nombre: 'TRANSPORTES POR TAXIS LIBRES Y RADIOTAXIS'},
    {id: '602220' , nombre: 'SERVICIOS DE TRANSPORTE A TURISTAS'},
    {id: '602230' , nombre: 'TRANSPORTE DE PASAJEROS EN VEHÍCULOS DE TRACCIÓN HUMANA Y ANIMAL'},
    {id: '602290' , nombre: 'OTROS TIPOS DE TRANSPORTE NO REGULAR DE PASAJEROS N.C.P.'},
    {id: '602300' , nombre: 'TRANSPORTE DE CARGA POR CARRETERA'},
    {id: '603000' , nombre: 'TRANSPORTE POR TUBERÍAS'},
    {id: '611001' , nombre: 'TRANSPORTE MARÍTIMO Y DE CABOTAJE DE PASAJEROS'},
    {id: '611002' , nombre: 'TRANSPORTE MARÍTIMO Y DE CABOTAJE DE CARGA'},
    {id: '612001' , nombre: 'TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES'},
    {id: '612002' , nombre: 'TRANSPORTE DE CARGA POR VÍAS DE NAVEGACIÓN INTERIORES'},
    {id: '621010' , nombre: 'TRANSPORTE REGULAR POR VÍA AÉREA DE PASAJEROS'},
    {id: '621020' , nombre: 'TRANSPORTE REGULAR POR VÍA AÉREA DE CARGA'},
    {id: '622001' , nombre: 'TRANSPORTE NO REGULAR POR VÍA AÉREA DE PASAJEROS'},
    {id: '622002' , nombre: 'TRANSPORTE NO REGULAR POR VÍA AÉREA DE CARGA'},
    {id: '630100' , nombre: 'MANIPULACIÓN DE LA CARGA'},
    {id: '630200' , nombre: 'SERVICIOS DE ALMACENAMIENTO Y DEPÓSITO'},
    {id: '630310' , nombre: 'TERMINALES TERRESTRES DE PASAJEROS'},
    {id: '630320' , nombre: 'ESTACIONAMIENTO DE VEHÍCULOS Y PARQUÍMETROS'},
    {id: '630330' , nombre: 'PUERTOS Y AEROPUERTOS'},
    {id: '630340' , nombre: 'SERVICIOS PRESTADOS POR CONCESIONARIOS DE CARRETERAS'},
    {id: '630390' , nombre: 'OTRAS ACTIVIDADES CONEXAS AL TRANSPORTE N.C.P.'},
    {id: '630400' , nombre: 'AGENCIAS Y ORGANIZADORES DE VIAJES, ACTIVIDADES DE ASISTENCIA A TURISTAS N.C.P.'},
    {id: '630910' , nombre: 'AGENCIAS DE ADUANAS'},
    {id: '630920' , nombre: 'AGENCIAS DE TRANSPORTE'},
    {id: '641100' , nombre: 'ACTIVIDADES POSTALES NACIONALES'},
    {id: '641200' , nombre: 'ACTIVIDADES DE CORREO DISTINTAS DE LAS ACTIVIDADES POSTALES NACIONALES'},
    {id: '642010' , nombre: 'SERVICIOS DE TELEFONÍA FIJA'},
    {id: '642020' , nombre: 'SERVICIOS DE TELEFONÍA MÓVIL'},
    {id: '642030' , nombre: 'PORTADORES TELEFÓNICOS (LARGA DISTANCIA NACIONAL E INTERNACIONAL)'},
    {id: '642040' , nombre: 'SERVICIOS DE TELEVISIÓN NO ABIERTA'},
    {id: '642050' , nombre: 'PROVEEDORES DE INTERNET'},
    {id: '642061' , nombre: 'CENTROS DE LLAMADOS, INCLUYE ENVÍO DE FAX'},
    {id: '642062' , nombre: 'CENTROS DE ACCESO A INTERNET'},
    {id: '642090' , nombre: 'OTROS SERVICIOS DE TELECOMUNICACIONES N.C.P.'},
    {id: '651100' , nombre: 'BANCA CENTRAL'},
    {id: '651910' , nombre: 'BANCOS'},
    {id: '651920' , nombre: 'FINANCIERAS'},
    {id: '651990' , nombre: 'OTROS TIPOS DE INTERMEDIACIÓN MONETARIA N.C.P.'},
    {id: '659110' , nombre: 'LEASING FINANCIERO'},
    {id: '659120' , nombre: 'LEASING HABITACIONAL'},
    {id: '659210' , nombre: 'FINANCIAMIENTO DEL FOMENTO DE LA PRODUCCIÓN'},
    {id: '659220' , nombre: 'ACTIVIDADES DE CRÉDITO PRENDARIO'},
    {id: '659231' , nombre: 'FACTORING'},
    {id: '659232' , nombre: 'SECURITIZADORAS'},
    {id: '659290' , nombre: 'OTROS INSTITUCIONES FINANCIERAS N.C.P.'},
    {id: '659911' , nombre: 'ADMINISTRADORAS DE FONDOS DE INVERSIÓN'},
    {id: '659912' , nombre: 'ADMINISTRADORAS DE FONDOS MUTUOS'},
    {id: '659913' , nombre: 'ADMINISTRADORAS DE FICES (FONDOS DE INVERSIÓN DE CAPITAL EXTRANJERO)'},
    {id: '659914' , nombre: 'ADMINISTRADORAS DE FONDOS PARA LA VIVIENDA'},
    {id: '659915' , nombre: 'ADMINISTRADORAS DE FONDOS PARA OTROS FINES Y/O GENERALES'},
    {id: '659920' , nombre: 'SOCIEDADES DE INVERSIÓN Y RENTISTAS DE CAPITALES MOBILIARIOS EN GENERAL'},
    {id: '660101' , nombre: 'PLANES DE SEGURO DE VIDA'},
    {id: '660102' , nombre: 'PLANES DE REASEGUROS DE VIDA'},
    {id: '660200' , nombre: 'ADMINISTRADORAS DE FONDOS DE PENSIONES (AFP)'},
    {id: '660301' , nombre: 'PLANES DE SEGUROS GENERALES'},
    {id: '660302' , nombre: 'PLANES DE REASEGUROS GENERALES'},
    {id: '660400' , nombre: 'ISAPRES'},
    {id: '671100' , nombre: 'ADMINISTRACIÓN DE MERCADOS FINANCIEROS'},
    {id: '671210' , nombre: 'CORREDORES DE BOLSA'},
    {id: '671220' , nombre: 'AGENTES DE VALORES'},
    {id: '671290' , nombre: 'OTROS SERVICIOS DE CORRETAJE'},
    {id: '671910' , nombre: 'CÁMARA DE COMPENSACIÓN'},
    {id: '671921' , nombre: 'ADMINISTRADORA DE TARJETAS DE CRÉDITO'},
    {id: '671929' , nombre: 'EMPRESAS DE ASESORÍA'},
    {id: '671930' , nombre: 'CLASIFICADORES DE RIESGOS'},
    {id: '671940' , nombre: 'CASAS DE CAMBIO Y OPERADORES DE DIVISA'},
    {id: '671990' , nombre: 'OTRAS ACTIVIDADES AUXILIARES DE LA INTERMEDIACIÓN FINANCIERA N.C.P.'},
    {id: '672010' , nombre: 'CORREDORES DE SEGUROS'},
    {id: '672020' , nombre: 'AGENTES Y LIQUIDADORES DE SEGUROS'},
    {id: '672090' , nombre: 'OTRAS ACTIVIDADES AUXILIARES DE LA FINANCIACIÓN DE PLANES DE SEGUROS Y DE PENSIONES N.C.P.'},
    {id: '701001' , nombre: 'ARRIENDO DE INMUEBLES AMOBLADOS O CON EQUIPOS Y MAQUINARIAS'},
    {id: '701009' , nombre: 'COMPRA'},
    {id: '702000' , nombre: 'CORREDORES DE PROPIEDADES'},
    {id: '711101' , nombre: 'ALQUILER DE AUTOS Y CAMIONETAS SIN CHOFER'},
    {id: '711102' , nombre: 'ALQUILER DE OTROS EQUIPOS DE TRANSPORTE POR VÍA TERRESTRE SIN OPERARIOS'},
    {id: '711200' , nombre: 'ALQUILER DE TRANSPORTE POR VÍA ACUÁTICA SIN TRIPULACIÓN'},
    {id: '711300' , nombre: 'ALQUILER DE EQUIPO DE TRANSPORTE POR VÍA AÉREA SIN TRIPULANTES'},
    {id: '712100' , nombre: 'ALQUILER DE MAQUINARIA Y EQUIPO AGROPECUARIO'},
    {id: '712200' , nombre: 'ALQUILER DE MAQUINARIA Y EQUIPO DE CONSTRUCCIÓN E INGENIERÍA CIVIL'},
    {id: '712300' , nombre: 'ALQUILER DE MAQUINARIA Y EQUIPO DE OFICINA (SIN OPERARIOS NI SERVICIO ADMINISTRATIVO)'},
    {id: '712900' , nombre: 'ALQUILER DE OTROS TIPOS DE MAQUINARIAS Y EQUIPOS N.C.P.'},
    {id: '713010' , nombre: 'ALQUILER DE BICICLETAS Y ARTÍCULOS PARA DEPORTES'},
    {id: '713020' , nombre: 'ARRIENDO DE VIDEOS'},
    {id: '713030' , nombre: 'ALQUILER DE MOBILIARIO PARA EVENTOS (SILLAS'},
    {id: '713090' , nombre: 'ALQUILER DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS N.C.P.'},
    {id: '722000' , nombre: 'ASESORES Y CONSULTORES EN INFORMÁTICA (SOFTWARE)'},
    {id: '724000' , nombre: 'PROCESAMIENTO DE DATOS Y ACTIVIDADES RELACIONADAS CON BASES DE DATOS'},
    {id: '726000' , nombre: 'EMPRESAS DE SERVICIOS INTEGRALES DE INFORMÁTICA'},
    {id: '725000' , nombre: 'MANTENIMIENTO Y REPARACIÓN DE MAQUINARIA DE OFICINA'},
    {id: '731000' , nombre: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA'},
    {id: '732000' , nombre: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES'},
    {id: '741110' , nombre: 'SERVICIOS JURÍDICOS'},
    {id: '741120' , nombre: 'SERVICIO NOTARIAL'},
    {id: '741130' , nombre: 'CONSERVADOR DE BIENES RAICES'},
    {id: '741140' , nombre: 'RECEPTORES JUDICIALES'},
    {id: '741190' , nombre: 'ARBITRAJES'},
    {id: '741200' , nombre: 'ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA, ASESORAMIENTOS TRIBUTARIOS'},
    {id: '741300' , nombre: 'INVESTIGACIÓN DE MERCADOS Y REALIZACIÓN DE ENCUESTAS DE OPINIÓN PÚBLICA'},
    {id: '741400' , nombre: 'ACTIVIDADES DE ASESORAMIENTO EMPRESARIAL Y EN MATERIA DE GESTIÓN'},
    {id: '742110' , nombre: 'SERVICIOS DE ARQUITECTURA Y TÉCNICO RELACIONADO'},
    {id: '742121' , nombre: 'EMPRESAS DE SERVICIOS GEOLÓGICOS Y DE PROSPECCIÓN'},
    {id: '742122' , nombre: 'SERVICIOS PROFESIONALES EN GEOLOGÍA Y PROSPECCIÓN'},
    {id: '742131' , nombre: 'EMPRESAS DE SERVICIOS DE TOPOGRAFÍA Y AGRIMENSURA'},
    {id: '742132' , nombre: 'SERVICIOS PROFESIONALES DE TOPOGRAFÍA Y AGRIMENSURA'},
    {id: '742141' , nombre: 'SERVICIOS DE INGENIERÍA PRESTADOS POR EMPRESAS N.C.P.'},
    {id: '742142' , nombre: 'SERVICIOS DE INGENIERÍA PRESTADOS POR PROFESIONALES N.C.P.'},
    {id: '742190' , nombre: 'OTROS SERVICIOS DESARROLLADOS POR PROFESIONALES'},
    {id: '742210' , nombre: 'SERVICIO DE REVISIÓN TÉCNICA DE VEHÍCULOS AUTOMOTORES'},
    {id: '742290' , nombre: 'OTROS SERVICIOS DE ENSAYOS Y ANALISIS TÉCNICOS'},
    {id: '743001' , nombre: 'EMPRESAS DE PUBLICIDAD'},
    {id: '743002' , nombre: 'SERVICIOS PERSONALES EN PUBLICIDAD'},
    {id: '749110' , nombre: 'SERVICIOS SUMINISTRO DE PERSONAL, EMPRESAS SERVICIOS TRANSITORIOS'},
    {id: '749190' , nombre: 'SERVICIOS DE RECLUTAMIENTO DE PERSONAL'},
    {id: '749210' , nombre: 'ACTIVIDADES DE INVESTIGACIÓN'},
    {id: '749221' , nombre: 'SERVICIOS INTEGRALES DE SEGURIDAD'},
    {id: '749222' , nombre: 'TRANSPORTE DE VALORES'},
    {id: '749229' , nombre: 'SERVICIOS PERSONALES RELACIONADOS CON SEGURIDAD'},
    {id: '749310' , nombre: 'EMPRESAS DE LIMPIEZA DE EDIFICIOS RESIDENCIALES Y NO RESIDENCIALES'},
    {id: '749320' , nombre: 'DESRATIZACIÓN Y FUMIGACIÓN NO AGRÍCOLA'},
    {id: '749401' , nombre: 'SERVICIOS DE REVELADO'},
    {id: '749402' , nombre: 'ACTIVIDADES DE FOTOGRAFÍA PUBLICITARIA'},
    {id: '749409' , nombre: 'SERVICIOS PERSONALES DE FOTOGRAFÍA'},
    {id: '749500' , nombre: 'SERVICIOS DE ENVASADO Y EMPAQUE'},
    {id: '749911' , nombre: 'SERVICIOS DE COBRANZA DE CUENTAS'},
    {id: '749912' , nombre: 'EVALUACIÓN Y CALIFICACIÓN DEL GRADO DE SOLVENCIA'},
    {id: '749913' , nombre: 'ASESORÍAS EN LA GESTIÓN DE LA COMPRA O VENTA DE PEQUENAS Y MEDIANAS EMPRESAS'},
    {id: '749921' , nombre: 'DISENADORES DE VESTUARIO'},
    {id: '749922' , nombre: 'DISENADORES DE INTERIORES'},
    {id: '749929' , nombre: 'OTROS DISENADORES N.C.P.'},
    {id: '749931' , nombre: 'EMPRESAS DE TAQUIGRAFÍA'},
    {id: '749932' , nombre: 'SERVICIOS PERSONALES DE TRADUCCIÓN'},
    {id: '749933' , nombre: 'EMPRESAS DE TRADUCCIÓN E INTERPRETACIÓN'},
    {id: '749934' , nombre: 'SERVICIOS DE FOTOCOPIAS'},
    {id: '749940' , nombre: 'AGENCIAS DE CONTRATACIÓN DE ACTORES'},
    {id: '749950' , nombre: 'ACTIVIDADES DE SUBASTA (MARTILLEROS)'},
    {id: '749961' , nombre: 'GALERÍAS DE ARTE'},
    {id: '749962' , nombre: 'FERIAS DE EXPOSICIONES CON FINES EMPRESARIALES'},
    {id: '749970' , nombre: 'SERVICIOS DE CONTESTACIÓN DE LLAMADAS (CALL CENTER)'},
    {id: '749990' , nombre: 'OTRAS ACTIVIDADES EMPRESARIALES N.C.P.'},
    {id: '751110' , nombre: 'GOBIERNO CENTRAL'},
    {id: '751120' , nombre: 'MUNICIPALIDADES'},
    {id: '751200' , nombre: 'ACTIVIDADES DEL PODER JUDICIAL'},
    {id: '751300' , nombre: 'ACTIVIDADES DEL PODER LEGISLATIVO'},
    {id: '752100' , nombre: 'RELACIONES EXTERIORES'},
    {id: '752200' , nombre: 'ACTIVIDADES DE DEFENSA'},
    {id: '752300' , nombre: 'ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD'},
    {id: '753010' , nombre: 'ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA RELACIONADOS CON SALUD'},
    {id: '753020' , nombre: 'CAJAS DE COMPENSACIÓN'},
    {id: '753090' , nombre: 'OTRAS ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA'},
    {id: '801010' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA PREESCOLAR'},
    {id: '801020' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA PRIMARIA'},
    {id: '802100' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA SECUNDARIA DE FORMACIÓN GENERAL'},
    {id: '802200' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA SECUNDARIA DE FORMACIÓN TÉCNICA Y PROFESIONAL'},
    {id: '803010' , nombre: 'UNIVERSIDADES'},
    {id: '803020' , nombre: 'INSTITUTOS PROFESIONALES'},
    {id: '803030' , nombre: 'CENTROS DE FORMACIÓN TÉCNICA'},
    {id: '809010' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA PRIMARIA Y SECUNDARIA PARA ADULTOS'},
    {id: '809020' , nombre: 'ESTABLECIMIENTOS DE ENSEÑANZA PREUNIVERSITARIA'},
    {id: '809030' , nombre: 'EDUCACIÓN EXTRAESCOLAR (ESCUELA DE CONDUCCIÓN'},
    {id: '809041' , nombre: 'EDUCACIÓN A DISTANCIA (INTERNET'},
    {id: '809049' , nombre: 'SERVICIOS PERSONALES DE EDUCACIÓN'},
    {id: '851110' , nombre: 'HOSPITALES Y CLÍNICAS'},
    {id: '851120' , nombre: 'CLÍNICAS PSIQUIATRICAS'},
    {id: '851211' , nombre: 'SERVICIOS DE MÉDICOS EN FORMA INDEPENDIENTE'},
    {id: '851212' , nombre: 'ESTABLECIMIENTOS MÉDICOS DE ATENCIÓN AMBULATORIA (CENTROS MÉDICOS)'},
    {id: '851221' , nombre: 'SERVICIOS DE ODONTÓLOGOS EN FORMA INDEPENDIENTE'},
    {id: '851222' , nombre: 'CENTROS DE ATENCIÓN ODONTOLÓGICA'},
    {id: '851910' , nombre: 'LABORATORIOS CLÍNICOS, INCLUYE BANCOS DE SANGRE'},
    {id: '851920' , nombre: 'OTROS PROFESIONALES DE LA SALUD'},
    {id: '851990' , nombre: 'OTRAS ACTIVIDADES EMPRESARIALES RELACIONADAS CON LA SALUD HUMANA'},
    {id: '852010' , nombre: 'ACTIVIDADES DE CLÍNICAS VETERINARIAS'},
    {id: '852021' , nombre: 'SERVICIOS DE MÉDICOS VETERINARIOS EN FORMA INDEPENDIENTE'},
    {id: '852029' , nombre: 'SERVICIOS DE OTROS PROFESIONALES INDEPENDIENTES EN EL ÁREA VETERINARIA'},
    {id: '853100' , nombre: 'SERVICIOS SOCIALES CON ALOJAMIENTO'},
    {id: '853200' , nombre: 'SERVICIOS SOCIALES SIN ALOJAMIENTO'},
    {id: '900010' , nombre: 'SERVICIOS DE VERTEDEROS'},
    {id: '900020' , nombre: 'BARRIDO DE EXTERIORES'},
    {id: '900030' , nombre: 'RECOGIDA Y ELIMINACIÓN DE DESECHOS'},
    {id: '900040' , nombre: 'SERVICIOS DE EVACUACIÓN DE RILES Y AGUAS SERVIDAS'},
    {id: '900050' , nombre: 'SERVICIOS DE TRATAMIENTO DE RILES Y AGUAS SERVIDAS'},
    {id: '900090' , nombre: 'OTRAS ACTIVIDADES DE MANEJO DE DESPERDICIOS'},
    {id: '911100' , nombre: 'ACTIVIDADES DE ORGANIZACIONES EMPRESARIALES Y DE EMPLEADORES'},
    {id: '911210' , nombre: 'COLEGIOS PROFESIONALES'},
    {id: '911290' , nombre: 'ACTIVIDADES DE OTRAS ORGANIZACIONES PROFESIONALES'},
    {id: '912000' , nombre: 'ACTIVIDADES DE SINDICATOS'},
    {id: '919100' , nombre: 'ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS'},
    {id: '919200' , nombre: 'ACTIVIDADES DE ORGANIZACIONES POLÍTICAS'},
    {id: '919910' , nombre: 'CENTROS DE MADRES Y UNIDADES VECINALES Y COMUNALES'},
    {id: '919920' , nombre: 'CLUBES SOCIALES'},
    {id: '919930' , nombre: 'SERVICIOS DE INSTITUTOS DE ESTUDIOS'},
    {id: '919990' , nombre: 'ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.'},
    {id: '921110' , nombre: 'PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS'},
    {id: '921120' , nombre: 'DISTRIBUIDORA CINEMATOGRÁFICAS'},
    {id: '921200' , nombre: 'EXHIBICIÓN DE FILMES Y VIDEOCINTAS'},
    {id: '921310' , nombre: 'ACTIVIDADES DE TELEVISIÓN'},
    {id: '921320' , nombre: 'ACTIVIDADES DE RADIO'},
    {id: '921411' , nombre: 'SERVICIOS DE PRODUCCIÓN DE RECITALES Y OTROS EVENTOS MUSICALES MASIVOS'},
    {id: '921419' , nombre: 'SERVICIOS DE PRODUCCIÓN TEATRAL Y OTROS N.C.P.'},
    {id: '921420' , nombre: 'ACTIVIDADES EMPRESARIALES DE ARTISTAS'},
    {id: '921430' , nombre: 'ACTIVIDADES ARTÍSTICAS, FUNCIONES DE ARTISTAS, ACTORES, MÚSICOS, CONFERENCISTAS, OTROS'},
    {id: '921490' , nombre: 'AGENCIAS DE VENTA DE BILLETES DE TEATRO'},
    {id: '921911' , nombre: 'INSTRUCTORES DE DANZA'},
    {id: '921912' , nombre: 'ACTIVIDADES DE DISCOTECAS'},
    {id: '921920' , nombre: 'ACTIVIDADES DE PARQUES DE ATRACCIONES Y CENTROS SIMILARES'},
    {id: '921930' , nombre: 'ESPECTÁCULOS CIRCENSES'},
    {id: '921990' , nombre: 'OTRAS ACTIVIDADES DE ENTRETENIMIENTO N.C.P.'},
    {id: '922001' , nombre: 'AGENCIAS DE NOTICIAS'},
    {id: '922002' , nombre: 'SERVICIOS PERIODÍSTICOS PRESTADO POR PROFESIONALES'},
    {id: '923100' , nombre: 'ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS'},
    {id: '923200' , nombre: 'ACTIVIDADES DE MUSEOS Y PRESERVACIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS'},
    {id: '923300' , nombre: 'ACTIVIDADES DE JARDINES BOTÁNICOS Y ZOOLÓGICOS Y DE PARQUES NACIONALES'},
    {id: '924110' , nombre: 'EXPLOTACIÓN DE INSTALACIONES ESPECIALIZADAS PARA LAS PRACTICAS DEPORTIVAS'},
    {id: '924120' , nombre: 'ACTIVIDADES DE CLUBES DE DEPORTES Y ESTADIOS'},
    {id: '924131' , nombre: 'FUTBOL PROFESIONAL'},
    {id: '924132' , nombre: 'FUTBOL AMATEUR'},
    {id: '924140' , nombre: 'HIPÓDROMOS'},
    {id: '924150' , nombre: 'PROMOCIÓN Y ORGANIZACIÓN DE ESPECTÁCULOS DEPORTIVOS'},
    {id: '924160' , nombre: 'ESCUELAS PARA DEPORTES'},
    {id: '924190' , nombre: 'OTRAS ACTIVIDADES RELACIONADAS AL DEPORTE N.C.P.'},
    {id: '924910' , nombre: 'SISTEMAS DE JUEGOS DE AZAR MASIVOS.'},
    {id: '924920' , nombre: 'ACTIVIDADES DE CASINO DE JUEGOS'},
    {id: '924930' , nombre: 'SALAS DE BILLAR'},
    {id: '924940' , nombre: 'CONTRATACIÓN DE ACTORES PARA CINE'},
    {id: '924990' , nombre: 'OTROS SERVICIOS DE DIVERSIÓN Y ESPARCIMIENTOS N.C.P.'},
    {id: '930100' , nombre: 'LAVADO Y LIMPIEZA DE PRENDAS DE TELA Y DE PIEL'},
    {id: '930200' , nombre: 'PELUQUERÍAS Y SALONES DE BELLEZA'},
    {id: '930310' , nombre: 'SERVICIOS FUNERARIOS'},
    {id: '930320' , nombre: 'SERVICIOS EN CEMENTERIOS'},
    {id: '930330' , nombre: 'SERVICIOS DE CARROZAS FÚNEBRES (TRANSPORTE DE CADÁVERES)'},
    {id: '930390' , nombre: 'OTRAS ACTIVIDADES DE SERVICIOS FUNERARIOS Y OTRAS ACTIVIDADES CONEXAS'},
    {id: '930910' , nombre: 'ACTIVIDADES DE MANTENIMIENTO FÍSICO CORPORAL (BAÑOS'},
    {id: '930990' , nombre: 'OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.'},
    {id: '950001' , nombre: 'HOGARES PRIVADOS INDIVIDUALES CON SERVICIO DOMÉSTICO'},
    {id: '950002' , nombre: 'CONSEJO DE ADMINISTRACIÓN DE EDIFICIOS Y CONDOMINIOS'},
    {id: '990000' , nombre: 'ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES'}
]

export default Actividades