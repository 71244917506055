import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, selectHOR, addFetch, delFetch, resetStoreHOR, resetErrorHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../services/api'

const t = makeTypes('profile')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETERROR = t('reset-error').single()

const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const selectPerfil = mac(SELECT, 'payload')
export const resetErrorPerfiles = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT} ))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, deleteReducer, resetStoreReducer, resetErrorReducer)

export const fetchProfiles = forced => async (dispatch, getState) => {
    const state = getState()
    if(state.Profiles.data.length === 0 || forced){
        dispatch(fetchActions.start())
        try {
            const data = await getRequest('/profiles')
            dispatch(fetchActions.success(data.data))
        } catch (error) {
            dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
        }
    }
}

export const editPerfiles = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/profiles`, values.id, values)
        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}

export const crearPerfil = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/profiles`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}

export const borrarPerfil = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    try {
        const data = await deleteRequest(`/profiles`, id)
        dispatch(deleteActions.success(data.data.id))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}

