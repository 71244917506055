import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, resetStoreHOR, addFetch } from './hors.js'
import { getRequest, postImageRequest } from '../services/api'

const t = makeTypes('friends')
const FETCH = t('fetch').async()
const ADD = t('add').async()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))

const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, resetStoreReducer, addReducer )

export const fetchFriendsCustomers = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/customers/get-friends?id=${values}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const fetchPagadoresUsuarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/users/get-friends?id=${values}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}