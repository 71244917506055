import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import Tabla from '../../components/Tabla'
import Backdrop from '../../components/Backdrop'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { currency2decimal } from '../../services/utils'
import MenuBalances from '../../components/MenuBalances';

const useStyles = makeStyles( theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    }
}));

const BalancesCuentas = props => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const workingaccounts = useSelector( state => state.Workingaccounts )
    const loading = useSelector( state => state.Workingaccounts.fetching )

    const fetchData = () => {
        dispatch(fetchWorkingaccounts({country: "Venezuela", active: true}))
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Grid className={classes.root}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Estatus Cajeros</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>

            <Hidden smDown>
                <MenuBalances />
            </Hidden>

            <Tabla 
                actualizar={() => fetchData()}
                grouping={true}
                columnas={[
                    { title: 'Cajero', field: 'cajero', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Total', field: 'saldoTotal', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Disp.', field: 'saldoDisp', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Fiat OB', field: 'fiat_ob', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Necesario MB', field: 'necesario_mb', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Necesario OB', field: 'necesario_ob', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'CupoMB', field: 'cupo_mb', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'CupoOB', field: 'cupo_ob', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Cupo Total', field: 'cupo', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Comisiones hoy', field: 'comisiones', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Total Dep.', field: 'abonos', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Deudor', field: 'saldoDeudor', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                ]}
                data={workingaccounts.data.map(x => ({
                    ...x,
                    comisiones: x.comisiones ? currency2decimal(x.comisiones) : 0,
                    saldoTotal: x.balance && x.balance.saldoTotal ? currency2decimal(x.balance.saldoTotal) : 0,
                    saldoDisp:  x.balance && x.balance.saldoDisp ? currency2decimal(x.balance.saldoDisp) : 0,
                    saldoDeudor: x.balance && x.balance.saldoDeudor ? currency2decimal(x.balance.saldoDeudor) : 0,
                    abonos: x.abonos ? currency2decimal(x.abonos) : 0,
                    fiat_ob: x.fiat_ob ? currency2decimal(x.fiat_ob) : 0,
                    cupo_mb: x.cupo_mb ? currency2decimal(x.cupo_mb - x.necesario_mb - x.fiat_mb) : "Sin límites",
                    cupo_ob: x.cupo_ob ? currency2decimal(x.cupo_ob - x.necesario_ob - x.fiat_ob) : "Sin límites",
                    necesario_ob: x.necesario_ob ? currency2decimal(x.necesario_ob) : 0,
                    necesario_mb: x.necesario_mb ? currency2decimal(x.necesario_mb) : 0,
                    cupo: x.balance ? currency2decimal(x.balance.saldoDeudor - x.necesario_mb - x.necesario_ob) : 0,
                    cajero: x.nombre

                }))}
            />

            <Backdrop open={loading} />

        </Grid>
    )
}

export default BalancesCuentas