import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function SimpleMenu(props) {
  const { 
    asociaciones, 
    verificacionUsuario, 
    usuario, 
    handleRechazar, 
    handleArchivos, 
    handleVerArchivos,
    rechazar, 
    handleAprobarTransaccion, 
    handleAsociaciones, 
    aprobacionTransaccion, 
    asignar, 
    editar,
    handleAsignar, 
    archivo, 
    activar, 
    borrar, 
    ver, 
    registro, 
    handleEditar, 
    handleActivar, 
    handleBorrar,
    desvincularUsuario,
    desvincularCliente,
    handleDesvincularCliente,
    handleDesvincularUsuario,
    agregarPagos,
    handleAgregarPagos,
    codigos,
    handleCodigos,
    empresa,
    handleEmpresa,
    editarEmpresa,
    handleEditarEmpresa,
    pagadores,
    handlePagadores,
    desvincularDeUsuario,
    handleDesvincularDeUsuario,
    seleccionar,
    handleSeleccionar
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon /> 
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {seleccionar && <MenuItem onClick={() => {handleSeleccionar();handleClose()}} >Seleccionar</MenuItem>}
        {verificacionUsuario && !usuario && <MenuItem onClick={() => {handleClose(); handleArchivos()}} >Verificar Usuario</MenuItem>}
        {ver && archivo && <MenuItem onClick={() => {handleVerArchivos();handleClose()}}>Ver Archivo</MenuItem>}
        {usuario && aprobacionTransaccion && <MenuItem onClick={() => {handleAprobarTransaccion();handleClose()}}>Aprobar</MenuItem>}
        {asignar && <MenuItem onClick={() => {handleAsignar();handleClose()}}>Asignar</MenuItem>}
        {asociaciones && <MenuItem onClick={() => {handleAsociaciones(); handleClose()} }>Ver Asociaciones</MenuItem>}
        {pagadores && <MenuItem onClick={() => {handlePagadores(); handleClose()} }>Ver Pagadores</MenuItem>}
        {editar && <MenuItem onClick={() => {handleEditar(); handleClose()} }>Editar</MenuItem>}
        {activar && <MenuItem onClick={() => {handleActivar(); handleClose()} }>{registro && registro.active ? "Desactivar" : "Activar"}</MenuItem>}
        {rechazar && <MenuItem onClick={() => {handleRechazar(); handleClose()} }>Rechazar</MenuItem>}
        {borrar && <MenuItem onClick={() => {handleBorrar(); handleClose()} }>Borrar</MenuItem>}
        {desvincularUsuario && <MenuItem onClick={() => {handleDesvincularUsuario(); handleClose()} }>Borrar</MenuItem>}
        {desvincularCliente && <MenuItem onClick={() => {handleDesvincularCliente(); handleClose()} }>Borrar</MenuItem>}
        {desvincularDeUsuario && <MenuItem onClick={() => {handleDesvincularDeUsuario(); handleClose()} }>Desvincular de Usuario</MenuItem>}
        {agregarPagos && <MenuItem onClick={() => {handleAgregarPagos(); handleClose()} }>Agregar Pagos</MenuItem>}
        {codigos && <MenuItem onClick={() => {handleCodigos(); handleClose()} }>Agregar Códigos</MenuItem>}
        {empresa && <MenuItem onClick={() => {handleEmpresa(); handleClose()} }>Agregar Empresa</MenuItem>}
        {editarEmpresa && <MenuItem onClick={() => {handleEditarEmpresa(); handleClose()} }>Editar Empresa</MenuItem>}
      </Menu>
    </div>
  );
}