import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, getFormMeta, change } from 'redux-form'
import { Button, Typography, Grid, Container } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import Loading from '../components/Loading'

const validate = (values, props) => {
  const { minimo, maximo, tasaAlternativa } = props

  const errors = {}
  const requiredFields = [
    'origen',
    'destino',
    'pais_origen',
    'pais_destino',
    'tasa',
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.origen && values.origen < minimo){
    errors.origen = `El monto mínimo a enviar es de ${minimo}`
  }

  if (values.origen && values.origen > maximo){
    errors.origen = `El monto máximo a enviar es de ${maximo}`
  }

  if(!tasaAlternativa){
    errors.pais_origen = `Este cambio no esta disponible`
    errors.pais_destino = `Este cambio no esta disponible`
  }

  return errors
}

const currency = value => {
  if(typeof(value) === "number"){
    value = value.toString()
  }
  let stringValue = value && value.replace(/\./g, '').replace(/\,/g, '');
  let valor = stringValue && parseFloat(stringValue);
  valor = valor && valor.toLocaleString('en-US').replace(/\,/g, '.');
  return valor ? valor : '';
};

const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}

let FormMonto = props => {
      const { handleSubmit, efectivo, tasa, tasa2, tasaAlternativa, formMeta, change, tasas, submitting, monto_destino, monto_origen, origen, destino, initialValues } = props
      
      setTimeout(() => {

        if(formMeta && formMeta.destino && formMeta.destino.active){
          if(tasa2 || tasaAlternativa ){
            change('origen', tasa2 ? Math.ceil(monto_destino / tasa2.tasa) : Math.ceil(monto_destino / tasaAlternativa.tasa ) )
            change('tasa', tasa ? tasa : tasaAlternativa)
          }
        }else{
          if(formMeta && formMeta.origen && formMeta.origen.active){
            if(tasa || tasaAlternativa){
              change('destino', tasa ? Math.floor(monto_origen * tasa.tasa) : Math.floor(monto_origen * tasaAlternativa.tasa) )
              change('tasa', tasa ? tasa : tasaAlternativa)
            }
          }
        }     
      },0)

      return(
          <Container>
                <form noValidate onSubmit={handleSubmit} >
                  <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Field
                                disabled
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                name="pais_origen"
                                options={tasas.data.filter(x => x.efectivo === efectivo).filter((value, index, self) => self.map(x => x.origen.nombre).indexOf(value.origen.nombre) === index)}
                                etiqueta="origen"
                                disableClearable={true}
                                defaultValue={initialValues.tasa}
                                component={SelectSearch}/>
                              
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                              <Field
                                type="text"
                                name="origen"
                                label="Tu envías"
                                format={currency}
                                normalize={numero}
                                component={TextField}
                              />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Field
                                disabled
                                required
                                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                                type="text"
                                change={change}
                                name="pais_destino"
                                etiqueta="destino"
                                disableClearable={true}
                                defaultValue={initialValues.tasa}
                                options={tasas.data.filter(x => x.efectivo === efectivo).filter(x => x.origen_id === origen).filter((value, index, self) => self.map(x => x.destino.nombre).indexOf(value.destino.nombre) === index)}
                                component={SelectSearch}/>

                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                              <Field
                                type="text"
                                format={currency}
                                normalize={numero}
                                name="destino"
                                label="Tu destinatario recibe"
                                component={TextField}
                              />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                          { submitting ? <Loading /> :
                            <Button variant="contained" color="primary" type="submit">
                                Agregar Transacción
                            </Button>}
                        </Grid>
                    </Grid>
                  </Grid>
                </form>
            </Container>
        )
    }

FormMonto = reduxForm({
  form: 'monto',
})(FormMonto);
    
const selector = formValueSelector('monto')

const mapStateToProps = state => {
  const origen = selector(state, 'pais_origen')
  const destino = selector(state, 'pais_destino')
  const monto_origen = selector(state, 'origen')
  const monto_destino = selector(state, 'destino')
  const efectivo = state.DetalleCambio.data ? state.DetalleCambio.data.efectivo : false
  const formMeta = getFormMeta('monto')(state)
  const tasa = state.Tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === origen) && (x.destino_id === destino) && ( x.rango.minimo <= monto_origen && x.rango.maximo >= monto_origen ) ) )
  const tasaRango = Math.min(...state.Tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === origen) && (x.destino_id === destino))).map(x => x.tasa))
  const tasa2 = state.Tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === origen) && (x.destino_id === destino) && ( x.rango.minimo <= monto_destino/tasaRango && x.rango.maximo >= monto_destino/tasaRango ) ) )
  const tasaAlternativa = state.Tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === origen) && (x.destino_id === destino) ) )      
  const minimo = Math.min(...state.Tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === origen) && (x.destino_id === destino))).map(x => x.rango.minimo)) * 7 /100
  const maximo = state.DetalleCambio.data.origen

  
  return{
    efectivo,
    minimo,
    maximo,
    tasa,
    tasa2,
    tasaAlternativa,
    origen,
    destino,
    monto_origen,
    monto_destino,
    formMeta,
    initialValues:{
      pais_origen: state.Tasas.data.find(x => (x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id)) && state.Tasas.data.find(x => x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id).origen_id,
      pais_destino: state.Tasas.data.find(x => (x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id)) && state.Tasas.data.find(x => x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id).destino_id,
      tasa: state.Tasas.data.find(x => (x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id)) && state.Tasas.data.find(x => x.origen.id === state.DetalleCambio.data.pais_origen.id && x.destino.id === state.DetalleCambio.data.pais_destino.id),
    },
    enableReinitialize : true,
    validate,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch)
    
export default connect(mapStateToProps, mapDispatchToProps)(FormMonto)
