import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Grid } from '@material-ui/core';
import SelectSearch from './SelectSearch2';
import TextField from './TextField';
import FileField from './FileField';
import Loading from './Loading';
import { searchWorkingaccounts, fetchWorkingaccounts } from '../ducks/Workingaccounts'
import { fetchFriendsCustomers } from '../ducks/Friends';
import { currency8decimal } from '../services/utils'

const validate = (values = {}, props) => {
  const errors = {}
  const requiredFields = [
    'imagen',
    'usuario',
    'cuenta',
    'comprobante',
    'monto'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if(values.monto > props.limite){
    errors.monto= "El monto no puede ser superior a la deuda!. Verifique los montos"
  }

  return errors
}

const selector = formValueSelector('form-agregar-pagos')

const numeroDecimal = value => {
  let valor
  valor = value.replace(/\./g, '')
  let partes = valor.split(",");
  let decimal = partes.length > 1 && partes[1].substring(0,2)
  valor = decimal ? partes[0]+","+decimal : valor
  valor = valor.replace(/\,/g, '.')
  return valor
}

const numeroOchoDecimal = value => {
  let valor
  valor = value.replace(/\./g, '')
  let partes = valor.split(",");
  let decimal = partes.length > 1 && partes[1].substring(0,8)
  valor = decimal ? partes[0]+","+decimal : valor
  valor = valor.replace(/\,/g, '.')
  return valor
}

const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}


let FormAgregarPago = props => {
    const { handleSubmit, loading, pais, change, recibido, customer } = props
    const workingaccounts = useSelector(state => state.Workingaccounts)
    const cuenta = useSelector( state => selector(state, 'cuenta' ))
    const friends = useSelector( state => state.Friends )
    const paises = useSelector( state => state.Paises )
    const paisSeleccionado = paises.data.find(x => x.id === (cuenta && cuenta.country_id))
    const dispatch = useDispatch()

    return(
        <Grid>
            <form noValidate onSubmit={handleSubmit} >
                <Grid container >
                    {recibido && 
                    <Field
                      required
                      style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                      type="text"
                      name="usuario"
                      disableClearable={true}
                      label="Usuario que transfiere"
                      options={[customer].concat(friends.data)}
                      component={SelectSearch}/>
                    }
                    
                    <Field
                        required
                        style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                        type="text"
                        name="cuenta"
                        onChange={() => change('monto', '')}
                        disableClearable={true}
                        label="Cuenta"
                        options={workingaccounts.data.filter(x => x.active)}
                        component={SelectSearch}/>

                    { cuenta && workingaccounts.data.filter(x => x.active).find(x => x.id === cuenta.id).nombre !== "Efectivo" &&
                        <Field
                            required
                            type="text"
                            name="comprobante"
                            label="Comprobante"
                            component={TextField}
                        />
                    }

                    <Field
                        required
                        type="text"
                        name="monto"
                        label="Monto"
                        format={currency8decimal}
                        normalize={paisSeleccionado && (paisSeleccionado.nombre !== "Panama" ? (paisSeleccionado.nombre !== "BTC" ? numero : numeroOchoDecimal ) : numeroDecimal )}
                        component={TextField}
                    />

                    {/* <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            {loading ? "" : <Field 
                                component={FileField}
                                accept="image/jpeg,image/gif,image/png,application/pdf"
                                label={label}
                                name="imagen"
                                handleChange={handleChange}
                            />}
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            <Button variant="contained" color="primary" type="submit">
                                Realizar Pago
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}

export default FormAgregarPago = reduxForm({
    form: 'form-agregar-pagos',
    validate,
})(FormAgregarPago);
