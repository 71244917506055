import Img1 from '../../public/assets/images/banner/animation/1.png'
import Img2 from '../../public/assets/images/banner/animation/2.png';
import Img3 from '../../public/assets/images/banner/animation/3.png';
import Img4 from '../../public/assets/images/banner/animation/4.png';
import Img5 from '../../public/assets/images/banner/animation/5.png';
import Img6 from '../../public/assets/images/banner/animation/6.png'
import Img7 from '../../public/assets/images/banner/animation/7.png';
import Img8 from '../../public/assets/images/banner/animation/8.png';
import Img9 from '../../public/assets/images/banner/animation/9.png';

const BannerBackgroundImages = [
    {
        img: Img1
    },
    {
        img: Img2
    },
    {
        img: Img3
    },
    {
        img: Img4
    },
    {
        img: Img5
    },
    {
        img: Img6
    },
    {
        img: Img7
    },
    {
        img: Img8
    },
    {
        img: Img9
    },
]

export default BannerBackgroundImages