import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Grid,Typography } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch2';
import Checkbox from './Checkbox';
import BancosVzla from '../constants/BancosVzla'
import TiposTransaccion from '../constants/TiposTransaccion'
import TipoAbonoDebito from '../constants/TipoAbonoDebito'
import { checkRut } from '../services/utils'

const validate = (values = {}, props) => {
    const errors = {}
    const requiredFields = [
      'monto',
      'tasa',
      'transaccion',
      'tipo',
      'pais',
      'pais2',
      'cuenta',
      'cuenta2',
      'banco',
      'comentarios',
    ]
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = true
      }
    })
  
    if (values.nombre && values.nombre.length <= 3 ){
      errors.nombre = "Nombre inválido"
    }
  
    if (values.telefono && values.telefono.length <= 7 ){
        errors.telefono = "Telefono inválido"
    }
  
    if (values.tipo_id && values.tipo_id.id !== "Rut" && values.rut && values.rut.length <= 7 ){
      errors.rut = "ID inválido"
    }
  
    if (values.password && values.password.length < 6 ){
      errors.password = "Password debe contener al menos 6 caracteres"
    }
  
    if (values.password && values.password_confirmation && values.password !== values.password_confirmation ){
      errors.password = "El password y la confirmación deben coincidir"
      errors.password_confirmation = "El password y la confirmación deben coincidir"
    }
  
    if ( values.tipo_id && values.tipo_id.id === "Rut" && values.rut && !checkRut(values.rut) ) {
      errors.rut = "Rut Inválido"
    }
  
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Correo Electrónico Inválido'
    }
    return errors
}  

const selector = formValueSelector('abonos-debitos')

let FormAbonosDebitos = props => {
    const { handleSubmit, busquedaAdmin, cuentaSeleccionada, paisId } = props
    const loading = useSelector(state => state.Beneficiarios.fetching)
    const nombre = useSelector(state => selector(state, 'nombre'))
    const workingaccounts = useSelector( state => state.Workingaccounts )
    const paises = useSelector( state => state.Paises )
    const pais = useSelector( state => selector(state, 'pais'))
    const pais2 = useSelector( state => selector(state, 'pais2'))
    const tipo = useSelector( state => selector(state, 'tipo'))
    const interna = useSelector( state => selector(state, 'interna'))

    const dispatch = useDispatch()

    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Field 
                    name="interna" 
                    component={Checkbox} 
                    label="Transacción Interna" 
                />

                <Field
                    type="text"
                    name="monto"
                    label="Monto"
                    component={TextField}
                />

                {interna && <Field
                    type="text"
                    name="tasa"
                    label="Tasa"
                    component={TextField}
                />}

                {interna && <Field 
                    name="proporcional" 
                    component={Checkbox} 
                    label="Tasa Proporcional" 
                />}

                {!interna &&<Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="tipo"
                    label="Tipo de Transacción"
                    component={SelectSearch}
                    options={TiposTransaccion}
                />}

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="transaccion"
                    label="Tipo de Abono/Debito"
                    component={SelectSearch}
                    options={TipoAbonoDebito}
                />

                {interna && <Typography variant="body1">Origen</Typography>}
                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="pais"
                    label="Pais"
                    component={SelectSearch}
                    options={paises.data}
                />

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="cuenta"
                    label="Cuenta"
                    component={SelectSearch}
                    options={workingaccounts.data.filter(x => x.country_id === (pais && pais.id) )}
                />
                
                {interna && <Typography variant="body1">Destino</Typography>}
                {interna && <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="pais2"
                    label="Pais"
                    component={SelectSearch}
                    options={paises.data}
                />}

                {interna && <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="cuenta2"
                    label="Cuenta"
                    component={SelectSearch}
                    options={workingaccounts.data.filter(x => x.country_id === (pais2 && pais2.id) )}
                />}

                {(tipo && tipo.id === "Debito" && pais && pais.nombre === "Venezuela") && <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="banco"
                    label="Banco Origen del pago"
                    component={SelectSearch}
                    options={BancosVzla} 
                />}

                <Field
                    type="text"
                    name="comentarios"
                    label="Comentarios"
                    component={TextField}
                />

                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Button variant="contained" color="primary" type="submit">
                            Realizar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}

FormAbonosDebitos = reduxForm({
  form: 'abonos-debitos',
  enableReinitialize : true,
  validate,
})(FormAbonosDebitos);

export default FormAbonosDebitos
