import React, {useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, Container, InputAdornment, IconButton } from '@material-ui/core'
import TextField from './TextField';
import Loading from '../components/Loading'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'cantidad',
    'palabra',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  return errors
}


let FormCodigos = props => {
      const { handleSubmit } = props
      const loading = useSelector( state => state.Tasas.fetching )

      return(
          <Container>
                <form noValidate onSubmit={handleSubmit} >
                    <Field
                        required
                        type="number"
                        name="cantidad"
                        label="Cantidad"
                        component={TextField}
                    />
                    <Field
                        required
                        type="text"
                        name="palabra"
                        label="Palabra Clave"
                        component={TextField}
                    />

                    <Grid item xs={12} lg={12} xl={12}>
                        <Grid container justify="center" alignItems="center"  >
                            { loading ? <Loading /> :
                            <Button variant="contained" color="primary" type="submit">
                                Crear Códigos Promocionales
                            </Button>}
                        </Grid>
                    </Grid>
                </form>
            </Container>
        )
    }


export default FormCodigos = reduxForm({
  form: 'codigos',
  enableReinitialize: true,
  validate,
})(FormCodigos);