const BancosChile = [  
    {id: 'BANCO DE CHILE', nombre:'BANCO DE CHILE'},
    {id: 'BANCO INTERNACIONAL', nombre: 'BANCO INTERNACIONAL'},
    {id: 'SCOTIABANK CHILE', nombre: 'SCOTIABANK CHILE'},
    {id: 'BANCO DE CREDITO E INVERSIONES', nombre: 'BANCO DE CREDITO E INVERSIONES'},
    {id: 'CORPBANCA', nombre: 'CORPBANCA'},
    {id: 'BANCO BICE', nombre: 'BANCO BICE'},
    {id: 'HSBC BANK (CHILE)', nombre: 'HSBC BANK (CHILE)'},
    {id: 'BANCO SANTANDER-CHILE', nombre: 'BANCO SANTANDER-CHILE'},
    {id: 'BANCO ITAÚ CHILE', nombre: 'BANCO ITAÚ CHILE'},
    {id: 'BANCO SECURITY', nombre: 'BANCO SECURITY'},
    {id: 'BANCO FALABELLA', nombre: 'BANCO FALABELLA'},
    {id: 'DEUTSCHE BANK (CHILE)', nombre: 'DEUTSCHE BANK (CHILE)'},
    {id: 'BANCO RIPLEY', nombre: 'BANCO RIPLEY'},
    {id: 'RABOBANK CHILE', nombre: 'RABOBANK CHILE'},
    {id: 'BANCO CONSORCIO', nombre: 'BANCO CONSORCIO'},
    {id: 'BANCO PENTA', nombre: 'BANCO PENTA'},
    {id: 'BANCO PARIS', nombre: 'BANCO PARIS'},
    {id: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA)', nombre: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA)'},
    {id: 'BANCO DEL ESTADO DE CHILE', nombre: 'BANCO DEL ESTADO DE CHILE'},
]

export default BancosChile