import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { addHOR, delHOR, resetHOR, resetStoreHOR } from './hors.js'
import { abrirModal, cerrarModal } from './Modales.js'
import { asignarPaso } from './Pasos'

const t = makeTypes('beneficiariosSeleccionados')
const ADD = t('add').single()
const DEL = t('del').single()
const RESET = t('reset').single()
const RESETSTORE = 'reset'

const agregarBeneficiario = values => mac(ADD, 'payload')({
    id: Math.random().toString(36),
    ...values,
})
export const borrarBeneficiarioSeleccionado = mac(DEL, 'payload')
export const resetBeneficiariosSeleccionados = mac(RESET, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const addReducer = createReducer(initialState, addHOR({ADD}))
const delReducer = createReducer(initialState, delHOR({DEL}))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(addReducer, delReducer, resetReducer, resetStoreReducer)


export const agregarBeneficiarioSeleccionado = values => (dispatch, getState) => {
    const state1 = getState()
    const sum1 = state1.BeneficiariosSeleccionados.data.length > 0 ? state1.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b) : 0
    if((sum1 + values.origen) <= state1.DetalleCambio.data.origen){
        dispatch(agregarBeneficiario({id: Math.random().toString(36), ...values}))
        const state = getState()
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        const sum = state.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b)
        if(sum === state.DetalleCambio.data.origen){
            dispatch(asignarPaso(state.Pasos.data + 1))
        }
    }else{
        dispatch(abrirModal({title:"Notificación", body:`No puede enviar mas del total seleccionado ${state1.DetalleCambio.data.origen}`, close: true}))
        throw "error"
    }
} 

