import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'

import Navbar from '../containers/Navbar/Navbar'
import MenuDetalle from './Drawer'
import ContenidoMenu from './ContenidoMenu'

const useStyles = makeStyles( theme => ({
    rootFullScreen: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        }
    },
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    }
}));

const Layout = props => {
    const { menu } = props
    const classes = useStyles();

    return(
        <Grid className={ menu ? classes.root : classes.rootFullScreen}>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            {props.children}
        </Grid>   
    )
}

export default Layout