import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, resetHOR, resetStoreHOR } from './hors.js'
import { getRequest } from '../services/api'

const t = makeTypes('beneficiariosSugeridos')
const FETCH = t('fetch').async()
const RESET = t('reset').single()
const RESETSTORE = 'reset'
 
const fetchActions = asyncMac(FETCH)
export const resetBeneficiariosSugeridos = mac(RESET)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, resetReducer, resetStoreReducer)

export const fetchBeneficiariosSugeridos = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/accounts/search-email?search=${values.email}&pais=${values.pais ? values.pais : ""}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}