import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import DoneIcon from '@material-ui/icons/Done';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  menuButton: {
    marginRight: 26,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 15,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // width: theme.spacing(0) + 1,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {

    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Page(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <div className={classes.paddinTop} onMouseEnter={() => handleDrawerOpen()}
        onMouseLeave={() => handleDrawerClose()}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}>
          <div className={classes.drawerHeader}>
          </div>
          <Divider />
          <List>
            {open &&
            <ListItem>
            <Typography variant="subtitle2" >
                Administración
                </Typography>
            </ListItem>
            }
              <ListItem button component={Link} to="/mayorista/agregar-solicitud"  >
                <ListItemIcon >
                  <AddIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Agregar Solicitud" />
              </ListItem>
              <ListItem button component={Link} to="/mayorista/solicitudes-abiertas"  >
                <ListItemIcon >
                  <CallReceivedIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Solicitudes Abiertas" />
              </ListItem>
              <ListItem button component={Link} to="/mayorista/clientes-mayoristas"  >
                <ListItemIcon >
                  <AccountBalanceIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Saldos Clientes" />
              </ListItem>
              <ListItem button component={Link} to="/mayorista/solicitudes-cerradas"  >
                <ListItemIcon >
                  <DoneIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Solicitudes Realizadas" />
              </ListItem>
              <ListItem button component={Link} to="/mayorista/solicitudes-rechazadas"  >
                <ListItemIcon >
                  <DoneIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Solicitudes Rechazadas" />
              </ListItem>
          </List>
        </Drawer>
      </div>
    </Fragment>
  );
}

export default Page;
