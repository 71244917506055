import React from 'react'
import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, selectHOR, addFetch, delFetch, resetStoreHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'

const t = makeTypes('rangos')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETSTORE = 'reset'



const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const selectRango = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT} ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, deleteReducer, resetStoreReducer)

export const fetchTaxes = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/taxes?nombre=${values && values.nombre ? values.nombre : ""}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
    }
}