import React from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import { Titlespan } from '../common/title/index';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core'
import Image from '../common/image/index';
import Calculadora from '../../containers/Calculadora'
import BannerBackgroundImages from '../../data/bannerimages';
import { history } from '../../index';
import { asignarDetalle } from '../../ducks/DetallesCambio';
import { asignarPaso } from '../../ducks/Pasos';
import { fetchBeneficiariosUsuarios } from '../../ducks/Beneficiarios';
import { fetchBeneficiariosSugeridos } from '../../ducks/BeneficiariosSugeridos';
import { fetchArchivos } from '../../ducks/Archivos';
import './Banner.scss';


function Banner(props) {
    const usuario = useSelector( state => state.Usuario.data[0])
    const tasas = useSelector( state => state.Tasas )
    const dispatch = useDispatch()
    return (
        <section className={`banner-wrapper ${props.ChangeClass}`} id="home">
            <div className="banner-outer">
                <div className="slider-animation-images">
                    {BannerBackgroundImages.map((img, index) => (
                        <span className={`image${index + 1}`} key={`banner-${index}`} ><Image Path={img.img} /></span>
                    ))}
                </div>
                <Grid container justify="center" alignItems="center">
                    <Grid item md={6} className="banner-content">
                        <div >
                            <Titlespan
                                Class="banner-main-title"
                                Label={<Typography color='inherit' variant="h3">Envía tu dinero con profesionales    </Typography>}
                                Name=""
                            />
                            
                            <Titlespan
                                Class="banner-dec"
                                Label={<Typography color='inherit' variant='h6'>Transferencias internacionales, pagos y remesas de forma rápida y segura. Hazlo donde quiera que estés y desde cualquier dispositivo.</Typography>}
                                Name=""
                            />

                            <Button id="boton-enviar" onClick={() => history.push("/clientes/agregar")} variant="contained" style={{ marginLeft:'20px', backgroundColor:'white', color:'#003250'}}>
                                ¡Enviar Dinero YA!
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div className="banner-image">
                            <Calculadora
                                codigoPromocional={true}  
                                perfil="Cliente" 
                                Class="banner-img" 
                                handleSubmit={async values => {
                                    if(usuario){
                                        await dispatch(fetchArchivos(usuario.id))
                                        await dispatch(fetchBeneficiariosSugeridos(usuario));
                                        await dispatch(fetchBeneficiariosUsuarios({ ...usuario, pais: values.pais_destino.id }));
                                        dispatch(asignarDetalle(values))
                                        dispatch(asignarPaso("home"))
                                        history.push("/clientes/agregar")
                                    }else{
                                        history.push("/clientes/agregar")
                                    }
                            }}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

export default Banner;
