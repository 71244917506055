import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Typography, Grid, Paper, Box, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TextField from './TextField';
import SelectSearch from './SelectSearch2';
import Loading from './Loading'
import BancosVzla from '../constants/BancosVzla'
import TiposCuentasVzla from '../constants/TiposCuentaVzla'
import TiposIdVzla from '../constants/TiposIdVzla'
import Checkbox from '../components/Checkbox'
import { buscarBeneficiarioNombre } from '../ducks/Beneficiarios'
import { numeroCuentaVzla, currency8decimal, numeroDecimal } from '../services/utils'

export const validate = (values = {}, props) => {
    const errors = {}
    const requiredFields = [
      'nombre',
      'idNumero',
      'idTipo',
      'banco',
      'tipoCuenta',
      'codigo',
      'cuenta',
    ]
    requiredFields.forEach(field => {
    if (!values[field]) {
        errors[field] = true
      }
    })
  
    if (values.nombre && values.nombre.length <= 3 ){
      errors.nombre = "Nombre inválido"
    }
  
    if (values.cuenta && values.cuenta.length !== 16){
        errors.cuenta = "Cuenta Inválida"
    }
    return errors
}

const selector = formValueSelector('form-beneficiario-vzla')

let FormBeneficiarioVzla = props => {
    const { handleSubmit, busquedaAdmin, cuentaSeleccionada, workingaccount, change, paisId } = props
    const loading = useSelector(state => state.Beneficiarios.fetching )
    const nombre = useSelector(state => selector(state, 'nombre'))
    const banco = useSelector(state => selector(state, 'banco'))
    const dispatch = useDispatch()

    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h6">Formulario Beneficiario Venezuela</Typography>
            </Grid>
            <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Field
                    type="text"
                    placeholder="Nombre y Apellido"
                    name="nombre"
                    label="Nombre y Apellido"
                    component={TextField}
                    InputProps={{
                        endAdornment: busquedaAdmin && <InputAdornment position="end"><IconButton onClick={() => dispatch(buscarBeneficiarioNombre({ nombre: nombre, pais: paisId }))}><SearchIcon /></IconButton></InputAdornment>,
                    }}
                />
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={3} xl={3}>
                            <Field
                                name="idTipo"
                                label="Tipo Doc."
                                component={SelectSearch}
                                options={TiposIdVzla}/>
                        </Grid>
                        <Grid item xs={12} lg={9} xl={9}>
                            <Field
                                type="text"
                                name="idNumero"
                                label="Número"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    name="banco"
                    label="Banco"
                    onChange={ value => change('codigo', value ? value.id : "") }
                    component={SelectSearch}
                    options={BancosVzla} 
                />
                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="tipoCuenta"
                    label="Tipo de Cuenta"
                    component={SelectSearch}
                    options={TiposCuentasVzla}
                />
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={3} xl={3}>
                            <Field
                                type="text"
                                name="codigo"
                                disabled
                                label="Código"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={9} xl={9}>
                            <Field
                                type="text"
                                name="cuenta"
                                label="Número de Cuenta"
                                normalize={numeroCuentaVzla}
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {workingaccount && <>
                    <Field
                        type="text"
                        name="profit"
                        label="Ganancia por Transacción"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />

                    <Field
                        type="text"
                        name="cupo_mb"
                        label="Cupo MB"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />

                    <Field
                        type="text"
                        name="cupo_ob"
                        label="Cupo OB"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />
                    <Field 
                        name="receiver" 
                        component={Checkbox} 
                        label="Recibe dinero?" 
                    />
                </>}
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Button variant="contained" color="primary" type="submit">
                            {cuentaSeleccionada ? 'Editar Beneficiario' : 'Agregar Beneficiario' }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}

export default FormBeneficiarioVzla = reduxForm({
  form: 'form-beneficiario-vzla',
  enableReinitialize: true,
  validate,
})(FormBeneficiarioVzla);

