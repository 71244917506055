import React from 'react'
import { useSelector } from 'react-redux'
import Tabla from '../../components/Tabla'
import { currency2decimal, Banco } from '../../services/utils'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const TablaBalances = props => {
    const {comentario} = props
    
    const balances = useSelector( state => state.Balances)
    const suma = useSelector( state => state.Balances.data.map(x => x.comision).length > 0 ? state.Balances.data.map(x => x.comision).reduce((a,b) => a+b) : 0)

    return(
        <Tabla 
            rowStyle={ (rowData, index) => {
                if(index === 0) {
                return {color: 'red'};
                }
                return {};
            }}
            sorting={false}
            loading={balances.fetching}
            columnas={[
                { title: 'Total Ganancia', field: 'ganancia', 
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Saldo Total', field: 'saldoTotal', 
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Saldo Disp.', field: 'saldoDisp', 
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Saldo Deudor', field: 'saldoDeudor',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Tip. Transaccion', field: 'tipoTransaccion',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Monto', field: 'monto',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Monto+Com.', field: 'montoCom',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Ganancia', field: 'comision',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Id. Transacción', field: 'id',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Cliente', field: 'cliente',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Benef.', field: 'beneficiario',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Banco', field: 'banco',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Cuenta', field: 'cuenta',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Ref.', field: 'referencia',
                    cellStyle: {
                    },headerStyle: {
                }},
                { title: 'Comentario', field: 'motivo',
                    cellStyle: {
                    },headerStyle: {
                }},
                ... comentario ? [{ title: 'Com. Interno', field: 'comentarios',
                    cellStyle: {
                    },headerStyle: {
                }}] : [],
                { title: 'Fecha', field: 'created_at',
                    cellStyle: {
                    },headerStyle: {
                }},
            ]}
                data={balances.data.map(x => ({
                    ...x,
                    saldoTotal: x.saldoTotal ? currency2decimal(x.saldoTotal) : "",
                    saldoDisp: x.saldoDisp ? currency2decimal(x.saldoDisp) : "",
                    saldoDeudor: x.saldoDeudor ? currency2decimal(x.saldoDeudor) : "",
                    monto: x.monto ? currency2decimal(x.monto) : "",
                    montoCom: x.montoCom ? currency2decimal(x.montoCom) : "",
                    comision: x.comision ? currency2decimal(x.comision) : "",
                    id: x.clpbstransaction ? x.clpbstransaction.id : "",
                    cliente: x.clpbstransaction ? (x.clpbstransaction.customer ? x.clpbstransaction.customer.cliente : (x.clpbstransaction.user ? (x.clpbstransaction.user.nombre+" "+x.clpbstransaction.user.apellido) : ""))  : "",
                    beneficiario: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.nombre :"") : "",
                    referencia: x.clpbstransaction ? x.clpbstransaction.referencia : "",
                    banco: x.clpbstransaction ? (x.clpbstransaction.account ? Banco(x.clpbstransaction.account.banco) :"") : "",
                    cuenta: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.cuenta :"") : "",
                    created_at: moment(x.created_at).format('DD-MM-YYYY'),
                    ganancia: currency2decimal(suma)
            }))} 
        />
    )
}

export default TablaBalances