import React from 'react';
import { useDispatch } from 'react-redux'
import { Titlespan } from '../common/title/index';
import { Button, Typography, Grid } from '@material-ui/core'
import Proceso from '../../img/proceso-envio-dinero.png'
import { abrirModal } from '../../ducks/Modales'
import ImagenDispositivos from '../../img/dinero-transferir-vzla.png'
import './About.scss';

function About() {
    const dispatch = useDispatch()
    return (
        <section className="about-wrapper gradient-color" id="about">
            <>
                <Grid container justify="center" alignItems="center">
                    <Grid item md={6}>
                        <div className="about-content-block main-title-wrapper" style={{ marginLeft:'20px'}}>
                            <Titlespan
                                Class="sitemain-subtitle"
                                Label={<Typography style={{ color: 'white' }} variant="h4">Enviar dinero es muy fácil</Typography>}
                                Name=""
                            />
                            <Titlespan
                                Class="about-dec about-dec-1"
                                Label={<Typography style={{ color: 'white' }} variant="h6">Ya no tienes que moverte de sitio, ni hacer largas filas, desde cualquier lugar y cualquier dispositivo podrás realizar transferencias internacionales. ¿Aún no sabes como?.</Typography>}
                                Name=""
                            />
                            <Button style={{ backgroundColor:'white', color: '#003250' }} onClick={() => dispatch(abrirModal({ title:"Proceso de envío", size: 'md', body: <Grid container justify="center" alignItems="center"><img width={800} heigth={800} src={Proceso} alt="Proceso de envío de dinero"/></Grid>, close:true }))} variant="contained" color="inherit">
                                VER MAS
                            </Button>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <div className="about-image">
                            <img src={ImagenDispositivos} alt="transferir-dinero" />
                        </div>
                    </Grid>
                </Grid>
            </>
        </section>
    );
}

export default About;
