import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import FormBeneficiarioChile from '../../components/FormBeneficiarioChile'
import FormBeneficiarioPnm from '../../components/FormBeneficiarioPnm'
import FormBeneficiariosVzla from '../../components/FormularioBeneficiariosVzla'
import SelectSearch from '../../components/SelectSearch2'
import BotonMenu from '../../components/BotonMenu'
import Backdrop from '../../components/Backdrop'
import Tabla from '../../components/Tabla'
import { fetchPaises } from '../../ducks/Paises'
import { resetMensaje } from '../../ducks/Mensajes'
import { fetchWorkingaccounts, crearWorkingaccount, borrarWorkingaccount,editWorkingaccount } from '../../ducks/Workingaccounts'
import { Banco, currency8decimal } from '../../services/utils'
import BancosPnm from '../../constants/BancosPnm'
import BancosVzla from '../../constants/BancosVzla'
import BancosChile from '../../constants/BancosChile'
import TiposCuentasChile from '../../constants/TiposCuentasChile'
import TiposCuentaPnm from '../../constants/TiposCuentaPnm'
import TiposIdVzla from '../../constants/TiposIdVzla'
import TiposCuentasVzla from '../../constants/TiposCuentaVzla'

const CuentasTrabajo  = props => {

    const dispatch = useDispatch()

    const [ modalCrear, setModalCrear ] = useState(false)
    const [ modalBorrar, setModalBorrar ] = useState(false)
    const [ modalEditar, setModalEditar ] = useState(false)
    const [ paisValue, setPaisValue ] = useState(null)
    const [ cuentaSeleccionada, setCuentaSeleccionada ] = useState(null)
    const workingaccounts = useSelector( state => state.Workingaccounts.data )
    const mensajes = useSelector( state => state.Mensajes )
    const paises = useSelector( state => state.Paises )
    const loading = useSelector( state => state.Workingaccounts.fetching || state.Paises.fetching )

    const fetchData = async () => {
        dispatch(fetchWorkingaccounts())
        dispatch(fetchPaises())
    }


    useEffect(() => {
        fetchData()
    },[])

    console.log(paisValue)

    let formulario

    if (paisValue){
        switch (paisValue.nombre) {
            case "Venezuela":
                formulario = 
                    <FormBeneficiariosVzla
                        workingaccount={true}
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && { 
                            ...cuentaSeleccionada, 
                            idTipo: TiposIdVzla.find(x => x.id === cuentaSeleccionada.idTipo),
                            banco: BancosVzla.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentasVzla.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            codigo: cuentaSeleccionada.banco, 
                            profit: cuentaSeleccionada.profit && cuentaSeleccionada.profit.toString(),
                            cupo_mb: cuentaSeleccionada.cupo_mb && cuentaSeleccionada.cupo_mb.toString(),
                            cupo_ob: cuentaSeleccionada.cupo_ob && cuentaSeleccionada.cupo_ob.toString(),
                            cuenta: cuentaSeleccionada.cuenta.substr(cuentaSeleccionada.cuenta.length - 16)}}
                        onSubmit={ async values => {
                            if(cuentaSeleccionada){
                                await dispatch(editWorkingaccount({ 
                                    ...values, 
                                    id: cuentaSeleccionada.id,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    profit: values.profit && values.profit.replace(/\,/g, '.'),
                                    profit: cuentaSeleccionada.profit.toString(),
                                    cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                    cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.'),
                                    cuenta: values.codigo+values.cuenta}))
                                setModalEditar(false)
                            }else{
                                dispatch(crearWorkingaccount({
                                    ...values,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo+values.cuenta, 
                                    country_id: paisValue.id,
                                    profit: values.profit && values.profit.replace(/\,/g, '.'),
                                    cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                    cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.')
                                }, 
                                paisValue.nombre 
                                ))
                                setModalCrear(false)
                            }
                        }}
                    />
                break;
            case "Chile":
                formulario = 
                    <FormBeneficiarioChile 
                        workingaccount={true}
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && {
                            ...cuentaSeleccionada,
                            rut: cuentaSeleccionada.idNumero,
                            banco: BancosChile.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentasChile.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            profit: cuentaSeleccionada.profit && cuentaSeleccionada.profit.toString(),
                            cupo_mb: cuentaSeleccionada.cupo_mb && cuentaSeleccionada.cupo_mb.toString(),
                            cupo_ob: cuentaSeleccionada.cupo_ob && cuentaSeleccionada.cupo_ob.toString(),
                        }} 
                        onSubmit={values => {
                            if(cuentaSeleccionada){
                                dispatch(editWorkingaccount({
                                    ...values,
                                    id: cuentaSeleccionada.id,
                                    idNumero: values.rut,
                                    idTipo: "Rut", 
                                    tipoCuenta: values.tipoCuenta.id, 
                                    banco: values.banco.id,
                                    profit: values.profit && values.profit.replace(/\,/g, '.'),
                                    cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                    cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.')
                                }))
                                setModalEditar(false)
                            }else{
                                dispatch(crearWorkingaccount(
                                    { 
                                        ...values, 
                                        idNumero: values.rut, 
                                        idTipo: "Rut", 
                                        tipoCuenta: values.tipoCuenta.id, 
                                        banco: values.banco.id, 
                                        country_id: paisValue.id,
                                        profit: values.profit && values.profit.replace(/\,/g, '.'),
                                        cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                        cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.')
                                    }, 
                                    paisValue.nombre 
                                ))
                                setModalCrear(false)
                            }
                        }} 
                    />
                break;
            case "Panama":
                formulario = 
                    <FormBeneficiarioPnm 
                        workingaccount={true}
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && {
                            ...cuentaSeleccionada,
                            banco: BancosPnm.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentaPnm.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            profit: cuentaSeleccionada.profit && cuentaSeleccionada.profit.toString(),
                            cupo_mb: cuentaSeleccionada.cupo_mb && cuentaSeleccionada.cupo_mb.toString(),
                            cupo_ob: cuentaSeleccionada.cupo_ob && cuentaSeleccionada.cupo_ob.toString(),
                        }} 
                        onSubmit={values => {
                            if(cuentaSeleccionada){
                                dispatch(editWorkingaccount({
                                    ...values,
                                    id: cuentaSeleccionada.id,
                                    tipoCuenta: values.tipoCuenta.id, 
                                    banco: values.banco.id,
                                    profit: values.profit && values.profit.replace(/\,/g, '.'),
                                    cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                    cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.')
                                }))
                                setModalEditar(false)
                            }else{
                                dispatch(crearWorkingaccount(
                                    { 
                                        ...values, 
                                        tipoCuenta: values.tipoCuenta.id, 
                                        banco: values.banco.id, 
                                        country_id: paisValue.id,
                                        profit: values.profit && values.profit.replace(/\,/g, '.'),
                                        cupo_mb: values.cupo_mb && values.cupo_mb.replace(/\,/g, '.'),
                                        cupo_ob: values.cupo_ob && values.cupo_ob.replace(/\,/g, '.')
                                    }, 
                                    paisValue.nombre 
                                ))
                                setModalCrear(false)
                            }
                        }} 
                    />;
                break;
            case "BTC": 
                formulario = 
                    <Typography variant="h6">Formulario para cuentas en BTC</Typography>
                break;
            default:
                formulario = 
                    <Typography variant="h6">Debe elegir un país que contenga formulario disponible</Typography>
                break;
        }
    }

    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Cuentas de trabajo</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Tabla
                agregar={() => {setCuentaSeleccionada(null);setModalCrear(true)}}
                columnas={[
                    { title: 'Acciones', field: 'accion', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'ID', field: 'id', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Nombre', field: 'nombre', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Cedula', field: 'cedula', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Banco', field: 'banco', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Cuenta', field: 'cuenta', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Pais', field: 'pais', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Ganancia', field: 'profit', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Recibe Dinero', field: 'receiver', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Cupo MB', field: 'cupo_mb', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                        }
                    },
                    { title: 'Cupo OB', field: 'cupo_ob', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'External Platform', field: 'external_platform', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                    { title: 'Activo', field: 'active', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                        }
                    },
                ]}
                data={workingaccounts.map(x => ({
                    ...x,
                    asignadas: x.pendientes.length,
                    cedula: x.idTipo || x.idNumero ? (x.idTipo ? x.idTipo : "-")+"-"+(x.idNumero ? x.idNumero : "-") : "Sin ID",
                    active: x.active ? "SI" : "NO",
                    banco: x.banco ? Banco(x.banco) : "",
                    cuenta: x.tipoCuenta +"-"+ x.cuenta,
                    cupo_mb: currency8decimal(x.cupo_mb),
                    cupo_ob: currency8decimal(x.cupo_ob),
                    receiver: x.receiver ? "SI" : "NO",
                    profit: x.profit,
                    pais: paises.data.find(y => y.id === x.country_id) ? paises.data.find(y => y.id === x.country_id).nombre : "",
                    accion: 
                    <BotonMenu 
                        registro={x}
                        editar={true}
                        activar={true}
                        borrar={true}
                        handleBorrar={() => {setCuentaSeleccionada(x);setModalBorrar(true)}}
                        handleActivar={() => {dispatch(editWorkingaccount({ ...x, active: !x.active}))}}
                        handleEditar={() => {
                            setCuentaSeleccionada(x);
                            setPaisValue(paises.data.find(y => y.id === x.country_id)); 
                            setModalEditar(true)}}
                    />,
                }))}
            />

            <Modal
                open={modalBorrar}
                title="Confirmación"
                close={true}
                handleClose={() => setModalBorrar(false)}
                action={ async () => {
                    await dispatch(borrarWorkingaccount(cuentaSeleccionada.id )) 
                    setModalBorrar(false)
                }}
            >
                ¿Está seguro que desea borrar la transacción?
            </Modal>

            <Modal
                open={modalEditar}
                title="Confirmación"
                close={true}
                loading={loading}
                handleClose={() => setModalEditar(false)}
            >
                {formulario}
            </Modal>

            <Modal>
                
            </Modal>

            <Snackbar 
                open={mensajes.data.length > 0 ? true : false}
                handleClose={() => dispatch(resetMensaje())}
                message={mensajes.data}
            />
            <Modal
                open={modalCrear}
                title="Crear Cuenta"
                close={true}
                handleClose={() => setModalCrear(false)}
            >
                <SelectSearch 
                    label="País"
                    input={{
                        name:"pais",
                        value: paisValue,
                        onChange:value => setPaisValue(value),
                        onBlur: value => {}
                    }}
                    options={paises.data}
                    meta={{
                        error: false,
                        touched: false,
                    }}
                />
                {formulario}
            </Modal>

            <Backdrop open={loading}/>
            
        </Box>
    )
}

export default CuentasTrabajo