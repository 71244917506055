import React, { Component } from 'react'
import MaterialTable from 'material-table'

const Tabla = props => {
    const { columnas, selection, grouping, handleSelection, filtering, title, pageSize, exportable, rowStyle, data, verDetalle, actualizar, loading, agregar, search, detalle, sorting }  = props
	return (
        <MaterialTable
          isLoading={loading}
          columns={columnas}
          data={data}
          title={title ? title : ""}
          localization={{
            body:{
              emptyDataSourceMessage: "No hay datos para mostrar"
            },
            toolbar:{
              searchPlaceholder: "Buscar"
            },
            pagination:{
              labelRowsSelect: "filas",
            },
            toolbar:{
              nRowsSelected: "{0} filas seleccionadas"
            }
          }}
          options={{
            pageSize: pageSize ? pageSize : 20,
            emptyRowsWhenPaging: false,
            doubleHorizontalScroll: true,
            search: search === false ? false : true,
            detailPanelType: "single",
            rowStyle: rowStyle,
            sorting: sorting === false ? false : true,
            exportButton: exportable,
            exportAllData: true,
            grouping: grouping ? true : false,
            filtering: filtering ? true : false,
            selection: selection
          }}
          onSelectionChange={(rows) => handleSelection(rows)}
          actions={
            [
              ... agregar ? [{
              icon: 'add',
              tooltip: 'Agregar',
              isFreeAction: true,
              onClick: (event) => agregar()
              }] : [],
              ... actualizar ? [{
                icon: 'update',
                tooltip: 'Actualizar',
                isFreeAction: true,
                onClick: event => actualizar()
              }] : [],
              ... verDetalle ? [{
                icon: 'pageview',
                tooltip: 'Detalle',
                isFreeAction: true,
                onClick: event => verDetalle()
              }] : [],
          ]}
          detailPanel={detalle}
        />
    )
}

export default Tabla
