import React, { useState } from 'react'

import SelectSearch from '../SelectSearch2'
import FormularioBeneficiariosVzla from '../FormularioBeneficiariosVzla'
import FormBeneficiarioChile from '../FormBeneficiarioChile'
import FormBeneficiarioPnm from '../FormBeneficiarioPnm'
import BancosChile from '../../constants/BancosChile'
import BancosPnm from '../../constants/BancosPnm'
import BancosVzla from '../../constants/BancosVzla'
import TiposCuentaPnm from '../../constants/TiposCuentaPnm'
import TiposCuentaVzla from '../../constants/TiposCuentaVzla'
import TiposCuentasChile from '../../constants/TiposCuentasChile'
import TiposId from "../../constants/TiposId";
import TiposIdVzla from "../../constants/TiposIdVzla";

const FormsBeneficarios = props => {
    const { paises, cuentaSeleccionada, handleSubmitVzla, handleSubmitChile, handleSubmitPnm } = props
    const [ pais, setPais ] = useState(paises.find(pais => pais.id === cuentaSeleccionada?.country_id))

    let formulario 
    if(pais){
        switch (pais.nombre) {
            case "Venezuela":
                formulario = 
                    <FormularioBeneficiariosVzla
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposIdVzla.find(x => x.id === cuentaSeleccionada.idTipo),
                            banco: cuentaSeleccionada &&  BancosVzla.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: cuentaSeleccionada && TiposCuentaVzla.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            codigo: cuentaSeleccionada && BancosVzla.find(x => x.id === cuentaSeleccionada.banco ).id,
                            cuenta: cuentaSeleccionada && cuentaSeleccionada.cuenta.substr(cuentaSeleccionada.cuenta.length - 16)
                        }}
                        cuentaSeleccionada={cuentaSeleccionada}
                        onSubmit={values => handleSubmitVzla({...values, country_id: pais.id})} 
                        paisId={pais.id}
                    />     
                break;
            case "Chile":
                formulario = 
                    <FormBeneficiarioChile 
                        initialValues={{
                            ...cuentaSeleccionada,
                            rut: cuentaSeleccionada && cuentaSeleccionada.idNumero,
                            banco: BancosChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentasChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        cuentaSeleccionada={cuentaSeleccionada}
                        onSubmit={values => handleSubmitChile({...values, country_id: pais.id})} 
                        paisId={pais.id}
                    />
                break;
            case "Panama":
                formulario = 
                    <FormBeneficiarioPnm 
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposId,
                            banco: BancosPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentaPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        cuentaSeleccionada={cuentaSeleccionada}
                        onSubmit={values => handleSubmitPnm({...values, country_id: pais.id})} 
                        paisId={pais.id}
                    />
                break;
            case "BTC":
                formulario = "No se pueden crear cuentas aún"
                break;
        
            default:
                formulario = "Aún no tenemos servicios para este país"
                break;
        }
    }

    return(
        <>
            {!cuentaSeleccionada && <SelectSearch 
                label="País"
                input={{
                    name:"pais",
                    value: pais,
                    onChange: value => setPais(value),
                    onBlur: () =>{}
                }}
                options={paises}
                meta={{
                    error: false,
                    touched: false,
                }}
            />}
            {formulario}
        </>
    )
}

export default FormsBeneficarios