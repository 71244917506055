import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box, Paper, Button } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import Tabla from '../../components/Tabla'
import ContenidoMenu from '../../components/ContenidoMenu'
import FormBusquedaBalance from '../../components/FormBusquedaBalance'
import FormAgregarPago from '../../components/FormAgregarPago'
import Modal from '../../components/Notificacion2'
import RadioButton from '../../components/RadioButton'
import MenuSolicitudes from "./menuSolicitudesMayorista"
import { fetchBalanceCliente, agregarPagoMayorista } from '../../ducks/Balances'
import { fetchWorkingaccounts, searchWorkingaccounts } from '../../ducks/Workingaccounts'
import { fetchPaises } from '../../ducks/Paises'
import { buscarUsuarios } from '../../ducks/Usuarios'
import { fetchPagadoresUsuarios } from '../../ducks/Friends'
import { currency2decimal } from '../../services/utils'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const ClientesMayoristas = props => {
    const [ customer, setCustomer ] = useState('')
    const [ pais, setPais ] = useState('')
    const [ modalPago, setModalPago ] = useState(false)
    const usuarios = useSelector( state => state.Usuarios )
    const workingaccounts = useSelector( state => state.Workingaccounts )
    const balances = useSelector( state => state.Balances )
    const dispatch = useDispatch()

    console.log(customer)

    const fetchData = () => {
        dispatch(fetchPaises())
        dispatch(buscarUsuarios({ profile: "Mayorista" }))
    }

    const verBalance = values => {
        dispatch(fetchPagadoresUsuarios(values.cliente.id))
        dispatch(searchWorkingaccounts(values.pais.id))
        dispatch(fetchBalanceCliente({ ...values, cliente: values.cliente.id, pais: values.pais.id }))
        setPais(values.pais)
        setCustomer(values.cliente)
    }

    useEffect(() => {
        fetchData()
    },[])
  
    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Usuarios Mayoristas</Typography>
            </Grid>
            <Navbar />
            <MenuSolicitudes />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={6} style={{ padding: 10 }} >
                    <Paper>
                        <Box p={4}>
                            <FormBusquedaBalance
                                onSubmit={values => verBalance(values) }
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {true && 
            <Grid container >
                <Grid item xs={12}style={{ padding: 10 }} >
                    <Tabla 
                        agregar={() => setModalPago(true)}
                        title="Balance Usuario"
                        columnas={[
                            { title: 'Saldo Dedudor', field: 'saldoDeudor', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Monto', field: 'monto', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Monto Com.', field: 'montoCom', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Transacción', field: 'tipoTransaccion', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Operador', field: 'operador', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Pais', field: 'country', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Comprobante', field: 'comprobante', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Cliente', field: 'cliente', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }},
                            { title: 'Proveedor', field: 'provider', 
                                cellStyle: {
                                    width: '10px',
                                },headerStyle: {
                                    width: '10px',
                            }}
                        ]}
                        data={balances.data.map(x => ({
                            ...x,
                            country: x.country ? x.country.nombre : "",
                            monto: x.monto ? currency2decimal(x.monto) : 0,
                            montoCom: x.montoCom ? currency2decimal(x.montoCom) : 0,
                            saldoDeudor: x.saldoDeudor ? currency2decimal(x.saldoDeudor) : 0,
                            operador: x.user ? x.user.nombre : "",
                            comprobante: x.clpbstransaction ? (x.clpbstransaction.comprobante ? x.clpbstransaction.comprobante : "No existe") : "No existe",
                            cliente: x.clpbstransaction ? (x.clpbstransaction.user ? x.clpbstransaction.user.nombre : "No existe") : "No existe",
                            provider: x.provider ? (x.provider.nombre ? x.provider.nombre : "No existe") : "No existe"
                        }))}
                    />
                </Grid>
            </Grid>
            }

            <Modal
                open={modalPago}
                title="Agregar Pago"
                close={true}
                handleClose={() => setModalPago(false)}
            >
                <FormAgregarPago
                    recibido={true}
                    customer={customer}
                    onSubmit={values => dispatch(agregarPagoMayorista({...values, pais: pais.id, usuario: customer.id, pagador: values.usuario.id, cuenta: values.cuenta.id}))}
                />
            </Modal>

            

        </Box>
    
    )
}

export default ClientesMayoristas