import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography} from '@material-ui/core'

import Layout from '../../components/Layout'
import MenuButton from '../../components/MenuButton/MenuButton'
import Backdrop from '../../components/Backdrop'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import FormProveedores from '../../components/FormProveedores'
import Tabla from '../../components/Tabla'
import { crearProveedor, fetchProveedores, editProveedor, borrarProveedor, resetErrorProveedores } from '../../ducks/Proveedores'

const Proveedores = props => {
    const proveedores = useSelector( state => state.Proveedores )
    const [ proveedor, setProveedor ] = useState(null)
    const [ modalEditar, setModalEditar ] = useState(false)
    const [ modalBorrar, setModalBorrar ] = useState(false)
    const [ modalProveedor, setModalProveedor ] = useState(false)
    const loading = useSelector( state => state.Proveedores.fetching )
    const dispatch = useDispatch()

    const fetchData = () =>{
        dispatch(fetchProveedores())
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Layout>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Proveedores</Typography>
            </Grid>
            <Tabla
                agregar={() => {setProveedor(null);setModalProveedor(true)}}
                columnas={[
                    { title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'ID', field: 'id', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Nombre', field: 'nombre',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Descripción', field: 'descripcion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Activo', field: 'active',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={proveedores.data.map(x => ({
                    ...x,
                    active: x.active ? "SI" : "NO",
                    accion: 
                        <MenuButton 
                            buttons={[
                                {
                                    name:"Editar",
                                    action: () => {setProveedor(x);setModalEditar(true)}
                                },
                                {
                                    name: x.active ? "Desactivar" : "Activar",
                                    action: () => {dispatch(editProveedor({ ...x, active: !x.active }))}
                                },
                                {
                                    name:"Borrar",
                                    action: () => {setProveedor(x);setModalBorrar(true)}
                                },
                            ]}
                        />
                }))}/>

            <Modal
                open={modalProveedor}
                title="Agregar Proveedor"
                close={true}
                handleClose={() => setModalProveedor(false)}
            >
                <FormProveedores 
                    onSubmit={ async values => {
                        await dispatch(crearProveedor(values))
                        setModalProveedor(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalEditar}
                title="Editar Proveedor"
                close={true}
                handleClose={() => setModalEditar(false)}
            >
                <FormProveedores 
                    proveedorSeleccionado={proveedor}
                    initialValues={{
                        ...proveedor
                    }}
                    onSubmit={ async values => {
                        await dispatch(editProveedor(values));
                        setModalEditar(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalBorrar}
                title="Confimación"
                close={true}
                handleClose={() => setModalBorrar(false)}
                action={() => {dispatch(borrarProveedor(proveedor.id)); setModalBorrar(false)}}
            >
                ¿Estás seguro de que deseas borrar el proveedor?
            </Modal>

            <Snackbar
                open={proveedores.error && proveedores.error.length > 0 ? true : false}
                handleClose={() => dispatch(resetErrorProveedores())}
                message={proveedores.error}
            />

            <Backdrop 
                open={loading}
            />
        </Layout>

    )
}


export default Proveedores