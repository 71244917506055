import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Titlespan } from '../common/title/index';
import { Typography, Grid } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Image from '../common/image/index';
import logo from '../../img/logo.png'
import { Link } from 'react-router-dom'
import prensa from '../../img/transferir-dinero.png'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './Footer.scss';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: '100px',
      marginBottom: '100px',
    },
    contacto: {
      //margin: theme.spacing(6),
      //textAlign: 'justify',
      //marginLeft: '20%',
    },
    link: {
        color:"#003520"
    }
}));

function Footer() {
    const classes = useStyles()
    return (
        <div className="footer-wrapper">
            <Grid container>
                <div className="footer-content-wrapper">
                    <Grid container justify="center">
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className="footer-logo-wrapper">
                                <Image Path={logo} Class="footer-logo" />
                                <Titlespan
                                    Class="footer-dec"
                                    Label={<Typography variant='body1' color='primary'>Profesionales en remesas, transferencias internacionales a la mejor tasa del mercado</Typography>}
                                    Name=""
                                />
                            </div>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <div className="footer-contact-us">
                                <Typography className="footer-title" variant='h6' color='primary'>Contacto</Typography>
                                <div className="footer-details-link">
                                    <ul>
                                        <li>
                                            <a className={classes.link} target="_blank" href="tel:+56954838838">
                                                <span>
                                                    <PhoneIcon />
                                                </span>
                                                <Typography className="footer-link" variant="body1" color='primary'>+56 9 5483 8838</Typography>
                                            </a>
                                        </li>
                                        <li>
                                            <a className={classes.link} target="_blank" href="tel:+50768927563">
                                                <span>
                                                    <WhatsAppIcon />
                                                </span>
                                                <Typography className="footer-link" variant="body1" color='primary'>+56 9 5483 8838/+507 6892 7563</Typography>
                                            </a>
                                        </li>
                                        <li>
                                            <a className={classes.link} target="_blank" href="mailto:contacto@aguacatecambios.com">
                                                <span>
                                                    <EmailIcon />
                                                </span>
                                                <Typography className="footer-link footer-email" variant="body2" color='primary'>contacto@aguacatecambios.com</Typography>
                                            </a>                              
                                        </li>
                                        <li>
                                            <a className={classes.link} target="_blank" href="https://goo.gl/maps/fxwjAMnVvZ1rLaNp7">
                                                <span>
                                                    <LocationOnIcon />
                                                </span>
                                                <Typography className="footer-link" variant="body2" color='primary'>Av. Providencia 1650, Oficina 704, Providencia. Santiago</Typography>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                            <div className="footer-about-us">
                                <div className="footer-about-outer">
                                    <Typography className="footer-title" variant="h6" color='primary'>Secciones</Typography>
                                    <div className="footer-details-link">
                                        <ul>
                                            <li>
                                                <Link to="/">
                                                    
                                                    <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Inicio</Typography>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/nosotros">
                                                    
                                                    <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Nosotros</Typography>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/beneficios">
                                                    
                                                    <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Beneficios</Typography>
                                                </Link>
                                            </li>
                                            {false &&<li>
                                                <Link to="/trabajando">
                                                    
                                                    <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Partners</Typography>
                                                </Link>
                                            </li>}
                                            {false &&<li>
                                                <Link to="/trabajando">
                                                    
                                                    <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Recompensa</Typography>
                                                </Link>
                                            </li>}
                                            {false &&<li>
                                                <Link to="/trabajando">
                                                
                                                <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Blog</Typography>
                                                </Link>
                                            </li>}
                                            <li>
                                                <Link to="/contacto">
                                                
                                                <Typography className="footer-link footer-arrow" variant="body2" color='primary'>Contacto</Typography>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <div className="footer-newsletter">
                                <Typography className="footer-title" variant="h6" color='primary'>Mención en prensa</Typography>
                                <div className="form-group">
                                    <a target="_blank" style={{cursor: 'pointer'}} href="https://www.latercera.com/pulso/noticia/la-compleja-opaca-ruta-del-envio-remesas-venezuela/747589/" >
                                        <img style={{ maxWidth: '80%'}} src={prensa} alt="Transferencia de dinero" />
                                    </a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </div>

    );
}

export default Footer;