import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Box } from '@material-ui/core'

import Backdrop from '../../components/Backdrop'
import Tabla from '../../components/Tabla'
import MenuButton from '../../components/MenuButton/MenuButton'
import FormRangos from '../../components/FormRangos'
import Layout from '../../components/Layout'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'

import { fetchPaises, resetErrorPaises } from '../../ducks/Paises'
import { editRangos, crearRango, borrarRango, fetchRangos, resetErrorRangos } from '../../ducks/Rangos';
import { currency } from '../../services/utils';

const Rangos = props => {

    const [ modalCrear, setModalCrear ] = useState(false)
    const [ modalEditar, setModalEditar] = useState(false)
    const [ modalBorrar, setModalBorrar] = useState(false)
    const [ rangoSeleccionado, setRangoSeleccionado ] = useState(false)

    const rangos = useSelector(state => state.Rangos)
    const paises = useSelector(state => state.Paises)
    const loading = useSelector(state => state.Paises.fetching || state.Rangos.fetching)
    const dispatch = useDispatch()

    const fetchData = () => {
        dispatch(fetchRangos())
        dispatch(fetchPaises())
    }
    
    useEffect(() => {
        fetchData() 
    },[])
    
    return (
        <Layout menu={false}>
            <Grid 
                container 
                justify="center"
                alignItems="center">
                <Typography variant="h5">Rangos</Typography>
            </Grid>
            <Tabla
                agregar={() => {setRangoSeleccionado(null); setModalCrear(true)}}
                columnas={[
                    { title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'ID', field: 'id', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Nombre', field: 'nombre',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Descripción', field: 'descripcion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Mínimo', field: 'minimo',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Máximo', field: 'maximo',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'País', field: 'pais',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Activo', field: 'active',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={rangos.data.map(x => ({
                    ...x,
                    minimo: currency(x.minimo),
                    maximo: currency(x.maximo),
                    active: x.active ? "SI" : "NO",
                    pais: paises.data.find(y => y.id === x.country_id) ? paises.data.find(y => y.id === x.country_id).nombre : "No existe",
                    accion: <MenuButton buttons={[
                        {
                            name:"Editar",
                            action: () => {setRangoSeleccionado(x); setModalEditar(true)}
                        },
                        {
                            name: x.active ? "Desactivar" : "Activar",
                            action: () => dispatch(editRangos({ ...x, active: !x.active }))
                        },
                        {
                            name:"Borrar",
                            action: () => {setRangoSeleccionado(x); setModalBorrar(true)}
                        },
                    ]}/>
            }))} />

            <Modal
                open={modalCrear}
                title="Crear Rango"
                close={true}
                handleClose={() => setModalCrear(false)}>
                    <FormRangos 
                        onSubmit={values => {
                            dispatch(crearRango({ ...values, country_id: values.country_id.id}))
                            setModalCrear(false)
                        }} 
                        paises={paises}/>

            </Modal>

            <Modal
                open={modalEditar}
                title="Editar Rango"
                close={true}
                handleClose={() => setModalEditar(false)}>
                    <FormRangos 
                        onSubmit={values => {
                            dispatch(editRangos({ id: rangoSeleccionado.id , ...values, country_id: values.country_id}))
                            setModalEditar(false)
                        }}
                        initialValues={{
                            nombre:  rangoSeleccionado && rangoSeleccionado.nombre,
                            descripcion: rangoSeleccionado && rangoSeleccionado.descripcion,
                            minimo:  rangoSeleccionado && rangoSeleccionado.minimo,
                            maximo:  rangoSeleccionado && rangoSeleccionado.maximo,
                            country_id: rangoSeleccionado && paises.data.find(x => x.id === (rangoSeleccionado.country_id)),
                        }}
                        rangoSeleccionado={rangoSeleccionado}
                        paises={paises}/>

            </Modal>

            <Modal
                open={modalBorrar}
                title="Confirmación"
                close={true}
                action={() => {dispatch(borrarRango(rangoSeleccionado.id)); setModalBorrar(false)}}
                handleClose={() => setModalBorrar(false)}>
                    ¿Está seguro de que desea borrar el rango seleccionado?
            </Modal>

            <Snackbar 
                open={rangos.error && rangos.error.length > 0 ? true : false}
                handleClose={() => {dispatch(resetErrorRangos())}}
                message={rangos.error?.map(error => error)}/>
            <Snackbar 
                open={paises.error && paises.error.length > 0 ? true : false}
                handleClose={() => {dispatch(resetErrorPaises())}}
                message={paises.error?.map(error => error)}/>
            
            <Backdrop open={loading} />
        </Layout>
    )
}

export default Rangos