import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, resetStoreHOR } from './hors.js'
import { postRequest } from '../services/api'


const t = makeTypes('archivos')
const FETCH = t('fetch').async()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, resetStoreReducer)

export const fetchArchivos = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await postRequest('/users_files/search', { id } )
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}