import React from 'react'
import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, delFetch, selectHOR, resetStoreHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../services/api'
import { abrirModal, cerrarModal } from './Modales'

const t = makeTypes('tasas')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const selectTasa = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT} ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, deleteReducer, resetStoreReducer)

export const fetchTasas = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/exchanges_rates?perfil=${values ? values : "Todas" }`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors?.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const editTasas = values => async (dispatch, getState) => {
    const state = getState()
    const tasas = state.Tasas.data.filter(x => x.active).filter(x => x.id !== values.id)
    const rangos = state.Rangos.data
    const rango = state.Rangos.data.find(x => x.id === (values.rango_id.id ? values.rango_id.id : values.rango_id))
    const perfil = state.Profiles.data.find(x => x.id === (values.profile_id.id ? values.profile_id.id : values.profile_id))
    const tasasInv = tasas.filter(x => (values.efectivo === true ? values.efectivo : false) === x.efectivo).filter(x => (x.origen_id === (values.origen_id.id ? values.origen_id.id : values.origen_id) && x.destino_id === (values.destino_id.id ? values.destino_id.id : values.destino_id) && x.profile_id === perfil.id ) )
    const rangoInv = rangos.filter(x => tasasInv.find(y => y.rango_id === x.id) )
    const rangoIncluido = rangoInv.find(x => ( (x.minimo <= rango.minimo && x.maximo >= rango.minimo) || (x.minimo <= rango.maximo && x.maximo >= rango.maximo ) ) )
    const rangoIncluido2 = rangoInv.find(x => ( (rango.minimo <= x.minimo && rango.maximo >= x.minimo) || (rango.minimo <= x.maximo && rango.maximo >= x.maximo ) ) )
    if(rangoIncluido || rangoIncluido2){
        dispatch(abrirModal({ title:"Notificación", body:"Revisar rangos existentes, el rango no se puede solapar", close: true}))
    }else{    
        dispatch(editActions.start())
        try {
                const data = await putRequest(`/exchanges_rates`, values.id, values)
                dispatch(editActions.success(data.data))
                state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        } catch (error) {
            dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : error) : error))
            dispatch(abrirModal({ 
                title:"Notificación", 
                body: error.data ? 
                <ul>
                    {error.data.errors ? error.data.errros.map(x => 
                    <li key={x}>
                        {x} 
                    </li>) : "Ocurrió un error contacte al admistrador"}
                </ul>
                : 
                "Ocurrió un error contacte al admistrador", 
                close: true }))
        }
    }
}

export const crearTasa = values => async (dispatch, getState) => {
    const state = getState()
    const tasas = state.Tasas.data.filter(x => x.active)
    const rangos = state.Rangos.data
    const rango = state.Rangos.data.find(x => x.id === values.rango_id)
    const perfil = state.Profiles.data.find(x => x.id === values.profile_id)
    const tasasInv = tasas.filter(x => (values.efectivo === true ? values.efectivo : false) === x.efectivo).filter(x => (x.origen_id === values.origen_id && x.destino_id === values.destino_id && x.profile_id === perfil.id ) )
    const rangoInv = rangos.filter(x => tasasInv.find(y => y.rango_id === x.id) )
    const rangoIncluido = rangoInv.find(x => ( (x.minimo <= rango.minimo && x.maximo >= rango.minimo) || (x.minimo <= rango.maximo && x.maximo >= rango.maximo ) ) )
    const rangoIncluido2 = rangoInv.find(x => ( (rango.minimo <= x.minimo && rango.maximo >= x.minimo) || (rango.minimo <= x.maximo && rango.maximo >= x.maximo ) ) )
    if(rangoIncluido || rangoIncluido2){
        dispatch(abrirModal({ title:"Notificación", body:"Revisar rangos existentes, el rango no se puede solapar", close: true}))
    }else{
        dispatch(addActions.start())
        try {
                const data = await postRequest(`/exchanges_rates`, values)
                dispatch(addActions.success(data.data))
                state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        } catch (error) {
            dispatch(addActions.error(error.data ? error.data.errors : error))
            dispatch(abrirModal({ 
                title:"Notificación", 
                body: error.data ? 
                <ul>
                    {error.data.errors.map(x => 
                    <li key={x}>
                        {x}
                    </li>)}
                </ul>
                : 
                "Ocurrió un error contacte al admistrador", 
                close: true }))
        }
    }
}

export const borrarTasa = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    const state = getState()
    try {
        const data = await deleteRequest(`/exchanges_rates`, id)
        dispatch(deleteActions.success(data.data.id))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const crearCodigos = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await postRequest(`/exchanges_rates/add-codes`, values )
        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: "Ocurrió un error, intente de nuevo o contacte al admin", 
            close: true }))
    }
}

export const eliminarCodigo = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await postRequest(`/exchanges_rates/delete-code`, values )
        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: "Ocurrió un error, intente de nuevo o contacte al admin", 
            close: true }))
    }
}