import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import createHistory from 'history/createBrowserHistory'
import thunk from 'redux-thunk'
import ducks from './ducks'
import ReactGA from 'react-ga';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './containers/hoc/ScrollTop'

const trackingId = "UA-155050408-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

export const history = createHistory()

export const store = createStore(combineReducers({
    ...ducks
}), applyMiddleware(thunk))

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ScrollToTop />
            <App history={history} />
        </Router>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
