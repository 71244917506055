import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AuthLayout from '../../components/Auth/AuthLayout'
import FormRegister from '../../components/Auth/FormRegister'
import Modal from '../../components/Notificacion2'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import { registerUsuario, resetErrorUsuario, registerUsuarioMayorista } from '../../ducks/Usuario'

const Register = props => {
    const { mayoristas } = props
    const didMountRef = useRef(false)
    const [ registeredModal, setRegisteredModal ] = useState(false)
    const loading = useSelector(state => state.Usuario.fetching)
    const added = useSelector(state => state.Usuario.fetched)
    const error = useSelector(state => state.Usuario.error)
    const dispatch = useDispatch()

    useEffect(() => {
        if(added && didMountRef.current){
            setRegisteredModal(true)
        }else{
            didMountRef.current = true
        }
    },[loading])

    return(
        <>
            <AuthLayout>
                <FormRegister 
                    onSubmit={values => {
                        if(mayoristas){
                            dispatch(registerUsuarioMayorista(values))
                        }else{
                            dispatch(registerUsuario(values)) 
                        }
                    }}
                />
            </AuthLayout>
            <Backdrop open={loading} />
            <Modal
                open={registeredModal}
                title="Notificación"
                close={true}
                handleClose={() => setRegisteredModal(false)}
            >
                Te enviamos a tu dirección de correo electronico la única instrucción para acceder por primera vez a aguacate cambios. 
                Si no recibes el correo electrónico en 15 minutos, revisa la carpeta de no deseados (spam) o comunícate con nosotros.
            </Modal>
            
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorUsuario()) }
                message={error && error.map( er => er)}
            />
        </>
    )

}

export default Register