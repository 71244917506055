import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { resetStore } from '../ducks/Usuario'

const PrivateRoute = ({ component: Component, permission, all, ...rest }) => {
    const dispatch = useDispatch()
    const perfil = useSelector(state => state.Usuario.data[0]);
    return (
        <Route {...rest} render={(props) => {
            if (perfil){
                if(all){
                    return <Component {...props} />
                }
                else if(perfil.active && (permission.indexOf(perfil.profile.nombre) !== -1)){
                    return <Component {...props} />
                }else{
                    return <Redirect to="/" />
                }
            }
            else{
                    setTimeout(() => {dispatch(resetStore())},2000)
                    return <Redirect to="/login" />

            }
        }}/>
    );
}

export default PrivateRoute;
