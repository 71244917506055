import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm, formValueSelector, getFormMeta, change, getFormSyncErrors } from 'redux-form'
import { Button, Typography, Grid, Container, Switch } from '@material-ui/core'
import renderCheckbox from './Checkbox'
import TextField from './TextField';
import SelectSearch from './SelectSearch2';
import { currency2decimal } from '../services/utils'

const validate = (values, props) => {
  const { tasas, calculadoraSec, limitMaximo } = props
  const errors = {}
  let tasaPaisValid
  let tasaEfectivoValid
  let tasaRangoValid
  let minimo
  let maximo

  if(calculadoraSec){
    tasaPaisValid= true
    tasaEfectivoValid= true
    maximo = limitMaximo
    if(values.pais_origen && values.pais_origen.nombre === "Panama"){
      minimo= 1
    }else if(values.pais_origen && values.pais_origen.nombre === "Chile"){
      minimo = 1000
    }else{
      minimo = 1000
    }
    tasaRangoValid = (minimo <= values.origen) && (maximo >= values.origen)
  }else{
    tasaPaisValid = tasas.data.find(x => ( (x.origen_id === (values.pais_origen && values.pais_origen.id)) && (x.destino_id === (values.pais_destino && values.pais_destino.id)) ) )
    tasaEfectivoValid = tasas.data.filter(x => x.efectivo === values.efectivo ).find(x => ( (x.origen_id === (values.pais_origen && values.pais_origen.id)) && (x.destino_id === (values.pais_destino && values.pais_destino.id)) ) )
    tasaRangoValid = tasas.data.filter(x => x.efectivo === values.efectivo ).find(x => ( (x.origen_id === (values.pais_origen && values.pais_origen.id)) && (x.destino_id === (values.pais_destino && values.pais_destino.id)) && ( x.rango.minimo <= values.origen && x.rango.maximo >= values.origen ) ) )
    minimo = Math.min(...tasas.data.filter(x => x.efectivo === values.efectivo).filter(x => ( (x.origen_id === (values.pais_origen && values.pais_origen.id)) && (x.destino_id === (values.pais_destino && values.pais_destino.id)))).map(x => x.rango.minimo))
    maximo = Math.max(...tasas.data.filter(x => x.efectivo === values.efectivo).filter(x => ( (x.origen_id === (values.pais_origen && values.pais_origen.id)) && (x.destino_id === (values.pais_destino && values.pais_destino.id)))).map(x => x.rango.maximo))
  }

  const requiredFields = [
    'origen',
    'destino',
    'pais_origen',
    'pais_destino',
    'tasa',
    'codigo'
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if(!values.tasa){
    errors.efectivo = `Tasa no disponible`
  }

  if(values.origen && !tasaRangoValid ){
    if(values.origen < minimo)
      errors.origen = `El monto mínimo permitido es ${minimo}`
    else if(values.origen > maximo)
      errors.origen = `El monto máximo permitido es ${maximo}`
    else
      errors.origen = 'Valores no válidos'
  }

  if(values.efectivo && !tasaEfectivoValid ){
    errors.efectivo = `Efectivo no esta disponible para esta tasa`
  }

  if(values.pais_origen && !tasaPaisValid ){
    errors.pais_origen = `${values.pais_origen.nombre}-${values.pais_destino.nombre} no disp.`
    errors.pais_destino = `${values.pais_origen.nombre}-${values.pais_destino.nombre} no disp.`
  }
  
  if(values.pais_destino && !tasaPaisValid ){
    errors.pais_destino = `${values.pais_origen.nombre}-${values.pais_destino.nombre} no disp.`
    errors.pais_origen = `${values.pais_origen.nombre}-${values.pais_destino.nombre} no disp.`
  }

  if( values.promocion && !(tasas.data.find( x => x.promotional_codes.find(y => y.codigo === values.codigo)))){
    errors.codigo = `Código Promocional Inválido`
  }

  return errors
}

const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '').replace(/\-/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}

const normalizeAmount = country => {
  if(country){
    if(country.nombre === "Panama") return numeroDecimal
    else if(country.nombre === "Venezuela") return numeroDecimal
    else return numero
  }else{
    return numero
  }
}

const numeroDecimal = value => {
  let valor
  valor = value.replace(/\./g, '').replace(/\-/g, '')
  let partes = valor.split(",");
  let decimal = partes.length > 1 && partes[1].substring(0,5)
  valor = decimal ? partes[0]+","+decimal : valor
  valor = valor.replace(/\,/g, '.')
  return valor
}

const selector = formValueSelector('calculadora')

let FormCalculadora = props => {
    const { handleSubmit, controlEfectivo, paisDisabled, change, descripcion, codigoPromocional } = props
    const tasas = useSelector(state => state.Tasas )
    const paises = useSelector(state => state.Paises )
    const efectivo = useSelector(state => selector(state, 'efectivo'))
    const pais_origen = useSelector(state => selector(state, 'pais_origen'))
    const pais_destino = useSelector(state => selector(state, 'pais_destino'))
    const promocion = useSelector(state => selector(state, 'promocion'))
    const codigoCalc = useSelector(state => selector(state, 'codigo'))
    const origen = useSelector(state => selector(state, 'origen'))
    const tasa = useSelector(state => selector(state, 'tasa'))
    let codigo = useSelector(state => state.DetalleCambio.data.codigo )

    if(codigoCalc){
      codigo = codigoCalc
    }


    const calculo = (tasaSeleccionada, tipo, value = origen ) => {
      change('tasa', tasaSeleccionada ? tasaSeleccionada : null)
      change('pais_origen', tasaSeleccionada ? tasaSeleccionada.origen : "" )
      change('pais_destino', tasaSeleccionada ? tasaSeleccionada.destino : "" )
      if(tipo === "origen"){
        switch (pais_origen.nombre) {
          case "Panama":
            change('origen', tasaSeleccionada ? Math.ceil(tasaSeleccionada.proporcional ? (value / tasaSeleccionada.tasa ) * 100 : (value * tasaSeleccionada.tasa) * 100 ) / 100 : "" )
            break;
          default:
            change('origen', tasaSeleccionada ? Math.ceil(tasaSeleccionada.proporcional ? (value / tasaSeleccionada.tasa ) * 100000 : (value * tasaSeleccionada.tasa) * 100000 ) / 100000 : "" )
            break;
        }
      }else{
        switch (pais_destino.nombre) {
          case "Panama":
            change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * value) * 100 : (value / tasaSeleccionada.tasa) * 100 ) / 100 : "" )
            break;
          default:
            change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * value) * 100000 : (value / tasaSeleccionada.tasa) * 100000 ) / 100000 : "" )
            break;
        }
      }
    }
    
    const handleChange = (e,value,name) => {
      let tasaSeleccionada

      if(e.target){
        if(e.target.name === "origen"){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) : tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id) && ( x.rango.minimo <= value && x.rango.maximo >= value ) ) )
          if(tasaSeleccionada){
            calculo(tasaSeleccionada, undefined, value)
          }else{
            const valoresMaximos = tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id))).map(x => x.rango.maximo )
            tasaSeleccionada = tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id)  ) )
            var closest = valoresMaximos.reduce(function(prev, curr) {
              return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
            });
            tasaSeleccionada = tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id))).find(x => x.rango.maximo === closest )
            calculo(tasaSeleccionada, undefined, value)
          }
        }else if(e.target.name === "destino"){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) : tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id) && ( x.proporcional ? ( (x.rango.minimo <= Math.floor(value / x.tasa)) && (x.rango.maximo >= Math.floor(value / x.tasa))) : ( (x.rango.minimo <= Math.floor(value * x.tasa)) && (x.rango.maximo >= Math.floor(value * x.tasa)) ) ) ) )
          if (tasaSeleccionada){
            calculo(tasaSeleccionada, "origen", value)
          }else{
            const valoresMaximos = tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id))).map(x => x.rango.maximo )
            tasaSeleccionada = tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id)  ) )
            const valor = tasaSeleccionada.proporcional ? Math.round(value / tasaSeleccionada.tasa) : Math.round(value * tasaSeleccionada.tasa)
            var closest = valoresMaximos.reduce(function(prev, curr) {
              return (Math.abs(curr - valor) < Math.abs(prev - valor) ? curr : prev);
            });
            tasaSeleccionada = tasas.data.filter(x => x.efectivo === efectivo).filter(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id))).find(x => x.rango.maximo === closest )
            calculo(tasaSeleccionada, "origen", value)
          }
        }else if(e.target.name === "efectivo"){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) : tasas.data.filter(x => x.efectivo === value).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id) && ( x.rango.minimo <= origen && x.rango.maximo >= origen ) ) )
          calculo(tasaSeleccionada, undefined)
        }else if(e.target.name === "codigo"){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === value)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === value)) : tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id) && ( x.rango.minimo <= origen && x.rango.maximo >= origen ) ) )
          calculo(tasaSeleccionada, undefined)
        }else if(e.target.name === "promocion"){
          change('codigo', '')
          tasaSeleccionada = tasas.data.filter(x => x.efectivo === efectivo).find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === pais_destino.id) && ( x.rango.minimo <= origen && x.rango.maximo >= origen ) ) )
          calculo(tasaSeleccionada, undefined)
        }
      }else{
        if(name === 'pais_origen'){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) : tasas.data.find(x => ( (x.origen_id === value.id) && (x.destino_id === pais_destino.id)) )
          change('tasa', tasaSeleccionada ? tasaSeleccionada : null)
          change('origen', tasaSeleccionada ? tasaSeleccionada.rango.minimo : "")
          switch (pais_destino.nombre) {
            case "Panama":
              change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * tasaSeleccionada.rango.minimo) * 100 : (tasaSeleccionada.rango.minimo / tasaSeleccionada.tasa) * 100 ) / 100 : "" )
              break;
            default:
              change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * tasaSeleccionada.rango.minimo) : (tasaSeleccionada.rango.minimo / tasaSeleccionada.tasa) ) : "" )
              break;
          }
        }else if(name === 'pais_destino'){
          tasaSeleccionada = tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) ? tasas.data.find( x => x.promotional_codes.find(y => y.codigo === codigo)) : tasas.data.find(x => ( (x.origen_id === pais_origen.id) && (x.destino_id === value.id) ) )
          change('tasa', tasaSeleccionada ? tasaSeleccionada : null)
          change('origen', tasaSeleccionada ? tasaSeleccionada.rango.minimo : "")
          switch (value.nombre) {
            case "Panama":
              change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * tasaSeleccionada.rango.minimo) * 100 : (tasaSeleccionada.rango.minimo / tasaSeleccionada.tasa) * 100 ) / 100 : "" )
              break;
            default:
              change('destino', tasaSeleccionada ? Math.floor(tasaSeleccionada.proporcional ? (tasaSeleccionada.tasa * tasaSeleccionada.rango.minimo) : (tasaSeleccionada.rango.minimo / tasaSeleccionada.tasa) ) : "" )
              break;
          }
        }  
      }
    } 

    return(
        <form noValidate onSubmit={handleSubmit} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Field
                          required
                          style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                          name="pais_origen"
                          label="Desde"
                          disableClearable={true}
                          etiqueta="origen"
                          disabled={paisDisabled}
                          onChange={(e,value) => handleChange(e,value, 'pais_origen')}
                          options={paises.data.filter( x => tasas.data.find(y => y.origen_id === x.id))}
                          component={SelectSearch}/>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Field
                          name="origen"
                          label="Tu envías"
                          autoComplete='off'
                          format={currency2decimal}
                          normalize={normalizeAmount(pais_origen)}
                          onChange={(e,value) => handleChange(e,value)}
                          component={TextField}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Field
                          required
                          style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                          name="pais_destino"
                          label="Hacia"
                          etiqueta="destino"
                          disabled={paisDisabled}
                          disableClearable={true}
                          onChange={(e,value) => handleChange(e,value, 'pais_destino')}
                          options={paises.data.filter( x => tasas.data.find(y => y.destino_id === x.id))}
                          component={SelectSearch}/>

                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Field
                          name="destino"
                          label="Tu destinatario recibe"
                          autoComplete='off'
                          format={currency2decimal}
                          normalize={normalizeAmount(pais_destino)}
                          onChange={(e,value) => handleChange(e,value)}
                          component={TextField}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {codigoPromocional && promocion && <Field
              name="codigo"
              label="Ingresa tu código"
              autoComplete='off'
              onChange={(e,value) => handleChange(e,value)}
              component={TextField}
            />}

            {controlEfectivo && <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                <Field 
                    name="efectivo" 
                    component={renderCheckbox} 
                    label="Efectivo" 
                    onChange={(e,value) => handleChange(e,value)}
                />
                </Grid>
            </Grid>}

            {codigoPromocional && <Grid item xs={12} lg={12} xl={12}>
              <Grid container justify="center" alignItems="center"  >
                <Field 
                    name="promocion" 
                    component={renderCheckbox} 
                    label="¿Tienes codigo promocional?" 
                    onChange={(e,value) => handleChange(e,value)}
                />
              </Grid>
            </Grid>}

        

            {descripcion &&
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ border: '1px dashed grey', margin:'10px', backgroundColor: '#efefef'}}>
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center">
                    <Typography variant='body1' align='justify'>
                        {`Tasa de cambio: ${tasa ? (tasa.descripcion ? tasa.descripcion : "" )  : ""}`}
                    </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                    <Typography variant='body1' align='justify'>
                        {`Tiempo máximo de envío: ${tasa ? (tasa.tiempo ? tasa.tiempo : "") : ""}`}
                    </Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>}


            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Button variant="contained" color="primary" type="submit">
                        Enviar Dinero
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default FormCalculadora = reduxForm({
  form: 'calculadora',
  enableReinitialize : true,
  validate,
})(FormCalculadora);