import React from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { countryToFlag } from '../services/utils'

const RenderSelect = ({
  input,
  label,
  options,
  meta: { touched, error },
  etiqueta,
  ...custom
}) => {
  return (
    <Autocomplete
      name={input.name}
      value={input.value ? input.value : null}
      {...custom}
      options={options}
      onBlur={(e,value) => input.onBlur(value)}
      onChange={(e, value) => input.onChange(value)}
      getOptionSelected={(option,value) => option.id === value.id}
      getOptionLabel={(options) => {
        if (etiqueta === "tasa"){
          return `${options.nombre ? options.nombre : options.cliente}${options.tasa ? "-" + options.tasa : ""}`
        }else if(etiqueta === "pais"){
          return options.code ? countryToFlag(options.code) + "  " + options.label : ""
        }else if(etiqueta === "codigo"){
          return options.code ? countryToFlag(options.code) + " +" + options.phone : ""
        }else if(etiqueta === "email"){
          return options.nombre ? (options.nombre ? options.nombre : "") : (options.email ? options.email : "");
        }else{
          return options.nombre ? (options.nombre ? options.nombre : "") : (options.cliente ? options.cliente : "");
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            required={Boolean(custom.required)}
            {...params}
            label={label}
            variant="outlined"
            error={touched && (error ? true : false)}
            helperText={touched && error && <span>{error}</span>}
            fullWidth
          />
        );
      }}
    />
  );
};

export default RenderSelect;
