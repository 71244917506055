import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Grid, InputAdornment, IconButton, Paper, Box, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TextField from './TextField';
import SelectSearch from './SelectSearch2';
import Loading from '../components/Loading'
import BancosPnm from '../constants/BancosPnm'
import Checkbox from '../components/Checkbox'
import TiposCuentaPnm from '../constants/TiposCuentaPnm'
import { buscarBeneficiarioNombre } from '../ducks/Beneficiarios'
import { numeroCuentaPnm, currency8decimal } from '../services/utils'

export const validate = (values = {}, props) => {
    const errors = {}
    const requiredFields = [
      'nombre',
      'email',
      'banco',
      'tipoCuenta',
      'cuenta',
    ]
    requiredFields.forEach(field => {
    if (!values[field]) {
        errors[field] = true
      }
    })
  
    if (values.nombre && values.nombre.length <= 3 ){
      errors.nombre = "Nombre inválido"
    }
  
    if (values.cuenta && values.cuenta.length < 3){
        errors.cuenta = "Cuenta Inválida"
    }

    if (values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }
    return errors
}

const selector = formValueSelector('form-beneficiario-pnm')
const numeroDecimal = value => value && value.replace(/\./g, '');

let FormBeneficiarioPnm = props => {
    const { handleSubmit, busquedaAdmin, workingaccount, cuentaSeleccionada, paisId } = props
    const loading = useSelector(state => state.Beneficiarios.fetching)
    const nombre = useSelector(state => selector(state, 'nombre'))
    const dispatch = useDispatch()

    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10, width: '100%' }}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h6">Formulario Beneficiario Panamá</Typography>
            </Grid>
            <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Field
                    type="text"
                    placeholder="Nombre y Apellido"
                    name="nombre"
                    label="Nombre y Apellido"
                    component={TextField}
                    InputProps={{
                    endAdornment: busquedaAdmin && <InputAdornment position="end"><IconButton onClick={() => dispatch(buscarBeneficiarioNombre({ nombre: nombre, pais: paisId }))}><SearchIcon /></IconButton></InputAdornment>,
                    }}
                />
                
                <Field
                    type="text"
                    name="email"
                    label="Correo Electróniaco"
                    component={TextField}
                />

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="tipoCuenta"
                    label="Tipo de Cuenta"
                    component={SelectSearch}
                    options={TiposCuentaPnm}
                />

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    type="select"
                    name="banco"
                    label="Banco"
                    component={SelectSearch}
                    options={BancosPnm} 
                />
                
                <Field
                    type="text"
                    name="cuenta"
                    label="Número de Cuenta"
                    normalize={numeroCuentaPnm}
                    component={TextField}
                />
                {workingaccount && <>
                    <Field
                        type="text"
                        name="profit"
                        label="Ganancia por Transacción"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />

                    <Field
                        type="text"
                        name="cupo_mb"
                        label="Cupo MB"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />

                    <Field
                        type="text"
                        name="cupo_ob"
                        label="Cupo OB"
                        format={currency8decimal}
                        normalize={numeroDecimal}
                        component={TextField}
                    />

                    <Field 
                        name="receiver" 
                        component={Checkbox} 
                        label="Recibe dinero?" 
                    />
                </>}
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Button variant="contained" color="primary" type="submit">
                            {cuentaSeleccionada ? 'Editar Beneficiario' : 'Agregar Beneficiario' }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}

FormBeneficiarioPnm = reduxForm({
  form: 'form-beneficiario-pnm',
  enableReinitialize : true,
  validate,
})(FormBeneficiarioPnm);

export default FormBeneficiarioPnm
