import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@material-ui/core'

import Layout from '../../components/Layout'
import TarjetaNueva from '../../components/TarjetaNueva'
import TarjetaCuentas from '../../components/TarjetaCuentas/TarjetaCuentas'
import Modal from '../../components/Notificacion2'

import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import { fetchBeneficiariosClientes, borrarBeneficiario, editCuenta, resetErrorBeneficiariosClientes, agregarBeneficiarioAUsuario } from '../../ducks/beneficiarios/BeneficiariosClientes'
import { fetchPaises } from '../../ducks/Paises'
import FormsBeneficiarios from '../../components/FormsBeneficiarios/FormsBeneficiarios';

const Cuentas = props => {
    const didMountRef = useRef(false);
    const dispatch = useDispatch()
    const [ message, setMessage ] = useState('')
    const [ paisId, setPaisId ] = useState(null)
    const [ cuentaSelectId, setCuentaSelectId ] = useState(null)
    const [ crearCuenta, setCrearCuenta ] = useState(false)
    const [ modalBorrarCuenta, setModalBorrarCuenta ] = useState(false)
    const [ modalEditarCuenta, setModalEditarCuenta ] = useState(false)
    const beneficiarios = useSelector(state => state.BeneficiariosClientes)
    const error = useSelector(state => state.BeneficiariosClientes.error)
    const paises = useSelector(state => state.Paises)
    const loading = useSelector(state => (
        state.BeneficiariosClientes.fetching || 
        state.BeneficiariosClientes.editing ||
        state.BeneficiariosClientes.adding ||
        state.BeneficiariosClientes.deleting ||
        state.Paises.fetching
        ))
    const paisSeleccionado = paises.data.find(p => p.id === paisId)
    const cuentaSeleccionada = beneficiarios.data.find(cuenta => cuenta.id === cuentaSelectId)

    const submitFormVzla = (values, action) => {
        if(action === "create"){
            dispatch(agregarBeneficiarioAUsuario({
                ...values,
                idTipo: values.idTipo.id,
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
                cuenta: values.codigo + values.cuenta,
            }))
        }else{
            dispatch(editCuenta({
                ...values,
                idTipo: values.idTipo.id,
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
                cuenta: values.codigo + values.cuenta,
            }))
        }
    }

    const submitFormChile = (values,action) => {
        if(action === "create"){
            dispatch(agregarBeneficiarioAUsuario({
                ...values,
                idNumero: values.rut,
                idTipo: "Rut",
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
            }))
        }else{
            dispatch(editCuenta({
                ...values,
                idNumero: values.rut,
                idTipo: "Rut",
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
            }))
        }
    }

    const submitFormPnm = (values,action) => {
        if(action === "create"){
            dispatch(agregarBeneficiarioAUsuario({
                ...values,
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
            }))
        }else{
            dispatch(editCuenta({
                ...values,
                tipoCuenta: values.tipoCuenta.id,
                banco: values.banco.id,
            }))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if(beneficiarios.data.length === 0){
                dispatch(fetchBeneficiariosClientes())
            }
            dispatch(fetchPaises())
        }
        fetchData()
    },[])

    useEffect(() => {
        if(beneficiarios.edited && !beneficiarios.editing && didMountRef.current){
            setMessage("Su Beneficiario fue editado")
            setModalEditarCuenta(false)
        }
    },[beneficiarios.editing])

    useEffect(() => {
        if(beneficiarios.deleted && !beneficiarios.deleting && didMountRef.current){
            setMessage("Su Beneficiario fue borrado")
            setModalBorrarCuenta(false)
        }
    },[beneficiarios.deleting])

    useEffect(() => {
        if(beneficiarios.added && !beneficiarios.adding && didMountRef.current){
            setMessage("Su Beneficiario fue registrado")
            setCrearCuenta(false)
        }
        didMountRef.current = true
    },[beneficiarios.adding])

    return (
        <Layout>
            <Grid container justify="flex-start" alignItems="center" >
                <Grid item>
                    <Typography variant="h5">Mis Beneficiarios</Typography>
                </Grid>
            </Grid>

            <Box mb={2}>
                <TarjetaNueva 
                    title="Nueva Cuenta"
                    descripcion="Click aquí para crear una nueva cuenta"
                    handleClick={() => {setCuentaSelectId(null);setPaisId(null);setCrearCuenta(true)}}
                />
            </Box>
            {beneficiarios.data.length > 0 ? beneficiarios.data.map(cuenta =>
                <Box key={cuenta.id} mb={2}>
                    <TarjetaCuentas
                        buttons={[
                            {
                                name: "Editar",
                                action: () => {setCuentaSelectId(cuenta.id);setPaisId(cuenta.country_id);setModalEditarCuenta(true)},
                            },
                            {
                                name: "Borrar",
                                action: () => {setCuentaSelectId(cuenta.id);setModalBorrarCuenta(true)},
                            }
                        ]}
                        cuenta={cuenta}
                    />
                </Box>)
                : 
                <Typography variant="body1" style={{ textAlign: "center", margin: 10 }}>No se han encontrado cuentas, puedes empezar a agregar tus beneficiarios!</Typography>
            }

            <Modal
                open={crearCuenta}
                title="Registrar Cuenta"
                close={true}
                handleClose={() => setCrearCuenta(false)}
            >
                <FormsBeneficiarios
                    handlePais={value => setPaisId(value.id)}
                    pais={paisSeleccionado}
                    paises={paises.data.filter( x => x.nombre !== "BTC")}
                    handleSubmitVzla={values => submitFormVzla(values,"create")}
                    handleSubmitChile={values => submitFormChile(values,"create")}
                    handleSubmitPnm={values => submitFormPnm(values,"create")}
                />
            </Modal>

            <Modal
                open={modalBorrarCuenta}
                title="Confirmación"
                close={true}
                handleClose={() => setModalBorrarCuenta(false)}
                action={async () => dispatch(borrarBeneficiario(cuentaSeleccionada.id))}
            >
                ¿Está seguro de que desea desvincular esta cuenta?
            </Modal>

            <Modal
                open={modalEditarCuenta}
                title="Editar Cuenta"
                close={true}
                handleClose={() => setModalEditarCuenta(false)}
            >
                <FormsBeneficiarios 
                    pais={paisSeleccionado}
                    paises={paises.data.filter( x => x.nombre !== "BTC")}
                    cuentaSeleccionada={cuentaSeleccionada}
                    handleSubmitVzla={values => submitFormVzla(values)}
                    handleSubmitChile={values => submitFormChile(values)}
                    handleSubmitPnm={values => submitFormPnm(values)}
                />
            </Modal>

            <Snackbar 
                open={!!message}
                handleClose={() => setMessage('')}
                message={message}
            />

            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorBeneficiariosClientes()) }
                message={error && error.map( er => er )}
            />

            <Backdrop 
                open={loading}
            />   
        </Layout>
    )

}

export default Cuentas