import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, resetErrorHOR, resetHOR } from '../hors.js'
import { postRequest } from '../../services/api'

const t = makeTypes('solicitudes-abiertas')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const RESET = t('reset').single()
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
export const resetSolicitudesAbiertas = mac(RESET)
export const resetErrorSolicitudesAbiertas = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers(fetchReducer, editReducer, resetErrorReducer, resetReducer)

export const fetchSolicitudesAbiertas = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await postRequest('/clpbstransactions/busqueda-avanzada', values)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const editarTransacciones = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await postRequest(`/clpbstransactions/edit-transactions`, values)
        data.data.map(transaccion => {
            dispatch(editActions.success(transaccion))
        })
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}   