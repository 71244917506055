const Profesiones = [  
    {id:"Administracion de Empresas", nombre:"Administración de Empresas"},
    {id:"Agronomia", nombre:"Agronomía"},
    {id:"Comerciante/Ventas", nombre:"Comerciante/Ventas"},
    {id:"Construccion", nombre:"Construcción"},
    {id:"Contador", nombre:"Contador"},
    {id:"Economia, Finanzas y Comercio Internacional", nombre:"Economía, Finanzas y Comercio Internacional"},
    {id:"Educacion", nombre:"Educación"},
    {id:"Enfermeria", nombre:"Enfermería"},
    {id:"Estadistica", nombre:"Estadística"},
    {id:"Estudiante", nombre:"Estudiante"},
    {id:"Derecho/Abogacia", nombre:"Derecho/Abogacía"},
    {id:"Gastronomia/Hoteleria", nombre:"Gastronomía/Hotelería"},
    {id:"Ginecologia y Obstetricia", nombre:"Ginecología y Obstetricia"},
    {id:"Ingenieria Civil", nombre:"Ingeniería Civil"},
    {id:"Ingenieria Comercial", nombre:"Ingeniería Comercial"},
    {id:"Ingenieria Electrica", nombre:"Ingeniería Eléctrica"},
    {id:"Ingenieria Electromecanica", nombre:"Ingeniería Electromecánica"},
    {id:"Ingenieria Electronica", nombre:"Ingeniería Electrónica"},
    {id:"Ingenieria de Minas", nombre:"Ingeniería de Minas"},
    {id:"Ingenieria de Produccion", nombre:"Ingeniería de Produccion"},
    {id:"Ingeneria de Sistemas/Computación/Informatica", nombre:"Ingenería de Sistemas/Computación/Informatica"},
    {id:"Ingenieria de Telecomunicaciones", nombre:"Ingeniería de Telecomunicaciones"},
    {id:"Ingeneria Industrial", nombre:"Ingenería Industrial"},
    {id:"Ingenieria Mecanica", nombre:"Ingeniería Mecanica"},
    {id:"Medicina", nombre:"Medicina"},
    {id:"Otras Ciencias", nombre:"Otras Ciencias"},
    {id:"Otra Profesion", nombre:"Otra Profesión"},
    {id:"Psicologia", nombre:"Psicología"},
    {id:"Quimica/Farmaceutica/Laboratorio", nombre:"Química/Farmacéutica/Laboratorio"},
    {id:"Tareas del Hogar", nombre:"Tareas del Hogar"},
    {id:"Transporte/Logistica", nombre:"Transporte/Logística"},
]

export default Profesiones