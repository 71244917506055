import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core'

import Datos from './Contacto'
import HomeLayout from '../HomeLayout/HomeLayout';
import { Titlespan } from '../common/title/index';
import { history } from '../../index';
import './Contacto.scss';

function Contacto(props) {
    return (
        <React.Fragment>
            <HomeLayout>
                <div id="main-content">
                    <section className={`banner-wrapper1 demo1`}>
                        <div className="banner-outer">
                            <>
                                <Grid container>
                                    <Grid item md={6}>
                                        <div className="banner-content1">
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="h4">Si tienes dudas...</Typography>}
                                                Name=""
                                            />
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="body1">No dudes en contactarnos, con todo gusto atenderemos a tus consultas. Estaremos felices de guiarte en todo el proceso</Typography>}
                                                Name=""
                                            />
                                            {false &&<div className="banner-btn-wrapper1">

                                                <Button onClick={() => history.push("/login")}
                                                    variant="outlined" color="primary">
                                                    Envía con nosotros
                                                </Button>
                                            </div>}
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </section>
                    <Datos />
                </div>
            </HomeLayout>
        </React.Fragment>
        
    );
}

export default Contacto;
