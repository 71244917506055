import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, Container, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import RadioButton from './RadioButton';
import Loading from '../components/Loading'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'razon',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.busqueda && values.busqueda.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  if (values.numero_cuenta && values.numero_cuenta.length !== 20){
    errors.numero_cuenta = "Cuenta inválida"
  }

  if (values.telefono && values.telefono.length <= 7 ){
      errors.telefono = "Telefono inválido"
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Corre Electrónico Inválido'
  }
  return errors
}

  

let FormRechazo = props => {
      const { handleSubmit, change, initialValues, nombre, busquedaAdmin, submitting, busqueda, buscarCliente, buscarClienteNombre } = props
      return(
          <Container>
                <form noValidate onSubmit={handleSubmit} >
                <Grid container  >
                  <Grid item xs={12} lg={12} xl={12}>
                      <Grid container justify="center" alignItems="center"  >
                          <Field
                              required
                              style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                              type="select"
                              change={change}
                              disableClearable={true}
                              options={[{id:'identificacion', nombre:"Identificación"},{id:'deposito', nombre:'Deposito'}]}
                              component={SelectSearch}
                              name="razon"
                              label="Razón de rechazo" />
                      </Grid>
                  </Grid>
                  <Grid item xs={12} lg={12} xl={12}>
                      <Grid container justify="center" alignItems="center"  >
                          <Field
                              name="descripcion"
                              label="Informacion Extra"
                              component={TextField}
                          />
                      </Grid>
                  </Grid>
                  <Grid item xs={12} lg={12} xl={12}>
                      <Grid container justify="center" alignItems="center"  >
                          { submitting ? <Loading /> :
                          <Button variant="contained" color="primary" type="submit">
                              Rechazar
                          </Button>}
                      </Grid>
                  </Grid>
                </Grid>
                </form>
            </Container>
        )
    }


FormRechazo = reduxForm({
  form: 'rechazo',
})(FormRechazo);

export default connect( state => {
    return{
        enableReinitialize : true,
        validate,
    }
})(FormRechazo)
