import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Paper, Box, Grid, Typography } from '@material-ui/core'
import FormCalculadora from '../components/FormCalculadora';
import Snackbar from '../components/Snackbar';
import Loading from '../components/Loading';
import { fetchTasas } from '../ducks/Tasas'
import { fetchPaises } from '../ducks/Paises'

const Calculadora = props => {
    const { handleSubmit, paisDisabled, controlEfectivo, descripcion, perfil, codigoPromocional } = props
    const dispatch = useDispatch()
    const tasas = useSelector(state => state.Tasas)
    const paises = useSelector(state => state.Paises)
    const tasaInicial = tasas.data.find(x => ((x.origen.nombre === "Chile") && (x.destino.nombre === "Venezuela") && (x.efectivo === false) && (x.rango.minimo === 10000) ) ) ? tasas.data.find(x => ((x.origen.nombre === "Chile") && (x.destino.nombre === "Venezuela") && (x.efectivo === false) && (x.rango.minimo === 10000) ) ) : (tasas.data[0] ? tasas.data[0] : "")  
    const paisOrigen = paises.data.find( x => x.id === tasaInicial.origen_id) ? paises.data.find( x => x.id === tasaInicial.origen_id) : ""
    const paisDestino = paises.data.find( x => x.id === tasaInicial.destino_id) ? paises.data.find( x => x.id === tasaInicial.destino_id) : ""
    const loading = useSelector( state => state.Tasas.fetching || state.Paises.fetching)
    const destino = 
        tasaInicial ?
            ( paisDestino.nombre === "Panama" ? 
                (tasaInicial ? 
                    Math.floor(tasaInicial.proporcional ? 
                        (tasaInicial.tasa * tasaInicial.rango.minimo) * 100 
                    : (tasaInicial.rango.minimo / tasaInicial.tasa) * 100 ) / 100 
                : "" ) 
            :   Math.floor(tasaInicial.proporcional ? 
                    (tasaInicial.tasa * tasaInicial.rango.minimo) * 100000
                : (tasaInicial.rango.minimo / tasaInicial.tasa) * 100000) / 100000)
        : "" 
    
    
    const fetchData = async () => {
        await dispatch(fetchTasas(perfil))
        await dispatch(fetchPaises())
    }

    useEffect(() => {
        fetchData()
    }, [])

    return(
        <Grid container alignItems="center" justify="center">
            <Grid item xs={12} lg={10} xl={10}>
                <Paper style={{ width: '100%' }}>
                    <Box p={4}>
                        <div>
                            <Grid container justify="center" alignItems="center" style={{ marginBottom: '20px'}} >
                                <Typography variant="h6">Calcula tu envío</Typography>
                            </Grid>
                            {loading ? 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container justify="center" alignItems="center"  >
                                        <Loading />
                                    </Grid>
                                </Grid> 
                                :
                                <FormCalculadora 
                                    codigoPromocional={codigoPromocional}
                                    controlEfectivo={controlEfectivo} 
                                    descripcion={descripcion} 
                                    tasas={tasas}
                                    paisDisabled={paisDisabled} 
                                    initialValues={{
                                        pais_origen: paisOrigen,
                                        pais_destino: paisDestino,
                                        tasa: tasaInicial ? tasaInicial : null,
                                        origen: tasaInicial ? tasaInicial.rango.minimo : "",
                                        efectivo: tasaInicial ? tasaInicial.efectivo : false,
                                        destino: destino,
                                    }}        
                                    onSubmit={ async values => {
                                        handleSubmit({ ...values, origen: parseFloat(values.origen), destino: parseFloat(values.destino) })
                                    }} 
                                />
                            } 
                        </div>
                    </Box>
                </Paper>
            </Grid>     
            <Snackbar handleClose={() => {}} noCloseButton={true} open={tasas.data.length === 0 && tasas.fetched}  message="El servicio esta deshabilidato momentaneamente" />                       
        </Grid>
    )
}

export default Calculadora
