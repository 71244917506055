import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import { IconButton, Typography, Button, Grid, Box } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DeleteIcon from '@material-ui/icons/Delete';

import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import FormCalculadora from '../../components/FormCalculadora';
import SelectSearch from '../../components/SelectSearch2';
import FormBeneficiarioChile from '../../components/FormBeneficiarioChile';
import FormBeneficiariosVzla from '../../components/FormularioBeneficiariosVzla';
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import { Banco } from '../../services/utils'
import { currency, currency2decimal } from '../../services/utils'
import { fetchPaises } from '../../ducks/Paises'
import { editCuentas, borrarCuenta, quitarDeCliente, quitarDeUsuario, seleccionarBeneficario, resetErrorBeneficiario, agregarBeneficiario } from '../../ducks/Beneficiarios'
import { agregarBeneficiarioSeleccionado } from '../../ducks/BeneficiariosSeleccionados'
import { asignarMensaje,resetMensaje } from '../../ducks/Mensajes'
import FormBeneficiarioPnm from '../../components/FormBeneficiarioPnm';
import BancosChile from '../../constants/BancosChile'
import BancosVzla from '../../constants/BancosVzla'
import BancosPnm from '../../constants/BancosPnm'
import TiposCuentasChile from '../../constants/TiposCuentasChile'
import TiposCuentasVzla from '../../constants/TiposCuentaVzla'
import TiposCuentaPnm from '../../constants/TiposCuentaPnm'
import TiposIdVzla from '../../constants/TiposIdVzla';


const TablaBeneficiarios = props => {
    const { 
        anadir,
        agregar, 
        seleccionar,
        handleSeleccionar,
        acciones, 
        desvincularCliente, 
        desvincularUsuario,
        nombre,
        cedula,
        banco,
        cuenta,
        activo,
        pais,
        id,
        editar,
        activar,
        borrar,
        email,
        desvincularDeUsuario,
        handleDesvincularDeUsuario
    } = props

    const beneficiariosSeleccionados = useSelector( state => state.BeneficiariosSeleccionados)
    const beneficiarios = useSelector( state => state.Beneficiarios)
    const beneficiarioSeleccionado = useSelector( state => state.Beneficiarios.data.find(x => x.selected === true))
    const paises = useSelector( state => state.Paises)
    const mensajes = useSelector( state => state.Mensajes)
    const detalle = useSelector(state => state.DetalleCambio)
    const suma = useSelector( state => state.BeneficiariosSeleccionados.data.map(x => x.origen).length > 0 ? state.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b) : 0)
    const tasas = useSelector( state => state.Tasas)
    const minimo = useSelector(state => Math.min( ...state.Tasas.data.filter(x => x.efectivo === state.DetalleCambio.data.efectivo).filter(x => ( (x.origen_id === state.DetalleCambio.data.pais_origen.id) && (x.destino_id === state.DetalleCambio.data.pais_destino.id))).map(x => x.rango.minimo) ))
    const loadingLocal = beneficiarios.fetching || paises.fetching
    const dispatch = useDispatch()
    const [ modalPais, setModalPais] = useState(false)
    const [ paisValue, setPaisValue] = useState(null)
    const [ cuentaSeleccionada, setCuentaSeleccionada] = useState(null)
    const [ modalBorrar, setModalBorrar] = useState(false)
    const [ modalEditar, setModalEditar] = useState(false)
    const [ modalDesvincularUsuario, setModalDesvincularUsuario] = useState(false)
    const [ modalDesvincularCliente, setModalDesvincularCliente] = useState(false)
    const [ modalEnviar, setModalEnviar] = useState(false)

    let formulario

    if (paisValue){
        switch (paisValue.nombre) {
            case "Venezuela":
                formulario = 
                    <FormBeneficiariosVzla
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && { 
                            ...cuentaSeleccionada, 
                            idTipo: TiposIdVzla.find(x => x.id === cuentaSeleccionada.idTipo),
                            banco: BancosVzla.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentasVzla.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            codigo: cuentaSeleccionada.banco, 
                            cuenta: cuentaSeleccionada.cuenta.substr(cuentaSeleccionada.cuenta.length - 16)}}
                        onSubmit={ async values => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({ 
                                    ...values, 
                                    id: cuentaSeleccionada.id,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo+values.cuenta, 
                                    country_id: paisValue.id }))
                                setModalEditar(false)
                            }else{
                                dispatch(agregarBeneficiario({
                                    ...values,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo+values.cuenta, 
                                    country_id: paisValue.id
                                }, 
                                paisValue.nombre 
                                ))
                            }
                        }}
                    />
                break;
            case "Chile":
                formulario = 
                    <FormBeneficiarioChile 
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && {
                            ...cuentaSeleccionada,
                            rut: cuentaSeleccionada.idNumero,
                            banco: BancosChile.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentasChile.find(x => x.id === cuentaSeleccionada.tipoCuenta ),

                        }} 
                        onSubmit={values => {
                            if(cuentaSeleccionada){
                                dispatch(editCuentas({
                                    ...values,
                                    id: cuentaSeleccionada.id,
                                    idNumero: values.rut,
                                    idTipo: "Rut", 
                                    tipoCuenta: values.tipoCuenta.id, 
                                    banco: values.banco.id, 
                                    country_id: paisValue.id,
                                }))
                                setModalEditar(false)
                            }else{
                                dispatch(agregarBeneficiario(
                                    { 
                                        ...values, 
                                        idNumero: values.rut, 
                                        idTipo: "Rut", 
                                        tipoCuenta: values.tipoCuenta.id, 
                                        banco: values.banco.id, 
                                        country_id: paisValue.id
                                    }, 
                                    paisValue.nombre 
                                ))
                            }
                        }} 
                    />
                break;
            case "Panama":
                formulario = 
                    <FormBeneficiarioPnm 
                        pais={paisValue.nombre}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={cuentaSeleccionada && {
                            ...cuentaSeleccionada,
                            banco: BancosPnm.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: TiposCuentaPnm.find(x => x.id === cuentaSeleccionada.tipoCuenta ),

                        }} 
                        onSubmit={values => {
                            if(cuentaSeleccionada){
                                dispatch(editCuentas({
                                    ...values,
                                    id: cuentaSeleccionada.id,
                                    tipoCuenta: values.tipoCuenta.id, 
                                    banco: values.banco.id, 
                                    country_id: paisValue.id,
                                }))
                                setModalEditar(false)
                            }else{
                                dispatch(agregarBeneficiario(
                                    { 
                                        ...values, 
                                        tipoCuenta: values.tipoCuenta.id, 
                                        banco: values.banco.id, 
                                        country_id: paisValue.id
                                    }, 
                                    paisValue.nombre 
                                ))
                            }
                        }} 
                    />;
                break;
            default:
                formulario = 
                    <Typography variant="h6">Debe elegir un país que contenga formulario disponible</Typography>
                break;
        }
    }
        
    useEffect( () => {
        async function fetchData(){
            await dispatch(fetchPaises())
        }
        fetchData()
    }, [])

    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <Grid item xs={12}>
                <Tabla
                    loading={loadingLocal}
                    agregar={agregar && ( () => {
                        setCuentaSeleccionada(null)
                        setModalPais(true)
                    })}
                    columnas={[
                        ... anadir ? [{ title: 'Enviar', field: 'anadir', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... seleccionar ? [{ title: 'Seleccionar', field: 'seleccionar', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... acciones ? [{ title: 'Acción', field: 'accion', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... id ? [{ title: 'ID', field: 'id', 
                            cellStyle: {
                            },headerStyle: {
                        }}] : [],
                        ... nombre ? [{ title: 'Nombre', field: 'nombre', 
                            cellStyle: {
                            },headerStyle: {
                        }}] : [],
                        ... cedula ? [{ title: 'Cedula', field: 'cedula',
                            cellStyle: {
                            },headerStyle: {
                        }}] : [],
                        ... banco ? [{ title: 'Banco', field: 'banco', 
                            cellStyle: {
                            },headerStyle: {
                            
                        }}] : [],
                        ... cuenta ? [{ title: 'Cuenta', field: 'cuenta', 
                            cellStyle: {
                            },headerStyle: {
                        }}] : [],
                        ... email ? [{ title: 'Email', field: 'email', 
                            cellStyle: {
                            },headerStyle: {
                        }}] : [],
                        ... pais ? [{ title: 'País', field: 'pais', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... activo ? [{ title: 'Activo', field: 'active', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ]}
                        data={beneficiarios.data.map(x => ({
                            ...x,
                            banco: Banco(x.banco),
                            cedula: (x.idTipo ? x.idTipo : "") + "-" + (x.idNumero ? x.idNumero : ""),
                            cuenta: (x.tipoCuenta ? x.tipoCuenta : "") + "-" + (x.cuenta ? x.cuenta : ""),
                            anadir: <Button color="primary" variant="contained" onClick={() => {
                                if(beneficiariosSeleccionados.data.find(y => y.cuenta === x.cuenta)){
                                    dispatch(asignarMensaje(['Ya agregó una transacción a esta cuenta, debe elegir una distinta']))
                                }else{
                                    if(detalle.data.origen/(beneficiariosSeleccionados.data.length+1) >= minimo){
                                        dispatch(seleccionarBeneficario(x.id));
                                        setModalEnviar(true)
                                    }else{
                                        dispatch(asignarMensaje([`No puede agregar mas de ${beneficiariosSeleccionados.data.length} transacciones por el cambio de ${detalle.data.origen}. Verifica el carro de compras`]))
                                    }   
                                }
                            }}>Enviar <AttachMoneyIcon /></Button>,
                            quitarCliente: <IconButton onClick={() => {setCuentaSeleccionada(x); setModalDesvincularCliente(true)}}><DeleteIcon /></IconButton>,
                            seleccionar: <IconButton onClick={ () => handleSeleccionar(x.id) }><CheckIcon /></IconButton>,
                            active: x.active ? "SI" : "NO",
                            pais: paises.data.find(y => y.id === x.country_id) ? paises.data.find(y => y.id === x.country_id).nombre : "Venezuela",
                            email: x.email ? x.email : "",
                            accion: 
                                <BotonMenu 
                                    editar={editar}
                                    borrar={borrar}
                                    activar={activar}
                                    desvincularCliente={desvincularCliente}
                                    desvincularUsuario={desvincularUsuario}
                                    desvincularDeUsuario={desvincularDeUsuario}
                                    handleDesvincularDeUsuario={() => handleDesvincularDeUsuario(x.id)}
                                    handleDesvincularCliente={() => {setCuentaSeleccionada(x); setModalDesvincularCliente(true)}}
                                    handleDesvincularUsuario={() => {setCuentaSeleccionada(x); setModalDesvincularUsuario(true)} } 
                                    handleEditar={() => {
                                        setCuentaSeleccionada(x); 
                                        setPaisValue(paises.data.find(y => y.id === x.country_id)); 
                                        setModalEditar(true)} }
                                    handleActivar={() => {dispatch(editCuentas({ ...x, active: !x.active }))}} registro={x} 
                                    handleBorrar={() => {setCuentaSeleccionada(x);setModalBorrar(true)}} />,
                    }))} 
                />

                <Modal
                    open={modalPais}
                    title="Elegir País"
                    close={true}
                    loading={loadingLocal}
                    handleClose={() => setModalPais(false)}
                    >
                        <SelectSearch 
                            label="País"
                            input={{
                                name:"pais",
                                value: paisValue,
                                onChange:value => setPaisValue(value)
                            }}
                            options={paises.data}
                            meta={{
                                error: false,
                                touched: false,
                            }}
                        />
                        {formulario}
                    
                </Modal>

                <Modal
                    open={modalBorrar}
                    title="Confirmación"
                    close={true}
                    handleClose={() => setModalBorrar(false)}
                    loading={loadingLocal}
                    action={ async () => {
                        await dispatch(borrarCuenta(cuentaSeleccionada.id))
                        setModalBorrar(false)
                    }}
                    >
                    ¿Está seguro que desea borrar la cuenta?
                </Modal>

                <Modal
                    open={modalEnviar}
                    title="Confirmación"
                    close={true}
                    loading={loadingLocal}
                    handleClose={() => setModalEnviar(false)}
                    >
                    <div>
                        <Typography variant="body1">{` Ingresa cuanto deseas enviar a ${beneficiarioSeleccionado && beneficiarioSeleccionado.nombre} - ${Banco(beneficiarioSeleccionado && beneficiarioSeleccionado.banco)}`}</Typography>
                        <Typography variant="h6"><strong>{`(Saldo Disponible: ${currency2decimal(detalle.data.origen - suma)}):`}</strong></Typography>
                        <FormCalculadora 
                            limitMaximo={detalle.data.origen}
                            calculadoraSec={true}
                            paisDisabled={true}
                            initialValues={{
                                pais_origen: detalle.data.pais_origen,
                                pais_destino: detalle.data.pais_destino,
                                efectivo: detalle.data.efectivo,
                                tasa: detalle.data.tasa
                            }}
                            onSubmit={ values => {
                                dispatch(agregarBeneficiarioSeleccionado({ ...beneficiarioSeleccionado, ...values, origen: parseFloat(values.origen), destino: parseFloat(values.destino)}));
                                setModalEnviar(false)}} 
                            tasas={tasas}
                        />
                    </div>
                </Modal>

                <Modal
                    open={modalDesvincularCliente}
                    title="Confirmación"
                    loading={loadingLocal}
                    close={true}
                    handleClose={() => setModalDesvincularCliente(false)}
                    action={ async () => {
                        await dispatch(quitarDeCliente(cuentaSeleccionada.id))
                        setModalDesvincularCliente(false)
                    }}
                    >
                    ¿Estás seguro que deseas desvincular esta cuenta?
                </Modal>

                <Modal
                    open={modalDesvincularUsuario}
                    title="Confirmación"
                    close={true}
                    loading={loadingLocal}
                    handleClose={() => setModalDesvincularUsuario(false)}
                    action={ async () => {
                        await dispatch(quitarDeUsuario(cuentaSeleccionada.id))
                        setModalDesvincularUsuario(false)
                    }}
                    >
                    ¿Estás seguro que deseas desvincular esta cuenta?
                </Modal>

                <Modal
                    open={modalEditar}
                    title="Edición de Cuenta"
                    close={true}
                    loading={loadingLocal}
                    handleClose={() => setModalEditar(false)}
                    >
                        {formulario}
                </Modal>

                <Modal
                    open={beneficiarios.error && beneficiarios.error.length > 0 ? true : false}
                    title="Notificación"
                    close={true}
                    handleClose={() => dispatch(resetErrorBeneficiario())}
                    >
                        <ul>
                            {beneficiarios.error && beneficiarios.error.map((x,id) => <li key={id}>{x}</li>)}
                        </ul>
                </Modal>

                <Snackbar 
                    open={mensajes.data.length > 0 ? true : false}
                    handleClose={() => dispatch(resetMensaje())}
                    message={mensajes.data}
                />
            </Grid>                                
        </Grid>
    )
}


export default TablaBeneficiarios
