import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { addFetch, createFetch, delFetch, editFetch, resetErrorHOR, resetHOR } from '../hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../../services/api'

const t = makeTypes('beneficiarios-clientes')
const FETCH = t('fetch').async()
const ADD = t('add').async()
const DEL = t('del').async()
const EDIT = t('edit').async()
const RESET = t('reset').single()
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)
const delActions = asyncMac(DEL)
const editActions = asyncMac(EDIT)
export const resetBeneficiariosClientes = mac(RESET)
export const resetErrorBeneficiariosClientes = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const delReducer = createReducer(initialState, delFetch( DEL ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers(fetchReducer, resetErrorReducer, resetReducer, editReducer, addReducer, delReducer)

export const fetchBeneficiariosClientes = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/accounts/my-accounts`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(["Ocurrió un error, contacte al admin"]))
    }
}

export const editCuenta = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/accounts/edit-account`, values.id, values)
        console.log("lo que devuelve la BBDD",data.data)
        dispatch(editActions.success(data.data))
        const state = getState()
        console.log(state.BeneficiariosClientes)
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const agregarBeneficiarioAUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/accounts/add-account-and-vinculate-user`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const borrarBeneficiario = id => async (dispatch, getState) => {
    dispatch(delActions.start())
    try {
        const data = await deleteRequest(`/accounts/delete-account-from-user`, id )
        console.log(data)
        dispatch(delActions.success(data.data.id))
    } catch (error) {
        console.log(error)
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}