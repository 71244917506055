import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex:6000,
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5)
  },
}));

export default function FloatingActionButtons(props) {
    const { handleAction, count } = props
    const classes = useStyles();

    return (
            <Tooltip title="Detalles">
                <Fab onClick={handleAction} className={classes.root}  color="primary" aria-label="add">
                    <Badge color="error" badgeContent={count}>
                      <ShoppingCartIcon />
                    </Badge>
                </Fab>
            </Tooltip>
    );
    }