import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const renderTextField = ({
    input,
    label,
    options,
    etiqueta,
    accept,
    handleChange,
    defaultValue,
    handleOnChange,
    meta: { touched, error },
    clear,
    disableClearable,
    change,
    ...custom
  }) => {
  return(
    <Grid container>
        <Grid item xs={12} lg={12}>
          <TextField
            label={label}
            style={{ width: "100%" }}
            placeholder={label ? label : "No se ha seleccionado archivo"}
            disabled
            fullWidth={false}
            error={touched && (error ? true : false)}
            helperText={touched && ((error && <span>{error}</span>))}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            size='large'
            style={{ width: "100%" }}
            variant="outlined"
            component="label"
            color="primary"
          > 
            Seleccionar
            <input
              onChange={e => handleChange(e)}
              style={{ display: 'none' }}
              type='file'
              accept={accept}
              multiple="multiple"
            />
          </Button>
        </Grid>
       
      </Grid>
  )
}

export default renderTextField