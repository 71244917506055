import React, { useEffect, useState } from 'react';
import { Grid, Hidden, Typography, Paper, Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import Tabla from '../../components/Tabla'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { fetchTransaccionesExternas, fetchBalances, addBalances, resetErrorBalance } from '../../ducks/Balances'
import { currency8decimal , Banco } from '../../services/utils'
import MenuBalances from '../../components/MenuBalances';
import FormBusquedaCuenta from '../../components/FormBusquedaCuenta';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const BalancesCuentas = props => {
    const dispatch = useDispatch()
    const [ limit, setLimit ] = useState(50) 
    const [ offset, setOffset ] = useState(0) 
    const [ cuenta, setCuenta ] = useState(null) 

    const balance = useSelector( state => state.Balances )
    const loading = useSelector( state => state.Workingaccounts.fetching || state.Balances.fetching)

    const cargarBalances = values => {
        dispatch(fetchBalances({ cuenta: values.cuenta.id, limit: limit, offset: 0 }))
        setCuenta(values.cuenta)
        setOffset(50)
    }

    const cargarMas = () => {
        dispatch(addBalances({ cuenta: cuenta.id, limit: limit, offset: offset }))
        setOffset( offset => offset + 50)
    }

    const fetchData = () => {
        dispatch(fetchWorkingaccounts({ cuenta: cuenta ? cuenta.id : "", active: true}))
    }

    const importarTransacciones = () => {
        dispatch(fetchTransaccionesExternas(cuenta.id))
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Layout menu={true}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Balances Cuentas</Typography>
            </Grid>
            <Hidden smDown>
                <MenuBalances />
            </Hidden>

            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
                        <Paper style={{ width: '100%'}} >
                            <Box p={4}>
                                <Grid container justify="center" alignItems="center" >
                                    <Typography variant="h6">Busqueda Cuenta</Typography>
                                </Grid>
                                <FormBusquedaCuenta 
                                    onSubmit={values => cargarBalances(values)}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            {cuenta && cuenta.external_platform && 
            <Grid item xs={12} lg={12} xl={12} style={{ margin: '10px' }}>
                <Grid container justify="center" alignItems="center"  >
                    <Button onClick={() => importarTransacciones()} variant="contained" color="primary" >
                        Importar Transacciones Externas
                    </Button>
                </Grid>
            </Grid>}

            <Tabla 
                sorting={false}
                columnas={[
                    { title: 'Fecha', field: 'created_at',
                        cellStyle: {
                            width: 120,
                            minWidth: 120
                        },headerStyle: {
                            width: 120,
                            minWidth: 120
                        }},
                    { title: 'Comentario', field: 'comentarios',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Total', field: 'saldoTotal', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Disp.', field: 'saldoDisp', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Saldo Deudor', field: 'saldoDeudor',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Tip. Transaccion', field: 'tipoTransaccion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Monto', field: 'monto',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Monto+Com.', field: 'montoCom',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Ganancia', field: 'comision',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Id. Transacción', field: 'id',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Benef.', field: 'beneficiario',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Banco', field: 'banco',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Cuenta', field: 'cuenta',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Ref.', field: 'referencia',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={balance.data.map(x => ({
                    ...x,
                    saldoTotal: x.saldoTotal ? currency8decimal(x.saldoTotal) : "",
                    saldoDisp: x.saldoDisp ? currency8decimal(x.saldoDisp) : "",
                    saldoDeudor: x.saldoDeudor ? currency8decimal(x.saldoDeudor) : "",
                    monto: x.monto ? currency8decimal(x.monto) : "",
                    montoCom: x.montoCom ? currency8decimal(x.montoCom) : "",
                    comision: x.comision ? currency8decimal(x.comision) : "",
                    id: x.clpbstransaction ? x.clpbstransaction.id : "",
                    beneficiario: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.nombre :"") : "",
                    referencia: x.clpbstransaction ? x.clpbstransaction.referencia : "",
                    banco: x.clpbstransaction ? (x.clpbstransaction.account ? Banco(x.clpbstransaction.account.banco) :"") : "",
                    cuenta: x.clpbstransaction ? (x.clpbstransaction.account ? x.clpbstransaction.account.cuenta :"") : "",
                    created_at: moment(x.created_at).format('DD-MM-YYYY HH:mm:ss'),
                }))} 
            />
            {balance.data.length % 50 === 0 && balance.data.length !== 0 &&  
            <Grid item xs={12} lg={12} xl={12} style={{ margin: '10px' }}>
                <Grid container justify="center" alignItems="center"  >
                    <Button onClick={() => cargarMas()} variant="contained" color="primary" >
                        Mostrar más
                    </Button>
                </Grid>
            </Grid>}

            <Snackbar 
                open={balance.error && balance.error.length > 0}
                handleClose={() => {dispatch(resetErrorBalance())}}
                message={balance.error?.map(error => error)}/>

            <Backdrop open={loading} />

        </Layout>
    )
}

export default BalancesCuentas