import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid} from '@material-ui/core'
import DatePicker from './DatePicker';
import moment from 'moment'

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'fecha_comienzo',
        'fecha_final'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
    }

    if (values.fecha_comienzo && values.fecha_final && moment(values.fecha_final).diff(moment(values.fecha_comienzo), 'days') > 31 ){
        errors.fecha_final = "El periodo seleccionado debe ser de un mes o menor"
    }
    if (values.fecha_final && values.fecha_final && moment(values.fecha_final).diff(moment(values.fecha_comienzo), 'days') < 0 ){
        errors.fecha_final = "La fecha final no puede ser anterior a la fecha de inicio"
    }

    })

    return errors
}

let FormBusquedaFecha = props => {
    const { handleSubmit } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                required
                name="fecha_comienzo"
                label="Desde"
                component={DatePicker}
                InputLabelProps={{
                    shrink: true,
                }}
                />

            <Field
                required
                name="fecha_final"
                label="Hasta"
                component={DatePicker}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Button variant="contained" color="primary" type="submit">
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

FormBusquedaFecha = reduxForm({
  form: 'busqueda-fecha',
  enableReinitialize : true,
  validate,
})(FormBusquedaFecha);

export default FormBusquedaFecha