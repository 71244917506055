import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import logo from '../img/logo-solo.png'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: '#fff',
  },
  root: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  content:{
    display:'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const BackdropLogo = props => {
    const { open } = props
    const classes = useStyles();

    return (
      <Backdrop className={classes.backdrop} open={open}>
        <div className={classes.content}>
            <img src={logo} width={100}/>
            <div className={classes.root}>
              <LinearProgress />
            </div>
        </div>
      </Backdrop>
    );
}

export default BackdropLogo