import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Typography, Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import TextField from '../TextField';
import renderCheckbox from '../Checkbox';

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'email',
        'password',
        'password_confirmation',
        'terminos'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
        }
    })

    if (!values.terminos){
        errors.terminos = "Debes aceptar los términos y condiciones"
    }

    if (values.password && values.password.length <= 6 ){
        errors.password = "Password debe contener al menos 6 caracteres"
    }

    if (values.password && values.password_confirmation && values.password !== values.password_confirmation ){
        errors.password = "El password y la confirmación deben coincidir"
        errors.password_confirmation = "El password y la confirmación deben coincidir"
    }

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Corre Electrónico Inválido'
    }

    return errors
}

let RegisterForm = props => {
    const { handleSubmit } = props

    return(
        <>
            <form noValidate onSubmit={handleSubmit} >
                <Field
                    type="text"
                    name="email"
                    label="Corre Electrónico"
                    component={TextField}
                />
                <Field
                    type="password"
                    name="password"
                    label="Contraseña"
                    autoComplete="off"
                    component={TextField}
                />
                <Field
                    type="password"
                    name="password_confirmation"
                    label="Confirmación de Contraseña"
                    autoComplete="off"
                    component={TextField}
                />

                <Button variant="contained" color="primary" fullWidth type="submit">
                    Registrarme
                </Button>

                <Box m={2}>

                    <Grid container justify="center" alignItems="center"  >
                        <Field 
                            name="terminos" 
                            component={renderCheckbox} 
                            label={<Typography variant="body2" color="primary">Acepto los <a style={{ cursor: 'pointer'}} target="_blank" href="https://aguacate.s3-sa-east-1.amazonaws.com/POLITICAS+DE+PRIVACIDAD.pdf">Términos y Condiciones</a></Typography>} 
                        />
                    </Grid>
                </Box>

            </form>
            <Grid container>
                <Grid item xs>
                    <Box my={2}>
                        <Link to= "/password">
                            <Typography color="primary" variant="body2">
                                ¿Olvidaste tu contraseña?
                            </Typography>
                        </Link>
                    </Box>
                    <Box my={2}>
                        <Link to= "/correo-confirmacion">
                            <Typography color="primary" variant="body2">
                            ¿No te llegó el correo de confirmación?
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={2}>
                        <Link to= "/login">
                            <Typography color="primary" variant="body2">¿Ya tienes una cuenta? Inicia Sesión </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default RegisterForm = reduxForm({
    form: 'registro',
    enableReinitialize : true,
    validate,
})(RegisterForm);