import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Typography, Grid, Box } from '@material-ui/core'
import TextField from '../TextField';
import { Link } from 'react-router-dom'

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'email',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
        }
    })

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Corre Electrónico Inválido'
    }
    return errors
}

let LoginForm = props => {
    const { handleSubmit, confirmacion } = props
    return(
        <>
            <form noValidate onSubmit={handleSubmit} >
                <Field
                    type="text"
                    name="email"
                    label="Inserte Correo Electrónico:"
                    component={TextField}
                    fullWidth
                    autoComplete="email"
                    autoFocus
                />

                <Button variant="contained" color="primary" fullWidth type="submit">
                    {confirmacion ? "Enviar Correo de Confirmación" : "Solicitar reinicio de Contraseña" }
                </Button>
            </form>
            <Grid container>
                <Grid item xs>
                    <Box my={2}>
                        <Link to= "/login">
                        <Typography color="primary" variant="body2">
                            Iniciar Sesión
                        </Typography>
                        </Link>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={2}>
                        <Link to= "/register">
                        <Typography color="primary" variant="body2">
                            ¿No tienes una cuenta? Regístrate
                        </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}


LoginForm = reduxForm({
    form: 'login',
    enableReinitialize : true,
    validate,
})(LoginForm);

export default LoginForm
