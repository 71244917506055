import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Grid, Button } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch2';
import Loading from './Loading'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { currency2decimal } from '../services/utils'

const validate = (values = {}, props) => {
  const errors = {}
  const requiredFields = [
    'comprobante',
    'tasa',
    'totalPesos',
    'cantidadPesos',
    'bolivares',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.cantidadPesos > (props.initialValues.cantidadPesos + 20) ){
    errors.cantidadPesos  = "La cantidad de pesos no puede ser mayor"
  }

  return errors
}

const numeroDecimal = value => value && value.replace(/\./g, '');
const selector = formValueSelector('form-edit-transaccion')


let FormEditTransaccion = props => {
      const { handleSubmit, change } = props
      const loading = useSelector(state => state.Transacciones.fetching)
      const tasas = useSelector(state => state.Tasas)
      const cantidadPesos = useSelector(state => selector(state,'cantidadPesos'))
      const tasa = useSelector(state => selector(state, 'tasa'))
      
      const handleChange = (name,value) => {
        if(name === "recibido" && tasa)
          change('bolivares', ( Math.floor(value * tasa.tasa) ) )
        if(name === "enviar" && tasa)
          change('cantidadPesos', ( Math.ceil(value / tasa.tasa) ) )
        if(name === 'tasa' && value)
            change('bolivares', ( Math.floor(cantidadPesos * value.tasa) ) )
      }

      return(
            <form noValidate onSubmit={handleSubmit} >
                <Field
                    name="comprobante"
                    label="Comprobante"
                    component={TextField}
                />
                <Field
                    name="totalPesos"
                    label="Total"
                    disabled={true}
                    component={TextField}
                    format={currency2decimal}
                    normalize={numeroDecimal}

                />
                <Field
                  style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                  name="tasa"
                  options={tasas.data}
                  etiqueta="tasa"
                  label="Tasa"
                  onChange={(e,value) => handleChange("tasa",value)}
                  component={SelectSearch}
                />
                <Field
                  name="cantidadPesos"
                  label="Monto Recibido"
                  onChange={(e,value) => handleChange("recibido",value)}
                  component={TextField}
                  format={currency2decimal}
                  normalize={numeroDecimal}
                />
                <Field
                  name="bolivares"
                  label="Monto a Pagar"
                  onChange={(e,value) => handleChange("enviar",value)}
                  component={TextField}
                  format={currency2decimal}
                  normalize={numeroDecimal}
                />
                <Grid item xs={12} lg={12} xl={12}>
                  <Grid container justify="center" alignItems="center"  >
                    { loading ? <Loading /> :
                      <Button variant="contained" color="primary" type="submit">
                          <SwapHorizIcon />
                      </Button>}
                  </Grid>
                </Grid>
            </form>
        )
    }


export default FormEditTransaccion = reduxForm({
  form:"form-edit-transaccion",
  enableReinitialize : true,
  validate,
})(FormEditTransaccion);
