import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Box, Grid } from '@material-ui/core'
import FormTasas from '../components/FormTasas'
import * as paisesDuck from '../ducks/Paises'
import * as rangosDuck from '../ducks/Rangos'
import * as tasasDuck from '../ducks/Tasas'

class FormularioRangos extends React.Component{
    render(){
        const { cargando, tasaSeleccionada, rangos, perfiles, paises, editTasas, crearTasa } = this.props   
        return(
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center">                                 
                    <Box p={4}>
                        <FormTasas 
                            paises={paises} 
                            rangos={rangos} 
                            perfiles={perfiles} 
                            cargando={cargando} 
                            tasaSeleccionada={tasaSeleccionada} 
                            onSubmit={ values => {
                                if(tasaSeleccionada){
                                    editTasas({ id: tasaSeleccionada.id, ...values, tasa: values.tasa.replace(/\,/g, '.') })
                                }else{
                                    crearTasa({...values, tasa: values.tasa.replace(/\,/g, '.')})
                                }
                        }}/>
                    </Box>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return{
        tasaSeleccionada: state.Tasas.data.find(x => x.selected === true),
        cargando: state.Tasas.fetching,
        paises: state.Paises,
        rangos: state.Rangos,
        perfiles: state.Profiles
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...paisesDuck,
    ...rangosDuck,
    ...tasasDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormularioRangos)
