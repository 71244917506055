import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, IconButton, Box } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import Modal from '../../components/Notificacion2'
import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import FormularioTasas from '../FormularioTasas'
import FormCodigos from '../../components/FormCodigos'
import { fetchRangos } from '../../ducks/Rangos'
import { fetchPaises } from '../../ducks/Paises'
import { abrirModal } from '../../ducks/Modales'
import { fetchTasas, selectTasa, editTasas, borrarTasa, crearCodigos, eliminarCodigo } from '../../ducks/Tasas'
import { fetchProfiles } from '../../ducks/Profiles'
import { currency, currency2decimal } from '../../services/utils'
import RemoveIcon from '@material-ui/icons/Remove';

const Tasas = () => {
    const [ modalCodigos, setModalCodigos ] = useState(false)
    const [ tasaSeleccionada, setTasaSeleccionada ] = useState(null)
    const rangos = useSelector( state => state.Rangos ) 
    const paises = useSelector( state => state.Paises ) 
    const tasas = useSelector( state => state.Tasas ) 
    const perfiles = useSelector( state => state.Profiles ) 
    const dispatch = useDispatch()

    const fetchData = async () => {
        await dispatch(fetchTasas())
        dispatch(fetchRangos())
        dispatch(fetchPaises())
        dispatch(fetchProfiles())
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Box mt={15} mb={5} ml={5} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Tasas del Día</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Tabla
                loading={tasas.fetching}
                agregar={() => {dispatch(selectTasa(''));dispatch(abrirModal({ title:"Formulario Tasas", body:<FormularioTasas />, close:true}))}}
                columnas={[
                    { title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'ID', field: 'id', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Nombre', field: 'nombre',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Descripción', field: 'descripcion',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Origen', field: 'origen',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Destino', field: 'destino',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Tasa', field: 'tasa',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Rango', field: 'rango',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Perfil', field: 'perfil',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Tiempo Máximo', field: 'tiempo',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Codigos', field: 'codigos',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Proporcional', field: 'proporcional',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Efectivo', field: 'efectivo',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Activo', field: 'active',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={tasas.data.map(x => ({
                    ...x,
                    tasa: currency2decimal(x.tasa),
                    origen: paises.data.find(y => y.id === x.origen_id) ? paises.data.find(y => y.id === x.origen_id).nombre : "",
                    rango: currency(rangos.data.find(y => y.id === x.rango_id) && rangos.data.find(y => y.id === x.rango_id).minimo) + "-" + currency(rangos.data.find(y => y.id === x.rango_id) && rangos.data.find(y => y.id === x.rango_id).maximo),
                    destino: paises.data.find(y => y.id === x.destino_id) ? paises.data.find(y => y.id === x.destino_id).nombre : "",
                    perfil: perfiles.data.find(y => y.id === x.profile_id) ? perfiles.data.find(y => y.id === x.profile_id).nombre : "",
                    active: x.active ? "SI" : "NO",
                    efectivo: x.efectivo ? "SI" : "NO",
                    proporcional: x.proporcional ? "SI" : "NO",
                    codigos: x.promotional_codes.map(y => 
                        <Grid container justify="center" alignItems="center" style={{ display:'flex', flexFlow: 'row' }} key={y.id}>
                            <Typography>{y.codigo}</Typography>
                            <IconButton onClick={() => dispatch(eliminarCodigo({exchange_rate:x.id, promotional_code: y.id}))} style={{ color: 'red'}}><RemoveIcon /></IconButton>
                        </Grid>),
                    accion: 
                        <BotonMenu
                            codigos={true}
                            handleCodigos={() => {setTasaSeleccionada(x.id);setModalCodigos(true)} } 
                            editar={true}
                            borrar={true}
                            activar={true}
                            handleEditar={() => {dispatch(selectTasa(x.id)); dispatch(abrirModal({title:"Formulario Tasas", body:<FormularioTasas />, close: true }))}} 
                            handleActivar={() => {dispatch(editTasas({ ...x, active: !x.active }))}} registro={x} 
                            handleBorrar={() => {dispatch(abrirModal({ title: "Confirmación", body:"¿Está seguro que desea borrar el registro?", action: () => {dispatch(borrarTasa(x.id))}, close: true}))}}/>,
                }))} 
            />

            <Modal
                open={modalCodigos}
                title="Crear códigos"
                close={true}
                handleClose={() => setModalCodigos(false)}
            >
                <FormCodigos 
                    onSubmit={values => {
                        dispatch(crearCodigos({ ...values, exchange_rate: tasaSeleccionada }));
                        setModalCodigos(false)
                    }}
                />
            </Modal>
        </Box>

    )

}

export default Tasas