import React from 'react'
import Banner from '../../components/Banner/Banner';
import HomeBeneficios from '../../components/Beneficios/HomeBeneficios';
import Contacto from '../../components/Contacto/Contacto';
import About from '../../components/About/About'
import SocialNetwork from '../../components/SocialNetwork/SocialNetwork'
import HomeLayout from '../../components/HomeLayout/HomeLayout'
import '../../public/assets/scss/style.scss';

const HomePage = (props) => {
    return (
            <React.Fragment>
                <HomeLayout >
                    <div id="main-content">
                        <Banner ChangeClass="demo1"/>
                        <HomeBeneficios />
                        <About />
                        <Contacto />
                        <SocialNetwork />
                    </div>
                </HomeLayout>
            </React.Fragment>


    )
}

export default HomePage