import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Paper, Hidden, Typography} from '@material-ui/core';
import Modal from './Notificacion2'
import Colors from '../constants/Colors'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { currency2decimal } from '../services/utils';

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    content:{
        display: 'flex',
        flexDirection: 'row'
    },
    contentLeft:{
		flex:0.6,
        overflow: 'hidden',
        cursor: 'pointer',
        flexDirection: 'column'
    },
    contentRight:{
		flex: 0.4,
        overflow: 'hidden',
        flexDirection: 'column',
        textAlign: 'end'
    },
    icon: {
        fontSize: 70,
    },
    iconContainer:{
        alignSelf: 'center'
    }
});

const TarjetaDetalle = props => {
	const { transaccion } = props
	const [ detalle, setDetalle ] = useState(false)
	const classes = useStyles();

	return (
		<>
			<Card className={classes.root}>
				<div className={classes.content}>
					<Hidden mdDown>
						<CardContent className={classes.iconContainer}>
							<Paper>
								<AttachMoneyIcon style={{ color:Colors.primary }} className={classes.icon}/>
							</Paper>
						</CardContent>
					</Hidden>
					<CardContent className={classes.contentLeft} onClick={() => setDetalle(true)}>
						<Typography variant='overline' gutterBottom>
                            <strong>
							    { transaccion.pais_destino && transaccion.pais_origen ? transaccion.pais_origen.nombre+"-"+transaccion.pais_destino.nombre : "" }
                            </strong>
						</Typography>
						<Typography variant='body2' gutterBottom>
							Envía: { transaccion.origen ? currency2decimal(transaccion.origen) : ""}
						</Typography>
						<Typography variant='body2' gutterBottom>
                            Recibe: { transaccion.destino ? currency2decimal(transaccion.destino) : ""}
						</Typography>
					</CardContent>
					<CardContent className={classes.contentRight}>
						<Typography variant='body2' gutterBottom>
                            Tasa: { transaccion.tasa ? currency2decimal(transaccion.tasa.tasa ? transaccion.tasa.tasa : transaccion.tasa) : ""}
						</Typography>
					</CardContent>
				</div>
			</Card>

			<Modal
                open={detalle}
                title="Detalle Cuenta"
                handleClose={() => setDetalle(false)}
                close={true}
            >
                <Typography variant='overline' gutterBottom>
                    <strong>
                        { transaccion.pais_destino && transaccion.pais_origen ? transaccion.pais_origen.nombre+"-"+transaccion.pais_destino.nombre : "" }
                    </strong>
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <strong>Envía:</strong> { transaccion.origen ? currency2decimal(transaccion.origen) : ""}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <strong>Recibe:</strong> { transaccion.destino ? currency2decimal(transaccion.destino) : ""}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <strong>Tasa:</strong> { transaccion.tasa ? currency2decimal(transaccion.tasa.tasa ? transaccion.tasa.tasa : transaccion.tasa)  : ""}
                </Typography>
            </Modal>
		</>
	);
}

export default TarjetaDetalle
