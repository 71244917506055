import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import logo from '../img/logo.png'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Link } from "react-router-dom";
import { abrirMenu } from '../ducks/Menu'
import { history } from '../index'

const useStyles = makeStyles( theme => ({
    list: {
        display: 'flex',
        padding: '0px',
        margin: '0px',
        width: 240
        },
    logo: {
        height: '100px',
        verticalAlign: "middle"
    },
    menuButton: {
        marginRight: theme.spacing(2),
      },

}));

export default function SimpleCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const perfil = useSelector(state => state.Usuario.data[0].profile.nombre);
    const [ administracion, setAdministracion ] = useState(false)
    const [ asociados, setAsociados ] = useState(false)
    const [ misCambios, setMisCambios ] = useState(false)
    const [ mayoristas, setMayoristas ] = useState(false)
    const [ transacciones, setTransacciones ] = useState(false)
    const [ balances, setBalances ] = useState(false)

    return (

        <List style={{ width: 240 }}>
            <ListItem>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <a className={classes.menuButton} style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
                        <img width="auto" className={classes.logo} src={logo} alt="Logo" />
                    </a>
                </Grid>
            </ListItem>

            { ( perfil === "Asociado" || perfil === "Admin" || perfil === "Oficina") && 
            <>
                <ListItem button onClick={ (e) => setAsociados(!asociados)}>
                    <ListItemText primary="Asociados" />
                    {asociados ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={asociados} timeout="auto" unmountOnExit>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/partners/agregar">
                        <ListItemText inset secondary="Agregar Transacción" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/partners/pendientes">
                        <ListItemText inset secondary="Trans. Pendientes" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/partners/realizadas">
                        <ListItemText inset secondary="Trans. Realizadas" />
                    </ListItem>
                </Collapse>
            </>
            }

            { ( perfil === "Admin" || perfil === "Oficina" || perfil == "Cajero") && 
            <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/cajeros">
                <ListItemText primary="Cajeros" />
            </ListItem>
            }

            { ( perfil === "Cliente" || perfil === "Admin" || perfil === "Oficina") &&
            <>
                <ListItem button onClick={ (e) => setMisCambios(!misCambios)}>
                    <ListItemText primary="Mis Cambios" />
                    {misCambios ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={misCambios} timeout="auto" unmountOnExit>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/clientes/agregar">
                        <ListItemText inset secondary="Agregar Transacción" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/clientes/pendientes">
                        <ListItemText inset secondary="Trans. Pendientes" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/clientes/realizadas">
                        <ListItemText inset secondary="Trans. Realizadas" />
                    </ListItem>
                </Collapse>
            </> 
            }

            { ( perfil === "Cliente" || perfil === "Admin" || perfil === "Oficina") &&
            <>
                <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/mis-beneficiarios">
                    <ListItemText primary="Mis Beneficiarios" />
                </ListItem>
            </>
            }

            { ( perfil === "Admin" || perfil === "Oficina") &&
            <>
                <ListItem button onClick={ (e) => setTransacciones(!transacciones)}>
                    <ListItemText primary="Detal" />
                    {transacciones ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={transacciones} timeout="auto" unmountOnExit>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/transacciones/agregar">
                        <ListItemText inset secondary="Agregar Transacción" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/transacciones/por-verificar">
                        <ListItemText inset secondary="Trans. por Verificar" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/transacciones/pendientes">
                        <ListItemText inset secondary="Trans. Pendientes" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/transacciones/realizadas">
                        <ListItemText inset secondary="Trans. Realizadas" />
                    </ListItem>
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/transacciones/rechazadas">
                        <ListItemText inset secondary="Trans. Rechazadas" />
                    </ListItem>
                </Collapse>
            </>
            }

            { ( perfil === "Admin" || perfil === "Oficina" || perfil === "Mayorista") &&
            <>
                <ListItem button onClick={ (e) => setMayoristas(!mayoristas)}>
                    <ListItemText primary="Mayorista" />
                    {mayoristas ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={mayoristas} timeout="auto" unmountOnExit>
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/mayorista/agregar-solicitud">
                        <ListItemText inset secondary="Agregar Transacción" />
                    </ListItem>}
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/mayorista/solicitudes-abiertas">
                        <ListItemText inset secondary="Trans. Abiertas" />
                    </ListItem>}
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/mayorista/clientes-mayoristas">
                        <ListItemText inset secondary="Clientes" />
                    </ListItem>}
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/mayorista/pagadores">
                        <ListItemText inset secondary="Mis Pagadores"  />
                    </ListItem>
                </Collapse>
            </>
            }

            { ( perfil === "Admin" || perfil === "Oficina") &&
            <>
                <ListItem button onClick={ (e) => setBalances(!balances)}>
                    <ListItemText primary="Balances Cuentas" />
                    {balances ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={balances} timeout="auto" unmountOnExit>
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/balances/estatus-cajeros">
                        <ListItemText inset secondary="Estatus Cajeros" />
                    </ListItem>}
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/balances/abonos-debitos">
                        <ListItemText inset secondary="Abonos y Debitos" />
                    </ListItem>}
                    { ( perfil === "Admin" || perfil === "Oficina" ) &&
                    <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/balances/balances-cuentas">
                        <ListItemText inset secondary="Balances Cuentas" />
                    </ListItem>}
                </Collapse>
            </>
            }

            <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/configuracion/perfil">
                <ListItemText primary="Mi Perfil" />
            </ListItem>
            
            {( perfil === "Admin" || perfil === "Oficina")  &&
            <>
                <ListItem button onClick={() => setAdministracion(!administracion)}>
                    <ListItemText primary="Administración" />
                    {administracion ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={administracion} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/beneficiarios">
                            <ListItemText inset secondary="Beneficiarios" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/clientes">                    
                            <ListItemText inset secondary="Clientes" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/comisiones">                    
                            <ListItemText inset secondary="Comisiones" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/cuentas-trabajo">
                            <ListItemText inset secondary="Cuentas de Trabajo" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/dashboard">                
                            <ListItemText inset secondary="Dashboard" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/estados">                
                        <ListItemText inset secondary="Estados" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/paises">                
                            <ListItemText inset secondary="Paises" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/perfiles">                
                            <ListItemText inset secondary="Perfiles" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/proveedores">                
                            <ListItemText inset secondary="Proveedores" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/rangos">                
                            <ListItemText inset secondary="Rangos" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/tasas">                
                            <ListItemText inset secondary="Tasas del día" />
                        </ListItem>
                        <ListItem button onClick={ (e) => dispatch(abrirMenu(false))} component={Link} to="/administracion/usuarios">                
                            <ListItemText inset secondary="Usuarios" />
                        </ListItem>
                    </List>
                </Collapse>
            </>
            }
        </List>
    )
}