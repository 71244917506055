import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import Transacciones from './transacciones'
import { busquedaAvanzadaTransacciones } from '../../ducks/Transacciones'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const TransaccionesRechazadas = props => {

    const dispatch = useDispatch()

    const fetchData = values => {
        dispatch(busquedaAvanzadaTransacciones({ state: "Rechazada", fecha_final:  values ? moment(values.fecha_final).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), fecha_comienzo: values ? moment(values.fecha_comienzo).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}))
    }

    useEffect(() => {
        fetchData()
    },[])


    return (
        <Transacciones 
            title="Transacciones Rechazadas"
            ver={true}
            rechazar={true}
            verificar={true}
            verificarUsuario={true}
            acciones={true} 
            id={true} 
            cliente={true} 
            beneficiario={true} 
            fecha={true} 
            cedula={true} 
            banco={true} 
            cuenta={true} 
            archivo={true} 
            pago={true} 
            recibe={true}
            fetchData={fetchData}
            formulario={true}
        />
    )
}

export default TransaccionesRechazadas