import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, resetStoreHOR } from './hors.js'
import { getRequest } from '../services/api'

const t = makeTypes('estadisticas')
const FETCH = t('fetch').async()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)

const initialState = {
    data: {},
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, resetStoreReducer)

export const fetchEstadisticas = values => async (dispatch, getState) => {
    console.log(values)
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/clpbstransactions/estadisticas?origen_id=${values.origen_id}&destino_id=${values.destino_id}&fecha_comienzo=${values.fecha_comienzo}&fecha_final=${values.fecha_final}`)
        console.log(data)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}