import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const renderCheckbox = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    return(
        <FormControl required error={touched && (error ? true : false)}>
            <FormControlLabel
            control={
                <Checkbox
                    {...input}
                    {...custom}                
                    color="primary"
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                />
            }
            label={label}
          />
        <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
  );
}

export default renderCheckbox
