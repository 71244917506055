import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from '../components/Loading'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'justify',
  },
}));

export default function AlertDialog(props) {
  const classes = useStyles();
  const { title, action, open, size, close, loading, handleClose, aceptar } = props

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={size}
        open={open}
        //onClose={handleClose(id)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.content}>
          {props.children}
        </DialogContent>
        <DialogActions>
          {loading ? <Loading /> :
          <div>
            {close &&
            <Button onClick={loading ? () => {} : handleClose} color="primary">
              Cerrar
            </Button>}
            {action &&
            <Button onClick={action} color="primary" autoFocus>
              {aceptar ? aceptar : "Aceptar"}
            </Button>}
          </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}