import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, IconButton, Paper, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import MenuDetalle from "../../components/Drawer";
import ContenidoMenu from "../../components/ContenidoMenu";
import FormBusquedaDash from "../../components/FormBusquedaDash";
import Modal from "../../components/Notificacion2";
import TablaTransacciones from "../transacciones/tablaTransacciones";
import PieChart from "./pieChart";
import Xychart from "./Xychart";
import SearchIcon from "@material-ui/icons/Search";
import * as estadisticas from "../../ducks/Estadisticas";
import Loading from "../../components/Loading";
import TablaBalance from '../workingaccounts/tablaBalances'
import { busquedaAvanzadaTransacciones } from "../../ducks/Transacciones";
import { fetchWorkingaccounts } from "../../ducks/Workingaccounts";
import { fetchBalances } from '../../ducks/Balances'
import moment from "moment";
import "moment/locale/es";
import { currency2decimal } from "../../services/utils";
moment.locale("es");

const Dashboard = (props) => {
  const [busquedaAvanzada, setBusquedaAvanzada] = useState(false);
  const [modalBalance, setModalBalance] = useState(false);
  const [modalTransacciones, setModalTransacciones] = useState(false);
  const [fechaComienzo, setFechaComienzo] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [origen, setOrigen] = useState(1)
  const [destino, setDestino] = useState(2)
  const [fechaFinal, setFechaFinal] = useState(moment().format("YYYY-MM-DD"));
  const loadingEstadisticas = useSelector(
    (state) => state.Estadisticas.fetching
  );
  const data = useSelector((state) => state.Estadisticas.data);
  const transacciones = useSelector((state) => state.Transacciones);
  const workingaccounts = useSelector((state) => state.Workingaccounts);
  const balances = useSelector((state) => state.Balances);

  let cuentasDestino = [];
  let cuentasOrigen = [];

  if (data.cuentasOrigen && data.cuentasDestino) {
    cuentasOrigen = data.cuentasOrigen.map((x) => ({
      id: workingaccounts.data.find((y) => y.id === parseInt(x.formaPago))
        ? workingaccounts.data.find((y) => y.id === parseInt(x.formaPago)).id
        : "",
      name: workingaccounts.data.find((y) => y.id === parseInt(x.formaPago))
        ? workingaccounts.data.find((y) => y.id === parseInt(x.formaPago))
            .nombre
        : "",
      value: x.sum,
    }));
    cuentasDestino = data.cuentasDestino.map((x) => ({
      id: workingaccounts.data.find((y) => y.id === x.workingaccount_id)
        ? workingaccounts.data.find((y) => y.id === x.workingaccount_id).id
        : "",
      name: workingaccounts.data.find((y) => y.id === x.workingaccount_id)
        ? workingaccounts.data.find((y) => y.id === x.workingaccount_id).nombre
        : "",
      value: x.sum,
    }));
  }

  const dispatch = useDispatch();

  const consultarTransacciones = async (values) => {
    dispatch(
      estadisticas.fetchEstadisticas({
        origen_id: values ? (values.origen_id ? values.origen_id : "") : origen,
        destino_id: values ? (values.destino_id ? values.destino_id : "") : destino,
        fecha_comienzo: values
          ? moment(values.fecha).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        fecha_final: values
          ? moment(values.fecha).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      busquedaAvanzadaTransacciones({
        origen_id: origen,
        destino_id: destino,
        fecha_comienzo: values
          ? moment(values.fecha).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        fecha_final: values
          ? moment(values.fecha).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      })
    );
    setFechaComienzo(
      values
        ? moment(values.fecha).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    );
    setFechaFinal(
      values
        ? moment(values.fecha).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    );
  };

  const fetchData = (values) => {
    dispatch(fetchWorkingaccounts());
    dispatch(
      estadisticas.fetchEstadisticas({
        origen_id: values ? (values.origen_id ? values.origen_id : "") : origen,
        destino_id: values ? (values.destino_id ? values.destino_id : "") : destino,
        fecha_comienzo: values
          ? moment(values.fecha_comienzo).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        fecha_final: values
          ? moment(values.fecha_final).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      busquedaAvanzadaTransacciones({
        origen_id: values ? (values.origen_id ? values.origen_id : "") : origen,
        destino_id: values ? (values.destino_id ? values.destino_id : "") : destino,
        fecha_comienzo: values
          ? moment(values.fecha_comienzo).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        fecha_final: values
          ? moment(values.fecha_final).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      })
    );
    setOrigen(values ? (values.origen_id ? values.origen_id : "") : origen)
    setDestino(values ? (values.destino_id ? values.destino_id : "") : destino)
    setFechaComienzo(
      values
        ? moment(values.fecha_comienzo).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    );
    setFechaFinal(
      values
        ? moment(values.fecha_final).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    );
  };

  const consultarCuentaEnv = (values) => {

    dispatch(
      busquedaAvanzadaTransacciones({
        origen_id: origen,
        destino_id: destino,
        fecha_comienzo: moment(fechaComienzo).format("YYYY-MM-DD"),
        fecha_final: moment(fechaFinal).format("YYYY-MM-DD"),
        cuenta_env: values.payload.id
      })
    );
    dispatch(fetchBalances({
      cuenta: values.payload.id, 
      fecha_comienzo: moment(fechaComienzo).format('YYYY-MM-DD'),
      fecha_final: moment(fechaFinal).format('YYYY-MM-DD')
    }))
    setModalBalance(true)
  };

  const consultarCuentaRec = (values) => {
    dispatch(
      busquedaAvanzadaTransacciones({
        origen_id: origen,
        destino_id: destino,
        fecha_comienzo: moment(fechaComienzo).format("YYYY-MM-DD"),
        fecha_final: moment(fechaFinal).format("YYYY-MM-DD"),
        cuenta_rec: values.payload.id.toString()
      })
    );
    dispatch(fetchBalances({
      cuenta: values.payload.id, 
      fecha_comienzo: moment(fechaComienzo).format('YYYY-MM-DD'),
      fecha_final: moment(fechaFinal).format('YYYY-MM-DD')
    }))
    setModalBalance(true)
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formulario = busquedaAvanzada && (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Paper style={{ maxWidth: "600px" }}>
        <Box p={4}>
          <Typography variant="h6">Busqueda Avanzada</Typography>
          <FormBusquedaDash onSubmit={(values) => fetchData(values)} />
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Box mt={15} mb={5} ml={5} mr={5}>
      <Grid container justify="center" alignItems="center">
        <Typography variant="h5">
          Dashboard
          <IconButton onClick={() => setBusquedaAvanzada(!busquedaAvanzada)}>
            <SearchIcon />
          </IconButton>
        </Typography>
      </Grid>
      <Navbar />
      <MenuDetalle>
        <ContenidoMenu />
      </MenuDetalle>

      {formulario}

      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Paper variant="elevation">
                <Box p={4}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">
                      Dinero Recibido({data.totalPeriodo ? currency2decimal(data.totalPeriodo.sum_origen_total) : ""})/Enviado({data.totalPeriodo ? currency2decimal(data.totalPeriodo.sum_destino_total) : ""})
                    </Typography>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    {loadingEstadisticas ? (
                      <Loading />
                    ) : (
                      data.sumTotales && data.sumTotales.length > 0 ?
                        <Xychart
                          data={data.sumTotales}
                          consultarTransacciones={consultarTransacciones}
                        />
                      :
                      <Typography variant="h6">
                        No hay datos para mostrar
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} xs={12} sm={12} md={5} lg={5} xl={5}>
              <Paper variant="elevation">
                <Box p={4}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">Distribución Recibido</Typography>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    {loadingEstadisticas ? (
                      <Loading />
                    ) : (
                      cuentasOrigen.length > 0 ?
                      <PieChart
                        data={cuentasOrigen}
                        consultarCuenta={consultarCuentaRec}
                      />
                      :
                      <Typography variant="h6">
                        No hay datos para mostrar
                      </Typography>

                    )}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12} xs={12} sm={12} md={5} lg={5} xl={5}>
              <Paper variant="elevation">
                <Box p={4}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">Distribución Enviado</Typography>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    {loadingEstadisticas ? (
                      <Loading />
                    ) : (
                      cuentasDestino.length > 0 ?
                      <PieChart
                        data={cuentasDestino}
                        consultarCuenta={consultarCuentaEnv}
                      />
                      :
                      <Typography variant="h6">
                        No hay datos para mostrar
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Paper variant="elevation">
                <Box p={4}>
                  <Grid container justify="center" alignItems="center">
                    <Grid container justify="center" alignItems="center" style={{margin: '5px'}}>
                      <Typography variant="h6">Transacciones</Typography>
                    </Grid>

                    <div style={{ width: "100%", height: 500 }}>
                      <TablaTransacciones
                        cliente={true}
                        beneficiario={true}
                        pago={true}
                        recibe={true}
                        estado={true}
                        pageSize={5}
                        loading={transacciones.fetching}
                        transacciones={transacciones}
                        exportable={true}
                        verDetalle={() => setModalTransacciones(true)}
                      />
                    </div>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
          open={modalBalance}
          title="Balances"
          close={true}
          size="xl"
          handleClose={() => setModalBalance(false)}
          >
          <TablaBalance comentario={true} />
      </Modal>

      <Modal
          open={modalTransacciones}
          title="Transacciones"
          close={true}
          size="xl"
          handleClose={() => setModalTransacciones(false)}
          >
            <TablaTransacciones
              id={true}
              cliente={true}
              comprobante={true}
              beneficiario={true}
              cedula={true}
              banco={true}
              cuenta={true}
              tasa={true}
              pago={true}
              recibe={true}
              estado={true}
              cajero={true}
              fecha={true}
              referencia={true}
              operador={true}
              pageSize={10}
              actualizacion={true}
              loading={transacciones.fetching}
              transacciones={transacciones}
            />      
      </Modal>

    </Box>
  );
};

export default Dashboard;
