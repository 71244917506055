import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, delFetch, resetErrorHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'

const t = makeTypes('proveedores')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const resetErrorProveedores = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers(fetchReducer, resetErrorReducer, editReducer, addReducer, deleteReducer )

export const fetchProveedores = forced => async (dispatch, getState) => {
    const state = getState()
    if(state.Estados.data.length === 0 || forced){
        dispatch(fetchActions.start())
        try {
            const data = await getRequest('/providers')
            dispatch(fetchActions.success(data.data))
        } catch (error) {
            dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        }
    }
}

export const editProveedor = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/providers`, values.id, values)
        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const crearProveedor = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/providers`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const borrarProveedor = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    try {
        const data = await deleteRequest(`/providers`, id)
        dispatch(deleteActions.success(data.data.id))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}
