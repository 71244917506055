import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, InputAdornment, IconButton } from '@material-ui/core'
import SelectSearch from '../components/SelectSearch2'
import TextField from './TextField';
import FileField from './FileField';
import { paises, formatoRut, checkRut } from '../services/utils';
import { fetchProfiles } from '../ducks/Profiles'
import TiposCuenta from '../constants/TiposCuenta'
import TiposId from '../constants/TiposId'
import Sexos from '../constants/Sexos'
import Profesiones from '../constants/Profesiones'
import DatePicker from './DatePicker'
import renderCheckbox from './Checkbox';
import SearchIcon from '@material-ui/icons/Search';

const validate = (values = {}, props) => {
    const errors = {}
    const requiredFields = [
        'nombre',
        'apellido',
        'tipo_id',
        'rut',
        'telefono',
        'email',
        'codigo',
        'tipo_cuenta',
        'profile_id',
        'ciudad',
        'direccion',
        ... !props.admin && (!props.usuarioSeleccionado || (props.usuarioSeleccionado && props.usuarioSeleccionado.images.length === 0)) ? ['imagen1'] : [],
        ... !props.admin && values.tipo_id && values.tipo_id.id === "Rut" && (!props.usuarioSeleccionado || (props.usuarioSeleccionado && props.usuarioSeleccionado.images.length === 0)) ? ['imagen2'] : [],
        ... !props.admin ? ['sexo', 'fecha_nacimiento', 'nacionalidad', 'ciudad', 'pais', 'direccion', 'profesion'] : []
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true
        }
    })

    if (values.nombre && values.nombre.length <= 3) {
        errors.nombre = "Nombre inválido"
    }

    if (values.telefono && values.telefono.length <= 7) {
        errors.telefono = "Telefono inválido"
    }

    if (values.tipo_id && values.tipo_id.id !== "Rut" && values.rut && values.rut.length <= 7) {
        errors.rut = "ID inválido"
    }

    if (values.password && values.password.length < 6) {
        errors.password = "Password debe contener al menos 6 caracteres"
    }

    if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
        errors.password = "El password y la confirmación deben coincidir"
        errors.password_confirmation = "El password y la confirmación deben coincidir"
    }

    if (values.tipo_id && values.tipo_id.id === "Rut" && values.rut && !checkRut(values.rut)) {
        errors.rut = "Rut Inválido"
    }

    if (values.ciudad && values.ciudad.length <= 3) {
        errors.ciudad = "Ciudad invalida"
    }

    if (values.direccion && values.direccion.length <= 3) {
        errors.direccion = "Dirección invalida"
    }

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Correo Electrónico Inválido'
    }
    return errors
}

const numeros = value => value.replace(/[^\d]/g, '')

const selector = formValueSelector('usuario')

let UsuarioForm = props => {
    const { handleSubmit, invalid, proceso, perfil, change, admin, handleBusqueda, usuarioSeleccionado, verificado, dirty } = props
    const dispatch = useDispatch()

    const [label, setLabel] = React.useState('');
    const [label2, setLabel2] = React.useState('');
    const nombre = useSelector(state => selector(state, 'nombre'))
    const tipo_id = useSelector(state => selector(state, 'tipo_id'))
    const tipo_cuenta = useSelector(state => selector(state, 'tipo_cuenta'))
    const usuario = useSelector(state => state.Usuario.data[0])
    const perfiles = useSelector(state => state.Profiles.data.filter(x => (x.nombre === "Cliente" || x.nombre === "Mayorista")))

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchProfiles())
        }
        fetchData()
    }, [])

    return (
        <form noValidate onSubmit={handleSubmit} >
            <Field
                required
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="tipo_cuenta"
                label="Tipo de Cuenta"
                component={SelectSearch}
                options={TiposCuenta}
                disabled={verificado && usuarioSeleccionado.tipo_cuenta ? true : false}
            />

            <Grid container justify="center" alignItems="center" >
                <Typography variant="h6">Datos Persona</Typography>
            </Grid>

            <Field
                required
                type="text"
                name="nombre"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Nombres Representante Legal" : "Nombres"}
                component={TextField}
                disabled={verificado ? true : false}
                InputProps={{
                    endAdornment: handleBusqueda && <InputAdornment position="end"><IconButton onClick={() => handleBusqueda(nombre)}><SearchIcon /></IconButton></InputAdornment>,
                }}
            />

            <Field
                required
                type="text"
                name="apellido"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Apellidos Representante Legal" : "Apellidos"}
                component={TextField}
                disabled={verificado ? true : false}
            />

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} lg={4} xl={4}>
                        <Field
                            required
                            style={{ width: '100%' }}
                            name="tipo_id"
                            label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Tipo ID Representante" : "Tipo ID"}
                            component={SelectSearch}
                            options={TiposId}
                            disabled={verificado ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={8}>
                        <Field
                            required
                            type="text"
                            name="rut"
                            label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Num. ID Representante" : "Número ID"}
                            normalize={tipo_id && tipo_id.id === "Rut" ? formatoRut : value => value}
                            component={TextField}
                            disabled={verificado ? true : false}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Field
                required
                type="text"
                name="email"
                disabled={usuarioSeleccionado ? true : false}
                label="Correo Electrónico"
                component={TextField}
            />

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} lg={4} xl={4}>
                        <Field
                            required
                            style={{ width: '100%' }}
                            type="select"
                            name="codigo"
                            label="Código"
                            component={SelectSearch}
                            etiqueta="codigo"
                            options={paises}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={8}>
                        <Field
                            required
                            type="text"
                            name="telefono"
                            label="Telefono"
                            normalize={numeros}
                            component={TextField}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {admin && perfil &&
                <Field
                    required
                    style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                    type="text"
                    name="profile_id"
                    label="Perfil"
                    options={perfiles.filter(x => x.active)}
                    component={SelectSearch}
                />}

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} lg={4} xl={4}>
                        <Field
                            style={{ width: '100%' }}
                            name="sexo"
                            label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Sexo Representante" : "Sexo"}
                            component={SelectSearch}
                            options={Sexos}
                            disabled={verificado && usuarioSeleccionado.tipo_cuenta ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={8}>
                        <Field
                            name="fecha_nacimiento"
                            openTo="year"
                            views={["year", "month", "date"]}
                            label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Fecha Nacimiento Representante" : "Fecha de Nacimiento"}
                            component={DatePicker}
                            disabled={verificado && usuarioSeleccionado.tipo_cuenta ? true : false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Field
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="nacionalidad"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Nacionalidad Representante" : "Nacionalidad"}
                component={SelectSearch}
                etiqueta="pais"
                options={paises}
                disabled={verificado && usuarioSeleccionado.tipo_cuenta ? true : false}
            />
            <Field
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="pais"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "País Residencia Representante" : "País Residencia"}
                component={SelectSearch}
                etiqueta="pais"
                options={paises}
            />
            <Field
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="ciudad"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Ciudad Representante" : "Ciudad"}
                component={TextField}
            />
            <Field
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="direccion"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Dirección Representante" : "Dirección"}
                component={TextField}
            />
            <Field
                style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                name="profesion"
                label={tipo_cuenta && tipo_cuenta.id === "empresa" ? "Profesión Representante" : "Profesión"}
                component={SelectSearch}
                options={Profesiones}
            />

            <Grid container>
                <Grid item xs={12} lg={12} xl={12}>
                    <Typography variant="body1" >{tipo_cuenta && tipo_cuenta.id === "empresa" ? "Adjunte cara frontal id Representante" : "Adjunte cara frontal ID"}</Typography>
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Field
                            component={FileField}
                            accept="image/jpeg,image/gif,image/png,application/pdf"
                            label={label}
                            name="imagen1"
                            handleChange={e => {
                                setLabel(e.target.files[0] ? e.target.files[0].name : "")
                                change('imagen1', e.target.files[0])
                            }}
                        />
                    </Grid>
                </Grid>
                {tipo_id && tipo_id.id === "Rut" && <Grid item xs={12} lg={12} xl={12}>
                    <>
                        <Grid item xs={12} lg={12} xl={12}>
                            <Typography variant="body1" >{tipo_cuenta && tipo_cuenta.id === "empresa" ? "Adjunte cara trasera id Representante" : "Adjunte cara trasera ID"}</Typography>
                        </Grid>
                        <Grid container justify="center" alignItems="center"  >
                            <Field
                                component={FileField}
                                accept="image/jpeg,image/gif,image/png,application/pdf"
                                label={label2}
                                name="imagen2"
                                handleChange={e => {
                                    setLabel2(e.target.files[0] ? e.target.files[0].name : "")
                                    change('imagen2', e.target.files[0])
                                }}
                            />
                        </Grid>
                    </>
                </Grid>}
            </Grid>

            {admin &&
                <Field
                    name="verified"
                    component={renderCheckbox}
                    label="Verificado"
                />
            }

            <Field
                name="pep"
                component={renderCheckbox}
                label="Persona Politicamente Expuesta"
            />

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center">
                    {usuarioSeleccionado ?
                        <Button disabled={!dirty || invalid} variant="contained" color="primary" type="submit">
                            {proceso ? "Continuar" : "Editar"}
                        </Button>
                        :
                        <Button disabled={invalid} variant="contained" color="primary" type="submit">
                            Agregar
                        </Button>
                    }
                </Grid>
            </Grid>

        </form>
    )
}


export default UsuarioForm = reduxForm({
    form: 'usuario',
    validate
})(UsuarioForm);
