import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Button, Paper, Box } from '@material-ui/core'
import Stepper from '../../components/Stepper'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import MenuPartners from '../MenuPartners'
import Calculadora from '../Calculadora'
import FormCliente from '../../components/FormCliente'
import FormularioBeneficiariosVzla from '../../components/FormularioBeneficiariosVzla'
import FormBeneficiarioPnm from '../../components/FormBeneficiarioPnm'
import FormBeneficiarioChile from '../../components/FormBeneficiarioChile'
import TablaBeneficiarios from '../beneficiarios/tablaBeneficiarios'
import TablaClientes from '../TablaClientes'
import Card from '../../components/Card'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import { resetClientes, agregarCliente } from '../../ducks/Clientes'
import { resetBeneficiarios, agregarBeneficiarioACliente, asignarBeneficiario } from '../../ducks/Beneficiarios'
import { asignarPaso }from '../../ducks/Pasos'
import { crearTransacciones, resetError } from '../../ducks/Transacciones'
import { resetMensaje } from '../../ducks/Mensajes'
import { asignarDetalle } from '../../ducks/DetallesCambio'
import { Banco, currency, currency2decimal } from '../../services/utils'

const AgregarPartner = props =>{
    const [ modalConfirmar, setModalConfirmar ] = useState(false)
    const pasoActivo = useSelector( state => state.Pasos )
    const clientes = useSelector( state => state.Clientes )
    const beneficiarios = useSelector( state => state.Beneficiarios )
    const detalle = useSelector( state => state.DetalleCambio )
    const paises = useSelector( state => state.Paises )
    const mensajes = useSelector( state => state.Mensajes )
    const transacciones = useSelector( state => state.Transacciones )

    const dispatch = useDispatch()

    const handlePrimerPaso = (values) => {
        dispatch(asignarDetalle(values));
        dispatch(asignarPaso(pasoActivo.data + 1));
    };

    const fetchData = () => {
        dispatch(asignarPaso(0))
        dispatch(resetClientes())
        dispatch(resetBeneficiarios())
    }

    let elegirBeneficiarios
    const paisSeleccionado = paises.data.find( x => x.id === (detalle.data.pais_destino && detalle.data.pais_destino.id) )
    if(paisSeleccionado){
        switch (paisSeleccionado.nombre) {
        case "Venezuela":
            elegirBeneficiarios =
            <>
            <Grid container justify="center">
                <Grid item xs={12} lg={6} xl={6}>
                    <FormularioBeneficiariosVzla
                        paisId={paisSeleccionado.id}
                        busquedaAdmin={true}
                        onSubmit={values => 
                            dispatch(agregarBeneficiarioACliente({
                                ...values,
                                idTipo: values.idTipo.id,
                                tipoCuenta: values.tipoCuenta.id,
                                banco: values.banco.id,
                                cuenta: values.codigo+values.cuenta, 
                                country_id: paisSeleccionado.id
                            }, 
                            paisSeleccionado.nombre 
                            ))
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <TablaBeneficiarios
                        seleccionar={true}
                        handleSeleccionar={values => {
                            dispatch(asignarBeneficiario(beneficiarios.data.filter(x => x.id === values)))
                            dispatch(asignarPaso(pasoActivo.data +1 ))
                        }}
                        acciones={true}
                        editar={true}
                        nombre={true}
                        cedula={true}
                        banco={true}
                        cuenta={true}
                    />
                </Grid>
            </Grid>
            </>
            break;
        case "Chile":
            elegirBeneficiarios =
            <Grid container justify="center">
                <Grid item xs={12} lg={6} xl={6}>
                <FormBeneficiarioChile
                    paisId={paisSeleccionado.id}
                    busquedaAdmin={true}                
                    onSubmit={values =>
                    dispatch(agregarBeneficiarioACliente(
                        {
                        ...values,
                        idNumero: values.rut,
                        idTipo: "Rut",
                        tipoCuenta: values.tipoCuenta.id,
                        banco: values.banco.id,
                        country_id: paisSeleccionado.id
                        },
                        paisSeleccionado.nombre
                    ))
                    }
                />
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <TablaBeneficiarios
                        seleccionar={true}
                        handleSeleccionar={values => {
                            dispatch(asignarBeneficiario(beneficiarios.data.filter(x => x.id === values)))
                            dispatch(asignarPaso(pasoActivo.data +1 ))
                        }}
                        acciones={true}
                        editar={true}
                        nombre={true}
                        cedula={true}
                        banco={true}
                        email={true}
                        cuenta={true}
                    />
                </Grid>
            </Grid>
            break;
        
        case "Panama":
            elegirBeneficiarios=
            <Grid container justify="center">
                <Grid item xs={12} lg={6} xl={6}>
                <FormBeneficiarioPnm
                    paisId={paisSeleccionado.id}
                    busquedaAdmin={true}                
                    onSubmit={values =>
                    dispatch(agregarBeneficiarioACliente(
                        {
                        ...values,
                        tipoCuenta: values.tipoCuenta.id,
                        banco: values.banco.id,
                        country_id: paisSeleccionado.id
                        },
                        paisSeleccionado.nombre
                    ))
                    }
                />
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <TablaBeneficiarios
                        seleccionar={true}
                        handleSeleccionar={values => {
                            dispatch(asignarBeneficiario(beneficiarios.data.filter(x => x.id === values)))
                            dispatch(asignarPaso(pasoActivo.data +1 ))
                        }}
                        acciones={true}
                        editar={true}
                        nombre={true}
                        banco={true}
                        email={true}
                        cuenta={true}
                    />
                </Grid>
            </Grid>
            break;
        
        
        default:
            elegirBeneficiarios =
            <Grid container justify="center" alignItems="center">
                <Typography variant="h6">Aún no podemos enviar dinero a este pais </Typography>
            </Grid>
            break;
        }
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Realiza tu cambio</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <MenuPartners />
            <Stepper pasoActivo={ pasoActivo } pasos={[ "Calcula tu cambio", "Elige el cliente", "Elige el beneficiario", "Confirma tu cambio"]} />
            
            {pasoActivo.data === 0 && (
                <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={8} xl={8}>
                    <Calculadora
                        descripcion={true}
                        perfil="Asociado"
                        handleSubmit={async (values) => {
                            handlePrimerPaso(values);
                        }}
                    />
                </Grid>
                </Grid>
            )}

            {pasoActivo.data === 1 && (
                <Grid container justify="center">
                    <Grid item xs={12} lg={6} xl={6}>
                        <Grid container
                        direction="column"
                        alignItems="center"
                        justify="center">
                        <Paper style={{ maxWidth: '600px'}}>
                            <Box p={4}>
                                <FormCliente
                                    busqueda={true}
                                    onSubmit={async (values) => {
                                        await dispatch(agregarCliente(values));
                                    }}
                                    />
                            </Box>
                        </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                        <TablaClientes
                            seleccionar={true}
                        />
                    </Grid>
                </Grid>
            )}

            {pasoActivo.data === 2 && (
                elegirBeneficiarios
            )}

            {pasoActivo.data === 3 && 
                <Grid container justify="center" alignItems="center"  >
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Card title="Cliente" styles={{ minHeight: '250px' }}>
                            {clientes.data.map( (x,id) => 
                                <Fragment key={id}>
                                    <Typography color="textSecondary"><strong>Nombre:</strong>{x.cliente ? x.cliente : x.nombre}</Typography>
                                    <Typography color="textSecondary"><strong>Identificación:</strong>{x.rut}</Typography>
                                    <Typography color="textSecondary"><strong>Correo:</strong>{x.email}</Typography>
                                    <Typography color="textSecondary"><strong>Telefono:</strong>{x.telefono}</Typography>
                                </Fragment> )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Card title="Beneficiario" styles={{ minHeight: '250px' }} >
                            {beneficiarios.data.map( (x,id) => 
                                <Fragment key={id}>
                                    <Typography color="textSecondary"><strong>Nombre:</strong>{x.nombre}</Typography>
                                    <Typography color="textSecondary"><strong>Identificación:</strong>{x.idTipo+ "-" + x.idNumero}</Typography>
                                    <Typography color="textSecondary"><strong>Banco:</strong>{Banco(x.banco)}</Typography>
                                    <Typography color="textSecondary"><strong>Cuenta:</strong>{x.cuenta}</Typography>
                                </Fragment> )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}> 
                        <Card title="Detalle del cambio" styles={{ minHeight: '250px' }} >
                            <Fragment>
                                <Typography color="textSecondary"><strong>Origen:</strong>{detalle.data.tasa.origen.nombre}</Typography>
                                <Typography color="textSecondary"><strong>Destino:</strong>{detalle.data.tasa.destino.nombre}</Typography>
                                <Typography color="textSecondary"><strong>Total a enviar:</strong>{currency(detalle.data.origen)}</Typography>
                                <Typography color="textSecondary"><strong>Total a recibir:</strong>{currency(detalle.data.destino)}</Typography>
                                <Typography color="textSecondary"><strong>Tasa:</strong>{`${currency2decimal(detalle.data.tasa.tasa)} ( Tasa para pagos entre ${currency(detalle.data.tasa.rango.minimo)} y ${currency(detalle.data.tasa.rango.maximo)} )`}</Typography>
                            </Fragment>
                        </Card>   
                    </Grid>
                </Grid>
            }
            {pasoActivo.data !== 0 &&
                <Grid container justify="center" alignItems="center" style={{ padding:'20px'}} >
                    <Button variant="contained" color="primary" onClick={ () => {dispatch(asignarPaso(0)); dispatch(resetClientes()); dispatch(resetBeneficiarios())} }>Cancelar Transacción</Button>
                    {pasoActivo.data === 3 && 
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={ () => 
                                setModalConfirmar(true)
                            }
                        >
                                    Confirmar Transacción
                        </Button>
                    }
                </Grid>
            }

            <Modal
                open={modalConfirmar}
                title="Notificación"
                close={true}
                handleClose={() => setModalConfirmar(false)}
                loading={transacciones.fetching}
                aceptar="Aceptar"
                action={async () => {
                    await dispatch(crearTransacciones({clientes, beneficiarios, detalle}))
                    setModalConfirmar(false)
                }}
            
            >
                ¿Estás seguro de crear esta transacción? Recuerda Verificar los datos

            </Modal>

            <Modal
                open={transacciones.error && transacciones.error.length > 0 ? true : false}
                title="Notificación"
                close={true}
                handleClose={() => dispatch(resetError())}
                >
                    <ul>
                        {transacciones.error && transacciones.error.map((x,id) => <li key={id}>{x}</li>)}
                    </ul>
            </Modal>

            <Snackbar
                open={mensajes.data.length > 0 ? true : false}
                handleClose={() => dispatch(resetMensaje())}
                message={mensajes.data}
            />
        </Box>

    )

}

export default AgregarPartner