import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import agregarPartner from './containers/transacciones/agregarPartner'
import Perfil from './containers/perfil'
import HomePage from './containers/HomePage/HomePage'
import Nosotros from './components/Nosotros/Nosotros'
import Beneficiarios from './containers/beneficiarios'
import Beneficios from './components/Beneficios/Beneficios'
import Contacto from './components/Contacto'
import PendientesAsociados from './containers/PendientesAsociados'
import RealizadasAsociados from './containers/RealizadasAsociados'
import NotificadasAsociados from './containers/NotificadasAsociados'
import Clientes from './containers/clientes'
import MisBeneficiarios from './containers/beneficiarios/beneficiariosClientes'
import Usuarios from './containers/usuarios'
import Perfiles from './containers/perfiles'
import Estados from './containers/estados'
import Pagadores from './containers/usuarios/pagadores'
import Proveedores from './containers/proveedores'
import Taxes from './containers/taxes'
import AgregarCliente from './containers/transacciones/agregarCliente'
import EstatusCajeros from './containers/balances'
import BalancesCuentas from './containers/balances/balancesCuentas'
import AbonosDebitos from './containers/balances/abonosDebitos'
import Login from './containers/auth/Login'
import Password from './containers/auth/Password'
import ConfirmationEmail from './containers/auth/ConfirmationEmail'
import Register from './containers/auth/Register'
import RegisterMayorista from './containers/auth/RegisterMayoristas'
import LoadingPage from './components/LoadingPage'
import Trabajando from './components/Trabajando'
import Rangos from './containers/rangos'
import Dashboard from './containers/dashboard'
import Pendientes from './containers/transacciones/pendientes'
import AgregarAdmin from './containers/transacciones/agregarAdmin'
import AgregarSolicitudMayorista from './containers/transacciones/agregarSolicitudMayorista'
import SolicitudesAbiertas from './containers/transacciones/solicitudesAbiertas'
import ClientesMayoristas from './containers/transacciones/clientesMayoristas'
import AdminWorkingaccounts from './containers/workingaccounts/adminWorkingaccounts'
import Realizadas from './containers/transacciones/realizadas'
import Recibidas from './containers/transacciones/recibidas'
import Rechazadas from './containers/transacciones/rechazadas'
import Workingaccounts from './containers/workingaccounts'
import TransaccionesCajeros from './containers/workingaccounts/transaccionesCajeros'
import RealizadasCajeros from './containers/workingaccounts/realizadasCajeros'
import Paises from './containers/paises'
import Tasas from './containers/tasas'
import PendientesClientes from './containers/transacciones/pendientesClientes'
import RealizadasClientes from './containers/transacciones/realizadasClientes'
import Modal from './containers/Modal'
import PasswordReset from './containers/auth/PasswordReset';
import { getUsuario } from './ducks/Usuario'
import { asignarUrl } from './ducks/Url'
import { CssBaseline, Box } from '@material-ui/core';
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'

const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                  backgroundColor: '#fafafa',
                },
            },
        },
    },
    palette: {
        
        primary: {
            //light: '#a64c46',
            main: '#003520',
            //dark: '#430000',
            contrastText: '#fff',
        },
        secondary: {
            //light: '#4f5b62',
            main: '#58585A',
            //dark: '#000a12',
            contrastText: '#fff',
        },
        blanco: {
            light: '#fff',
            main: '#fff',
            dark: '#fff',
            contrastText: '#fff',
        },
        typography: {
            fontSize: 8,
        },
    },
});

const App = props => {
    const [ loading, setLoading ] = useState(true)
    const usuario = useSelector(state => state.Usuario.data[0])
    const dispatch = useDispatch()

    const fetchData = async () => {
        const usuario = await dispatch(getUsuario())
        if(!usuario || (usuario && usuario.profile.nombre === "Cliente" )){
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.id="chattawk"
                s1.async=true;
                s1.src='https://embed.tawk.to/5ebc8e9c967ae56c52199655/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();
            Tawk_API.onLoad = function(){
                console.log("dasdasd")
                var pageStatus = Tawk_API.getStatus();
                console.log(pageStatus)
            };
        }
        dispatch(asignarUrl(window.location.pathname))
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    },[])

    return(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {loading ? <LoadingPage /> 
            :
            <Switch>
                <PublicRoute restricted={true} path="/login" component={Login} />
                <PublicRoute restricted={true} path="/password" component={Password} />
                <PublicRoute restricted={true} path="/correo-confirmacion" component={ConfirmationEmail} />
                <PublicRoute restricted={true} path="/register" component={Register} />
                <PublicRoute restricted={true} path="/register-mayoristas" component={RegisterMayorista} />
                <PublicRoute restricted={true} path="/password-reset" component={PasswordReset} />
                <PublicRoute restricted={false} path="/trabajando" component={Trabajando} />
                <PublicRoute restricted={false} exact={true} path="/" component={HomePage} />
                <PublicRoute restricted={false} path="/nosotros" component={Nosotros} />
                <PublicRoute restricted={false} path="/beneficios" component={Beneficios} />
                <PublicRoute restricted={false} path="/contacto" component={Contacto} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/mayorista/agregar-solicitud" component={AgregarSolicitudMayorista} />
                <PrivateRoute exact={true} permission={["Admin", "Mayorista"]} path="/mayorista/pagadores" component={Pagadores} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/mayorista/solicitudes-abiertas" component={SolicitudesAbiertas} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/mayorista/clientes-mayoristas" component={ClientesMayoristas} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/transacciones/agregar" component={AgregarAdmin} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/transacciones/por-verificar" component={Recibidas} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/transacciones/pendientes" component={Pendientes} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/transacciones/realizadas" component={Realizadas} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/transacciones/rechazadas" component={Rechazadas} />
                <PrivateRoute exact={true} permission={["Admin", "Asociado"]} path="/partners/agregar" component={agregarPartner} />
                <PrivateRoute exact={true} permission={["Admin", "Asociado"]} path="/partners/pendientes" component={PendientesAsociados} />
                <PrivateRoute exact={true} permission={["Admin", "Asociado"]} path="/partners/realizadas" component={RealizadasAsociados} />
                <PrivateRoute exact={true} permission={["Admin", "Asociado"]} path="/partners/notificadas" component={NotificadasAsociados} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/dashboard" component={Dashboard} />
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/clientes" component={Clientes}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/usuarios" component={Usuarios}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/proveedores" component={Proveedores}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/comisiones" component={Taxes}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/beneficiarios" component={Beneficiarios}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/balances/estatus-cajeros" component={EstatusCajeros}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/balances/abonos-debitos" component={AbonosDebitos}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/balances/balances-cuentas" component={BalancesCuentas}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina", "Cajero"]} path="/cajeros" component={Workingaccounts}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina", "Cajero"]} path="/cajeros/:id/pendientes" component={TransaccionesCajeros}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina", "Cajero"]} path="/cajeros/:id/realizadas" component={RealizadasCajeros}/>
                <PrivateRoute exact={true} all={true} path="/clientes/pendientes" component={PendientesClientes}/>
                <PrivateRoute exact={true} all={true} path="/clientes/realizadas" component={RealizadasClientes}/>
                <PrivateRoute exact={true} all={true} path="/clientes/agregar" component={AgregarCliente}/>
                <PrivateRoute exact={true} all={true} path="/mis-beneficiarios" component={MisBeneficiarios}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/rangos" component={Rangos}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/paises" component={Paises}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/tasas" component={Tasas}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/perfiles" component={Perfiles}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/estados" component={Estados}/>
                <PrivateRoute exact={true} permission={["Admin", "Oficina"]} path="/administracion/cuentas-trabajo" component={AdminWorkingaccounts}/>
                <PrivateRoute exact={true} all={true} path="/configuracion/perfil" component={Perfil}/>
                <Redirect to="/" />
            </Switch>}
            <Modal />
        </ThemeProvider >
    )
}

export default App