import React from 'react';
import { useDispatch } from 'react-redux'
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import TablaClientes from './tablaClientes'
import FormBusquedaUsuarios from '../../components/FormBusquedaUsuarios'
import { buscarClientes } from '../../ducks/Clientes'

const Clientes = props => {
    const dispatch = useDispatch()

    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Clientes Registrados</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Box m={2}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xl={6} lg={6} sm={12}>
                        <Paper>
                            <Grid container justify="center" alignItems="center" >
                                <Typography variant="h6">Busqueda de Clientes</Typography>
                            </Grid>
                            <Box p={2}>
                                <FormBusquedaUsuarios onSubmit={values => dispatch(buscarClientes(values))} />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} lg={12} xl={12}>
                    <TablaClientes
                        id={true}
                        activo={true}
                        nombre={true}
                        acciones={true}
                        cedula={true}
                        email={true}
                        telefono={true}
                    />
                </Grid>
            </Grid>
        </Box>
    
    )
}

export default Clientes