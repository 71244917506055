import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, addFetchUnShift, resetErrorHOR, resetStoreHOR, addFetch } from './hors.js'
import { getRequest, postRequest } from '../services/api'

const t = makeTypes('balances')
const FETCH = t('fetch').async()
const ADDUNSHIFT = t('add-unshift').async()
const ADD = t('add').async()
const RESETERROR = t('reset-error').single()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADDUNSHIFT)
const addNormalActions = asyncMac(ADD)
export const resetErrorBalance = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetchUnShift( ADDUNSHIFT ))
const addNormalReducer = createReducer(initialState, addFetch( ADD ))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, addNormalReducer, addReducer, resetErrorReducer, resetStoreReducer)

export const fetchBalances = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    console.log(values)
    try {
        const data = await getRequest(`/balances/last-ten?cuenta=${values.cuenta ? values.cuenta : ""}&limit=${values.limit ? values.limit : ""}&fecha_comienzo=${values.fecha_comienzo ? values.fecha_comienzo : ""}&fecha_final=${values.fecha_final ? values.fecha_final : ""}`)
        console.log(data)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}

export const addBalances = values => async (dispatch, getState) => {
    dispatch(addNormalActions.start())
    try {
        const data = await getRequest(`/balances/last-ten?cuenta=${values.cuenta ? values.cuenta : ""}&limit=${values.limit ? values.limit : ""}&offset=${values.offset ? values.offset : ""}&fecha_comienzo=${values.fecha_comienzo ? values.fecha_comienzo : ""}&fecha_final=${values.fecha_final ? values.fecha_final : ""}`)
        dispatch(addNormalActions.success(data.data))
    } catch (error) {
        dispatch(addNormalActions.error(error))
    }
}

export const fetchTransaccionesExternas = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/balances/load-external-balance?id=${values}`)
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]) : ["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}

export const fetchBalanceCliente = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/balances/balance-customer?cliente=${values.cliente ? values.cliente : ""}&pais=${values.pais ? values.pais : ""}&fecha_comienzo=${values.fecha_comienzo ? values.fecha_comienzo : ""}&fecha_final=${values.fecha_final ? values.fecha_final : ""}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const fetchBalanceBancario = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/balances/balance-banco-estado`)
        console.log(data)
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const calcularBalance = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/balances`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error())
        throw error
    }
}

export const agregarPagoMayorista = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    console.log(values)
    try {
        const data = await postRequest(`/balances/customer-payment`, values)
        console.log(data)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
} 