import React from 'react'
import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, selectHOR, addFetch, delFetch, resetHOR, editFetch, resetStoreHOR, resetErrorHOR } from './hors.js'
import { getRequest, postRequest, deleteRequest, putRequest, postImageRequest } from '../services/api'
import { abrirModal, cerrarModal} from './Modales'
import { asignarPaso } from './Pasos.js'
import { fetchBeneficiarios } from './Beneficiarios'

const t = makeTypes('clientes')
const FETCH = t('fetch').async()
const ADD = t('add').async()
const SELECT = t('select').single()
const DEL = t('del').async()
const EDIT = t('edit').async()
const RESET = t('reset').single()
const RESETSTORE = 'reset'
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)
const editActions = asyncMac(EDIT)
const deleteActions = asyncMac(DEL)
export const seleccionarCliente = mac(SELECT, 'payload')
export const resetClientes = mac(RESET, 'payload')
export const resetErrorCliente = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const selectReducer = createReducer(initialState, selectHOR({ SELECT }))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers(fetchReducer, resetErrorReducer, editReducer, selectReducer, addReducer, deleteReducer, resetReducer, resetStoreReducer)

export const fetchClientes = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/customers`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const buscarCliente = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/customers/search?email=${values}`)
        if(data.data){
            dispatch(fetchActions.success([data.data]))
        }else{
            dispatch(fetchActions.success([]))
            dispatch(abrirModal({ title:"Notificación", body:"No se encontraron clientes con ese correo", close:true}))
        }
        
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const buscarClientes = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await postRequest('/customers/advance-search', {...values, cliente: values.nombre})
        dispatch(fetchActions.success(response.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const buscarClienteNombre = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/customers/search?search=${values}`)
        if(data.data.length > 0){
            dispatch(fetchActions.success(data.data))
        }else{
            dispatch(fetchActions.success([]))
            dispatch(abrirModal({ title:"Notificación", body:"No se encontraron coincidencias", close:true}))
        }
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarCliente = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest('/customers', { ...values, codigo: values.codigo.phone ? values.codigo.phone : values.codigo, rut: values.tipo_id === "Pasaporte" ? values.pasaporte : values.rut, cliente: values.cliente ? values.cliente : values.nombre})
        dispatch(addActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(deleteActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const borrarCliente = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    const state = getState()
    try {
        const data = await deleteRequest(`/customers`, id)
        dispatch(deleteActions.success(data.data.id))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const editCliente = values => async (dispatch, getState) => {
    const state = getState()    
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/customers`, values.id, { ...values, rut: values.tipo_id === "Pasaporte" ? values.pasaporte : values.rut})
        dispatch(editActions.success(data.data))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const asignarCliente = (values,paso,pais) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        await dispatch(fetchBeneficiarios({...values, pais }))
        dispatch(asignarPaso(paso+1))
        dispatch(fetchActions.success([values]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const agregarClienteImg = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const response = await postImageRequest(`/customers/add-customer`, { data: values, image: values.images } )
        dispatch(addActions.success(response.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        throw error
    }
}
