import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MenuButton = props =>  {
    const { buttons } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
         setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon /> 
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                
                { buttons && buttons.map( (button,index) => <MenuItem key={index} onClick={() => {button.action();handleClose()}} >{button.name}</MenuItem> )}

            </Menu>
        </>
    );
}

MenuButton.propTypes = {
    buttons: PropTypes.array
}

export default MenuButton