import React from 'react';
import { useSelector } from 'react-redux'
import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import { Button } from '@material-ui/core'
import { currency2decimal, Banco } from '../../services/utils'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const TablaTransacciones = props => {
    const {
        exportable,
        actualizar,
        verDetalle,
        pageSize,
        grouping,
        acciones,
        id,
        comprobante,
        pais,
        cliente,
        beneficiario,
        cedula,
        banco,
        cuenta,
        tasa,
        pago,
        recibe,
        recibeCajero,
        estado,
        comentarios,
        proveedor,
        cajero,
        referencia,
        operador,
        fecha,
        actualizacion,
        realizar,
        handleArchivos,
        handleAprobarTransaccion,
        aprobacionTransaccion,
        handleRechazar,
        rechazar,
        handleEditar,
        editar,
        handleBorrar,
        borrar,
        handleAsignar,
        asignar,
        handleVerArchivos,
        ver,
        agregarPagos,
        handleAgregarPagos,
        verificacionUsuario,
        handleRealizar,
        selection,
        handleSelection,
        transacciones,
        paises,
        estados
    } = props
    
    return (
        <Tabla
            rowStyle={ (rowData) => {
                if(rowData.cajero && asignar)
                    return { color: 'red' }
            }}
            exportable={exportable}
            actualizar={actualizar}
            verDetalle={verDetalle}
            pageSize={pageSize}
            grouping={grouping}
            selection={selection}
            handleSelection={handleSelection}
            columnas={[
                ... acciones ? [{ title: 'Acción', field: 'accion',  export: false,
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... id ? [{ title: 'Nº', field: 'id',  export: false,
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... comprobante ? [{ title: 'Comprobante', field: 'comprobante', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... pais ? [{ title: 'Origen/Destino', field: 'pais', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... cliente ? [{ title: 'Cliente', field: 'cliente',
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... beneficiario ? [{ title: 'Beneficiario', field: 'beneficiario', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... cedula ? [{ title: 'Cedula', field: 'cedula', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... banco ? [{ title: 'Banco', field: 'banco', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... cuenta ? [{ title: 'Cuenta', field: 'cuenta', export: false,
                    cellStyle: {
                        width: '200px'
                    },headerStyle: {
                        width: '200px'
                }}] : [],
                ... tasa ? [{ title: 'Tasa', field: 'tasa', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... pago ? [{ title: 'Pago', field: 'pago',
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... recibe ? [{ title: 'Recibe', field: 'recibe', 
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... recibeCajero ? [{ title: 'Recibe', field: 'recibecajero', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... estado ? [{ title: 'Estado', field: 'estado', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... comentarios ? [{ title: 'Comentarios', field: 'comentarios', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... proveedor ? [{ title: 'Proveedor', field: 'proveedor', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... cajero ? [{ title: 'Cajero', field: 'cajero', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... referencia ? [{ title: 'Referencia', field: 'referencia', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... operador ? [{ title: 'Operador', field: 'operador', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... fecha ? [{ title: 'Fecha', field: 'created_at', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... actualizacion ? [{ title: 'Ultima Act.', field: 'updated_at', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ... realizar ? [{ title: 'Realizar', field: 'realizar', export: false,
                    cellStyle: {
                        width: '150px'
                    },headerStyle: {
                        width: '150px'
                }}] : [],
                ]}
            data={transacciones.filter( x => x.active ).map(x => ({
                ...x,
                cliente: x.customer ? x.customer.cliente : (x.user ? (x.user.nombre + " " + x.user.apellido) : ""),
                beneficiario: x.account ? x.account.nombre : "",
                pais: x.exchange_rate ? paises.data.find(y => y.id === x.exchange_rate.origen_id)?.nombre +"/"+ paises.data.find(y => y.id === x.exchange_rate.destino_id)?.nombre  : "",
                cedula: x.account ? (x.account.idTipo ? x.account.idTipo : "") + "-" + (x.account.idNumero ? x.account.idNumero : "") : "",
                banco: x.account ? Banco(x.account.banco) : "",
                cuenta: x.account ? x.account.tipoCuenta+ "-"+x.account.cuenta : "",
                tasa: x.exchange_rate ? x.exchange_rate.nombre+ "-"+ x.tasa : "",
                pago: x.cantidadPesos ? currency2decimal(x.cantidadPesos) : "",
                recibe: x.bolivares ? currency2decimal(x.bolivares) : "",
                recibecajero: x.bolivares ? x.bolivares : "",
                cajero: x.workingaccount ? x.workingaccount.nombre : "",
                created_at: moment(x.created_at).format('DD-MM-YYYY HH:mm'),
                operador: x.operario_id ? (x.operario ? x.operario.nombre : "") : x.operador,
                updated_at: moment(x.updated_at).format('DD-MM-YYYY HH:mm'),
                estado:  estados.data.find(y => y.id === x.state_id) ? estados.data.find(y => y.id === x.state_id).nombre : "",
                comentarios: comentarios && x.audits.filter(y => y.action_triggered === "Rechazo").map(y => y.description),
                proveedor: x.provider ? (x.provider.nombre+"-"+x.provider.descripcion) : "Sin asignar",
                realizar:<Button variant='contained' color='primary' onClick={handleRealizar}>Realizar</Button>,
                accion: 
                <BotonMenu 
                    usuario={x.user && x.user.verified}
                    handleArchivos={() => handleArchivos(x)}
                    handleAprobarTransaccion={() => handleAprobarTransaccion(x)}
                    handleRechazar={() => handleRechazar(x)}
                    handleBorrar={() => handleBorrar(x)}
                    handleAsignar={() => handleAsignar(x)}
                    handleEditar={() => handleEditar(x)}
                    handleVerArchivos={() => handleVerArchivos(x)}
                    handleAgregarPagos={() => handleAgregarPagos(x)}
                    agregarPagos={agregarPagos}
                    rechazar={rechazar}
                    verificacionUsuario={verificacionUsuario}
                    aprobacionTransaccion={aprobacionTransaccion} 
                    asignar={asignar} 
                    archivo={x.images.length > 0 ? true : false} 
                    ver={ver}
                    borrar={borrar} 
                    editar={editar} 
                />
            }))} 
        />         
    )
}

export default TablaTransacciones