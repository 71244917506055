import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@material-ui/core'

import TarjetaUsuario from '../../components/TarjetaUsuario'
import TarjetaDetalle from '../../components/TarjetaDetalle'
import TarjetaCuentas from '../../components/TarjetaCuentas/TarjetaCuentas'

const Resumen = props => {
    const { cliente, multiple } = props

    const usuario = useSelector( state => {
        if(cliente){
            return state.Clientes.data.find(cliente => cliente.selected)
        }else{
            return state.Usuarios.data.find(us => us.selected)
        }
    })
    const beneficiariosSeleccionados = useSelector(state => state.Beneficiarios )
    const beneficiario = useSelector( state => state.Beneficiarios.data.find(benef => benef.selected))    
    const detalle = useSelector( state => state.DetalleCambio.data )    
    
    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body1">Cliente</Typography>
                <TarjetaUsuario 
                    usuario={usuario}
                />
                {multiple ? 
                beneficiariosSeleccionados.data.map((x, id) => (
                    <Grid key={id}>
                        <Box m={2}>
                            <Typography variant="h6">Transacción {id+1}</Typography>
                        </Box>
                        <Box m={2}>
                            <Typography variant="body1">Beneficiario</Typography>
                        </Box>
                        <Box m={2}>
                            <TarjetaCuentas 
                                cuenta={x}
                            />
                        </Box>
                        <Box m={2}>
                            <Typography variant="body1">Detalle</Typography>
                        </Box>
                        <Grid style={{ margin: 10 }}>
                            <TarjetaDetalle 
                                transaccion={x}
                            />
                        </Grid>
                    </Grid>))
                :
                <Grid>
                    <Typography variant="body1">Beneficiario</Typography>
                    <TarjetaCuentas 
                        cuenta={beneficiario}
                    />
                    <Typography variant="body1">Detalle</Typography>
                    <TarjetaDetalle transaccion={detalle}/>
                </Grid>
                }
            </Grid>
        </Grid>

    )
}

export default Resumen