import React from 'react'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderSelect = ({
    nombre,
    options,
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => 
  {
    return(
      <FormControl variant="outlined" {...custom}>
        <InputLabel>{label ? label : ""}</InputLabel>
        <Select
          {...input}
          children={children}
          error={touched && error}
          variant="outlined"
          inputProps={{
            name: input.name
          }}
        >
          {options.map(x => <MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>)}
        </Select>
        {touched && error && <FormHelperText style={{ color: 'red'}}>Requerido</FormHelperText>}
      </FormControl>
    )
  }

export default renderSelect
