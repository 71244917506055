import React from 'react';
import { Grid, Button, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import HomeLayout from '../HomeLayout/HomeLayout'
import HomeBeneficios from './HomeBeneficios'
import './Beneficios.scss';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Titlespan } from '../common/title/index';
import { history } from '../../index';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: '220px',
      backgroundColor: '#003520',
      color: 'white',
      borderRadius: '15px',
      height: '400px',
      textAlign: "center",
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

function Beneficios(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <HomeLayout>
                <div id="main-content">
                    <div className={`banner-wrapper3 demo1`}>
                        <div className="banner-outer">
                            <>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <div className="banner-content3">
                                            
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="h4">¡Envía dinero al extranjero de forma fácil, rápida y segura!</Typography>}
                                                Name=""
                                            />
                                            
                                            <Titlespan
                                                Class="banner-main-title"
                                                Label={<Typography color='inherit' variant="body1">Puedes enviar dinero desde cualquier lugar y dispositivo a la mejor tasa del mercado, es muy facil</Typography>}
                                                Name=""
                                            />

                                            <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/clientes/cambio')} >Enviar Dinero</Button>
                                            {false &&<div className="banner-btn-wrapper3">

                                                <Button onClick={() => history.push("/login")}
                                                    variant="outlined" color="primary">
                                                    Envía con nosotros
                                                </Button>
                                            </div>}
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </div>
                    <HomeBeneficios />
                    <Grid container style={{ margin: '160px 0px 160px 0px'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container justify="center" alignItems="center"   >
                                <Typography variant="h4" color="primary" style={{ textAlign: "center", margin: '40px', textDecoration: 'underline' }}>¿Por qué Aguacatecambios?</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Grid container justify="center" alignItems="center"  >
                                <Paper className={classes.paper}>
                                    <span><WatchLaterIcon style={{ color: '#FBF1BA', fontSize: '60px', margin: '40px 0px 10px 0px' }}/></span>
                                    <Typography variant="h6" color="inherit" style={{ padding: '20px' }}>Recibe tu dinero en tiempo record</Typography>
                                    <Typography variant="body2" color="inherit" style={{ padding: '20px' }}>Basta de preocuparte en pensar cuando llegará tu dinero, lo enviamos el mismo día que recibimos tu pago.</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Grid container justify="center" alignItems="center"  >
                                <Paper className={classes.paper}>
                                    <span><MonetizationOnIcon style={{ color: '#FBF1BA', fontSize: '60px', margin: '40px 0px 10px 0px' }}  /></span>
                                    <Typography variant="h6" color="inherit" style={{ padding: '20px' }}>Sin comisiones ocultas</Typography>
                                    <Typography variant="body2" color="inherit" style={{ padding: '20px' }}>La tasa de cambio es la publicada, no hay mas comisiones que pagar.</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Grid container justify="center" alignItems="center"  >
                                <Paper className={classes.paper}>
                                    <span><LocationOnIcon style={{ color: '#FBF1BA', fontSize: '60px', margin: '40px 0px 10px 0px' }} /></span>
                                    <Typography variant="h6" color="inherit" style={{ padding: '20px' }}>Desde donde quiera que estés</Typography>
                                    <Typography variant="body2" color="inherit" style={{ padding: '20px' }}>Olvidate de las largas filas de espera y de tener que moverse de sitio, envía dinero desde cualquier lugar.</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </HomeLayout>
        </React.Fragment>
        
    );
}

export default Beneficios;
