import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Button, Grid, Container } from "@material-ui/core";
import TextField from "./TextField";
import SelectSearch from "./SelectSearch";
import Loading from "./Loading";
import DatePicker from './DatePicker'
import { fetchPaises } from "../ducks/Paises";

const validate = (values = {}) => {

  const errors = {};
  const requiredFields = [
      "origen_id",
      "destino_id",
      "fecha_final",
      "fecha_comienzo"
    ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = true;
    }
  });

  return errors;
};

let FormBusquedaDashboard = (props) => {
  const { handleSubmit, submitting, change } = props;
  const dispatch = useDispatch();
  const paises = useSelector((state) => state.Paises);
  
  const fetchData = () => {
      dispatch(fetchPaises())
  }

  useEffect(() => {
      fetchData()
  },[])

  return (
    <Container>
      <form noValidate onSubmit={handleSubmit}>
        <Field
          required
          style={{ width: "100%", padding: "10px 0px 10px 0px " }}
          type="text"
          change={change}
          name="origen_id"
          disableClearable={true}
          label="Pais Origen"
          options={paises.data.filter((x) => x.active)}
          component={SelectSearch}
        />

        <Field
          required
          style={{ width: "100%", padding: "10px 0px 10px 0px " }}
          type="text"
          change={change}
          name="destino_id"
          disableClearable={true}
          label="Pais Destino"
          options={paises.data.filter((x) => x.active)}
          component={SelectSearch}
        />

        <Field
          required
          name="fecha_comienzo"
          label="Desde"
          component={DatePicker}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Field
          required
          name="fecha_final"
          label="Hasta"
          component={DatePicker}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Grid item xs={12} lg={12} xl={12}>
          <Grid container justify="center" alignItems="center">
            {submitting ? (
              <Loading />
            ) : (
              <Button variant="contained" color="primary" type="submit">
                Buscar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

FormBusquedaDashboard = reduxForm({
  form: "busqueda-dashboard",
  enableReinitialize: true,
  validate,
})(FormBusquedaDashboard);

export default FormBusquedaDashboard
