import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, addFetch, selectHOR, resetHOR, assignHOR, delFetch, editFetch, resetErrorHOR, addHOR, resetStoreHOR} from './hors.js'
import { getRequest, deleteRequest, putRequest, postRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'
import { asignarMensaje } from './Mensajes'
import {reset} from 'redux-form';

const t = makeTypes('beneficiarios')
const FETCH = t('fetch').async()
const SELECT = t('select').single()
const RESET = t('reset').single()
const ASSIGN = t('assign').single()
const ANEXAR = t('ANEXAR').single()
const ADD = t('add').async()
const DEL = t('del').async()
const EDIT = t('edit').async()
const RESETERROR = t('reset-error').single()
const RESETSTORE = 'reset'


const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)
const delActions = asyncMac(DEL)
const editActions = asyncMac(EDIT)
export const resetErrorBeneficiario = mac(RESETERROR)
export const anexarBeneficiario = mac(ANEXAR, 'payload')
export const seleccionarBeneficario = mac(SELECT, 'payload')
export const resetBeneficiarios = mac(RESET, 'payload')
export const asignarBeneficiario = mac(ASSIGN, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const selectReducer = createReducer(initialState, selectHOR({ SELECT }))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const assignReducer = createReducer(initialState, assignHOR({ ASSIGN }))
const delReducer = createReducer(initialState, delFetch( DEL ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const anexarReducer = createReducer(initialState, addHOR({ADD: ANEXAR} ))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers( resetErrorReducer, editReducer,fetchReducer, selectReducer, resetReducer, assignReducer, addReducer, anexarReducer, delReducer, resetStoreReducer)

export const fetchTodosBeneficiarios = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/accounts`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarBeneficiarioACliente = (values,pais,idCliente) => async (dispatch, getState) => {
    dispatch(addActions.start())
    const state = getState()
    const beneficiarios = state.Beneficiarios.data
    const cliente = idCliente || state.Clientes.data[0].id
    try {
        if (beneficiarios.find(x => x.cuenta === values.cuenta )){
            dispatch(
                abrirModal({
                  title: "Notificación",
                  close: true,
                  body: "Este beneficiario ya se encuentra en tu lista",
                })
            )
            dispatch(addActions.success([]))
            return;
        }
        const data = await postRequest(`/accounts/add-account-customer`, {
            ...values,
            cliente,
        })
        if(pais === "Chile"){
            dispatch(reset('form-beneficiario-chile'))
        }else if( pais === "Venezuela"){
            dispatch(reset('form-beneficiario-vzla'))
        }else if(pais === "Panama"){
            dispatch(reset('form-beneficiario-pnm'))
        }
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const agregarBeneficiarioAUsuario = (values,pais,idUsuario) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const state = getState()
        const beneficiarios = state.Beneficiarios.data
        const usuario = idUsuario || state.Usuario.data[0].id
        if (beneficiarios.find(x => x.cuenta === values.cuenta )){
            dispatch(
                abrirModal({
                  title: "Notificación",
                  close: true,
                  body: "Este beneficiario ya se encuentra en tu lista",
                })
            )
            dispatch(addActions.success([]))
            return;
        }
        const data = await postRequest(`/accounts/add-account-user`, {
            ...values,
            usuario,
        })
        if(pais === "Chile"){
            dispatch(reset('form-beneficiario-chile'))
        }else if( pais === "Venezuela"){
            dispatch(reset('form-beneficiario-vzla'))
        }else if(pais === "Panama"){
            dispatch(reset('form-beneficiario-pnm'))
        }
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}



export const agregarBeneficiario = (values, pais) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/accounts`, values)
        if(values.usuario){
            dispatch(agregarBeneficiarioUsuario(data.data.id))
            dispatch(reset('beneficiario'))
            return
        }
        if(values.cliente){
            dispatch(agregarBeneficiarioCliente(data.data.id))
            dispatch(reset('beneficiario'))
            return
        }
        if(pais === "Chile"){
            dispatch(reset('form-beneficiario-chile'))
        }else if( pais === "Venezuela"){
            dispatch(reset('form-beneficiario-vzla'))
        }else if(pais === "Panama"){
            dispatch(reset('form-beneficiario-pnm'))
        }
        dispatch(asignarMensaje("Su registro se creó con éxito"))
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const fetchBeneficiarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    const state = getState()
    try {
        let data
        if(values){
            data = await getRequest(`/accounts/search?search=${values.id}&pais=${values.pais}`)
        }else{
            data = await getRequest(`/accounts/search?search=${state.Clientes.data[0].id}`)
        }
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const fetchBeneficiariosUsuarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data
        const state = getState()
        if(values){
            data = await getRequest(`/accounts/search_accounts_user?search=${values.id}&pais=${values.pais ? values.pais : ""}`)
        }else{
            data = await getRequest(`/accounts/search_accounts_user?search=${state.Usuario.data[0].id}`)
        }        
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const agregarBeneficiarioUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    const state = getState()
    const usuario = state.Usuario.data[0]
    try {
        const data = await postRequest('/accounts/agregar-usuario', {account: values, customer: usuario.id})
        if(data.data){
            dispatch(addActions.success(data.data))
            dispatch(abrirModal({title:"Notificación", close:true, body:`Beneficiario ${data.data.nombre} ID: ${data.data.idTipo +"-"+ data.data.idNumero} ha sido asociado a su cuenta` }))
        }else{
            dispatch(addActions.success(data.data))
        }
    } catch (error) {
        dispatch(addActions.error(error))
    }
}

export const vincularBenefUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest('/accounts/vincular-benef-usuario', {account: values.account, user: values.user})
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

// export const vincularBeneficiarioUsuario = values => async (dispatch, getState) => {
//     dispatch(addActions.start())
//     try {
//         const data = await postRequest('/accounts/vincular-beneficiario', values)
//         dispatch(addActions.success([]))
//     } catch (error) {
//         dispatch(addActions.error(error))
//     }
// }

export const agregarBeneficiarioCliente = (values, usuarioSelect) => async (dispatch, getState) => {
    dispatch(addActions.start())
    const state = getState()
    const usuario = usuarioSelect || state.Clientes.data[0].id
    try {
        const data = await postRequest('/accounts/agregar-usuario', {account: values, cliente: usuario})
        if(data.data){
            dispatch(addActions.success(data.data))
            dispatch(asignarMensaje("Se vinculó el beneficiario"))
        }else{
            dispatch(addActions.success(data.data))
        }
    } catch (error) {
        console.log(error)
        dispatch(addActions.error(error))
    }
}

export const quitarDeUsuario = values => async (dispatch, getState) => {
    dispatch(delActions.start())
    const state = getState()
    const usuario = state.Usuario.data[0]
    try {
        const data = await postRequest(`/accounts/quitar-usuario`, {usuario: values.usuario ? values.usuario : usuario.id, cuenta: values.cuenta ? values.cuenta : values})
        dispatch(delActions.success(data.data[0].id))
    } catch (error) {
        dispatch(delActions.error(error))
    }
}

export const quitarDeCliente = values => async (dispatch, getState) => {
    dispatch(delActions.start())
    const state = getState()
    const cliente = state.Clientes.data.find(x => x.selected)
    try {
        const data = await postRequest(`/accounts/quitar-cliente`, {cliente: cliente.id, cuenta: values})
        dispatch(delActions.success(data.data[0].id))
    } catch (error) {
        dispatch(delActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const editCuentas = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    const state = getState()
    try {
        const data = await putRequest(`/accounts`, values.id, values)
        dispatch(editActions.success(data.data))
        dispatch(asignarMensaje("Su registro se editó con éxito"))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const buscarBeneficiarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await postRequest('/accounts/advanced-search', values)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
        
    }

}

export const buscarBeneficiarioNombre = values => async (dispatch, getState) => {
    if(values.nombre.length < 3){
        dispatch(abrirModal({ title:"Notificación", body:"Para realizar la busqueda el nombre debe ser mas largo", close:true}))
        return;
    }
    dispatch(addActions.start())
    try {
        
        const data = await getRequest(`/accounts/search-others?search=${values.nombre}&pais=${values.pais}`)
        if(data.data.length > 0){
            dispatch(addActions.success(data.data))
        }else{
            dispatch(abrirModal({ title:"Notificación", body:"No se encontraron coincidencias", close:true}))
            dispatch(addActions.success([]))
        }
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const borrarCuenta = id => async (dispatch, getState) => {
    dispatch(delActions.start())
    const state = getState()
    try {
        const data = await deleteRequest(`/accounts`, id)
        dispatch(delActions.success(data.data.id))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        dispatch(abrirModal({ title:"Notificación", body:"El registro se borró con éxito", close: true}))
    } catch (error) {
        dispatch(delActions.error(error))
        dispatch(abrirModal({ title:"Notificación", body: error.data.errors, close: true}))

    }
}
