import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import './CopyRight.scss';

function CopyRight() {
    return (
        <div className="footer-wrapper-copytext">
            <>
                <div className="footer-copyright-text">
                    <div className="copyright-link">
                        <Grid container justify="center" alignItems="center"  >
                            <Typography variant="body1" color="primary">
                                <span>© Copyright 2020. Todos los derechos reservados </span>
                                <span>-</span>
                            </Typography>
                            <Typography variant="body2" color="primary"><a style={{ cursor: 'pointer'}}  target="_blank" href="https://aguacate.s3-sa-east-1.amazonaws.com/POLITICAS+DE+PRIVACIDAD.pdf">Términos y Condiciones</a></Typography>
                        </Grid>
                    </div>
                </div>
            </>
        </div>
    );
}

export default CopyRight;

