import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Typography, Grid, Paper, Box, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import RadioButton from './RadioButton';
import Loading from '../components/Loading'
import { paises } from '../services/utils'
import { buscarCliente, buscarClienteNombre } from '../ducks/Clientes'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'tipo_id',
    'pasaporte',
    'rut',
    'codigo',
    'telefono',
    'email',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.busqueda && values.busqueda.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  if (values.numero_cuenta && values.numero_cuenta.length !== 20){
    errors.numero_cuenta = "Cuenta inválida"
  }

  if (values.telefono && values.telefono.length <= 7 ){
      errors.telefono = "Telefono inválido"
  }

  if ( !checkRut(values.rut) ) {
    errors.rut = "Rut Inválido"
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Corre Electrónico Inválido'
  }
  return errors
}


const checkRut = (rut = "") =>  {
    
    // Despejar Puntos
    var valor = rut.replace(/\./g,'');
    // Despejar Guión
    valor = valor.replace(/\-/g,'');
    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
    rut = cuerpo + '-'+ dv
    
    if(cuerpo.length < 7) { 
        return false;  
    }
  
    var suma = 0;
    var multiplo = 2;
    
    // Para cada dígito del Cuerpo
    for( var i=1;i<=cuerpo.length;i++) {    
        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * valor.charAt(cuerpo.length - i);
        // Sumar al Contador General
        suma = suma + index;
        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { 
            multiplo = multiplo + 1; 
        } else { 
            multiplo = 2; 
        }
    }
    
    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);
    
    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;
    
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado != dv) { 
        return false; 
    }
  
    return true
}
  
const formatoRut = rut => {
    var textoRut = rut.replace(/[\W\s\._\-a-jA-Jl-zL-Z]+/g, '');
    var fullRut = "";
    
    if (textoRut.length <= 1) {
        if (textoRut == "k") {
            fullRut = "K";
        } else {
            fullRut = textoRut;
        }
    } else {
        var cont = 0;
        for (var i = (textoRut.length - 1); i >= 0; i--) {
            if (i == (textoRut.length - 1)) {
                if (textoRut.charAt(i) == "k") {
                    fullRut = "-K";
                } else {
                    fullRut = "-" + textoRut.charAt(i);
                }
            } else {
            if (cont == 3) {
                fullRut = "." + fullRut;
                cont = 0;
            }
            fullRut = textoRut.charAt(i) + fullRut;
            cont++;
            }
        }
    }
    return fullRut
}
  
const numeros = value => value.replace(/[^\d]/g, '')  


let FormClientes = props => {
    const { handleSubmit, tipo_id, clienteSeleccionado, change, email, nombre, busquedaAdmin, submitting, busqueda, initialValues } = props
    const dispatch = useDispatch()
    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <Paper style={{ width: '100%'}} >
                <Box p={4}>
                    <Grid container justify="center" alignItems="center"  >
                        <Typography variant="h6">Agregar Cliente</Typography>
                    </Grid>
                    <form noValidate onSubmit={handleSubmit} >

                        <Field
                            required
                            type="text"
                            name="nombre"
                            label="Nombre y Apellido"
                            component={TextField}
                            InputProps={{
                                endAdornment: busquedaAdmin && <InputAdornment position="end"><IconButton onClick={() => dispatch(buscarClienteNombre(nombre))}><SearchIcon /></IconButton></InputAdornment>,
                            }}
                        />

                        <Field 
                            required
                            component={RadioButton}
                            name="tipo_id"
                            label="Tipo de Identificación"
                            opciones={["Rut", "Pasaporte"]}
                        />

                        {tipo_id === 'Rut' &&
                            <Field
                                required
                                type="text"
                                name="rut"
                                label="RUT"
                                normalize={formatoRut}
                                component={TextField}
                            />
                        }
                        {tipo_id === "Pasaporte" &&
                            <Field
                                required
                                type="text"
                                name="pasaporte"
                                label="Pasaporte"
                                component={TextField}
                            />
                        }

                        <Field
                            required
                            type="text"
                            name="email"
                            label="Corre Electrónico"
                            component={TextField}
                            InputProps={{
                                endAdornment: busqueda && <InputAdornment position="end"><IconButton onClick={() => dispatch(buscarCliente(email))}><SearchIcon /></IconButton></InputAdornment>,
                            }}
                        />

                        <Grid item xs={12} lg={12} xl={12}>
                            <Grid container justify="center" alignItems="center"  >
                                <Grid item xs={12} lg={5} xl={5}>
                                    <Field
                                        style={{ width: '100%'}}
                                        type="select"
                                        name="codigo"
                                        label="Código"
                                        change={change}
                                        defaultValue={initialValues.codigo}
                                        component={SelectSearch}
                                        etiqueta="codigo"
                                        options={paises}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={7} xl={7}>
                                    <Field
                                        type="text"
                                        name="telefono"
                                        label="Telefono"
                                        normalize={numeros}
                                        component={TextField}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                            <Grid container justify="center" alignItems="center"  >
                                {clienteSeleccionado ? 
                                submitting ? <Loading /> :
                                    <Button variant="contained" color="primary" type="submit">
                                        Editar Cliente
                                    </Button> 
                                :
                                submitting ? <Loading /> :
                                    <Button variant="contained" color="primary" type="submit">
                                        Crear Cliente
                                    </Button> 
                                }
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>

        </Grid>
    )
}


FormClientes = reduxForm({
  form: 'cliente',
})(FormClientes);

const selector = formValueSelector('cliente')

export default connect( state => {
    const tipo_id = selector(state, 'tipo_id')
    const email = selector(state, 'email')
    const nombre = selector(state, 'nombre')
    return{
        clienteSeleccionado: state.Clientes.data.find(x => x.selected),
        tipo_id,
        email,
        nombre,
        enableReinitialize : true,
        validate,
        initialValues: {
            nombre:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).cliente,
            tipo_id:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).tipo_id,
            email:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).email,
            telefono:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).telefono,
            codigo:  state.Clientes.data.find(x => x.selected === true) ? paises.find(x => x.phone === state.Clientes.data.find(x => x.selected === true).codigo) : paises.find(x => x.phone == '56'),
            rut:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).rut,
            pasaporte:  state.Clientes.data.find(x => x.selected === true) && state.Clientes.data.find(x => x.selected === true).rut,
        }
    }
})(FormClientes)
