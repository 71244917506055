import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Notificacion from '../components/Notificacion'
import * as modalesDuck from '../ducks/Modales'

class Modal extends Component {

    handleClose = id => e => {
        const { cerrarModal, openModal } = this.props
        cerrarModal(id)
    }

    render() {
        const { modales, loading } = this.props
        return (
            modales.map((x,id) => 
                <Notificacion 
                    key={id}
                    id={x.id}
                    title={x.title}
                    body={x.body}
                    action={x.action}
                    close={x.close}
                    loading={loading}
                    handleClose={this.handleClose}
                    aceptar={x.aceptar}
                    size={x.size}
                />
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        modales: state.Modales.data,
        loading: 
            state.Usuario.fetching || 
            state.Usuarios.fetching || 
            state.Clientes.fetching || 
            state.Tasas.fetching || 
            state.Rangos.fetching || 
            state.Paises.fetching || 
            state.Transacciones.fetching || 
            state.Beneficiarios.fetching

    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...modalesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)

