import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from '@material-ui/core'
import TextField from '../TextField';

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'password_confirmation',
        'password',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
        }
    })

    if (values.password && values.password.length <= 6 ){
        errors.password = "Password debe contener al menos 6 caracteres"
    }

    if (values.password && values.password_confirmation && values.password !== values.password_confirmation ){
        errors.password = "El password y la confirmación deben coincidir"
        errors.password_confirmation = "El password y la confirmación deben coincidir"
    }

    return errors
}

let PasswordForm = props => {
    const { handleSubmit } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                type="password"
                name="password"
                label="Contraseña"
                component={TextField}
            />

            <Field
                type="password"
                name="password_confirmation"
                label="Confirmar Contraseña"
                component={TextField}
            />

            <Button fullWidth variant="contained" color="primary" type="submit">
                Reiniciar Contraseña
            </Button>
        </form>
    )
}

PasswordForm = reduxForm({
    form: 'password',
    enableReinitialize : true,
    validate,
})(PasswordForm);

export default PasswordForm
