import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Paper, Hidden, Typography } from '@material-ui/core';
import Colors from '../constants/Colors'
import AddBoxIcon from '@material-ui/icons/AddBox'

const useStyles = makeStyles({
root: {
    minWidth: 300,
    flex: 1,
    cursor: 'pointer'
},
content:{
	display: 'flex',
	flexDirection: 'row'
},
contentLeft:{
    flex:0.6,
    cursor: 'pointer'
},
icon:{
	fontSize: 70,
	color: Colors.primary
},
iconContainer:{
	alignSelf: 'center'
}
});

const TarjetaNueva = props => {
    const { title, descripcion, handleClick } = props
    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={handleClick}>
            <div className={classes.content}>
                <Hidden smDown>
                    <CardContent className={classes.iconContainer}>
                        <Paper>
                            <AddBoxIcon className={classes.icon}/>
                        </Paper>
                    </CardContent>
                </Hidden>
                <CardContent className={classes.contentLeft}>
                    <Typography variant='overline' gutterBottom>
                        <strong>
                            {title}
                        </strong>
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                        <strong>
                            {descripcion}
                        </strong>
                    </Typography>
                </CardContent>
            </div>
        </Card>
    );
}

export default TarjetaNueva
