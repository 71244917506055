import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Paper, Box, Grid, Typography } from '@material-ui/core'
import FormBusquedaFecha from '../components/FormBusquedaFecha'
import * as customersDuck from '../ducks/Clientes'

class Register extends React.Component{
    render(){
        const { handleSubmit } = this.props
        return(
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center">                                 
                <Paper style={{ maxWidth: '600px'}}>
                    <Box p={4}>
                        <FormBusquedaFecha onSubmit={ values => handleSubmit(values)} />
                    </Box>
                </Paper>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return{
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...customersDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Register)
