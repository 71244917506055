import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR, resetHOR, resetStoreHOR } from './hors.js'

const t = makeTypes('mensajes')
const ASSIGN = t('assign').single()
const RESET = t('reset').single()
const RESETSTORE = 'reset'

export const asignarMensaje = mac(ASSIGN, 'payload')
export const resetMensaje = mac(RESET)

const initialState = {
    data: [],
}

const assignReducer = createReducer(initialState, assignHOR({ ASSIGN }))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(assignReducer, resetReducer, resetStoreReducer)