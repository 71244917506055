const BancosPnm = [  
    {id: 'ATLAS BANK', nombre: 'ATLAS BANK'},
    {id: 'BAC INTERNATIONAL BANK', nombre: 'BAC INTERNATIONAL BANK'},
    {id: 'BALBOA BANK & TRUST', nombre: 'BALBOA BANK & TRUST'},
    {id: 'BANCO ALIADO', nombre: 'BANCO ALIADO'},
    {id: 'BANCO AZTECA', nombre: 'BANCO AZTECA'},
    {id: 'BANCO DAVIVIENDA PANAMA. S.A.', nombre: 'BANCO DAVIVIENDA PANAMA. S.A.'},
    {id: 'BANCO DEL PACIFICO', nombre: 'BANCO DEL PACIFICO'},
    {id: 'BANCO DELTA', nombre: 'BANCO DELTA'},
    {id: 'BANCO FICOHSA PANAMA', nombre: 'BANCO FICOHSA PANAMA'},
    {id: 'BANCO GENERAL', nombre: 'BANCO GENERAL'},
    {id: 'BANCO INT. DE COSTA RICA', nombre: 'BANCO INT. DE COSTA RICA'},
    {id: 'BANCO LAFISE PANAMA. S.A.', nombre: 'BANCO LAFISE PANAMA. S.A.'},
    {id: 'BANCOLOMBIA', nombre: 'BANCOLOMBIA'},
    {id: 'BANCO NACIONAL DE PANAMA', nombre: 'BANCO NACIONAL DE PANAMA'},
    {id: 'BANCO PICHINCHA DE PANAMA', nombre: 'BANCO PICHINCHA DE PANAMA'},
    {id: 'BANESCO', nombre: 'BANESCO'},
    {id: 'BANISI. S.A.', nombre: 'BANISI. S.A.'},
    {id: 'BANISTMO. S.A.', nombre: 'BANISTMO. S.A.'},
    {id: 'BANK OF CHINA', nombre: 'BANK OF CHINA'},
    {id: 'BBP BANK. S.A.', nombre: 'BBP BANK. S.A.'},
    {id: 'BCT BANK INTERNATIONAL', nombre: 'BCT BANK INTERNATIONAL'},
    {id: 'BI BANK', nombre: 'BI BANK'},
    {id: 'CACECHI', nombre: 'CACECHI'},
    {id: 'CAJA DE AHORROS', nombre: 'CAJA DE AHORROS'},
    {id: 'CANAL BANK', nombre: 'CANAL BANK'},
    {id: 'CAPITAL BANK', nombre: 'CAPITAL BANK'},
    {id: 'CITIBANK. N.A.', nombre: 'CITIBANK. N.A.'},
    {id: 'COEDUCO', nombre: 'COEDUCO'},
    {id: 'COOESAN', nombre: 'COOESAN'},
    {id: 'COOPEDUC', nombre: 'COOPEDUC'},
    {id: 'COOPERATIVA CRISTOBAL', nombre: 'COOPERATIVA CRISTOBAL'},
    {id: 'COOPERATIVA DE SERVICIOS MULTI', nombre: 'COOPERATIVA DE SERVICIOS MULTI'},
    {id: 'COOPERATIVA PROFESIONALES R.L', nombre: 'COOPERATIVA PROFESIONALES R.L'},
    {id: 'COOPEVE', nombre: 'COOPEVE'},
    {id: 'COOPRAC, R.L.', nombre: 'COOPRAC, R.L.'},
    {id: 'CREDICORPBANK', nombre: 'CREDICORPBANK'},
    {id: 'ECASESO', nombre: 'ECASESO'},
    {id: 'GLOBAL BANK', nombre: 'GLOBAL BANK'},
    {id: 'MERCANTIL BANK', nombre: 'MERCANTIL BANK'},
    {id: 'METROBANK', nombre: 'METROBANK'},
    {id: 'MMG BANK', nombre: 'MMG BANK'},
    {id: 'MULTIBANK', nombre: 'MULTIBANK'},
    {id: 'PRIVAL BANK', nombre: 'PRIVAL BANK'},
    {id: 'PRODUBANK', nombre: 'PRODUBANK'},
    {id: 'RESERVA FEDERAL DE EUA', nombre: 'RESERVA FEDERAL DE EUA'},
    {id: 'SCOTIABANK', nombre: 'SCOTIABANK'},
    {id: 'ST GEORGES BANK', nombre: 'ST GEORGES BANK'},
    {id: 'TELERED', nombre: 'TELERED'},
    {id: 'TOWERBANK', nombre: 'TOWERBANK'},
    {id: 'UNI BANK & TRUST INC', nombre: 'UNI BANK & TRUST INC'},
]

export default BancosPnm