import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  Hidden
} from "@material-ui/core";
import Stepper from "../../components/Stepper";
import Backdrop from "../../components/Backdrop";
import Navbar from "../Navbar/Navbar";
import Snackbar from '../../components/Snackbar'
import MenuDetalle from "../../components/Drawer";
import MenuTransacciones from "../MenuTransacciones";
import Calculadora from "../Calculadora";
import ContenidoMenu from "../../components/ContenidoMenu";
import FormularioBeneficiariosVzla from "../../components/FormularioBeneficiariosVzla";
import FormBeneficiarioChile from "../../components/FormBeneficiarioChile";
import FormBeneficiarioPnm from "../../components/FormBeneficiarioPnm";
import TarjetaUsuario from "../../components/TarjetaUsuario";
import TextField from "../../components/TextField";
import TarjetaCuentas from "../../components/TarjetaCuentas/TarjetaCuentas";
import TarjetaDetalle from "../../components/TarjetaDetalle";
import FormCuenta from "../../components/FormCuenta";
import FormCalculadora from "../../components/FormCalculadora";
import BotonFlotante from "../../components/BotonFlotante";
import Card from "../../components/Card";
import { resetClientes, agregarClienteImg, buscarClienteNombre, resetErrorCliente } from "../../ducks/Clientes";
import { agregarTransaccionesAdmin, resetError } from "../../ducks/Transacciones";
import {
  resetBeneficiarios,
  agregarBeneficiarioACliente,
  fetchBeneficiarios,
  editCuentas,
  resetErrorBeneficiario,
  agregarBeneficiarioCliente,
  quitarDeUsuario
} from "../../ducks/Beneficiarios";
import { asignarPaso } from "../../ducks/Pasos";
import Modal from '../../components/Notificacion2'
import { asignarDetalle } from "../../ducks/DetallesCambio";
import { abrirMenuDerecha } from "../../ducks/MenuDerecha";
import { resetComprobantes } from "../../ducks/Comprobante";
import { resetBeneficiariosSeleccionados, borrarBeneficiarioSeleccionado, agregarBeneficiarioSeleccionado } from "../../ducks/BeneficiariosSeleccionados";
import { Banco, currency2decimal } from "../../services/utils";
import { resetMensaje, asignarMensaje } from '../../ducks/Mensajes'
import DeleteIcon from "@material-ui/icons/Delete";
import BancosChile from '../../constants/BancosChile'
import BancosPnm from '../../constants/BancosPnm'
import BancosVzla from '../../constants/BancosVzla'
import TiposCuentaPnm from '../../constants/TiposCuentaPnm'
import TiposCuentaVzla from '../../constants/TiposCuentaVzla'
import TiposCuentasChile from '../../constants/TiposCuentasChile'
import FormUsuario from "../../components/FormUsuario";
import TiposCuenta from "../../constants/TiposCuenta";
import TiposId from "../../constants/TiposId";
import TiposIdVzla from "../../constants/TiposIdVzla";
import { paises as codigo } from '../../services/utils'
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles( theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    }
}));

const AgregarTransaccionAdmin = (props) => {
    const classes = useStyles();
    const [ formCuenta, setFormCuenta ] = useState('')
    const [ filtroCliente, setFiltroCliente ] = useState('')
    const [ filtroBeneficiario, setFiltroBeneficiario ] = useState('')
    const [ modalFormCuenta, setModalFormCuenta ] = useState(false)
    const [ usuarioSeleccionado, setUsuarioSeleccionado ] = useState(null)
    const [ cuentaSeleccionada, setCuentaSeleccionada ] = useState(null)
    const [ crearCuenta, setCrearCuenta ] = useState(false)
    const [ modalEditarCuenta, setModalEditarCuenta ] = useState(false)
    const [ modalBorrarCuenta, setModalBorrarCuenta ] = useState(false)
    const [ beneficiarioSeleccionado, setBeneficiarioSeleccionado ] = useState(null)
    const [ modalEnviar, setModalEnviar ] = useState(false)
    const clientes = useSelector((state) => state.Clientes);
    const pasoActivo = useSelector((state) => state.Pasos);
    const paises = useSelector((state) => state.Paises);
    const beneficiarios = useSelector((state) => state.Beneficiarios);
    const menuIzquierda = useSelector((state) => state.Menu.data);
    const menuDerecha = useSelector((state) => state.MenuDerecha.data);
    const detalle = useSelector((state) => state.DetalleCambio);
    const mensajes = useSelector((state) => state.Mensajes);
    const comprobante = useSelector(state => state.Comprobante.data)
    const transacciones = useSelector(state => state.Transacciones )
    const beneficiariosSeleccionados = useSelector(
        (state) => state.BeneficiariosSeleccionados
    );
    const tasas = useSelector( state => state.Tasas)
    const minimo = useSelector(state => Math.min( ...state.Tasas.data.filter(x => x.efectivo === state.DetalleCambio.data.efectivo).filter(x => ( (x.origen_id === state.DetalleCambio.data.pais_origen.id) && (x.destino_id === state.DetalleCambio.data.pais_destino.id))).map(x => x.rango.minimo) ))
    const suma = useSelector( state => state.BeneficiariosSeleccionados.data.map(x => x.origen).length > 0 ? state.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b) : 0)
    const menuEstatus = useSelector((state) => state.MenuDerecha.data);
    const loading = useSelector((state) => (state.Usuario.fetching || state.Clientes.fetching || state.Tasas.fetching || state.Beneficiarios.fetching || state.BeneficiariosSugeridos.fetching || state.Transacciones.fetching ));
    const clientesFiltrados = clientes.data.filter(x => ((x.cliente && x.cliente.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) || (x.email && x.email.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) || (x.rut && x.rut.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) ))
    const beneficiariosFiltrados = beneficiarios.data.filter(x => ((x.nombre && x.nombre.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) || (x.cuenta && x.cuenta.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) || (x.idNumero && x.idNumero.toLowerCase().indexOf(filtroBeneficiario ? filtroBeneficiario : "") === -1 ? false : true) ))
    const dispatch = useDispatch();

    const resetData = () => {
        dispatch(asignarPaso(0));
        dispatch(resetClientes());
        dispatch(resetBeneficiarios());
        dispatch(resetBeneficiariosSeleccionados());
    };

    const handlePrimerPaso = (values) => {
        dispatch(asignarDetalle(values));
        dispatch(asignarPaso(pasoActivo.data + 1));
    };

    const handleSegundoPaso = async values => {
        await dispatch(fetchBeneficiarios({id: values.id, pais: detalle.data.pais_destino && detalle.data.pais_destino.id}))
        dispatch(resetBeneficiariosSeleccionados())
        setUsuarioSeleccionado(values);
        dispatch(asignarPaso(2))
    }

    let formularioCrearCuenta
    const paisSeleccionado = paises.data.find( x => x.id === (detalle.data.pais_destino && detalle.data.pais_destino.id) )
    if(paisSeleccionado){
        switch (paisSeleccionado.nombre) {
            case "Venezuela":
                formularioCrearCuenta = 
                    <FormularioBeneficiariosVzla
                        busquedaAdmin={true}
                        cuentaSeleccionada={cuentaSeleccionada}
                        pais={paisSeleccionado.nombre}
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposIdVzla.find(x => x.id === cuentaSeleccionada.idTipo),
                            banco: cuentaSeleccionada &&  BancosVzla.find(x => x.id === cuentaSeleccionada.banco ),
                            tipoCuenta: cuentaSeleccionada &&  TiposCuentaVzla.find(x => x.id === cuentaSeleccionada.tipoCuenta ),
                            codigo: cuentaSeleccionada && BancosVzla.find(x => x.id === cuentaSeleccionada.banco ).id,
                            cuenta: cuentaSeleccionada && cuentaSeleccionada.cuenta.substr(cuentaSeleccionada.cuenta.length - 16)
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    idTipo: values.idTipo.id,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    cuenta: values.codigo + values.cuenta,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                                setCuentaSeleccionada(null)
                            }else{
                                await dispatch(agregarBeneficiarioACliente({
                                        ...values,
                                        idTipo: values.idTipo.id,
                                        tipoCuenta: values.tipoCuenta.id,
                                        banco: values.banco.id,
                                        cuenta: values.codigo + values.cuenta,
                                        country_id: paisSeleccionado.id,
                                    },paisSeleccionado.nombre,
                                    usuarioSeleccionado.id
                                    )
                                )
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
            case "Chile":
                formularioCrearCuenta =
                    <FormBeneficiarioChile
                        busquedaAdmin={true}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={{
                            ...cuentaSeleccionada,
                            rut: cuentaSeleccionada && cuentaSeleccionada.idNumero,
                            banco: BancosChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentasChile.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    idNumero: values.rut,
                                    idTipo: "Rut",
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                                setCuentaSeleccionada(null)
                            }else{
                                await dispatch(agregarBeneficiarioACliente(
                                    {
                                    ...values,
                                    idNumero: values.rut,
                                    idTipo: "Rut",
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                    },
                                    paisSeleccionado.nombre,
                                    usuarioSeleccionado.id
                                ))
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
            case "Panama":
                formularioCrearCuenta =
                    <FormBeneficiarioPnm
                        busquedaAdmin={true}
                        cuentaSeleccionada={cuentaSeleccionada}
                        initialValues={{
                            ...cuentaSeleccionada,
                            idTipo: cuentaSeleccionada && TiposId,
                            banco: BancosPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.banco) ),
                            tipoCuenta: TiposCuentaPnm.find(x => x.id === (cuentaSeleccionada && cuentaSeleccionada.tipoCuenta )),
                        }}
                        paisId={paisSeleccionado.id}
                        onSubmit={async (values) => {
                            if(cuentaSeleccionada){
                                await dispatch(editCuentas({
                                    ...values,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                }))
                                setModalEditarCuenta(false)
                                setCuentaSeleccionada(null)
                            }else{
                                await dispatch(agregarBeneficiarioACliente({
                                    ...values,
                                    tipoCuenta: values.tipoCuenta.id,
                                    banco: values.banco.id,
                                    country_id: paisSeleccionado.id,
                                },
                                paisSeleccionado.nombre,
                                usuarioSeleccionado.id
                                ))
                                setCrearCuenta(false)
                            }
                        }}
                    />
                break;
            default:
                formularioCrearCuenta = (
                <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">
                    Aún no podemos enviar dinero a este pais{" "}
                    </Typography>
                </Grid>
                );
                break;
        }
    }

    useEffect(() => {
        resetData();
    }, []);

    return (
        <Grid className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Typography variant="h5">Realiza tu cambio</Typography>
            </Grid>

            <Navbar />

            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Hidden smDown>
                <MenuTransacciones />
            </Hidden>

            <MenuDetalle>
                {menuIzquierda && <ContenidoMenu />}
                {menuDerecha &&
                beneficiariosSeleccionados.data.map((x, id) => (
                    <Card
                    styles={{ minHeight: "100px" }}
                    title={`Transferencia ${id + 1}`}
                    key={id}
                    >
                    <Typography color="textSecondary">
                        <strong>Nombre:</strong>
                        {x.nombre}
                    </Typography>
                    <Typography color="textSecondary">
                        <strong>Banco:</strong>
                        {Banco(x.banco)}
                    </Typography>
                    <Typography color="textSecondary">
                        <strong>Envía:</strong>
                        {currency2decimal(x.origen)}
                    </Typography>
                    <Typography color="textSecondary">
                        <strong>Recibe:</strong>
                        {currency2decimal(x.destino)}
                    </Typography>
                    <Typography color="textSecondary">
                        <strong>Eliminar:</strong>
                        <Button onClick={() => dispatch(borrarBeneficiarioSeleccionado(x.id))}>
                        <DeleteIcon />
                        </Button>
                    </Typography>
                    </Card>
                ))}
            </MenuDetalle>

            <Stepper
                pasoActivo={pasoActivo}
                pasos={[
                    "Calcula tu cambio",
                    "Elige el Cliente",
                    "Elige los Beneficiarios",
                    "Confirma tu cambio",
                ]}
            />

            {pasoActivo.data === 0 && (
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={8} xl={8}>
                    <Calculadora
                        codigoPromocional={true}  
                        controlEfectivo={true}
                        descripcion={false}
                        perfil="Admin"
                        handleSubmit={async (values) => {
                        handlePrimerPaso(values);
                        }}
                    />
                </Grid>
            </Grid>
            )}

            {pasoActivo.data === 1 && (
            <Grid container justify="center">
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
                        <Paper style={{ width: '100%'}} >
                            <Box p={4}>
                                <FormUsuario 
                                    initialValues={{
                                        tipo_cuenta: TiposCuenta.find(x => x.id === "persona") ,
                                        codigo: codigo.find(x => x.phone === "56"),
                                    }}
                                    busquedaAdmin={true}
                                    admin={true}
                                    handleBusqueda={async (nombre) => await dispatch(buscarClienteNombre(nombre))}
                                    onSubmit={async values => 
                                        await dispatch(agregarClienteImg({
                                            ...values,
                                            cliente: values.nombre + " " + values.apellido, 
                                            sexo: values.sexo ? values.sexo.id : null,
                                            tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                                            nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                                            pais: values.pais ? values.pais.label : null,
                                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                                            profesion: values.profesion ? values.profesion.id : null,
                                            codigo: values.codigo ? values.codigo.phone : null,
                                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                                        }))
                                    }
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    {clientes.data.length > 0 && 
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={6} xl={6} style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <TextField
                                label="Buscar"
                                input={{
                                    name:"filtroCliente",
                                    value: filtroCliente,
                                    onChange: value => setFiltroCliente(value.target.value),
                                    onBlur: () => {},
                                }}
                                meta={{
                                    error: false,
                                    touched: false,
                                }}

                            />
                        </Grid>
                    </Grid>}
                    {clientesFiltrados.length > 0 ? clientesFiltrados.map((x,id) => 
                        <Grid key={id} style={{ margin: 10 }}>
                            <TarjetaUsuario 
                                // acciones={true}
                                usuario={x}
                                handleDetalle={() => handleSegundoPaso(x)}
                            />
                        </Grid>
                    ) : <Typography variant="body1" style={{ textAlign: "center" }}>No se ha buscado ningun cliente aún, Realiza una búsqueda!</Typography>}
                </Grid>
            </Grid>
            )}

        {pasoActivo.data === 2 && (
            <Grid container justify="center">
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
                        <Paper style={{ width: '100%'}} >
                            <Box p={4}>
                                {formularioCrearCuenta}
                            </Box>                
                        </Paper>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} lg={6} xl={6}>
                    {beneficiarios.data.length > 0 && 
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={6} xl={6} style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <TextField
                                label="Buscar"
                                input={{
                                    name:"filtroBeneficiario",
                                    value: filtroBeneficiario,
                                    onChange: value => setFiltroBeneficiario(value.target.value),
                                    onBlur: () => {},
                                }}
                                meta={{
                                    error: false,
                                    touched: false,
                                }}

                            />
                        </Grid>
                    </Grid>}
                    {beneficiariosFiltrados.length > 0 ? beneficiariosFiltrados.map((x,id) => 
                        <Grid key={id} style={{ margin: 10 }}>
                            <TarjetaCuentas
                                cuenta={x}
                                buttons={[
                                    {
                                        name:"Editar",
                                        action: () => {setCuentaSeleccionada(x); setModalEditarCuenta(true)}
                                    },
                                    {
                                        name:"Borrar",
                                        action: () => {setCuentaSeleccionada(x); setModalBorrarCuenta(true)}
                                    },
                                ]}
                                handleClick={() => {
                                    if(beneficiariosSeleccionados.data.find(y => y.cuenta === x.cuenta)){
                                        dispatch(asignarMensaje(['Ya agregó una transacción a esta cuenta, debe elegir una distinta']))
                                    }else{
                                        if(detalle.data.origen/(beneficiariosSeleccionados.data.length+1) >= minimo){
                                            setBeneficiarioSeleccionado(x);
                                            setModalEnviar(true)
                                        }else{
                                            dispatch(asignarMensaje([`No puede agregar mas de ${beneficiariosSeleccionados.data.length} transacciones por el cambio de ${detalle.data.origen}. Verifica el carro de compras`]))
                                        }   
                                    }
                                }}
                            />
                        </Grid>) 
                        : 
                        <Typography variant="body1" style={{ textAlign: "center", margin: 10 }}>No se han encontrado cuentas, agrega algún beneficiario!</Typography>
                    }
                </Grid>
                {!menuDerecha && beneficiariosSeleccionados.data.length > 0 && (
                <BotonFlotante
                    handleAction={() =>
                    dispatch(abrirMenuDerecha(!menuEstatus))
                    }
                    count={beneficiariosSeleccionados.data.length}
                />
                )}
            </Grid>
        )}

        {pasoActivo.data === 3 && (
            <Grid>
                <Typography variant="body1" style={{ marginLeft: 20 }}>Cliente</Typography>
                <TarjetaUsuario 
                    usuario={usuarioSeleccionado}
                />
                {beneficiariosSeleccionados.data.map((x, id) => (
                    <Grid key={id}>
                        <Grid>
                            <Typography variant="h6">Transacción {id+1}</Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="body1" style={{ marginLeft: 20 }}>Beneficiario</Typography>
                        </Grid>
                        <Grid style={{ margin: 10 }}>
                            <TarjetaCuentas 
                                cuenta={x}
                            />
                        </Grid>
                        <Grid>
                            <Typography variant="body1" style={{ marginLeft: 20 }}>Detalle</Typography>
                        </Grid>
                        <Grid style={{ margin: 10 }}>
                            <TarjetaDetalle 
                                transaccion={x}
                            />
                        </Grid>
                    </Grid>))}
            </Grid>
        )}

        {pasoActivo.data !== 0 && (
            <Grid
                container
                justify="center"
                alignItems="center"
            >
                <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if(pasoActivo.data === 3){
                        dispatch(resetBeneficiariosSeleccionados())
                    }
                    dispatch(asignarPaso(pasoActivo.data - 1)) 
                    setFiltroBeneficiario("")
                    setFiltroCliente("")}
                }
                style={{ margin: "10px" }}

                >
                Volver
                </Button>
                {pasoActivo.data === 3 && (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalFormCuenta(true)}
                    style={{ margin: "10px" }}
                    >
                        Confirmar Transacción
                    </Button>
                )}
            </Grid>
        )}

        <Modal
            open={crearCuenta}
            title="Crear Cuenta"
            close={true}
            handleClose={() => setCrearCuenta(false)}
        >
            {formularioCrearCuenta}

        </Modal>

        <Modal
            open={modalEnviar}
            title="Confirmación"
            close={true}
            handleClose={() => setModalEnviar(false)}d
        >
            <div>
                <Typography variant="body1">{` Ingresa cuanto deseas enviar a ${beneficiarioSeleccionado && beneficiarioSeleccionado.nombre} - ${Banco(beneficiarioSeleccionado && beneficiarioSeleccionado.banco)}`}</Typography>
                <Typography variant="h6"><strong>{`(Saldo Disponible: ${currency2decimal(detalle.data.origen - suma)}):`}</strong></Typography>
                <FormCalculadora 
                    limitMaximo={detalle.data.origen}
                    calculadoraSec={true}
                    paisDisabled={true}
                    initialValues={{
                        pais_origen: detalle.data.pais_origen,
                        pais_destino: detalle.data.pais_destino,
                        efectivo: detalle.data.efectivo,
                        tasa: detalle.data.tasa
                    }}
                    onSubmit={ async values => {
                        dispatch(agregarBeneficiarioSeleccionado({ ...beneficiarioSeleccionado, ...values, origen: parseFloat(values.origen), destino: parseFloat(values.destino)})); 
                        await dispatch(agregarBeneficiarioCliente(beneficiarioSeleccionado.id, usuarioSeleccionado.id))
                        setModalEnviar(false)}} 
                    tasas={tasas}
                />
            </div>
        </Modal>


        <Modal
            open={modalBorrarCuenta}
            title="Confirmación"
            close={true}
            handleClose={() => setModalBorrarCuenta(false)}
            action={() => {dispatch(quitarDeUsuario(cuentaSeleccionada.id));setModalBorrarCuenta(false)}}
        >
            ¿Está seguro de que desea desvincular esta cuenta?

        </Modal>

        <Modal
            open={modalEditarCuenta}
            title="Editar Cuenta"
            close={true}
            handleClose={() => {setModalEditarCuenta(false); setCuentaSeleccionada(null)}}
        >
            {formularioCrearCuenta}
        </Modal>

        <Modal
        open={modalFormCuenta}
        title="Elegir Cuenta"
        close={true}
        handleClose={() => setModalFormCuenta(false)}
        >
            <FormCuenta
                pais={detalle.data.pais_origen && detalle.data.pais_origen.id}
                onSubmit={async values => {
                    await dispatch(agregarTransaccionesAdmin({ ...values, cliente: usuarioSeleccionado }, true))
                    setFormCuenta({ ...values, cliente: usuarioSeleccionado })
                    setModalFormCuenta(false)
                }}
            />

        </Modal>

      <Modal
       open={comprobante.length > 0 ? true : false}
       title="Coincidencias"
       close={true}
       aceptar="Agregar Igual"
       loading={transacciones.fetching}
       handleClose={() => dispatch(resetComprobantes())}
       action={ async () => {
           await dispatch(agregarTransaccionesAdmin(formCuenta, false))
           dispatch(resetComprobantes())
           setModalFormCuenta(false)
        }}
      >
          {comprobante.length > 0 && comprobante.map(x =>
            <ul key={x.id}>
                <li>Cliente: {x.customer ? x.customer.cliente : (x.user ? x.user.nombre+" "+x.user.apellido : "-")}</li>
                <li>Beneficiario: {x.account ? x.account.nombre : ""}</li>
                <li>CantidadPesos: {x.cantidadPesos ? x.cantidadPesos : ""}</li>
                <li>Fecha: {x.created_at ? moment(x.created_at).format('DD-MM-YYYY') : ""}</li>
            </ul>
        )}


      </Modal>

      <Snackbar
        open={transacciones.error && transacciones.error.length > 0 ? true : false}
        handleClose={() => dispatch(resetError())}
        message={transacciones.error}
      />

      <Snackbar
        open={clientes.error && clientes.error.length > 0 ? true : false}
        handleClose={() => dispatch(resetErrorCliente())}
        message={clientes.error}
      />

      <Snackbar
        open={beneficiarios.error && beneficiarios.error.length > 0 ? true : false}
        handleClose={() => dispatch(resetErrorBeneficiario())}
        message={beneficiarios.error}
      />

      <Snackbar
        open={mensajes.data.length > 0 ? true : false}
        handleClose={() => dispatch(resetMensaje())}
        message={mensajes.data}
      />
      <Backdrop 
        open={loading}
      />
    </Grid>
  );
};

export default AgregarTransaccionAdmin;
