import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, delFetch, editFetch, resetErrorHOR, resetHOR, addFetch, addFetchUnShift, resetStoreHOR } from './hors.js'
import { getRequest, postRequest, postRequestImage, putRequest, deleteRequest } from '../services/api'
import { abrirModal, editarModal, cerrarModal } from './Modales'
import { asignarPaso } from './Pasos'
import { resetBeneficiarios } from './Beneficiarios'
import { resetClientes } from './Clientes'
import { resetBeneficiariosSeleccionados } from './BeneficiariosSeleccionados'
import { fetchEstados } from './Estados'
import { validateFile } from '../services/utils'
import { editUsuarioActual } from './Usuario'
import { asignarMensaje } from './Mensajes'
import { calcularBalance } from './Balances'
import { verificarComprobante } from './Comprobante'
import { fetchTasas } from './Tasas.js'

const t = makeTypes('transacciones')
const FETCH = t('fetch').async()
const ADD = t('add').async()
const ADD_UNSHIFT = t('add-unshift').async()
const DEL = t('del').async()
const EDIT = t('edit').async()
const RESET = t('reset').single()
const RESETERROR = t('reset-error').single()
const RESETSTORE = 'reset'

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)
const addUnshiftActions = asyncMac(ADD_UNSHIFT)
const delActions = asyncMac(DEL)
const editActions = asyncMac(EDIT)
export const resetTransacciones = mac(RESET)
export const resetError = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const addUnshiftReducer = createReducer(initialState, addFetchUnShift( ADD_UNSHIFT ))
const delReducer = createReducer(initialState, delFetch( DEL ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, addUnshiftReducer, addReducer, delReducer, editReducer, resetErrorReducer, resetReducer, resetStoreReducer)

export const transaccionClientes = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        await dispatch(fetchEstados())
        await dispatch(fetchTasas('Cliente'))
        const state = getState()
        if(state.Usuario.data[0].first_session){
            await dispatch(editUsuarioActual({ first_session: false }))
        }
        var length = values.imagen.length
        for (var x = 0; x < length; x++) {
            validateFile(values.imagen[x])
        }

        const tasas = state.Tasas.data
        const tasaSelect = state.DetalleCambio.data.tasa.id
        if(!tasas.find(x => x.id === tasaSelect)){
            throw { data:{ errors: ["La tasa de cambio de tu cotización ya no esta disponible, lo sentimos. Te invitamos a cotizar nuevamente"] }}
        }
        const transacciones = state.BeneficiariosSeleccionados.data.map( x => ({
            totalPesos: state.DetalleCambio.data.origen,
            promotional_code: state.DetalleCambio.data.codigo ? state.DetalleCambio.data.codigo : null,
            cantidadPesos: x.origen,
            bolivares: x.destino,
            diferencia:"Completa",
            tasa: x.tasa.tasa,
            account_id: x.id,
            exchange_rate_id: x.tasa.id,
        }))
        await postRequestImage('/clpbstransactions/add-transaction-user', { 
            transactions: transacciones, images: values.imagen
        })
        dispatch(addActions.success([]))
        dispatch(asignarPaso(0))
        dispatch(resetClientes())
        dispatch(resetBeneficiarios()) 
        dispatch(asignarMensaje("Hemos recibido tu transacción. Gracias por preferirnos!"))
        dispatch(resetBeneficiariosSeleccionados())    
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const editarEstadoTransaccion = (id,values) => async (dispatch, getState) => {
    dispatch(delActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await putRequest('/clpbstransactions', id, { ...values, estatus: values.estado, state_id: state.Estados.data.find(x => x.nombre === values.estado).id })
        await postRequest('/clpbstransactions/verificar', { ...values, id, emailOperador: state.Usuario.data[0].email, idOperador: state.Usuario.data[0].id })
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        dispatch(delActions.success(data.data.id))
    } catch (error) {
        dispatch(abrirModal({title:"Notificación", body:"Ocurrió un error, contacte al admin", close:true}))
        dispatch(delActions.error(error))
    }

}

export const editarTransaccion = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/clpbstransactions`, values.id, values)
        dispatch(asignarMensaje("La transacción fue editada"))
        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}   

export const editarTransacciones = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await postRequest(`/clpbstransactions/edit-transactions`, values)
        data.data.map(transaccion => {
            dispatch(editActions.success(transaccion))
        })
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}   

export const fetchTransacciones = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest('/clpbstransactions')
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}


export const busquedaAvanzadaTransacciones = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await postRequest('/clpbstransactions/busqueda-avanzada', values)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const buscarTransacciones = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await postRequest('/clpbstransactions/search', { ...values, state_id: state.Estados.data.find(x => x.nombre === values.state_id ).id})
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error))
    }
}

export const transaccionesAsociados = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await postRequest('/clpbstransactions/busqueda-avanzada', { ...values, operario_id: state.Usuario.data[0].id, state_id: state.Estados.data.find(x => x.nombre === values.state_id ).id})
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error))
    }
}

export const fetchMisTransacciones = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await postRequest('/clpbstransactions/mis-transacciones', { ...values, state_id: state.Estados.data.find(x => x.nombre === values.state_id ).id })
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error))
    }
}

export const crearTransacciones = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        await postRequest('/clpbstransactions', {
            totalPesos: values.detalle.data.origen,
            cantidadPesos: values.detalle.data.origen,
            bolivares: values.detalle.data.destino,
            estatus: "Pendiente",
            diferencia:"Completa",
            state_id: state.Estados.data.find(x => x.nombre === "Pendiente").id,
            tasa: values.detalle.data.tasa.tasa,
            account_id: values.beneficiarios.data[0].id,
            exchange_rate_id: values.detalle.data.tasa.id,
            customer_id: values.clientes.data[0].id,
            operario_id: state.Usuario.data[0].id,
            user_id: state.Usuario.data[0].id,
        })
        dispatch(asignarPaso(0))
        dispatch(resetClientes())
        dispatch(resetBeneficiarios())
        dispatch(addActions.success([]))
        dispatch(asignarMensaje("La transacción fue agregada con exito"))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const agregarTransaccionesAdmin = (values, verificar) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const state = getState()
        if(verificar){
            await dispatch(verificarComprobante(values))
            const state = getState()
            const comprobante = state.Comprobante.data
            if(comprobante.length > 0){
                dispatch(addActions.success([]))   
                return
            }
        }
        const transacciones = state.BeneficiariosSeleccionados.data.map( x => ({
            comprobante: values.comprobante,
            formaPago: values.cuenta,
            totalPesos: state.DetalleCambio.data.origen,
            promotional_code: state.DetalleCambio.data.codigo ? state.DetalleCambio.data.codigo : null,
            cantidadPesos: x.origen,
            bolivares: x.destino,
            estatus:"Pendiente",
            diferencia:"Completa",
            tasa: x.tasa.tasa,
            account_id: x.id,
            exchange_rate_id: x.tasa.id,
            customer_id: values.cliente.id,
            operario_id: state.Usuario.data[0].id,
            operador: state.Usuario.data[0].email
        }))

        await postRequest('/clpbstransactions/add-transaction-admin', { 
            transactions: transacciones, 
            total: state.DetalleCambio.data.origen,
            cuenta: values.cuenta
        })
        dispatch(asignarPaso(0))
        dispatch(resetClientes())
        dispatch(resetBeneficiarios())   
        dispatch(resetBeneficiariosSeleccionados())
        dispatch(asignarMensaje("La transacción fue agregada"))
        dispatch(addActions.success([]))        
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const transaccionesAdmin = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        await dispatch(fetchEstados())
        const state = getState()
        dispatch(editarModal({ id: state.Modales.data[0].id, body:"Agregando Transacciones"}))
        await Promise.all(state.BeneficiariosSeleccionados.data.map( async x => {
            return {
                transacciones: await postRequest('/clpbstransactions/agregar', {
                comprobante: values.comprobante,
                formaPago: values.cuenta,
                totalPesos: state.DetalleCambio.data.origen,
                cantidadPesos: x.origen,
                bolivares: x.destino,
                state_id: state.Estados.data.find(x => x.nombre === "Pendiente").id,
                estatus:"Pendiente",
                diferencia:"Completa",
                tasa: x.tasa.tasa,
                account_id: x.id,
                exchange_rate_id: x.tasa.id,
                customer_id: state.Clientes.data[0].id,
                operario_id: state.Usuario.data[0].id,
                operador: state.Usuario.data[0].email
        })}}))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
        dispatch(abrirModal({title:"Notificación", body:"Transacción agregada con éxito", close: true}))
        dispatch(asignarPaso(0))
        dispatch(resetClientes())
        dispatch(resetBeneficiarios())   
        dispatch(resetBeneficiariosSeleccionados())   
        dispatch(addActions.success([]))
    } catch (error) {
        console.log(error)
        dispatch(abrirModal({title:"Notificación", body:"Ocurrió un error, consulte al admin", close: true}))
        dispatch(addActions.error(error))        
    }    
}

export const rechazarTransaccion = (transaccion, values) => async (dispatch, getState) => {
    dispatch(delActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await postRequest('/clpbstransactions/rechazo', { ...values, id: transaccion.id, estado: values.estado, state_id: state.Estados.data.find(x => x.nombre === "Rechazada").id } )
        await postRequest('/audits', { table_name: "Clpbstransacciones", row: data.data.id, user_id: state.Usuario.data[0].id, description: values.descripcion, action_triggered: 'Rechazo' } )
        dispatch(delActions.success(data.data.id))
    } catch (error) {
        console.log(error)
        dispatch(delActions.error(error))
    }
}

export const fetchTransaccionesCajeros = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/clpbstransactions/transacciones-cajeros?cuenta=${values.cuenta ? values.cuenta : ""}&fecha_comienzo=${values.fecha_comienzo ? values.fecha_comienzo : ""}&fecha_final=${values.fecha_final ? values.fecha_final : ""}&estado=${values.estado ? values.estado : ""}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        console.log(error)
        dispatch(fetchActions.error(error))
    }
}

export const realizarTransaccion = (id,values) => async (dispatch, getState) => {
    dispatch(delActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        const data = await putRequest('/clpbstransactions', id, { ...values, estatus: values.estado, state_id: state.Estados.data.find(x => x.nombre === values.estado).id })
        await dispatch(calcularBalance({id: id, workingaccount: values.workingaccount_id }))
        dispatch(delActions.success(data.data.id))
        dispatch(asignarMensaje("La transacción fue marcada como realizada"))
    } catch (error) {
        dispatch(delActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const crearTransaccion = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await postRequest(`/clpbstransactions`, values)
        dispatch(addActions.success(data.data))
    } catch (error) {
        dispatch(delActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const agregarSolicitudDivisas = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const transaccion = {
            totalPesos: values.detalle.origen,
            cantidadPesos: values.detalle.origen,
            bolivares: values.detalle.destino,
            tasa: values.detalle.tasa,
            account_id: values.beneficiario.id,
            user_id: values.usuario.id,
            pais: values.detalle.pais_origen.id,
            pais_destino: values.detalle.pais_destino.id
        }
        const data = await postRequest(`/clpbstransactions/money-request`, transaccion)
        dispatch(addActions.success(data.data))        
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const asignarPagoRecibido = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {

        const transaccion = {
            comprobante: values.comprobante,
            formaPago: values.cuenta.id,
            totalPesos: values.monto,
            cantidadPesos: values.monto,
            bolivares: values.transaccion.bolivares,
            tasa: values.transaccion.tasa,
            account_id: values.transaccion.account_id,
            exchange_rate_id: values.transaccion.exchange_rate_id.id,
            customer_id: values.usuario.id,
        }
        const data = await postRequestImage('/clpbstransactions/add-transaction-to-request', { 
            transactions: {...transaccion, solicitud: values.transaccion.id },
            images: values.imagen,
        })

        if(state.Estados.data.find(x => x.id === data.data.state_id).nombre === "Solicitud Cerrada"){
            dispatch(delActions.success(data.data.id))
            return
        }

        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }    
}

export const pagoSolicitud = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    await dispatch(fetchEstados())
    const state = getState()
    try {
        
        const data = await postRequestImage('/clpbstransactions/request-payment', { 
            transactions: {...values, solicitud: values.transaccion.id },
            images: values.imagen,
        })

        if(state.Estados.data.find(x => x.id === data.data.state_id).nombre === "Solicitud Cerrada"){
            dispatch(delActions.success(data.data.id))
            return
        }

        dispatch(editActions.success(data.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }    
}