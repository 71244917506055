import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useSelector, useDispatch } from "react-redux";
import { abrirMenuDerecha } from "../ducks/MenuDerecha";
import { abrirMenu } from "../ducks/Menu";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const openRight = useSelector(state => state.MenuDerecha.data);
  const openLeft = useSelector(state => state.Menu.data)
  const openTop = false
  const openBottom = false
  const dispatch = useDispatch();

  const toggleDrawer = (side) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if(side === 'right'){
        dispatch(abrirMenuDerecha(false))
    }
    if(side === 'left'){
        dispatch(abrirMenu(false))
    }
    if(side === 'top'){
    }
    if(side === 'bottom'){
    }
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
        {props.children}
    </div>
  );

  const fullList = side => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      {props.children}
    </div>
  );

  return (
    <div>
      <Drawer open={openLeft} onClose={toggleDrawer('left')}>
        {sideList('left')}
      </Drawer>
      <Drawer anchor="top" open={openTop} onClose={toggleDrawer('top')}>
        {fullList('top')}
      </Drawer>
      <Drawer anchor="bottom" open={openBottom} onClose={toggleDrawer('bottom')}>
        {fullList('bottom')}
      </Drawer>
      <Drawer anchor="right" open={openRight} onClose={toggleDrawer('right')}>
        {sideList('right')}
      </Drawer>
    </div>
  );
}