import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import { Link } from "react-router-dom";
import { Banco, currency8decimal } from '../../services/utils'
import { editWorkingaccount, borrarWorkingaccount } from '../../ducks/Workingaccounts'
import { resetMensaje } from '../../ducks/Mensajes'

const TablaCuentasTrabajo = props => {
    const { 
        ver,
        nombre,
        banco,
        cuenta,
        pais,
        profit,
        asignadas,
        active,
        id,
        cupos,
        cedula,
        acciones,
        agregar,
        editar,
        borrar,
        activar,
        handleEditar,
        handleBorrar,
        handleActivar
    } = props

    const workingaccounts = useSelector(state => state.Workingaccounts.data)
    const paises = useSelector(state => state.Paises.data)

    return(
        <Tabla
            agregar={agregar}
            columnas={[
                ... ver ? [{ title: 'Ver', field: 'ver', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... asignadas ? [{ title: 'Transacciones Asignadas', field: 'asignadas', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... acciones ? [{ title: 'Acciones', field: 'accion', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... id ? [{ title: 'ID', field: 'id', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... nombre ? [{ title: 'Nombre', field: 'nombre', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... cedula ? [{ title: 'Cedula', field: 'cedula', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... banco ? [{ title: 'Banco', field: 'banco', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... cuenta ? [{ title: 'Cuenta', field: 'cuenta', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... pais ? [{ title: 'Pais', field: 'pais', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... profit ? [{ title: 'Ganancia', field: 'profit', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... cupos ? [{ title: 'Cupo MB', field: 'cupo_mb', 
                cellStyle: {
                    width: '10px',
                },headerStyle: {
                    width: '10px',
                }}] : [],
                ... cupos ? [{ title: 'Cupo OB', field: 'cupo_ob', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ... active ? [{ title: 'Activo', field: 'active', 
                    cellStyle: {
                        width: '10px',
                    },headerStyle: {
                        width: '10px',
                }}] : [],
                ]}
            data={workingaccounts.map(x => ({
                ...x,
                asignadas: x.pendientes.length,
                cedula: x.idTipo || x.idNumero ? (x.idTipo ? x.idTipo : "-")+"-"+(x.idNumero ? x.idNumero : "-") : "Sin ID",
                active: x.active ? "SI" : "NO",
                banco: x.banco ? Banco(x.banco) : "",
                cuenta: x.tipoCuenta +"-"+ x.cuenta,
                cupo_mb: currency8decimal(x.cupo_mb),
                cupo_ob: currency8decimal(x.cupo_ob),
                ver: <Link to={`/cajeros/${x.id}/pendientes`}>Ver</Link>,
                pais: paises.find(y => y.id === x.country_id) ? paises.find(y => y.id === x.country_id).nombre : "",
                accion: 
                <BotonMenu 
                    registro={x}
                    editar={editar}
                    activar={activar}
                    borrar={borrar}
                    handleBorrar={handleBorrar}
                    handleEditar={handleEditar}
                    handleActivar={handleActivar}
                />,
            }))} 
        />
    )
} 

export default TablaCuentasTrabajo
