import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Grid } from '@material-ui/core'
import Tabla from '../components/Tabla'
import CheckIcon from '@material-ui/icons/Check';
import BotonMenu from '../components/BotonMenu'
import TablaBeneficiarios from '../containers/beneficiarios/tablaBeneficiarios'
import { asignarCliente } from '../ducks/Clientes'
import { fetchBeneficiarios } from '../ducks/Beneficiarios'


const TablaClientes = props => {

    const { seleccionar, editar, borrar, activar, asociaciones, acciones, abrirModal, seleccionarCliente, resetClientes } = props
    const clientes = useSelector( state => state.Clientes)
    const pasoActivo = useSelector( state =>  state.Pasos )
    const detalle = useSelector( state =>  state.DetalleCambio )
    const dispatch = useDispatch()
    
    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <Grid item xs={12}>
                <Tabla
                    loading={clientes.fetching}
                    columnas={[
                        ... seleccionar ? [{ title: 'Seleccionar', field: 'seleccionar', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... acciones ? [{ title: 'Acción', field: 'accion', 
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        { title: 'Nombre', field: 'cliente', 
                            cellStyle: {
                            textAlign:"center"
                            },headerStyle: {
                            textAlign: "center",
                        }},
                        { title: 'ID', field: 'rut',
                            cellStyle: {
                            textAlign:"center"
                            },headerStyle: {
                            textAlign: "center",
                        }},
                        { title: 'Correo', field: 'email', 
                            cellStyle: {
                            textAlign:"center"
                            },headerStyle: {
                            textAlign: "center",
                            
                        }},
                        { title: 'Telefono', field: 'telefono', 
                            cellStyle: {
                            textAlign:"center"
                            },headerStyle: {
                            textAlign: "center",
                        }},
                        ]}
                        data={clientes.data.map(x => ({
                            ...x,
                            rut: x.tipo_id ? x.tipo_id + "-"+ x.rut : x.rut,
                            accion: 
                                <BotonMenu 
                                    asociaciones={asociaciones}
                                    editar={editar}
                                    borrar={borrar}
                                    activar={activar}                                
                                    handleAsociaciones={() => {seleccionarCliente(x.id);fetchBeneficiarios({email: x.email});abrirModal({title:"Cuentas Asociadas", body:<TablaBeneficiarios desvincularCliente={true} />, close: true })} }
                                    //handleEditar={() => {seleccionarCliente(x.id); abrirModal({title:"Formulario Rango", body:<FormularioRangos />, close: true })}} 
                                    handleActivar={() => {resetClientes({ ...x, active: !x.active })}} registro={x} 
                                    handleBorrar={() => {abrirModal({ title: "Confirmación", body:"¿Está seguro que desea borrar el registro?", action: () => {resetClientes(x.id)}, close: true})}}/>,
                                    seleccionar: <IconButton onClick={ () => dispatch(asignarCliente(x, pasoActivo.data, detalle.data.pais_destino.id )) }><CheckIcon /></IconButton>
                    }))} 
                />
            </Grid>
        </Grid>
    )
}

export default TablaClientes
