import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { currency2decimal } from '../../services/utils'
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div style={{ padding: '10', backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <p style={{ color: payload[0] ? payload[0].fill : "" }}>{`${moment(label).format('DD-MM-YYYY')}`}</p>
          <p style={{ color: payload[0] ? payload[0].fill : "" }}>{`${payload[0] ? currency2decimal(payload[0].value) : ""}`}</p>
        </div>
      );
    }
  
    return null;
}

const Xychart = props => {
    const { data, consultarTransacciones } = props
    return (
        <div style={{ width: '100%', height: 400 }}>
            <p>Dinero recibido en Origen</p>
            <div style={{ width: '100%', height: 150 }}>
                <ResponsiveContainer>
                    <BarChart syncId="anyId" width={200} height={150} data={data}>
                        <XAxis dataKey="fecha" stroke="#8884d8" />
                        <YAxis tickFormatter={name => currency2decimal(name)} width={100} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <CartesianGrid />
                        <Bar dataKey="sum_origen" fill="#003520" barSize={30} onClick={values => consultarTransacciones(values)} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <p>Dinero enviado a Destino</p>
            <div style={{ width: '100%', height: 150 }}>
                <ResponsiveContainer>
                    <BarChart syncId="anyId" width={500} height={150} data={data}>
                        <XAxis dataKey="fecha" stroke="#8884d8" />
                        <YAxis tickFormatter={name => currency2decimal(name)} width={100} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <CartesianGrid />
                        <Bar dataKey="sum_destino" fill="#003520" barSize={30} onClick={values => consultarTransacciones(values)} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default Xychart;
