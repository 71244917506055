import React from 'react';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../img/logo.png'
import loginImage from '../../img/envio de dinero.jpg'
import { history } from '../../index';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
    },
    image: {
        backgroundImage: `url("${loginImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const AuthLayout = props => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Grid className={classes.paper}>
                    <a style={{ cursor: 'pointer' }} onClick={() => history.push("/")}>
                        <img style={{
                            height: 150, display: 'flex',
                            alignItems: 'center', marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '10px'
                        }} src={logo} alt="logo" />   
                    </a>
                    {props.children} 
                    <Box mt={5}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'Copyright © '}
                            <Link color="inherit" href="https://www.aguacatecambios.com/">
                                Aguacatecambios
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AuthLayout