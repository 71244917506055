import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Paper, Box, Grid, Typography } from '@material-ui/core'

import FormUsuario from "../../components/FormUsuario"
import TarjetaUsuario from "../../components/TarjetaUsuario"
import Backdrop from "../../components/Backdrop"
import Snackbar from "../../components/Snackbar"
import TextField from "../../components/TextField"
import { agregarUsuario, buscarUsuarios, selectUsuario, resetErrorUsuarios } from '../../ducks/Usuarios'
import { asignarPaso } from '../../ducks/Pasos'

const ElegirClienteMayorista = props => {
    const [ filtroCliente, setFiltroCliente ] = useState('')
    const dispatch = useDispatch()

    const usuarios = useSelector(state => state.Usuarios )
    const perfiles = useSelector(state => state.Profiles.data )
    const error = useSelector(state => state.Usuarios.error)
    const pasoActivo = useSelector(state => state.Pasos.data )
    const loading = useSelector(state => (state.Usuarios.fetching || state.Usuarios.adding || state.Usuarios.editing || state.Usuarios.deleting) )
    const usuariosFiltrados = usuarios.data
        .filter(user => ((user.nombre && user.nombre.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) 
            || (user.email && user.email.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) 
            || (user.rut && user.rut.toLowerCase().indexOf(filtroCliente ? filtroCliente : "") === -1 ? false : true) ))


    const handleContinue = id => {
        dispatch(selectUsuario(id))
        dispatch(asignarPaso( pasoActivo + 1 ))
    }

    useEffect(() => {
        return () => dispatch(resetErrorUsuarios())
    },[])

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper>
                    <Box p={4}>
                        <FormUsuario
                            handleBusqueda={value => {dispatch(buscarUsuarios({nombre: value, profile: "Mayorista"}))}}
                            proceso={true}
                            admin={true}
                            onSubmit={async values => {
                                await dispatch(agregarUsuario({
                                    ...values,
                                    sexo: values.sexo ? values.sexo.id : null,
                                    tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                                    password: Math.random().toString(36),
                                    nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                                    pais: values.pais ? values.pais.label : null,
                                    tipo_id: values.tipo_id ? values.tipo_id.id : null,
                                    profesion: values.profesion ? values.profesion.id : null,
                                    codigo: values.codigo ? values.codigo.phone : null,
                                    profile_id: perfiles.find(perfil => perfil.nombre === "Mayorista").id,
                                    images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                                }))
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {usuarios.data.length > 0 && <TextField
                    label="Buscar"
                    input={{
                        name:"filtroCliente",
                        value: filtroCliente,
                        onChange: value => setFiltroCliente(value.target.value),
                        onBlur: () => {},
                    }}
                    meta={{
                        error: false,
                        touched: false,
                    }}
                />}
                {usuariosFiltrados.length > 0 ? usuariosFiltrados.map((x,id) => 
                    <Box key={id} pb={1}>
                        <TarjetaUsuario 
                            usuario={x}
                            handleDetalle={() => handleContinue(x.id)}
                        />
                    </Box>
                ) : <Typography variant="body1" style={{ textAlign: "center" }}>Realiza una busqueda!</Typography>}
            </Grid>

            <Backdrop open={!!loading} />
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorUsuarios()) }
                message={error && error.map( er => er)}
            />
        </Grid>

    )
}

export default ElegirClienteMayorista