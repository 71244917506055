import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AuthLayout from '../../components/Auth/AuthLayout'
import FormPassword from '../../components/Auth/FormPassword'
import Backdrop from '../../components/Backdrop'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import { resetPassword, resetErrorUsuario } from '../../ducks/Usuario'
import { getQueryVariable } from '../../services/utils'
import { history } from '../../index'

const PasswordReset = props => {
    const didMountRef = useRef(false)
    const [ modalPassword, setModalPassword ] = useState(false)
    const loading = useSelector(state => state.Usuario.fetching)
    const changed = useSelector(state => state.Usuario.fetched)
    const error = useSelector(state => state.Usuario.error)
    const access_token = getQueryVariable("access-token") ? getQueryVariable("access-token") : "";
    const client = getQueryVariable("client") ? getQueryVariable("client") : "";
    const uid = getQueryVariable("uid") ? decodeURIComponent(getQueryVariable("uid")) : "";
    const dispatch = useDispatch()

    useEffect(() => {
        if(changed && didMountRef.current){
            setModalPassword(true)
            setTimeout(() => history.push('/login'), 3000)
        }else{
            didMountRef.current = true
        }
        return () => setModalPassword(false)
    },[loading])

    return(
        <>
            <AuthLayout>
                <FormPassword onSubmit={values => dispatch(resetPassword({ ...values, access_token, uid, client })) } />
            </AuthLayout>
            <Backdrop open={loading} />
            <Modal
                open={modalPassword}
                title="Notificación"
            >
                Su cambio se realizó de manera existosa. Será redirigido para que inicie sesión
            </Modal>
            
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorUsuario()) }
                message={error && error.map( er => er)}
            />
        </>
    )

}

export default PasswordReset