import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid, Box, Paper, Typography } from '@material-ui/core'
import SelectSearch from './SelectSearch2'

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'cuenta',
    ]

    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = true
      }
    })
  
    return errors
  } 
  

let FormBusquedaCuenta = props => {
    const { handleSubmit, perfil } = props
    const workingaccounts = useSelector(state => state.Workingaccounts)
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                required
                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                type="text"
                name="cuenta"
                label="Cuentas"
                options={workingaccounts.data.filter(x => x.active)}
                component={SelectSearch}
            />
            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    <Button variant="contained" color="primary" type="submit">
                        Ver Balance
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default FormBusquedaCuenta = reduxForm({
  form: 'busqueda-cuenta',
  validate
})(FormBusquedaCuenta);
