import React from 'react';
import { Grid } from '@material-ui/core'
import './Beneficios.scss';
import transferir from '../../img/transferencias-dinero.png'
import { Typography } from '@material-ui/core';


function Beneficios(props) {
    return (
        <React.Fragment>
            <Grid container justify="center" alignItems="center" style={{ margin: '160px 0px 160px 0px'}} >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container  justify="center" alignItems="center" >
                        <Typography variant="h4" color="primary" style={{ marginBottom:"50px", marginLeft: '20px'}}>
                            La mejor tasa del mercado sin comisiones adicionales
                        </Typography>
                        <Typography variant="h6" color="primary" style={{ marginLeft: '20px', textAlign: 'justify'}}>
                            Mantener la confianza de nuestros clientes y partners es nuestra prioridad. Nuestro compromiso de garantizar pagos seguros en línea es la razón por la cual nuestros clientes nos eligen para hacer sus pagos.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container  justify="center" alignItems="center" >
                        <img src={transferir} />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>  
    );
}

export default Beneficios;
