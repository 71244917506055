import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from '../../img/logo.png';
import { history } from '../../index';
import { abrirModal } from '../../ducks/Modales'
import { cerrarUsuario } from '../../ducks/Usuario'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const usuario = useSelector(state => state.Usuario)
  const dispatch = useDispatch()
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <p>Inicio</p>
      </MenuItem>
      <MenuItem onClick={() => history.push("/nosotros")}>
        <p>Nosotros</p>
      </MenuItem>
      <MenuItem onClick={() => history.push("/beneficios")}>
        <p>Beneficios</p>
      </MenuItem>
      {false &&<MenuItem>
        <p>Partners</p>
      </MenuItem>}
      {false &&<MenuItem onClick={() => history.push("/trabajando")}>
        <p>Recompensa</p>
      </MenuItem>}
      {false &&<MenuItem onClick={() => history.push("/trabajando")}>
        <p>Blog</p>
      </MenuItem>}
      <MenuItem onClick={() => history.push("/contacto")}>
        <p>Contacto</p>
      </MenuItem>
      { !usuario.data[0] &&
      <div>
        <MenuItem>
          <Button color="inherit" onClick={() => history.push("/login")} color="inherit">
              Iniciar Sesión
          </Button>
        </MenuItem>
        <MenuItem>
          <Button color="inherit" onClick={() => history.push("/register")} color="inherit">
              Registrarme
          </Button>
        </MenuItem>
      </div>
      }
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ position: 'absolute', background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
          <a className={classes.menuButton} style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
            <img src={logo} style={{ width: '150px' }}  />
          </a>
          <div className={classes.search}>
            
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button onClick={() => history.push("/")} color="primary">
                Inicio
            </Button>
            <Button color="primary" onClick={() => history.push("/nosotros")}>
                Nosotros
            </Button>
            <Button color="primary" onClick={() => history.push("/beneficios")}>
                Beneficios
            </Button>
            {false &&<Button color="primary" >
                Partners
            </Button>}
            {false &&<Button color="primary" onClick={() => history.push("/trabajando")}>
                Recompensa
            </Button>}
            {false &&<Button color="primary" onClick={() => history.push("/trabajando")}>
                Blog
            </Button>}
            <Button color="primary" onClick={() => history.push("/contacto")} >
                Contacto
            </Button>
            { usuario.data[0] ? 
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="primary"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={e => { 
                  if(usuario.data[0].profile.nombre === "Admin" || usuario.data[0].profile.nombre === "Oficina" ) {
                    history.push('/transacciones/agregar')
                  }else if(usuario.data[0].profile.nombre === "Cliente"){
                    history.push('/clientes/agregar')
                  }else if(usuario.data[0].profile.nombre === "Cajero"){
                    history.push('/cajeros')
                  }else if(usuario.data[0].profile.nombre === "Asociado"){
                    history.push('/partners/agregar')
                  }else if(usuario.data[0].profile.nombre === "Mayorista"){
                    history.push('/mayorista/pagadores')
                  }
                }}>Panel Administrativo</MenuItem>
                <MenuItem onClick={e => history.push('/configuracion/perfil')}>Mi Perfil</MenuItem>
                <MenuItem onClick={e => dispatch(abrirModal({title:"Confirmación", body:"¿Está seguro que desea cerrar sesión?", close:true, action: e => dispatch(cerrarUsuario() )}))}>Cerrar Sesión</MenuItem>
              </Menu>
            </div>
            :
              <div>
                <Button color="primary" onClick={() => history.push("/login")} style={{ margin:"10px" }} variant="outlined">
                    Iniciar Sesión
                </Button>
                <Button color="primary" onClick={() => history.push("/register")} style={{ margin:"10px" }} variant="outlined">
                    Registrarme
                </Button>
              </div>
            }
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
            { usuario.data[0] && <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="primary"
                onClick={handleMenu}
              >
              <AccountCircle />
            </IconButton>}
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
