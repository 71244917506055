import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import Backdrop from '../../components/Backdrop'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import FormProveedores from '../../components/FormProveedores'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import { crearProveedor, editProveedor, borrarProveedor, resetErrorProveedores } from '../../ducks/Proveedores'
import { fetchTaxes } from '../../ducks/Taxes'
import { fetchPaises } from '../../ducks/Paises';

const useStyles = makeStyles( theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    }
}));

const Taxes = props => {
    const classes = useStyles();
    const taxes = useSelector( state => state.Taxes )
    const [ proveedor, setProveedor ] = useState(null)
    const [ modalEditar, setModalEditar ] = useState(false)
    const [ modalBorrar, setModalBorrar ] = useState(false)
    const [ modalProveedor, setModalProveedor ] = useState(false)
    const loading = useSelector( state => state.Proveedores.fetching )
    const dispatch = useDispatch()

    const fetchData = () =>{
        dispatch(fetchPaises())
        dispatch(fetchTaxes())
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Grid className={classes.root}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Proveedores</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Tabla
                agregar={() => {setProveedor(null);setModalProveedor(true)}}
                columnas={[
                    { title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Nombre', field: 'name', 
                        cellStyle: {
                            width: '10px'
                        },headerStyle: {
                            width: '10px'
                    }},
                    { title: 'Porcentaje', field: 'amount_percentage',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Monto Fijo', field: 'amount_fix',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Limite', field: 'limit',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Pais', field: 'country',
                        cellStyle: {
                        },headerStyle: {
                    }},
                ]}
                data={taxes.data.map(x => ({
                    ...x,
                    active: x.active ? "SI" : "NO",
                    accion: 
                        <BotonMenu 
                            editar={true}
                            borrar={true}
                            activar={true}
                            registro={x}
                            handleActivar={() => {dispatch(editProveedor({ ...x, active: !x.active }))}}
                            handleEditar={() => {setProveedor(x);setModalEditar(true)}} 
                            handleBorrar={() => {setProveedor(x);setModalBorrar(true)}}
                        />
            }))} />

            <Modal
                open={modalProveedor}
                title="Agregar Proveedor"
                close={true}
                handleClose={() => setModalProveedor(false)}
            >
                <FormProveedores 
                    onSubmit={ async values => {
                        await dispatch(crearProveedor(values))
                        setModalProveedor(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalEditar}
                title="Editar Proveedor"
                close={true}
                handleClose={() => setModalEditar(false)}
            >
                <FormProveedores 
                    proveedorSeleccionado={proveedor}
                    initialValues={{
                        ...proveedor
                    }}
                    onSubmit={ async values => {
                        await dispatch(editProveedor(values));
                        setModalEditar(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalBorrar}
                title="Confimación"
                close={true}
                handleClose={() => setModalBorrar(false)}
                action={() => {dispatch(borrarProveedor(proveedor.id)); setModalBorrar(false)}}
            >
                ¿Estás seguro de que deseas borrar el proveedor?
            </Modal>

            <Snackbar
                open={taxes.error && taxes.error.length > 0 ? true : false}
                handleClose={() => dispatch(resetErrorProveedores())}
                message={taxes.error}
            />

            <Backdrop 
                open={loading}
            />
        </Grid>

    )
}


export default Taxes