import React from 'react';
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import MenuTransacciones from '../MenuTransacciones'
import MenuSolicitudesMayoristas from './menuSolicitudesMayorista'
import TablaTransacciones from './tablaTransacciones'
import FormBusquedaFecha from '../../components/FormBusquedaFecha'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const transacciones = props => {
    const {
        editar,
        borrar,
        asignar,
        acciones, 
        id, 
        cliente, 
        beneficiario, 
        fecha, 
        cedula, 
        banco, 
        cuenta, 
        archivo, 
        pago, 
        recibe, 
        cajero,
        comprobante,
        operador,
        actualizacion,
        title,
        ver,
        rechazar,
        aprobacionTransaccion,
        verificacionUsuario,
        fetchData,
        formulario,
        pais,
        exportable,
        tasa,
        mayorista,
        agregarDeposito,
        realizarPago,
        deuda,
        agregarPagos,
        grouping,
        rut,
    } = props
    
    return (
        <Box mt={15} mb={5} ml={10} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">{title}</Typography>
            </Grid>
            {formulario &&
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Paper style={{ maxWidth: "600px" }}>
                    <Box p={4}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Typography variant="h6">Busqueda Avanzada</Typography>
                        </Grid>
                        <FormBusquedaFecha onSubmit={values => fetchData(values) }/>
                    </Box>
                </Paper>
            </Grid>}
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            {!mayorista && !transacciones.fetching && <MenuTransacciones />}
            {mayorista && !transacciones.fetching && <MenuSolicitudesMayoristas />}
            <TablaTransacciones
                agregarDeposito={agregarDeposito}
                realizarPago={realizarPago}
                editar={editar}
                borrar={borrar}
                asignar={asignar}
                acciones={acciones} 
                id={id} 
                cliente={cliente} 
                rut={rut}
                beneficiario={beneficiario} 
                fecha={fecha} 
                cedula={cedula} 
                banco={banco} 
                cuenta={cuenta} 
                archivo={archivo} 
                pago={pago} 
                recibe={recibe} 
                cajero={cajero}
                comprobante={comprobante}
                operador={operador}
                actualizacion={actualizacion}
                ver={ver}
                rechazar={rechazar}
                pais={pais}
                aprobacionTransaccion={aprobacionTransaccion}
                verificacionUsuario={verificacionUsuario}
                exportable={exportable}
                tasa={tasa}
                deuda={deuda}
                agregarPagos={agregarPagos}
                grouping={grouping}
            />
        </Box>
    
    )
}

export default transacciones