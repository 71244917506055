import React, { Fragment, useState } from "react";
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from "moment";
import "moment/locale/es";
moment.locale("es");



const BasicDatePicker = ({ input, label, meta: { touched, error }, ...custom }) => {
    return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        name={input.name}
        onChange={(value) => input.onChange(moment(value).format('YYYY-MM-DD'))}
        {...custom}
        disableFuture
        label={label}
        value={input.value !== '' ? input.value : null}
        format="DD-MM-YYYY"
        label={label}
        inputVariant="outlined"
        style={{ width: '100%', padding: '10px 0px 10px 0px '}}
        error={touched && (error ? true : false)}
        helperText={touched && ((error && <span>{error}</span>))}
      />
    </MuiPickersUtilsProvider>
    );
}

export default BasicDatePicker;