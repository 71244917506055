import { makeTypes, mac, createReducer, reduceReducers, } from 'ducks-maker'
import { assignHOR, resetStoreHOR } from './hors.js'

const t = makeTypes('total')
const ASSIGN = t('ASSIGN').single()
const RESETSTORE = 'reset'

export const asignarDeposito = mac(ASSIGN, 'payload')

const initialState = {
    data: {},
}

const assignReducer = createReducer(initialState, assignHOR({ ASSIGN }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers( assignReducer, resetStoreReducer )
