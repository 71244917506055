import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import Loading from './Loading'

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre'
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }


  return errors
}
  

let FormBusquedaBeneficiario = props => {
      const { handleSubmit, change } = props
      const usuarios = useSelector(state => state.Usuarios)
      const paises = useSelector( state => state.Paises)
      return(
            <form noValidate onSubmit={handleSubmit} >
                <Field
                    required
                    type="text"
                    name="nombre"
                    label="Nombre"
                    component={TextField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Field
                  required
                  style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                  type="text"
                  change={change}
                  name="pais"
                  disableClearable={true}
                  label="Pais"
                  options={paises.data}
                  component={SelectSearch}
                />
                <Field
                    required
                    type="text"
                    name="cuenta"
                    label="Numero de Cuenta"
                    component={TextField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        { usuarios.fetching ? <Loading /> :
                        <Button variant="contained" color="primary" type="submit">
                            Buscar
                        </Button>}
                    </Grid>
                </Grid>
            </form>
        )
    }


export default FormBusquedaBeneficiario = reduxForm({
  form: 'busqueda-beneficiario',
  validate,
})(FormBusquedaBeneficiario);
