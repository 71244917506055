import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import FormAbonosDebitos from '../../components/FormAbonosDebitos'
import Backdrop from '../../components/Backdrop'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { fetchPaises } from '../../ducks/Paises'
import { currency2decimal } from '../../services/utils'
import MenuBalances from '../../components/MenuBalances';

const useStyles = makeStyles( theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    }
}));

const AbonosDebitos = props => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const workingaccounts = useSelector( state => state.Workingaccounts )
    const paises = useSelector( state => state.Paises )
    const loading = useSelector( state => state.Workingaccounts.fetching )

    const fetchData = () => {
        dispatch(fetchWorkingaccounts({active: true}))
        dispatch(fetchPaises())
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Grid className={classes.root}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Abonos/Debitos</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>

            <Hidden smDown>
                <MenuBalances />
            </Hidden>

            <FormAbonosDebitos 
                initialValues={{
                    tasa:1,
                    proporcional: true,
                }}
                onSubmit={values => console.log(values)}
            />

            <Backdrop open={loading} />

        </Grid>
    )
}

export default AbonosDebitos