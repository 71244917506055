import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const renderRadioField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    return(
      <FormControl
        label={label}
        {...input}
        {...custom}
        error={touched && (error ? true : false)}
        component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup value={input.value} row>
            {custom.opciones.map((x,id) => 
                <FormControlLabel
                    key={id} 
                    value={x}
                    control={<Radio color="primary" />}
                    label={x}
                    labelPlacement="end"/>
            )}
        </RadioGroup>
      </FormControl>
  );
}

export default renderRadioField
