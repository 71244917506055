import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid, Box, Paper, Typography } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch2'

const validate = (values = {}, props) => {
  const errors = {}
  const requiredFields = [
      ...(values.perfil && values.perfil.nombre === "Cliente") || (props.perfil && !values.perfil) ? ['nombre'] : []
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if ((values.nombre && values.nombre.length <= 2 && (values.perfil && values.perfil.nombre === "Cliente")) || (props.perfil && !values.perfil)){
    errors.nombre = "Nombre inválido"
  }

  if (values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }

  return errors
} 

let FormBusquedaUsuario = props => {
    const { handleSubmit, perfil } = props
    const perfiles = useSelector(state => state.Profiles)
    return(
        <Grid container alignItems="center" justify="center" style={{ padding: 10 }}>
            <Paper style={{ width: '100%'}} >
                <Box p={4}>
                    <Grid container justify="center" alignItems="center" >
                        <Typography variant="h6">Búsqueda Usuario</Typography>
                    </Grid>
                    <form noValidate onSubmit={handleSubmit} >
                        <Field
                            required
                            type="text"
                            name="nombre"
                            label="Nombre"
                            component={TextField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {perfil && <Field
                            required
                            style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                            type="text"
                            name="perfil"
                            label="Perfil"
                            options={perfiles.data.filter(x => x.active)}
                            component={SelectSearch}
                        />}
                        <Grid item xs={12} lg={12} xl={12}>
                            <Grid container justify="center" alignItems="center"  >
                                <Button variant="contained" color="primary" type="submit">
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Grid>
    )
}

export default FormBusquedaUsuario = reduxForm({
  form: 'busqueda-usuario',
  validate,
})(FormBusquedaUsuario);
