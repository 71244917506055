import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid, Typography, ClickAwayListener } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import TablaCuentas from './tablaCuentasTrabajo'
import ContenidoMenu from '../../components/ContenidoMenu'
import Backdrop from '../../components/Backdrop'
import Card from '../../components/Card'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { fetchPaises } from '../../ducks/Paises'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(10),
          marginLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(15),
          marginLeft: theme.spacing(5),
          marginBottom: theme.spacing(5),
          marginRight: theme.spacing(5),
        }
    },
    container:{
        cursor: 'pointer',
        backgroundColor: 'black'
    }
  }));

const Workingaccounts = props => {
    const classes = useStyles();    
    const workingaccounts = useSelector(state => state.Workingaccounts)
    const loading = useSelector(state => state.Workingaccounts.fetching || state.Paises.fetching)
    const usuario = useSelector(state => state.Usuario.data[0])
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchWorkingaccounts({active: true}))
            await dispatch(fetchPaises())
        }
        fetchData()
    },[])

    return(
        <Grid className={classes.root}>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            {workingaccounts.data.filter(x => x.user_id === usuario.id).length > 0 &&
            <>
                <Grid container justify="center" alignItems="center" >
                    <Typography variant="h5">Mis cuentas</Typography>
                </Grid>
                <TablaCuentas 
                    ver={true}
                    nombre={true}
                    cuenta={true}
                    pais={true}
                    asignadas={true}
                    workingaccounts={workingaccounts.data.filter(x => x.receiver === false).filter(x => x.user_id === usuario.id)}
                />
            </>
            }

            {workingaccounts.data.filter(x => x.receiver === false).filter(x => x.user_id !== usuario.id).length > 0 &&
            <>
                <Grid container justify="center" alignItems="center" >
                    <Typography variant="h5">Cuentas de Cajeros</Typography>
                </Grid>
                <TablaCuentas
                    ver={true}
                    nombre={true}
                    cuenta={true}
                    pais={true}
                    asignadas={true}
                    workingaccounts={workingaccounts.data.filter(x => x.user_id !== usuario.id)}
                
                />
            </>
            }

            <Backdrop open={loading}/>
            
        </Grid>
    );
}

export default Workingaccounts