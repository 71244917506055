import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Hidden, Button } from '@material-ui/core'
import Layout from '../../components/Layout'
import Backdrop from '../../components/Backdrop'
import Modal from '../../components/Notificacion2'
import FormAsignarCajero from '../../components/FormAsignarCajero'
import FormEditTransaccion from '../../components/FormEditTransaccion'
import Snackbar from '../../components/Snackbar'
import FormBusquedaBeneficiario from '../../components/FormBusquedaBeneficiario'
import FormBusquedaUsuarios from '../../components/FormBusquedaUsuarios'
import TablaBeneficiarios from '../beneficiarios/tablaBeneficiarios'
import TablaClientes from '../clientes/tablaClientes'
import MenuTransacciones from '../MenuTransacciones'
import TablaTransacciones from './tablaTransaccioness'
import { busquedaAvanzadaTransacciones, editarTransaccion, editarTransacciones, crearTransaccion, resetError } from '../../ducks/Transacciones'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import moment from 'moment'
import 'moment/locale/es'
import { fetchPaises } from '../../ducks/Paises';
import { fetchEstados } from '../../ducks/Estados';
import { Banco } from '../../services/utils'
import { fetchTasas } from '../../ducks/Tasas';
import { buscarBeneficiarios } from '../../ducks/Beneficiarios'
import { buscarClientes } from '../../ducks/Clientes'
import { resetMensaje } from '../../ducks/Mensajes'
moment.locale('es')

const PendientesAdmin = props => {  
    const [ transaccion, setTransaccion ] = useState(null)
    const [ modalBorrar, setModalBorrar ] = useState(false)
    const [ modalAsignar, setModalAsignar ] = useState(false)
    const [ modalAsignarVarias, setModalAsignarVarias ] = useState(false)
    const [ modalArchivos, setModalArchivos ] = useState(false)
    const [ modalEditar, setModalEditar ] = useState(false)
    const [ modalDividir, setModalDividir ] = useState(false)
    const [ diferencia, setDiferencia ] = useState(false)
    const [ modalBeneficiarios, setModalBeneficiarios ] = useState(false)
    const [ modalClientes, setModalClientes ] = useState(false)
    const [ crear, setCrear ] = useState(false)
    const [ transaccionesSeleccionadas, setTransaccionesSeleccionadas ] = useState([])
    const transacciones = useSelector(state => state.Transacciones)
    const paises = useSelector(state => state.Paises)
    const estados = useSelector(state => state.Estados)
    const tasas = useSelector(state => state.Tasas)
    const mensajes = useSelector(state => state.Mensajes)
    const transaccionSeleccionada = transaccion && transacciones.data.filter(x => x.active).find(x => x.id === transaccion.id)
    const loading = useSelector(state => (state.Transacciones.fetching || state.Workingaccounts.fetching || state.Paises.fetching || state.Estados.fetching ))
    const dispatch = useDispatch()

    const fetchData = () => {
        dispatch(fetchEstados())
        dispatch(fetchPaises())
        dispatch(fetchTasas())
        dispatch(busquedaAvanzadaTransacciones({ state: "Pendiente"}))
        dispatch(fetchWorkingaccounts({ active:true }))
    }

    useEffect(() => {
        fetchData()
    },[])
    
    return (
        <Layout menu={true}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Transacciones Pendientes</Typography>
            </Grid>
            <Hidden smDown>
                <MenuTransacciones />
            </Hidden>
            <TablaTransacciones
                editar={true}
                borrar={true}
                asignar={true}
                acciones={true} 
                id={true} 
                cliente={true} 
                beneficiario={true} 
                fecha={true} 
                cedula={true} 
                banco={true} 
                cuenta={true} 
                archivo={true} 
                ver={true}
                pago={true} 
                recibe={true} 
                cajero={true}
                comprobante={true}
                operador={true} 
                actualizacion={true}
                pais={true}
                tasa={true}
                selection={true}
                transacciones={transacciones.data.map(transaccion => transaccionesSeleccionadas.find(row => row.id === transaccion.id)  ? {...transaccion, tableData: { checked: true }} : transaccion )}
                paises={paises}
                estados={estados}
                handleSelection={transacciones => setTransaccionesSeleccionadas(transacciones) }
                handleBorrar={value => {setTransaccion(value); setModalBorrar(true)}}
                handleAsignar={value => {setTransaccion(value); setModalAsignar(true)}}
                handleVerArchivos={value => {setTransaccion(value); setModalArchivos(true)}}
                handleEditar={value => {setTransaccion(value); setModalEditar(true)}}
            />
            
            {transaccionesSeleccionadas.length > 0 &&  
            <Grid item xs={12} lg={12} xl={12} style={{ margin: '10px' }}>
                <Grid container justify="center" alignItems="center"  >
                    <Button onClick={() => setModalAsignarVarias(true)} variant="contained" color="primary" >
                        Asignar Varias
                    </Button>
                </Grid>
            </Grid>}

            <Modal
                open={modalBorrar}
                title="Confirmación"
                close={true}
                handleClose={() => setModalBorrar(false)}
                action={ async () => {
                    dispatch(editarTransaccion({ id: transaccion.id, active: !transaccion.active})) 
                    setModalBorrar(false)
                }}
            >
                ¿Está seguro que desea borrar la transacción?
            </Modal>

            <Modal
                open={modalAsignar}
                title="Asignar Transacción"
                close={true}
                handleClose={() => setModalAsignar(false)}
            >
                <FormAsignarCajero 
                    pais={transaccion ? (transaccion.exchange_rate ? transaccion.exchange_rate.destino_id : "") : ""}
                    onSubmit={ values =>{
                        dispatch(editarTransaccion({ id: transaccion.id, workingaccount_id: values.workingaccount.id})) 
                        setModalAsignar(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalAsignarVarias}
                title="Asignar Transacciones"
                close={true}
                handleClose={() => setModalAsignarVarias(false)}
            >
                <FormAsignarCajero 
                    pais={transaccionesSeleccionadas[0]?.exchange_rate?.destino_id}
                    onSubmit={ values =>{
                        dispatch(editarTransacciones({ transacciones: transaccionesSeleccionadas.map(transaccion => transaccion.id), workingaccount_id: values.workingaccount.id })) 
                        setModalAsignarVarias(false)
                        setTransaccionesSeleccionadas([])
                    }}
                />
            </Modal>

            <Modal
                open={modalArchivos}
                title="Ver Archivos"
                close={true}
                handleClose={() => setModalArchivos(false)}
            >
                {transaccion && transaccion.images.map((x,index) =>
                <Grid key={index}>
                    <Typography 
                        style={{ cursor: 'pointer'}}
                        color="primary"
                        variant="body2" 
                        onClick={() => {
                            window.open(x)
                            if(transaccion.images.length === 1){
                                setModalArchivos(false)
                            }
                        }}
                    >
                        Archivo {index+1}
                    </Typography>
                </Grid>
                )}
            </Modal>

            <Modal
                open={modalEditar}
                title="Editar Transacción"
                close={true}
                size="xl"
                handleClose={() => setModalEditar(false)}

            >   
                <Grid container>
                    <Grid item xs={4}>
                        <ul>
                            <li><strong>Cliente</strong></li>
                            <li>Nombre: {transaccionSeleccionada && (transaccionSeleccionada.user ? (transaccionSeleccionada.user.nombre +" "+ transaccionSeleccionada.user.apellido) : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.cliente : "")) }</li>
                            <li>ID: {transaccionSeleccionada && (transaccionSeleccionada.user ? transaccionSeleccionada.user.rut : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.rut : "")) }</li>
                            <li>Email: {transaccionSeleccionada && (transaccionSeleccionada.user ? transaccionSeleccionada.user.email : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.email : "")) }</li>
                            {transaccionSeleccionada && transaccionSeleccionada.customer &&
                                <li><Button onClick={() => setModalClientes(true)} variant='contained' color='primary' ><SwapHorizIcon /></Button></li>
                            }
                        </ul>
                    </Grid>
                    <Grid item xs={4}>
                        <ul>
                            <li><strong>Beneficiario</strong></li>
                            <li>Nombre: {transaccionSeleccionada && (transaccionSeleccionada.account ? transaccionSeleccionada.account.nombre :  "") }</li>
                            <li>ID: {transaccionSeleccionada && (transaccionSeleccionada.account ? (transaccionSeleccionada.account.idTipo+" "+ transaccionSeleccionada.account.idNumero) : "" ) }</li>
                            <li>Banco: {transaccionSeleccionada && (transaccionSeleccionada.account ? Banco(transaccionSeleccionada.account.banco) : "") }</li>
                            <li>Cuenta: {transaccionSeleccionada && (transaccionSeleccionada.account ? (transaccionSeleccionada.account.tipoCuenta + " "+ transaccionSeleccionada.account.cuenta) : "") }</li>
                            <li><Button onClick={() => setModalBeneficiarios(true)} variant='contained' color='primary' ><SwapHorizIcon /></Button></li>
                        </ul>
                    </Grid>
                    <Grid item xs={4}>
                        <FormEditTransaccion 
                            initialValues={{
                                comprobante: transaccionSeleccionada && transaccionSeleccionada.comprobante,
                                totalPesos: transaccionSeleccionada && transaccionSeleccionada.totalPesos,
                                cantidadPesos: transaccionSeleccionada && transaccionSeleccionada.cantidadPesos,
                                bolivares: transaccionSeleccionada && transaccionSeleccionada.bolivares,
                                tasa: transaccionSeleccionada ? tasas.data.find(x => x.id === transaccionSeleccionada.exchange_rate_id) : null,
                            }}
                            onSubmit={async values => {
                                if(values.cantidadPesos < transaccionSeleccionada.cantidadPesos){
                                    dispatch(editarTransaccion({id: transaccionSeleccionada.id, ...values, tasa: values.tasa.tasa, exchange_rate_id: values.tasa.id }))
                                    setModalDividir(true)
                                    setDiferencia({cantidadPesos: Math.floor(transaccionSeleccionada.cantidadPesos - values.cantidadPesos), bolivares: Math.floor((transaccionSeleccionada.cantidadPesos - values.cantidadPesos ) * values.tasa.tasa) })
                                }else{
                                    dispatch(editarTransaccion({id: transaccionSeleccionada.id, ...values, tasa: values.tasa.tasa, exchange_rate_id: values.tasa.id }))
                                    setModalEditar(false)
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={modalDividir}
                title="Confirmación"
                close={true}
                handleClose={() => setModalDividir(false)}
                action={ async () => {
                    setModalDividir(false)
                    setModalEditar(false)
                    setModalBeneficiarios(true)
                    setCrear(true)
                }}
            >
                ¿Deseas crear una transacción con la diferencia?
            </Modal>

            <Modal
                open={modalBeneficiarios}
                title="Elegir Beneficiario"
                close={true}
                handleClose={() => setModalBeneficiarios(false)}
            >
                <FormBusquedaBeneficiario 
                    onSubmit={async values => {
                        await dispatch(buscarBeneficiarios(values))
                    }}
                />

                <TablaBeneficiarios
                    agregar={true}
                    editar={true}
                    acciones={true}
                    nombre={true}
                    cedula={true}
                    banco={true}
                    cuenta={true}
                    seleccionar={true}
                    handleSeleccionar={ async id => {
                        if(crear){
                            dispatch(crearTransaccion({ ...transaccion, ...diferencia, account_id: id }))
                            setModalBeneficiarios(false)
                            setCrear(false)
                        }else{
                            dispatch(editarTransaccion({ id: transaccion.id, account_id: id }))
                            setModalBeneficiarios(false)
                        }
                    }}
                />
            </Modal>

            <Modal
                open={modalClientes}
                title="Elegir Cliente"
                close={true}
                handleClose={() => setModalClientes(false)}
            >
                <FormBusquedaUsuarios 
                    onSubmit={ async values => 
                        await dispatch(buscarClientes(values))} 
                />
                <TablaClientes 
                    acciones={true}
                    id={true}
                    nombre={true}
                    seleccionar={true}
                    cedula={true}
                    email={true}
                    telefono={true}
                    handleSeleccionar={ async id => {
                        dispatch(editarTransaccion({ id: transaccion.id, customer_id: id }))
                        setModalClientes(false)
                    }}
                />
            </Modal>

            <Modal
                open={transacciones.error && transacciones.error.length > 0 ? true : false}
                title="Notificación"
                close={true}
                handleClose={() => {
                    dispatch(resetError());
                    setModalBorrar(false)
                    setModalAsignar(false)
                    setModalClientes(false)
                    setModalBeneficiarios(false)
                    setModalEditar(false)
                    setModalDividir(false)
                    setModalArchivos(false)
                }}
            >
                    <ul>
                        {transacciones.error && transacciones.error.map((x,id) => <li key={id}>{x}</li>)}
                    </ul>
            </Modal>

            <Snackbar 
                open={mensajes.data.length > 0 ? true : false}
                handleClose={() => dispatch(resetMensaje())}
                message={mensajes.data}
            />

            <Backdrop open={loading} />
        </Layout>    
    )
}

export default PendientesAdmin