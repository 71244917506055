import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Button, Grid } from '@material-ui/core'
import Tabla from '../../components/Tabla'
import BotonMenu from '../../components/BotonMenu'
import { Banco } from '../../services/utils'
import { currency2decimal } from '../../services/utils'
import FormularioCuenta from '../FormularioCuenta'
import FormRechazo from '../../components/FormRechazo'
import FormReferencia from '../../components/FormReferencia'
import FormEditTransaccion from '../../components/FormEditTransaccion'
import FormBusquedaBeneficiario from '../../components/FormBusquedaBeneficiario'
import FormBusquedaUsuarios from '../../components/FormBusquedaUsuarios'
import FormAgregarPago from '../../components/FormAgregarPago'
import RadioButton from '../../components/RadioButton'
import FormAsignarCajero from '../../components/FormAsignarCajero'
import Loading from '../../components/Loading'
import TablaBeneficiarios from '../beneficiarios/tablaBeneficiarios'
import TablaClientes from '../clientes/tablaClientes'
import { fetchArchivos } from '../../ducks/Archivos'
import { fetchWorkingaccounts } from '../../ducks/Workingaccounts'
import { verificarUsuario, buscarUsuario } from '../../ducks/Usuarios'
import { editarTransaccion, rechazarTransaccion, asignarPagoRecibido, realizarTransaccion, pagoSolicitud, resetError, crearTransaccion } from '../../ducks/Transacciones'
import { resetMensaje } from '../../ducks/Mensajes'
import { fetchPaises } from '../../ducks/Paises'
import { fetchEstados } from '../../ducks/Estados'
import { fetchTasas } from '../../ducks/Tasas'
import { buscarBeneficiarios } from '../../ducks/Beneficiarios'
import { buscarClientes } from '../../ducks/Clientes'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const TablaTransacciones = props => {
    const { 
        id, 
        acciones, 
        comprobante, 
        cliente, 
        beneficiario, 
        cedula, 
        banco, 
        cuenta, 
        tasa, 
        pago, 
        recibe, 
        estado,
        cajero,
        referencia, 
        operador,
        fecha, 
        actualizacion,
        aprobacionTransaccion, 
        verificacionUsuario, 
        asignar, 
        ver, 
        editar, 
        borrar,
        comentarios,
        rechazar,
        actualizar,
        realizar,
        pageSize,
        recibeCajero,
        verDetalle,
        pais,
        exportable,
        agregarPagos,
        handleEditarSolicitud,
        proveedor,
        rut,
        grouping
    } = props

        const transacciones = useSelector(state => state.Transacciones)
        const estados = useSelector( state => state.Estados )
        const archivos = useSelector( state => state.Archivos.data)
        const mensajes = useSelector( state => state.Mensajes)
        const usuario = useSelector( state => state.Usuarios.data[0])
        const loading = useSelector(state => state.Transacciones.fetching || state.Usuarios.fetching)
        const tasas = useSelector(state => state.Tasas)
        const paises = useSelector(state => state.Paises)

        const dispatch = useDispatch()
        const [ modalRealizar, setModalRealizar ] = useState(false)
        const [ tipoPago, setTipoPago ] = useState('Agregar Pago Recibido')
        const [ modalEditarSolicitud, setModalEditarSolicitud ] = useState(false)
        const [ verArchivos, setVerArchivos ] = useState(false)
        const [ crear, setCrear ] = useState(false)
        const [ diferencia, setDiferencia ] = useState(null)
        const [ modalDividir, setModalDividir ] = useState(false)
        const [ modalEditar, setModalEditar ] = useState(false)
        const [ modalAsignar, setModalAsignar ] = useState(false)
        const [ modalRechazo, setModalRechazo ] = useState(false)
        const [ modalClientes, setModalClientes ] = useState(false)
        const [ modalBeneficiarios, setModalBeneficiarios ] = useState(false)
        const [ modalVerificar, setModalVerificar ] = useState(false)
        const [ modalBorrar, setModalBorrar ] = useState(false)
        const [ modalVerificarUsuario, setModalVerificarUsuario ] = useState(false)
        const [ transaccion, setTransaccion ] = useState(null)
        const transaccionSeleccionada = transaccion && transacciones.data.filter(x => x.active).find(x => x.id === transaccion.id)
        const cerrarModalVerificar = () => {
            setModalVerificar(false)
        }

        const fetchData = () => {
            dispatch(fetchEstados())
            dispatch(fetchTasas())
            dispatch(fetchPaises())
            dispatch(fetchWorkingaccounts({ active:true }))
        }

        useEffect(() => {
            fetchData()
        },[])

        return(
            <>
                <Tabla
                    exportable={exportable}
                    actualizar={actualizar}
                    verDetalle={verDetalle}
                    loading={loading}
                    pageSize={pageSize}
                    grouping={grouping}
                    columnas={[
                        ... acciones ? [{ title: 'Acción', field: 'accion',  export: false,
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... id ? [{ title: 'Nº', field: 'id',  export: false,
                            cellStyle: {
                                width: '10px',
                            },headerStyle: {
                                width: '10px',
                        }}] : [],
                        ... comprobante ? [{ title: 'Comprobante', field: 'comprobante', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... pais ? [{ title: 'Origen/Destino', field: 'pais', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... rut ? [{ title: 'Ident.', field: 'rut',
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... cliente ? [{ title: 'Cliente', field: 'cliente',
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... beneficiario ? [{ title: 'Beneficiario', field: 'beneficiario', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... cedula ? [{ title: 'Cedula', field: 'cedula', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... banco ? [{ title: 'Banco', field: 'banco', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... cuenta ? [{ title: 'Cuenta', field: 'cuenta', export: false,
                            cellStyle: {
                                width: '200px'
                            },headerStyle: {
                                width: '200px'
                        }}] : [],
                        ... tasa ? [{ title: 'Tasa', field: 'tasa', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... pago ? [{ title: 'Pago', field: 'pago',
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... recibe ? [{ title: 'Recibe', field: 'recibe', 
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... recibeCajero ? [{ title: 'Recibe', field: 'recibecajero', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... estado ? [{ title: 'Estado', field: 'estado', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... comentarios ? [{ title: 'Comentarios', field: 'comentarios', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... proveedor ? [{ title: 'Proveedor', field: 'proveedor', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... cajero ? [{ title: 'Cajero', field: 'cajero', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... referencia ? [{ title: 'Referencia', field: 'referencia', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... operador ? [{ title: 'Operador', field: 'operador', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... fecha ? [{ title: 'Fecha', field: 'created_at', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... actualizacion ? [{ title: 'Ultima Act.', field: 'updated_at', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ... realizar ? [{ title: 'Realizar', field: 'realizar', export: false,
                            cellStyle: {
                                width: '150px'
                            },headerStyle: {
                                width: '150px'
                        }}] : [],
                        ]}
                        data={transacciones.data.filter( x => x.active ).map(x => ({
                            ...x,
                            cliente: x.customer ? x.customer.cliente : (x.user ? (x.user.nombre + " " + x.user.apellido) : ""),
                            rut: x.customer ? (x.customer.tipo_id+"-"+x.customer.rut) : (x.user ? (x.user.tipo_id+"-"+x.user.rut ) : ""),
                            pais: x.exchange_rate ? paises.data.find(y => y.id === x.exchange_rate.origen_id).nombre +"/"+ paises.data.find(y => y.id === x.exchange_rate.destino_id).nombre  : "",
                            beneficiario: x.account ? x.account.nombre : "",
                            cedula: x.account ? (x.account.idTipo ? x.account.idTipo : "") + "-" + (x.account.idNumero ? x.account.idNumero : "") : "",
                            banco: x.account ? Banco(x.account.banco) : "",
                            cuenta: x.account ? x.account.tipoCuenta+ "-"+x.account.cuenta : "",
                            tasa: x.exchange_rate ? x.exchange_rate.nombre+ "-"+ x.tasa : "",
                            pago: x.cantidadPesos ? currency2decimal(x.cantidadPesos) : "",
                            recibe: x.bolivares ? currency2decimal(x.bolivares) : "",
                            recibecajero: x.bolivares ? x.bolivares : "",
                            cajero: x.workingaccount ? x.workingaccount.nombre : "",
                            created_at: moment(x.created_at).format('DD-MM-YYYY HH:mm'),
                            operador: x.operario_id ? (x.operario ? x.operario.nombre : "") : x.operador,
                            updated_at: moment(x.updated_at).format('DD-MM-YYYY HH:mm'),
                            estado:  estados.data.find(y => y.id === x.state_id) ? estados.data.find(y => y.id === x.state_id).nombre : "",
                            comentarios: comentarios && x.audits.filter(y => y.action_triggered === "Rechazo").map(y => y.description),
                            realizar:<Button variant='contained' color='primary' onClick={() => {setTransaccion(x);setModalRealizar(true)}}>Realizar</Button>,
                            proveedor: x.provider ? (x.provider.nombre+"-"+x.provider.descripcion) : "Sin asignar",
                            accion: <BotonMenu 
                                        usuario={x.user && x.user.verified}
                                        handleArchivos={ async () => {
                                            setTransaccion(x);
                                            await dispatch(buscarUsuario(x.user.id));
                                            setModalVerificarUsuario(true)
                                        }}
                                        handleAprobarTransaccion={() => {
                                            setTransaccion(x) 
                                            setModalVerificar(true)
                                        }}
                                        handleRechazar={ () => {
                                            setTransaccion(x) 
                                            setModalRechazo(true)} 
                                        }
                                        handleBorrar={() => {
                                            setTransaccion(x) 
                                            setModalBorrar(true)}
                                        }
                                        handleAsignar={() => {
                                            setTransaccion(x)
                                            setModalAsignar(true)
                                        }}
                                        handleEditar={() => {
                                            setTransaccion(x)
                                            setModalEditar(true)
                                            setCrear(false)
                                        }}
                                        handleVerArchivos={() => {
                                            setTransaccion(x)
                                            setVerArchivos(true)
                                        }}
                                        handleAgregarPagos={() => {
                                            setTransaccion(x); 
                                            setModalEditarSolicitud(true) 
                                        }}
                                        agregarPagos={agregarPagos}
                                        rechazar={rechazar}
                                        verificacionUsuario={verificacionUsuario}
                                        aprobacionTransaccion={aprobacionTransaccion} 
                                        asignar={asignar} 
                                        archivo={x.images.length > 0 ? true : false} 
                                        ver={ver}
                                        borrar={borrar} 
                                        editar={editar} />

                    }))} 
                />

                <Modal
                    open={modalBeneficiarios}
                    title="Elegir Beneficiario"
                    close={true}
                    loading={loading}
                    handleClose={() => setModalBeneficiarios(false)}
                >
                    <FormBusquedaBeneficiario 
                        onSubmit={async values => {
                            await dispatch(buscarBeneficiarios(values))
                        }}
                    />

                    <TablaBeneficiarios
                        agregar={true}
                        editar={true}
                        acciones={true}
                        nombre={true}
                        cedula={true}
                        banco={true}
                        cuenta={true}
                        seleccionar={true}
                        handleSeleccionar={ async id => {
                            if(crear){
                                await dispatch(crearTransaccion({ ...transaccion, ...diferencia, account_id: id }))
                                setModalBeneficiarios(false)
                                setCrear(false)
                            }else{
                                await dispatch(editarTransaccion({ id: transaccion.id, account_id: id }))
                                setModalBeneficiarios(false)
                            }
                        }}
                    />
                </Modal>

                <Modal
                    open={modalClientes}
                    title="Elegir Cliente"
                    close={true}
                    loading={loading}
                    handleClose={() => setModalClientes(false)}
                >
                    <FormBusquedaUsuarios 
                        onSubmit={ async values => 
                            await dispatch(buscarClientes(values))} 
                    />
                    <TablaClientes 
                        acciones={true}
                        id={true}
                        nombre={true}
                        seleccionar={true}
                        cedula={true}
                        email={true}
                        telefono={true}
                        handleSeleccionar={ async id => {
                            await dispatch(editarTransaccion({ id: transaccion.id, customer_id: id }))
                            setModalClientes(false)
                        }}
                    />
                </Modal>


                <Modal
                    open={modalBorrar}
                    title="Confirmación"
                    close={true}
                    loading={loading}
                    handleClose={() => setModalBorrar(false)}
                    action={ async () => {
                        await dispatch(editarTransaccion({ id: transaccion.id, active: !transaccion.active})) 
                        setModalBorrar(false)
                    }}
                >
                    ¿Está seguro que desea borrar la transacción?
                </Modal>

                <Modal
                    open={modalDividir}
                    title="Confirmación"
                    close={true}
                    loading={loading}
                    handleClose={() => setModalDividir(false)}
                    action={ async () => {
                        setModalDividir(false)
                        setModalEditar(false)
                        setModalBeneficiarios(true)
                        setCrear(true)
                    }}
                >
                    ¿Deseas crear una transacción con la diferencia?
                </Modal>

                <Modal
                    open={modalAsignar}
                    title="Asignar Transacción"
                    close={true}
                    loading={loading}
                    handleClose={() => setModalAsignar(false)}
                >
                    <FormAsignarCajero 
                        pais={transaccion ? (transaccion.exchange_rate ? transaccion.exchange_rate.destino_id : "") : ""}
                        onSubmit={ async values =>{
                            await dispatch(editarTransaccion({ id: transaccion.id, workingaccount_id: values.workingaccount.id})) 
                            setModalAsignar(false)
                        }}
                    />
                </Modal>

                <Modal
                    open={verArchivos}
                    title="Ver Archivos"
                    close={true}
                    loading={loading}
                    handleClose={() => setVerArchivos(false)}
                >
                    {transaccion && transaccion.images.map((x,index) =>
                    <div key={index}>
                        <a target="_blank" href={x}>Archivo {index+1}</a>
                    </div>
                    )}
                </Modal>

                <Modal
                    open={modalEditar}
                    title="Editar Transacción"
                    close={true}
                    loading={loading}
                    size="xl"
                    handleClose={() => setModalEditar(false)}

                >   
                    <Grid container>
                        <Grid item xs={4}>
                            {loading ? <Loading /> 
                            :
                            <ul>
                                <li><strong>Cliente</strong></li>
                                <li>Nombre: {transaccionSeleccionada && (transaccionSeleccionada.user ? (transaccionSeleccionada.user.nombre +" "+ transaccionSeleccionada.user.apellido) : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.cliente : "")) }</li>
                                <li>ID: {transaccionSeleccionada && (transaccionSeleccionada.user ? transaccionSeleccionada.user.rut : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.rut : "")) }</li>
                                <li>Email: {transaccionSeleccionada && (transaccionSeleccionada.user ? transaccionSeleccionada.user.email : (transaccionSeleccionada.customer ? transaccionSeleccionada.customer.email : "")) }</li>
                                {transaccionSeleccionada && transaccionSeleccionada.customer &&
                                    <li><Button onClick={() => setModalClientes(true)} variant='contained' color='primary' ><SwapHorizIcon /></Button></li>
                                }
                            </ul>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {loading ? <Loading /> : 
                            <ul>
                                <li><strong>Beneficiario</strong></li>
                                <li>Nombre: {transaccionSeleccionada && (transaccionSeleccionada.account ? transaccionSeleccionada.account.nombre :  "") }</li>
                                <li>ID: {transaccionSeleccionada && (transaccionSeleccionada.account ? (transaccionSeleccionada.account.idTipo+" "+ transaccionSeleccionada.account.idNumero) : "" ) }</li>
                                <li>Banco: {transaccionSeleccionada && (transaccionSeleccionada.account ? Banco(transaccionSeleccionada.account.banco) : "") }</li>
                                <li>Cuenta: {transaccionSeleccionada && (transaccionSeleccionada.account ? (transaccionSeleccionada.account.tipoCuenta + " "+ transaccionSeleccionada.account.cuenta) : "") }</li>
                                <li><Button onClick={() => setModalBeneficiarios(true)} variant='contained' color='primary' ><SwapHorizIcon /></Button></li>
                            </ul>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <FormEditTransaccion 
                                initialValues={{
                                    comprobante: transaccionSeleccionada && transaccionSeleccionada.comprobante,
                                    totalPesos: transaccionSeleccionada && transaccionSeleccionada.totalPesos,
                                    cantidadPesos: transaccionSeleccionada && transaccionSeleccionada.cantidadPesos,
                                    bolivares: transaccionSeleccionada && transaccionSeleccionada.bolivares,
                                    tasa: transaccionSeleccionada ? tasas.data.find(x => x.id === transaccionSeleccionada.exchange_rate_id) : null,
                                }}
                                onSubmit={async values => {
                                    if(values.cantidadPesos < transaccionSeleccionada.cantidadPesos){
                                        await dispatch(editarTransaccion({id: transaccionSeleccionada.id, ...values, tasa: values.tasa.tasa, exchange_rate_id: values.tasa.id }))
                                        setModalDividir(true)
                                        setDiferencia({cantidadPesos: Math.floor(transaccionSeleccionada.cantidadPesos - values.cantidadPesos), bolivares: Math.floor((transaccionSeleccionada.cantidadPesos - values.cantidadPesos ) * values.tasa.tasa) })
                                    }else{
                                        await dispatch(editarTransaccion({id: transaccionSeleccionada.id, ...values, tasa: values.tasa.tasa, exchange_rate_id: values.tasa.id }))
                                        setModalEditar(false)
                                    }
                                }}
                            />
                        </Grid>
                        
                    </Grid>
                </Modal>

                <Modal
                    open={modalEditarSolicitud}
                    title="Editar Solicitud"
                    close={true}
                    loading={loading}
                    size={tipoPago === "Afiliar Usuario" ? "md" : "sm"}
                    handleClose={() => setModalEditarSolicitud(false)}

                >   
                    <Grid container>
                        <Grid item>
                            <RadioButton input={{name: "Tipo de Pago", value: tipoPago, onChange: e => setTipoPago(e.target.value)}} opciones={['Agregar Pago Recibido','Agregar Pago Enviado','Afiliar Usuario', 'Ver detalle']} meta={{touched:true}} />
                            {tipoPago === "Agregar Pago Recibido" && 
                            <Grid>
                                <Typography>Agregar Comprobantes de Pago Recibido</Typography>
                                <FormAgregarPago
                                    loading={loading}
                                    limite={transaccion ? transaccion.totalPesos : 0}
                                    customer={transaccion ? (transaccion.customer ? transaccion.customer : "") : ""}
                                    recibido={true}
                                    pais={transaccion ? (transaccion.exchange_rate ? transaccion.exchange_rate.origen_id : "") : ""}
                                    onSubmit={values => {dispatch(asignarPagoRecibido({ transaccion: transaccion, ...values })); setModalEditarSolicitud(false)} }
                                />
                            </Grid>}
                            {tipoPago === "Agregar Pago Enviado" &&
                            <Grid>
                                <Typography>Agregar Comprobante de Pago Realizado</Typography>
                                <FormAgregarPago
                                    loading={loading}
                                    customer={transaccion ? (transaccion.customer ? transaccion.customer : "") : ""}
                                    pais={transaccion ? (transaccion.exchange_rate ? transaccion.exchange_rate.destino_id : "") : ""}
                                    onSubmit={values => {dispatch(pagoSolicitud({ transaccion: transaccion, ...values })); setModalEditarSolicitud(false)} }
                                />
                            </Grid>
                            }
                            {tipoPago === "Afiliar Usuario" &&
                            <Grid>
                                <FormBusquedaUsuarios 
                                    onSubmit={ async values => 
                                        await dispatch(buscarClientes(values))} 
                                />
                                <TablaClientes 
                                    acciones={true}
                                    id={true}
                                    nombre={true}
                                    seleccionar={true}
                                    cedula={true}
                                    email={true}
                                    telefono={true}
                                    handleSeleccionar={ async id => {
                                        console.log("elegido")
                                    }}
                                />
                            </Grid>
                            }
                            {tipoPago === "Ver detalle" &&
                            <Grid>
                                <FormBusquedaUsuarios 
                                    onSubmit={ async values => 
                                        await dispatch(buscarClientes(values))} 
                                />
                                <TablaClientes 
                                    acciones={true}
                                    id={true}
                                    nombre={true}
                                    seleccionar={true}
                                    cedula={true}
                                    email={true}
                                    telefono={true}
                                    handleSeleccionar={ async id => {
                                        console.log("elegido")
                                    }}
                                />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Modal>

                <Modal
                    open={modalVerificarUsuario}
                    title="Verificar Datos"
                    close={true}
                    handleClose={() => setModalVerificarUsuario(false)}
                    aceptar="Verificar Usuario"
                    action={ async () => { 
                        await dispatch(verificarUsuario(transaccion && transaccion.user.id)); 
                        setModalVerificarUsuario(false)
                    }}
                    loading={loading}
                >
                    <div>
                        <Typography>Nombre: { usuario ? usuario.nombre : ""}</Typography>
                        <Typography>Apellido: { usuario ? usuario.apellido : ""}</Typography>
                        <Typography>ID: { usuario && (usuario.tipo_id ? (usuario.tipo_id + "-" + usuario.rut) : "")}</Typography>
                        <Typography>Identificación:</Typography>
                        {usuario && usuario.UserFile.map((x,index) =>
                            <div key={index}>
                                <a target="_blank" href={x.archivo}>Archivo {index+1}</a>
                            </div>
                        )}
                        {usuario && usuario.images.map((x,index) =>
                            <div key={index}>
                                <a target="_blank" href={x}>Archivo {index+1}</a>
                            </div>
                        )}
                    </div>
                </Modal>

                <Modal
                    open={modalVerificar}
                    title="Confirmación"
                    close={true}
                    handleClose={() => setModalVerificar(false)}
                >
                    <FormularioCuenta cerrarModalVerificar={cerrarModalVerificar} idTransaccion={transaccion ? transaccion.id : ""} verificar={true} pais={transaccion ? (transaccion.exchange_rate ? transaccion.exchange_rate.origen_id :"") : ""} />
                </Modal>

                <Modal
                    open={modalRechazo}
                    title="Confirmación"
                    close={true}
                    handleClose={() => setModalRechazo(false)}
                >
                    <FormRechazo 
                        onSubmit={ async values => { await dispatch(rechazarTransaccion(transaccion,values)); setModalRechazo(false)} }
                    />
                </Modal>

                <Modal
                    open={modalRealizar}
                    title="Marcar transacción como realizada"
                    close={true}
                    handleClose={() => setModalRealizar(false)}
                >   
                        <Typography>Esta transacción será marcada como realizada:</Typography>
                        <Typography>Beneficiario: { transaccion && transaccion.account ? transaccion.account.nombre : ""}</Typography>
                        <Typography>Banco: { transaccion && transaccion.account ? Banco(transaccion.account.banco) : ""}</Typography>
                        <Typography>Cuenta: { transaccion && transaccion.account ? transaccion.account.cuenta : ""}</Typography>
                        <Typography>Monto: { transaccion ? currency2decimal(transaccion.bolivares) : ""}</Typography>
                        <FormReferencia 
                            onSubmit={async values => {
                                await dispatch(realizarTransaccion(transaccion.id,{ ...values, workingaccount_id: transaccion.workingaccount_id, estado: "Notificada"}));
                                setModalRealizar(false)}}
                        />
                </Modal>

                <Modal
                    open={transacciones.error && transacciones.error.length > 0 ? true : false}
                    title="Notificación"
                    close={true}
                    handleClose={() => {
                        dispatch(resetError());                       
                    }}
                    >
                        <ul>
                            {transacciones.error && transacciones.error.map((x,id) => <li key={id}>{x}</li>)}
                        </ul>
                </Modal>
                <Snackbar 
                    open={mensajes.data.length > 0 ? true : false}
                    handleClose={() => dispatch(resetMensaje())}
                    message={mensajes.data}
                />
            </>
        )
    }

export default TablaTransacciones
