import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import SelectSearch from '../components/SelectSearch2'
import TextField from './TextField';
import { paises, formatoRut, checkRut } from '../services/utils';
import TiposId from '../constants/TiposId'
import Actividades from '../constants/Actividades'

const validate = (values = {}, props) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'nombre_fantasia',
    'tipo_id',
    'numero_id',
    ... !props.admin ? ['pais', 'ciudad', 'direccion', 'actividad'] : []
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  if (values.tipo_id && values.tipo_id.id !== "Rut" && values.rut && values.rut.length <= 7 ){
    errors.rut = "ID inválido"
  }

  if (values.password && values.password.length < 6 ){
    errors.password = "Password debe contener al menos 6 caracteres"
  }

  if (values.password && values.password_confirmation && values.password !== values.password_confirmation ){
    errors.password = "El password y la confirmación deben coincidir"
    errors.password_confirmation = "El password y la confirmación deben coincidir"
  }

  if ( values.tipo_id && values.tipo_id.id === "Rut" && values.numero_id && !checkRut(values.numero_id) ) {
    errors.numero_id = "Id Inválido"
  }

  return errors
}

const numeros = value => value.replace(/[^\d]/g, '')

const selector = formValueSelector('empresa')

let FormEmpresa = props => {
    const { handleSubmit, invalid, verificado, usuarioSeleccionado, dirty } = props
    const tipo_id = useSelector( state => selector(state, 'tipo_id'))

    return(
        <form noValidate onSubmit={handleSubmit} >
            <Grid container  > 
                <Field
                    type="text"
                    name="nombre"
                    label="Nombre"
                    disabled={verificado ? true : false}
                    component={TextField}
                />

                <Field
                    type="text"
                    name="nombre_fantasia"
                    label="Nombre de Fantasía"
                    disabled={verificado ? true : false}
                    component={TextField}
                />

                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={4} xl={4}>
                            <Field
                                style={{ width: '100%'}}
                                name="tipo_id"
                                label="Tipo ID"
                                disabled={verificado ? true : false}
                                component={SelectSearch}
                                options={TiposId}
                            />
                        </Grid>
                        <Grid item xs={12} lg={8} xl={8}>
                        <Field
                            type="text"
                            name="numero_id"
                            label="Número ID Empresa"
                            disabled={verificado ? true : false}
                            normalize={tipo_id && tipo_id.id === "Rut" ? formatoRut : value => value}
                            component={TextField}
                        />
                        </Grid>
                    </Grid>
                </Grid>

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    name="pais"
                    label="País"
                    component={SelectSearch}
                    disabled={verificado ? true : false}
                    etiqueta="pais"
                    options={paises}
                />

                <Field
                    name="ciudad"
                    label="Ciudad"
                    component={TextField}
                />

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    name="direccion"
                    label="Direccion"
                    component={TextField}
                />

                <Field
                    style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                    name="actividad"
                    label="Actividad"
                    disabled={verificado ? true : false}
                    component={SelectSearch}
                    options={Actividades}
                />

                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center">
                        {usuarioSeleccionado ?
                        <Button disabled={!dirty} variant="contained" color="primary" type="submit">
                            Editar
                        </Button>
                        :
                        <Button disabled={invalid} variant="contained" color="primary" type="submit">
                            Agregar
                        </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </form>
        )
    }


export default FormEmpresa = reduxForm({
  form: 'empresa',
  validate
})(FormEmpresa);
