import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckIcon from '@material-ui/icons/Check';
import { IconButton } from '@material-ui/core'
import Tabla from "../../components/Tabla";
import TablaBeneficiarios from "../beneficiarios/tablaBeneficiarios";
import BotonMenu from "../../components/BotonMenu";
import FormCliente from "../../components/FormCliente";
import { abrirModal } from "../../ducks/Modales";
import { fetchBeneficiarios } from "../../ducks/Beneficiarios";
import {
  borrarCliente,
  seleccionarCliente,
  agregarCliente,
  editCliente,
} from "../../ducks/Clientes";

const TablaClientes = (props) => {
  const {
    seleccionar,
    handleSeleccionar,
    acciones,
    id,
    nombre,
    cedula,
    email,
    telefono,
    activo,
  } = props;
  const clientes = useSelector((state) => state.Clientes);
  const dispatch = useDispatch();

  return (
    <Tabla
      loading={clientes.fetching}
      agregar={() => {
        dispatch(seleccionarCliente(""));
        dispatch(
          abrirModal({
            title: "Formulario Usuarios",
            body: (
              <FormCliente
                onSubmit={async (values) =>
                  await dispatch(agregarCliente(values))
                }
              />
            ),
            close: true,
          })
        );
      }}
      columnas={[
        ...(acciones
          ? [
              {
                title: "Acción",
                field: "accion",
                cellStyle: {
                  width: "10px",
                },
                headerStyle: {
                  width: "10px",
                },
              },
            ]
          : []),
        ...(seleccionar
          ? [
              {
                title: "Seleccionar",
                field: "seleccionar",
                cellStyle: {
                  width: "10px",
                },
                headerStyle: {
                  width: "10px",
                },
              },
            ]
          : []),
        ...(id
          ? [
              {
                title: "ID",
                field: "id",
                cellStyle: {
                  width: "10px",
                },
                headerStyle: {
                  width: "10px",
                },
              },
            ]
          : []),
        ...(nombre
          ? [
              {
                title: "Nombre",
                field: "cliente",
                cellStyle: {},
                headerStyle: {},
              },
            ]
          : []),
        ...(cedula
          ? [
              {
                title: "Cédula",
                field: "cedula",
                cellStyle: {},
                headerStyle: {},
              },
            ]
          : []),
        ...(email
          ? [{ title: "Email", field: "email", cellStyle: {}, headerStyle: {} }]
          : []),
        ...(telefono
          ? [
              {
                title: "Telefono",
                field: "telefono",
                cellStyle: {},
                headerStyle: {},
              },
            ]
          : []),
        ...(activo
          ? [
              {
                title: "Activo",
                field: "active",
                cellStyle: {},
                headerStyle: {},
              },
            ]
          : []),
      ]}
      data={clientes.data.map((x) => ({
        ...x,
        cedula: x.tipo_id ? x.tipo_id + "-" + x.rut : x.rut,
        active: x.active ? "SI" : "NO",
        telefono: "+" + x.codigo + "-" + x.telefono,
        seleccionar: (
          <IconButton onClick={() => handleSeleccionar(x.id)}>
            <CheckIcon />
          </IconButton>
        ),
        accion: (
          <BotonMenu
            editar={true}
            borrar={true}
            activar={true}
            asociaciones={true}
            handleAsociaciones={async () => {
              dispatch(seleccionarCliente(x.id));
              await dispatch(fetchBeneficiarios());
              dispatch(
                abrirModal({
                  title: "Cuentas Asociadas",
                  body: (
                    <TablaBeneficiarios
                      nombre={true}
                      cedula={true}
                      banco={true}
                      cuenta={true}
                      acciones={true}
                      desvincularCliente={true}
                    />
                  ),
                  close: true,
                })
              );
            }}
            handleEditar={() => {
              dispatch(seleccionarCliente(x.id));
              dispatch(
                abrirModal({
                  title: "Formulario Usuarios",
                  body: (
                    <FormCliente
                      onSubmit={async (values) =>
                        dispatch(editCliente({ id: x.id, ...values }))
                      }
                    />
                  ),
                  close: true,
                })
              );
            }}
            handleActivar={() => {
              dispatch(editCliente({ ...x, active: !x.active }));
            }}
            registro={x}
            handleBorrar={() => {
              dispatch(
                abrirModal({
                  title: "Confirmación",
                  body: "¿Está seguro que desea borrar el registro?",
                  action: () => {
                    dispatch(borrarCliente(x.id));
                  },
                  close: true,
                })
              );
            }}
          />
        ),
      }))}
    />
  );
};

export default TablaClientes;
