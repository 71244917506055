import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AuthLayout from '../../components/Auth/AuthLayout'
import FormLogin from '../../components/Auth/FormLogin'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import { loginUsuario, resetErrorUsuario } from '../../ducks/Usuario'
import { getQueryVariable } from '../../services/utils'

const Login = props => {
    const [ confirmacionModal, setConfirmationModal ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState(false)
    const confirmation = getQueryVariable("account_confirmation_success") ? getQueryVariable("account_confirmation_success") : "";
    const confirmationError = getQueryVariable("error") ? getQueryVariable("error") : "";
    const loading = useSelector(state => state.Usuario.adding ? state.Usuario.adding : false )
    const error = useSelector(state => state.Usuario.error)
    const dispatch = useDispatch()
    
    useEffect(() => {
        if(confirmation){
            setConfirmationModal(true)
        }else if(confirmationError){
            setErrorMessage(true)
        }
        
        return () => setConfirmationModal(false)
    },[])

    return(
        <>
            <AuthLayout>
                <FormLogin onSubmit={values => dispatch(loginUsuario(values))} />
            </AuthLayout>
            <Backdrop open={loading} />
            <Snackbar 
                open={confirmacionModal}
                handleClose={() => setConfirmationModal(false) }
                message={"Su cuenta ha sido confirmada exitosamente!"}
            />
            <Snackbar 
                open={errorMessage}
                handleClose={() => setErrorMessage(false) }
                message="Su enlace ya no sirve, solicite otro"
            />
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorUsuario()) }
                message={error && error.map( er => er)}
            />
        </>
    )

}

export default Login