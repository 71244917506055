import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Transacciones from './transacciones'
import { busquedaAvanzadaTransacciones } from '../../ducks/Transacciones'

const TransaccionesRecibidas = props => {

    const dispatch = useDispatch()
    const usuarios = useSelector(state => state.Usuarios)

    const fetchData = () => {
        dispatch(busquedaAvanzadaTransacciones({ state: "Por Aprobar"}))
    }

    useEffect(() => {
        fetchData()
    },[usuarios])


    return (
        <Transacciones 
            title="Transacciones por Aprobar"
            ver={true}
            rechazar={true}
            aprobacionTransaccion={true}
            verificacionUsuario={true}
            acciones={true} 
            id={true} 
            rut={true}
            cliente={true} 
            beneficiario={true} 
            fecha={true} 
            cedula={true} 
            banco={true} 
            cuenta={true} 
            archivo={true} 
            pago={true} 
            recibe={true} 
            pais={true}
            tasa={true}
        />
    )
}

export default TransaccionesRecibidas