import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Typography, Button, Box } from '@material-ui/core'
import Navbar from './Navbar/Navbar'
import MenuDetalle from '../components/Drawer'
import ContenidoMenu from '../components/ContenidoMenu'
import MenuPartners from './MenuPartners'
import TablaTransacciones from './transacciones/tablaTransacciones'
import * as transaccionesDuck from '../ducks/Transacciones'
import * as estadosDuck from '../ducks/Estados'


class Partners extends React.Component{
    
    state={
        loading: true,
    }

    async componentDidMount(){
        const { transaccionesAsociados } = this.props
        await transaccionesAsociados({ state_id: "Pendiente", estatus: "Pendiente"})
        this.setState({ ...this.state, loading: false})
    }


    render(){
        const { transacciones } = this.props
        return (
            <Box mt={15} mb={5} ml={10} mr={5}>
                <Grid container justify="center" alignItems="center" >
                    <Typography variant="h5">Transacciones Pendientes</Typography>
                </Grid>
                <Navbar />
                <MenuDetalle>
                    <ContenidoMenu />
                </MenuDetalle>
                <MenuPartners />
                <TablaTransacciones
                    loading={this.state.loading}
                    id={true} 
                    cliente={true} 
                    beneficiario={true} 
                    fecha={true} 
                    cedula={true} 
                    banco={true} 
                    cuenta={true} 
                    archivo={true} 
                    pago={true} 
                    recibe={true} 
                    operador={true}
                    actualizacion={true}
                    transacciones={transacciones} />
            </Box>

        )
    }
}

const mapStateToProps = state => {
    return {
        transacciones: state.Transacciones,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...transaccionesDuck,
    ...estadosDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Partners)