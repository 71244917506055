import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import TablaBeneficiarios from '../beneficiarios/tablaBeneficiarios'
import FormBusquedaUsuarios from '../../components/FormBusquedaUsuarios'
import FormUsuario from '../../components/FormUsuario'
import FormEmpresa from '../../components/FormEmpresa'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import TarjetaUsuario from '../../components/TarjetaUsuario'
import TarjetaNueva from '../../components/TarjetaNueva'
import Modal from '../../components/Notificacion2'
import { fetchBeneficiariosUsuarios, quitarDeUsuario } from '../../ducks/Beneficiarios'
import { fetchPagadoresUsuarios } from '../../ducks/Friends'
import { crearEmpresa, editUsuarios, borrarUsuario, buscarUsuarios, editarUsuario, editEmpresa, agregarUsuario, resetErrorUsuarios } from '../../ducks/Usuarios'
import { paises } from '../../services/utils';
import TiposId from '../../constants/TiposId';
import Sexos from '../../constants/Sexos';
import TiposCuenta from '../../constants/TiposCuenta';
import Actividades from '../../constants/Actividades';
import Profesiones from '../../constants/Profesiones';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const useStyles = makeStyles({
    container: {
        margin: 10,
    },
});

const Usuarios = props => {
    const classes = useStyles();
    const [ crear, setCrear ] = useState(false)
    const [ modalDetalle, setModalDetalle ] = useState(false)
    const [ modalDetalleEmpresa, setModalDetalleEmpresa ] = useState(false)
    const [ modalEdit, setModalEdit ] = useState(false)
    const [ verAsociaciones, setVerAsociaciones ] = useState(false)
    const [ modalBorrar, setModalBorrar ] = useState(false)
    const [ modalEmpresa, setModalEmpresa ] = useState(false)
    const [ modalEditarEmpresa, setModalEditarEmpresa ] = useState(false)
    const [ verPagadores, setVerPagadores ] = useState(false)
    const [ usuarioSeleccionado, setUsuarioSeleccionado ] = useState(null)
    const [ usuarioSeleccionado2, setUsuarioSeleccionado2 ] = useState(null)
    const usuarios = useSelector(state => state.Usuarios)
    const pagadores = useSelector(state => state.Friends)
    const perfiles = useSelector(state => state.Profiles)
    const loading = useSelector(state => state.Usuarios.fetching || state.Friends.fetching || state.Profiles.fetching )
    const dispatch = useDispatch()

    return (
        <Box mt={15} mb={5} ml={5} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Usuarios Registrados</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Box m={2}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xl={6} lg={6} sm={12}>
                        <FormBusquedaUsuarios perfil={true} onSubmit={values => dispatch(buscarUsuarios({ ...values, profile: values.perfil ? values.perfil.nombre : "" }))} />
                    </Grid>
                </Grid>
            </Box>
            <Grid container className={classes.container}>
                <TarjetaNueva 
                    title="Nuevo Usuario"
                    descripcion="Click aquí para crear un nuevo usuario"
                    handleClick={() => setCrear(true)}
                />
            </Grid>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} lg={12} xl={12}>
                    {usuarios.data.map(x => 
                        <Grid key={x.id} container className={classes.container}>
                            <TarjetaUsuario
                                perfil={true}
                                acciones={true}
                                admin={true}
                                usuario={x}
                                editar={true}
                                borrar={true}
                                asociaciones={true}
                                empresa={true}
                                handleAsociaciones={async () => {
                                    await dispatch(fetchBeneficiariosUsuarios({ ...x, pais: undefined }))
                                    setUsuarioSeleccionado(x)
                                    setVerAsociaciones(true)
                                }}
                                pagadores={true}
                                activar={true}
                                handlePagadores={ async () => {
                                    setUsuarioSeleccionado2(x)
                                    await dispatch(fetchPagadoresUsuarios(x.id));
                                    setVerPagadores(true)
                                }}
                                handleEditar={() => {setUsuarioSeleccionado(x);setModalEdit(true)}}
                                handleBorrar={async () => {setUsuarioSeleccionado(x);setModalBorrar(true)}}
                                handleActivar={ async () => await dispatch(editUsuarios({ ...x, active: !x.active }))}
                                handleEditarEmpresa={() => {setUsuarioSeleccionado(x);setModalEditarEmpresa(true)}}
                                handleDetalle={ () => {setUsuarioSeleccionado(x);setModalDetalle(true)}}
                                handleDetalleEmpresa={() => {
                                    setUsuarioSeleccionado(x)
                                    if(x.company){
                                        setModalDetalleEmpresa(true)
                                    }else{
                                        setModalEmpresa(true)
                                    }
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Modal
                open={crear}
                title="Crear Usuario"
                handleClose={() => setCrear(false)}
                close={true}
            >
                <FormUsuario
                    admin={true}
                    perfil={true}
                    onSubmit={async values => {
                        await dispatch(agregarUsuario({
                            ...values,
                            sexo: values.sexo ? values.sexo.id : null,
                            tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                            password: Math.random().toString(36),
                            nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            profesion: values.profesion ? values.profesion.id : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        }))
                        setCrear(false)
                        
                    }}
                />
            </Modal>

            <Modal
                open={modalEdit}
                title="Editar Usuario"
                close={true}
                handleClose={() => setModalEdit(false)}
            >
                <FormUsuario
                    admin={true}
                    perfil={true}
                    usuarioSeleccionado={usuarioSeleccionado}
                    initialValues={{
                        ...usuarioSeleccionado,
                        sexo: Sexos.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.sexo ) ),
                        pais: paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.pais) ),
                        nacionalidad:  paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.nacionalidad) ),
                        tipo_cuenta:  TiposCuenta.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.tipo_cuenta) ),
                        tipo_id: TiposId.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.tipo_id) ),
                        profesion: Profesiones.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.profesion) ),
                        profile_id: perfiles.data.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.profile_id) ),
                        codigo: paises.find(y => y.phone === (usuarioSeleccionado && usuarioSeleccionado.codigo) ),
                    }}
                    onSubmit={async values => {await dispatch(editarUsuario({
                        ...values,
                        sexo: values.sexo ? values.sexo.id : null,
                        tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                        nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                        pais: values.pais ? values.pais.label : null,
                        tipo_id: values.tipo_id ? values.tipo_id.id : null,
                        profesion: values.profesion ? values.profesion.id : null,
                        codigo: values.codigo ? values.codigo.phone : null,
                        profile_id: values.profile_id ? values.profile_id.id : null,
                        images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                    }))
                    setModalEdit(false)
                    if(verPagadores){
                        await dispatch(fetchPagadoresUsuarios(usuarioSeleccionado2.id))
                    }
                
                }}
                />
                
            </Modal>

            <Modal
                open={modalEmpresa}
                title="Agregar Empresa"
                close={true}
                handleClose={() => setModalEmpresa(false)}
            >
                <FormEmpresa 
                    onSubmit={async values => {
                        await dispatch(crearEmpresa({
                            ...values,
                            usuario: usuarioSeleccionado ? usuarioSeleccionado.id : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        }))
                    }}
                />
                
            </Modal>
            <Modal
                open={modalBorrar}
                title="Confirmación"
                close={true}
                handleClose={() => setModalBorrar(false)}
                action={async () => {
                    await dispatch(borrarUsuario(usuarioSeleccionado.id));
                    setModalBorrar(false) 
                }}
            >
                ¿Está seguro que desea borrar el usuario?
            </Modal>

            <Modal
                open={verPagadores}
                title="Pagadores"
                close={true}
                handleClose={() => setVerPagadores(false)}
            >
                <Grid container justify="center" alignItems="center" >
                    <Grid item xs={12} lg={12} xl={12}>
                        {pagadores.data.length > 0 ? pagadores.data.map(x => 
                            <Grid key={x.id} container className={classes.container}>
                                <TarjetaUsuario 
                                    acciones={true}
                                    admin={true}
                                    usuario={x}
                                    editar={true}
                                    handleEditar={() => {setUsuarioSeleccionado(x);setModalEdit(true)}}
                                />
                            </Grid>
                        ) : "No tiene pagadores asociados!"}
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={verAsociaciones}
                title="Cuentas Asociadas"
                close={true}
                handleClose={() => setVerAsociaciones(false)}
            >
                <TablaBeneficiarios 
                    nombre={true}
                    cedula={true}
                    banco={true}
                    cuenta={true}
                    acciones={true}
                    desvincularDeUsuario={true} 
                    handleDesvincularDeUsuario={id => dispatch(quitarDeUsuario({ usuario: usuarioSeleccionado.id , cuenta: id}))} 
                />
            </Modal>

            <Modal
                open={modalEditarEmpresa}
                title="Editar Empresa"
                close={true}
                handleClose={() => setModalEditarEmpresa(false)}
            >
                <FormEmpresa 
                    usuarioSeleccionado={usuarioSeleccionado}
                    initialValues={{
                        ... usuarioSeleccionado ? usuarioSeleccionado.company : [],
                        pais: paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.pais) ),
                        tipo_id: TiposId.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.tipo_id) ),
                        actividad: Actividades.find(y => y.nombre === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.actividad) ),
                    }}
                    onSubmit={async values => {
                        await dispatch(editEmpresa({
                            ...values,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        })); setModalEditarEmpresa(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalDetalle}
                title="Detalle Usuario"
                handleClose={() => setModalDetalle(false)}
                close={true}
            >
                <Typography color="textSecondary" gutterBottom><strong>Tipo Cuenta:</strong> { usuarioSeleccionado && usuarioSeleccionado.tipo_cuenta ? usuarioSeleccionado.tipo_cuenta : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nombre:</strong> { usuarioSeleccionado && usuarioSeleccionado.nombre ? usuarioSeleccionado.nombre : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Apellido:</strong> { usuarioSeleccionado && usuarioSeleccionado.apellido ? usuarioSeleccionado.apellido : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Identificación:</strong> { usuarioSeleccionado && usuarioSeleccionado.tipo_id && usuarioSeleccionado.rut ? usuarioSeleccionado.tipo_id+"-"+usuarioSeleccionado.rut : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Email:</strong> { usuarioSeleccionado && usuarioSeleccionado.email ? usuarioSeleccionado.email : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Telefono:</strong> { usuarioSeleccionado && usuarioSeleccionado.codigo && usuarioSeleccionado.telefono ? "+"+usuarioSeleccionado.codigo+"-"+usuarioSeleccionado.telefono : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nacionalidad:</strong> { usuarioSeleccionado && usuarioSeleccionado.nacionalidad ? usuarioSeleccionado.nacionalidad : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Pais de Residencia: </strong>{ usuarioSeleccionado && usuarioSeleccionado.pais ? usuarioSeleccionado.pais : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Dirección:</strong> { usuarioSeleccionado && usuarioSeleccionado.direccion ? usuarioSeleccionado.direccion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Profesion:</strong> { usuarioSeleccionado && usuarioSeleccionado.profesion ? usuarioSeleccionado.profesion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Verificado:</strong> { usuarioSeleccionado && usuarioSeleccionado.verified ? "SI": "NO"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>PEP:</strong> { usuarioSeleccionado && usuarioSeleccionado.pep ? "SI": "NO"}</Typography>
                {usuarioSeleccionado && usuarioSeleccionado.UserFile.length > 0 && usuarioSeleccionado.UserFile.map((x,index) =>
                    <div key={index}>
                        <a target="_blank" href={x.archivo}>Archivo {index+1}</a>
                    </div>
                )}
                {usuarioSeleccionado && usuarioSeleccionado.images.length > 0 && usuarioSeleccionado.images.map((x,index) =>
                    <div key={index}>
                        <a target="_blank" href={x}>Archivo {index+1}</a>
                    </div>
                )}
            </Modal>

            <Modal
                open={modalDetalleEmpresa}
                title="Detalle Empresa"
                handleClose={() => setModalDetalleEmpresa(false)}
                close={true}
            >
                <Typography color="textSecondary" gutterBottom><strong>Nombre:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.nombre : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nombre de Fantasía:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.nombre_fantasia : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Identificación:</strong> {usuarioSeleccionado && usuarioSeleccionado.company? usuarioSeleccionado && usuarioSeleccionado.company.tipo_id+"-"+usuarioSeleccionado && usuarioSeleccionado.company.numero_id : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Pais:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.pais : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Ciudad:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.ciudad : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Dirección:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.direccion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Actividad:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.actividad : ""}</Typography>
            </Modal>

            <Snackbar 
                open={usuarios.error && usuarios.error.length > 0 ? true : false}
                handleClose={() => dispatch(resetErrorUsuarios())}
                message={usuarios.error}
            />

            <Backdrop open={loading}/>
        </Box>
    
    )
}

export default Usuarios