import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box, Hidden, IconButton, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';

import { history } from '../../index'
import TarjetaNueva from '../../components/TarjetaNueva';
import Layout from '../../components/Layout'
import MenuClientes from '../../components/MenuClientes'
import Snackbar from '../../components/Snackbar'
import Backdrop from '../../components/Backdrop'
import TarjetaTransaccion from '../../components/TarjetaTransaccion/TarjetaTransaccion'
import FormBusquedaFecha from '../../components/FormBusquedaFecha'
import { fetchTransRealizadasClientes, resetErrorTransRealClientes } from '../../ducks/transacciones/RealizadasClientes';
import { fetchPaises } from '../../ducks/Paises';
import { fetchEstados } from '../../ducks/Estados';
import Colors from '../../constants/Colors'
import moment from 'moment'

const RealizadasClientes = props => {
    const dispatch = useDispatch()
    const [ search, setSearch ] = useState(false)

    const transacciones = useSelector(state => state.TransaccionesRealizadasClientes)
    const error = useSelector(state => state.TransaccionesRealizadasClientes.error)
    const loading = useSelector(state => state.TransaccionesRealizadasClientes.fetching || state.Paises.fetching || state.Estados.fetching)

    useEffect(() => {
        const fetchData = () => {
            if(transacciones.data.length === 0){
                dispatch(fetchTransRealizadasClientes({ state:"Notificada", state2:"Notificada" , fecha_comienzo: moment().format('YYYY-MM-DD'), fecha_final: moment().format('YYYY-MM-DD') }))
            }
            dispatch(fetchEstados())
            dispatch(fetchPaises())
        }
        fetchData()
    }
    ,[])

    return(
        <Layout menu>
            <Grid container justify="space-between" alignItems="center" >
                <Grid item>
                    <Typography variant="h5">Mis Transacciones Realizadas</Typography>
                </Grid>
                <Grid item>
                    <Box p={1}>
                        <IconButton style={{ background: Colors.primary, color: 'white' }} onClick={() => setSearch( prevState => !prevState)}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            
            {search && 
                <Paper>
                    <Box p={4}>
                        <FormBusquedaFecha 
                            onSubmit={values => dispatch(fetchTransRealizadasClientes({ state:"Notificada", state2:"Notificada" , fecha_comienzo: values.fecha_comienzo, fecha_final: values.fecha_final }))}
                        />
                    </Box>
                </Paper>
            }

            <Hidden smDown>
                <MenuClientes />
            </Hidden>
            
            <Box mb={2}>
                <TarjetaNueva 
                    title="Nueva Transacción"
                    descripcion="Click aquí para realizar una transacción"
                    handleClick={() => history.push('/clientes/agregar')}
                />
            </Box>

            {transacciones.data.map(transaccion => 
                <Box key={transaccion.id} mb={2}>
                    <TarjetaTransaccion  transaccion={transaccion} />    
                </Box>
            )}

            
        <Backdrop open={loading} />
        <Snackbar 
            open={error && error.length > 0}
            handleClose={() => dispatch(resetErrorTransRealClientes()) }
            message={error && error.map( er => er)}
        />
        </Layout>

    )
}

export default RealizadasClientes