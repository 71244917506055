import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import SelectSearch from './SelectSearch2'

let FormCajeros = props => {
      const { handleSubmit, pais } = props
      const workingaccounts = useSelector( state => [{id:null, nombre: "Desasignar"}].concat(state.Workingaccounts.data.filter(x => x.country_id === pais)) )
      
      return(
            <form noValidate onSubmit={handleSubmit} >
                <Field
                  style={{ width: '100%', padding: '10px 0px 10px 0px ' }}
                  name="workingaccount"
                  options={workingaccounts}
                  label="Cuentas Cajeros"
                  component={SelectSearch}
                />
                
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Button variant="contained" color="primary" type="submit">
                            Asignar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }


export default FormCajeros = reduxForm({
  form: 'cajeros',
  enableReinitialize : true,
})(FormCajeros);
