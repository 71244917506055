import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Typography, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import Backdrop from '../../components/Backdrop'
import Tabla from '../../components/Tabla'
import TextField from '../../components/TextField'
import SelectSearch from '../../components/SelectSearch2'
import Modal from '../../components/Notificacion2'
import Checkbox from '../../components/Checkbox'
import MenuTransacciones from '../MenuTransacciones'
import { busquedaAvanzadaTransacciones } from '../../ducks/Transacciones'
import { fetchProveedores } from '../../ducks/Proveedores'
import { editarTransaccion } from '../../ducks/Transacciones'
import { currency2decimal } from '../../services/utils'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import TimerIcon from '@material-ui/icons/Timer';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const useStyles = makeStyles( theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(15),
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        }
    },
    buttons: {
        display: 'flex',
    }
}));

const SolicitudesAbiertas = props => {
    const classes = useStyles();
    const [ id, setId ] = useState(null)
    const [ edit, setEdit ] = useState(false)
    const [ borrar, setBorrar ] = useState(false)
    const [ tasa, setTasa ] = useState(false)
    const [ pago, setPago ] = useState(false)
    const [ recibe, setRecibe ] = useState(false)
    const [ proporcional, setProporcional ] = useState(false)
    const [ proveedor, setProveedor ] = useState(false)
    const transacciones = useSelector(state => state.Transacciones )
    const proveedores = useSelector(state => state.Proveedores )
    const loading = useSelector(state => state.Transacciones.fetching )
    const dispatch = useDispatch()
    console.log(proveedor)

    const numeroDecimal = value => {
        let valor
        valor = value.replace(/\./g, '').replace(/\-/g, '')
        let partes = valor.split(",");
        let decimal = partes.length > 1 && partes[1].substring(0,2)
        valor = decimal ? partes[0]+","+decimal : valor
        valor = valor.replace(/\,/g, '.')
        return valor
    }

    const handleChange = (e,value) => {
        if(e.target.name === "tasa"){
            const value = numeroDecimal(e.target.value)
            setTasa(currency2decimal(value))
            proporcional ? setRecibe(currency2decimal(value *numeroDecimal(pago))) : setRecibe(currency2decimal(numeroDecimal(pago))/value)
        }else if(e.target.name === "pago"){
            const value = numeroDecimal(e.target.value)
            setPago(currency2decimal(value))
            setRecibe(currency2decimal(value * numeroDecimal(tasa)))
            proporcional ? setRecibe(currency2decimal(value * numeroDecimal(tasa))) : setRecibe(currency2decimal(value / numeroDecimal(tasa)))
        }else if(e.target.name === "recibe"){
            const value = numeroDecimal(e.target.value)
            setRecibe(currency2decimal(value))
            setPago(currency2decimal(value / numeroDecimal(tasa)))
            proporcional ? setPago(currency2decimal(value / numeroDecimal(tasa))) : setPago(currency2decimal(numeroDecimal(tasa)) * value)
        }else if(e.target.name === "proporcional"){
            setProporcional(value)
            value ? setRecibe(currency2decimal(numeroDecimal(pago) * numeroDecimal(tasa))) : setRecibe(currency2decimal(numeroDecimal(pago) / numeroDecimal(tasa)))    
        }
    }

    const fetchData = () => {
        dispatch(fetchProveedores())
        dispatch(busquedaAvanzadaTransacciones({ state: "Solicitud Abierta", state2: "Solicitud Pagada"}))
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Grid className={classes.root}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Solicitudes Abiertas</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Hidden smDown>
                <MenuTransacciones />
            </Hidden>

            <Tabla 
                grouping={true}
                title="Solicitudes"
                columnas={[
                    { title: 'Acciones', field: 'acciones', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Nº', field: 'id', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Cliente', field: 'cliente', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Beneficiario', field: 'beneficiario', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }},
                    { title: 'Tasa', field: 'tasa', 
                        cellStyle: {
                            minWidth: edit ? '200px' : '10px'
                        },headerStyle: {
                            minWidth: edit ? '200px' : '10px'
                    }},
                    { title: 'Pago', field: 'pago', 
                        cellStyle: {
                            minWidth: edit ? '200px' : '10px'
                        },headerStyle: {
                            minWidth: edit ? '200px' : '10px'
                    }},
                    { title: 'Recibe', field: 'recibe', 
                        cellStyle: {
                            minWidth: edit ? '200px' : '10px'
                        },headerStyle: {
                            minWidth: edit ? '200px' : '10px'
                    }},
                    { title: 'Proveedor', field: 'proveedor', 
                        cellStyle: {
                            minWidth: edit ? '200px' : '10px'
                        },headerStyle: {
                            minWidth: edit ? '200px' : '10px'
                    }}
                ]}
                data={transacciones.data.filter( x => x.active ).map(x => ({
                    ...x,
                    acciones:
                        x.id === id && edit ?
                        <Grid className={classes.buttons}>
                            <IconButton color="primary" onClick={ () => {dispatch(editarTransaccion({ ...x, cantidadPesos: numeroDecimal(pago), bolivares: numeroDecimal(recibe), tasa: numeroDecimal(tasa), provider_id: proveedor.id}));setEdit(false);setId(null)}}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => {setEdit(false);setId(null)}}>
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                        :
                        id && edit ?  
                        <Grid className={classes.buttons}>
                            <IconButton color="primary">
                                <TimerIcon />
                            </IconButton>
                        </Grid>
                        : 
                        <Grid className={classes.buttons}>
                            <IconButton color="primary" onClick={() => {setId(x.id); setTasa(currency2decimal(x.tasa)); setPago(currency2decimal(x.cantidadPesos)); setProveedor(x.provider); setProporcional(true); setRecibe(currency2decimal(x.bolivares)); setEdit(true)}}>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => {setId(x.id); setBorrar(true)}}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        ,
                    cliente: x.customer ? x.customer.cliente : (x.user ? (x.user.nombre + " " + x.user.apellido) : ""),
                    beneficiario: x.account ? x.account.nombre : "",
                    tasa: 
                        x.id === id && edit ? 
                        <Grid>
                            <TextField
                                label="Tasa"
                                input={{
                                    name:"tasa",
                                    value: tasa,
                                    onChange: e => handleChange(e),
                                    onBlur: () => {},
                                }}
                                meta={{
                                    error: false,
                                    touched: false,
                                }}
                            />
                            <Checkbox 
                                label="Proporcional"
                                input={{
                                    name:"proporcional",
                                    value: proporcional,
                                    onChange: (e,value) => handleChange(e,value),
                                    onBlur: () => {}
                                }}
                                meta={{
                                    error: false,
                                    touched: false,
                                }}
                            />

                        </Grid>
                        : 
                        (x.tasa ? currency2decimal(x.tasa) : ""),
                    pago: 
                        x.id === id && edit ? 
                        <TextField
                            label="Pago"
                            input={{
                                name:"pago",
                                value: pago,
                                onChange: e => handleChange(e),
                                onBlur: () => {},
                            }}
                            meta={{
                                error: false,
                                touched: false,
                            }}
                        />  
                        : 
                        (x.cantidadPesos ? currency2decimal(x.cantidadPesos) : ""),
                    recibe: 
                        x.id === id && edit ? 
                        <TextField
                            label="Recibe"
                            input={{
                                name:"recibe",
                                value: recibe,
                                onChange: e => handleChange(e),
                                onBlur: () => {},
                            }}
                            meta={{
                                error: false,
                                touched: false,
                            }}
                        />  
                        : 
                        (x.bolivares ? currency2decimal(x.bolivares) : ""),
                    proveedor: 
                        x.id === id && edit ? 
                        <SelectSearch 
                            label="Proveedor"
                            input={{
                                name:"proveedor",
                                value: proveedor,
                                onChange: value => setProveedor(value),
                                onBlur: () =>{}
                            }}
                            options={proveedores.data.filter(x => x.active)}
                            meta={{
                                error: false,
                                touched: false,
                            }}
                        /> 
                        : 
                        (x.provider ? (x.provider.nombre+"-"+x.provider.descripcion) : "Sin asignar"),
                }))}
            />

            <Modal
                open={borrar}
                close={true}
                handleClose={() => {setId(null); setBorrar(false)}}
                title="Confirmación"
            >
                ¿Estas seguro de que deseas borrar la solicitud?
            </Modal>

            <Backdrop 
                open={loading}
            />

        </Grid>
    )
}

export default SolicitudesAbiertas