import React from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: '100px',
      marginBottom: '100px',
    },
    contacto: {
      //margin: theme.spacing(6),
      //textAlign: 'justify',
      //marginLeft: '20%',
    },
    link: {
        color:"#003520"
    }
}));

function Contacto(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} lg={12} xl={12}  className={classes.contacto}>
                        <Grid container justify="center" alignItems="center" style={{ marginLeft: '20px', marginBottom: '40px'}}  >
                            <Grid item xs={12} sm={2} md={2}  lg={2} xl={2}>
                                <Typography variant="h6" color="primary"><PhoneIcon fontSize="large"/></Typography>  
                            </Grid>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}> 
                                <Typography variant="h6" color="primary"><strong>Teléfono</strong></Typography>
                                <Typography variant="body1" color="primary"><a className={classes.link} target="_blank" href="tel:+56954838838">+56 9 5483 8838</a></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}  className={classes.contacto}>
                        <Grid container justify="center" alignItems="center" style={{ marginLeft: '20px', marginBottom: '40px'}}  >
                            <Grid item xs={12} sm={2} md={2}  lg={2} xl={2}>
                                <Typography variant="h6" color="primary"><WhatsAppIcon fontSize="large"/></Typography>  
                            </Grid>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}> 
                                <Typography variant="h6" color="primary"><strong>WhatsApp</strong></Typography>
                                <Typography variant="body1" color="primary"><a className={classes.link} target="_blank" href="tel:+56954838838">+56 9 5483 8838</a>/<a className={classes.link} href="tel:+50768927563">+507 6892 7563</a></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12} className={classes.contacto}>
                        <Grid container justify="center" alignItems="center"  style={{ marginLeft: '20px', marginBottom: '40px'}}   >
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <Typography variant="h6" color="primary"><EmailIcon fontSize="large"/></Typography>  
                            </Grid>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                                <Typography variant="h6" color="primary"><strong>Correo Electrónico</strong></Typography>
                                <Typography variant="body1" color="primary"><a className={classes.link} target="_blank" href="mailto:contacto@aguacatecambios.com">contacto@aguacatecambios.com</a></Typography> 
                            </Grid>
                        </Grid>
                    </Grid>    
                    <Grid item xs={12} lg={12} xl={12} className={classes.contacto}>
                        <Grid container justify="center" alignItems="center"  style={{ marginLeft: '20px', marginBottom: '40px'}}  >
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <Typography variant="h6" color="primary"><LocationOnIcon fontSize="large"/></Typography>   
                            </Grid>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                                <Typography variant="h6" color="primary"><strong>Oficina</strong></Typography>
                                {/* <Typography variant="body1" color="primary"><a className={classes.link} target="_blank" href="https://goo.gl/maps/fxwjAMnVvZ1rLaNp7">Av. Providencia 1650, Oficina 704. Santiago</a></Typography>  */}
                                <Typography variant="body1" color="primary"><a className={classes.link} target="_blank" href="https://goo.gl/maps/VnqxJwh85fEW3Crz6">Santa Magdalena 74, Oficina 203, Providencia. Region Metropolitana de Santiago.</a></Typography> 
                            </Grid>
                        </Grid>
                    </Grid>    
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container justify="center" alignItems="center" >
                        {/* <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.874889844434!2d-70.61828268501773!3d-33.42650598078075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6383ac6e6b%3A0x24d2440872142db9!2sAv.%20Providencia%201650%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1582833025185!5m2!1ses-419!2scl"} width={450} height={450} frameBorder={0} style={{ border: 0}} allowFullScreen=""></iframe>                     */}
                        <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.0945555364933!2d-70.6116833923577!3d-33.42077910245075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6880536b77%3A0xdc91fdbe47a739a0!2sSta%20Magdalena%2074%2C%20oficina%20203%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sde!4v1612207626037!5m2!1ses!2sde"} width={450} height={450} frameborder={0} style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>                    
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default Contacto;
