import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AuthLayout from '../../components/Auth/AuthLayout'
import FormEmail from '../../components/Auth/FormEmail'
import Modal from '../../components/Notificacion2'
import Backdrop from '../../components/Backdrop'
import Snackbar from '../../components/Snackbar'
import { solicitarPassword, resetErrorUsuario } from '../../ducks/Usuario'

const Password = props => {
    const didMountRef = useRef(false)
    const [ passwordModal, setPasswordModal ] = useState(false)
    const loading = useSelector(state => state.Usuario.fetching)
    const changed = useSelector(state => state.Usuario.fetched)
    const error = useSelector(state => state.Usuario.error)
    const dispatch = useDispatch()

    useEffect(() => {
        if(changed && didMountRef.current){
            setPasswordModal(true)
        }else{
            didMountRef.current = true
        }
    },[loading])

    return(
        <>
            <AuthLayout>
                <FormEmail onSubmit={values => dispatch(solicitarPassword(values))} />  
            </AuthLayout>
            <Backdrop open={loading} />
            <Modal
                open={passwordModal}
                title="Notificación"
                close={true}
                handleClose={() => setPasswordModal(false)}
            >
                Se ha enviado un correo electrónico con las instrucciones para reestablecer su contraseña.
                Si no recibe el correo puede solicitarlo nuevamente, si la situacion persiste comuniquese con nosotros
            </Modal>
            
            <Snackbar 
                open={error && error.length > 0}
                handleClose={() => dispatch(resetErrorUsuario()) }
                message={error && error.map( er => er)}
            />
        </>
    )

}

export default Password

