export const createFetch = ({ START, SUCCESS, ERROR }) => {  
  return{
    [START]: state => ({ ...state, fetching: true, fetched: false }),
    [SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      fetched: true,
      fetching: false,
    }),
    [ERROR]: (state, { error }) => ({
      ...state,
      error,
      fetching: false,
      fetched: false
    }),
}}

export const addFetch = ({ START, SUCCESS, ERROR }) => ({
  [START]: state => ({ ...state, adding: true, added: false }),
  [SUCCESS]: (state, { payload }) => ({
    ...state,
    data: state.data.concat(... payload ? [payload] : []),
    adding: false,
    added: true,
  }),
  [ERROR]: (state, { error }) => ({
    ...state,
    error,
    adding: false,
    added: false
  }),
})

export const addFetchUnShift = ({ START, SUCCESS, ERROR }) => ({
  [START]: state => ({ ...state, adding: true }),
  [SUCCESS]: (state, { payload }) => ({
    ...state,
    data: [ ... payload ? [payload] : [] , ...state.data],
    added: true,
    adding: false,
  }),
  [ERROR]: (state, { error }) => ({
    ...state,
    error,
    adding: false,
  }),
})

export const editFetch = ({ START, SUCCESS, ERROR }) => ({
  [START]: state => ({ ...state, editing: true }),
  [SUCCESS]: (state, { payload }) => ({
    ...state,
    data: state.data.map(x => {
      if(x.id === payload.id)
        return payload 
      else
        return x
    }),
    edited: true,
    editing: false,
  }),
  [ERROR]: (state, { error }) => ({
    ...state,
    error,
    editing: false,
  }),
})

export const delFetch = ({ START, SUCCESS, ERROR }) => ({
  [START]: state => ({ ...state, deleting: true }),
  [SUCCESS]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(x => x.id !== payload),
    deleted: true,
    deleting: false,
  }),
  [ERROR]: (state, { error }) => ({
    ...state,
    error,
    deleting: false,
  }),
})

export const searchHOR = ({ SEARCH }) => ({
  [SEARCH]: state => ({
    ...state,
    fetching: false,
    fetched: true,
    }),
})

export const assignHOR = ({ ASSIGN }) => ({
  [ASSIGN]: (state, { payload }) => ({
    ...state,
    data: payload
    }),
})

export const selectHOR = ({ SELECT }) => ({
  [SELECT]: (state, { payload }) => ({
      ...state,
      data: state.data.map(x => ({
      ...x,
      selected: x.id === payload,
      }))
  }),
})

export const addHOR = ({ ADD }) => ({
    [ADD]: (state, { payload }) => ({
      ...state,
      data: state.data.concat([payload]),
    }),
})
  
export const editHOR = ({ EDIT }) => ({
  [EDIT]: (state, { payload }) => ({
    ...state,
    data: state.data.map(x => {
      if(x.id === payload.id)
        return payload 
      else
        return x
    })
  })
})

export const toggleHOR = ({ TOGGLE }) => ({
  [TOGGLE]: (state, { payload }) => ({
    ...state,
    data: !state.data
  }),
})

export const delHOR = ({ DEL }) => ({
  [DEL]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(x => x.id !== payload)
  }),
})

export const resetHOR = ({ RESET }) => {
  return{
  [RESET]: (state) => ({
    ...state,
    data: [],
    fetched: false,
    fetching: false
  }),
}}

export const resetErrorHOR = ({ RESETERROR }) => ({
  [RESETERROR]: (state) => ({
    ...state,
    error: [],
  }),
})

export const editModalHOR = ({ EDIT }) => ({
  [EDIT]: (state, { payload }) => ({
    ...state,
    data: state.data.map(x => {
      if(x.id === payload.id)
        return { ...x, ...payload} 
      else
        return x
    })
  })
})

export const resetStoreHOR = ({ RESETSTORE, initialState }) => {
  return{
  [RESETSTORE]: (state) => ({
    ...state,
    ...initialState,
    error:null
  })
}}
