import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Typography, Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import TextField from '../TextField';

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'email',
        'password',
    ]
    
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
        }
    })

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Corre Electrónico Inválido'
    }
    
    return errors
}

let LoginForm = props => {
    const { handleSubmit } = props
    return(
        <>
            <form noValidate onSubmit={handleSubmit} >
                <Field
                    type="text"
                    name="email"
                    label="Corre Electrónico"
                    component={TextField}
                    margin="normal"
                    fullWidth
                    autoComplete="email"
                    autoFocus        
                />
                <Field
                    type="password"
                    name="password"
                    label="Contraseña"
                    autoComplete="off"
                    component={TextField}
                    margin="normal"
                    fullWidth        
                />
                <Button  variant="contained" color="primary" fullWidth type="submit">
                    Iniciar Sesión
                </Button>

                <Box m={2}>
                    <Grid container justify="center" alignItems="center"  >
                        <Typography variant="body2" color="primary">Al continuar, estas aceptando nuestros <a style={{ cursor: 'pointer'}}  target="_blank" href="https://aguacate.s3-sa-east-1.amazonaws.com/POLITICAS+DE+PRIVACIDAD.pdf">Términos y Condiciones</a></Typography>
                    </Grid>
                </Box>
            </form>

            <Grid container>
                <Grid item xs>
    
                    <Box my={2}>
                        <Link to= "/password">
                            <Typography color="primary" variant="body2">
                                ¿Olvidaste tu contraseña?
                            </Typography>
                        </Link>
                    </Box>
                    <Box my={2}>
                        <Link to= "/correo-confirmacion">
                            <Typography color="primary" variant="body2">
                                ¿No te llegó el correo de confirmación?
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={2}>
                        <Link to= "/register">
                            <Typography color="primary" variant="body2">
                                ¿No tienes una cuenta? Registrate
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}


LoginForm = reduxForm({
  form: 'login',
  enableReinitialize : true,
  validate,
})(LoginForm);

export default LoginForm
