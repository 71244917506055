import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Paper, Hidden, Typography, Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import BotonMenu from './BotonMenu'
import Colors from '../constants/Colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    content:{
        display: 'flex',
        flexDirection: 'row'
    },
    contentLeft:{
        flex:0.6,
        overflow: 'hidden',
        cursor: 'pointer',
        flexDirection: 'column'
    },
    contentRight:{
        flex: 0.4,
        overflow: 'hidden',
        flexDirection: 'column',
        textAlign: 'end'
    },
    icon: {
        fontSize: 70,
    },
    iconContainer:{
        alignSelf: 'center'
    }
});

const TarjetaUsuario = props => {
    const classes = useStyles();
    const { usuario, perfil, handleDetalle, handleDetalleEmpresa, editar, acciones, borrar, seleccionar, handleSeleccionar, handleEditar, handleBorrar, empresa, handleAsociaciones, handleEditarEmpresa, asociaciones, activar, handleActivar, pagadores, handlePagadores } = props

    return (
        <>
            <Card className={classes.root}>
                <div className={classes.content}>
                    <Hidden smDown>
                        <CardContent className={classes.iconContainer}>
                            <Paper>
                                <PersonIcon style={{ color: usuario.active ? Colors.primary : Colors.secondary}} className={classes.icon}/>
                            </Paper>
                        </CardContent>
                    </Hidden>
                    <CardContent className={classes.contentLeft} >
                        <Grid container className={classes.contentLeft} onClick={handleDetalle}>
                            <Typography variant='overline' gutterBottom>
                                <strong>
                                    {usuario.cliente ? usuario.cliente : ((usuario.nombre ? usuario.nombre : "-") + " " + (usuario.apellido ? usuario.apellido : "-"))}
                                </strong>
                                {perfil && usuario.profile && ( " - " + usuario.profile.nombre)}
                                {usuario.verified && <CheckCircleIcon fontSize="small" style={{ color: Colors.primary }} />}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>
                                    {usuario.email ? usuario.email : "-"}
                                </strong>
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>
                                    {(usuario.codigo ? usuario.codigo : "") +"-"+ (usuario.telefono ? usuario.telefono : "")}
                                </strong>
                            </Typography>
                        </Grid>
                        {usuario.tipo_cuenta === "empresa" && 
                        <Grid container className={classes.contentLeft} onClick={handleDetalleEmpresa}>
                            <Typography variant='body2' style={{color: usuario.company ? "" : "red"}} gutterBottom>
                                <strong>
                                    {usuario.company ? usuario.company.nombre+"-"+usuario.company.numero_id : "Ingresar datos Empresa"}
                                </strong>
                            </Typography>
                        </Grid>
                        }
                    </CardContent>
                    <CardContent className={classes.contentRight}>
                        <Typography variant='body2' gutterBottom>
                            {(usuario.tipo_id ? usuario.tipo_id : "")+ "-"+ (usuario.rut ? usuario.rut : "")}
                        </Typography>
                        {acciones && <BotonMenu 
                            editar={editar}
                            borrar={borrar}
                            registro={usuario}
                            handleEditar={handleEditar}
                            handleBorrar={handleBorrar}
                            activar={activar}
                            handleActivar={handleActivar}
                            asociaciones={asociaciones}
                            pagadores={pagadores}
                            seleccionar={seleccionar}
                            handleSeleccionar={handleSeleccionar}
                            empresa={empresa && usuario.tipo_cuenta === "empresa" && !usuario.company ? true : false}
                            editarEmpresa={empresa && usuario.tipo_cuenta === "empresa" && usuario.company ? true : false}
                            handleEditarEmpresa={handleEditarEmpresa}
                            handlePagadores={handlePagadores}
                            handleAsociaciones={handleAsociaciones}
                            handleEmpresa={handleDetalleEmpresa}

                        />}
                    </CardContent>
                </div>
            </Card>
        </>
    );
}

export default TarjetaUsuario
