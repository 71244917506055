import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import TextField from './TextField';

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'descripcion',
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.nombre && values.nombre.length <= 3 ){
    errors.nombre = "Nombre inválido"
  }

  return errors
}

let FormEstados = props => {
    const { handleSubmit, estadoSeleccionado } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                type="text"
                name="nombre"
                label="Nombre"
                component={TextField}
            />
            <Field
                type="text"
                name="descripcion"
                label="Descripción"
                component={TextField}
            />
            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    {estadoSeleccionado ? 
                    <Button variant="contained" color="primary" type="submit">
                        Editar Estado
                    </Button>
                    :
                    <Button variant="contained" color="primary" type="submit">
                        Agregar Estado
                    </Button>}
                </Grid>
            </Grid>
        </form>
    )
}

FormEstados = reduxForm({
  form: 'estados',
  enableReinitialize : true,
  validate,
})(FormEstados);

export default FormEstados
