import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box, Hidden, IconButton } from '@material-ui/core'
import LoopIcon from '@material-ui/icons/Loop';

import { history } from '../../index'
import TarjetaNueva from '../../components/TarjetaNueva';
import Layout from '../../components/Layout'
import MenuClientes from '../../components/MenuClientes'
import Snackbar from '../../components/Snackbar'
import Backdrop from '../../components/Backdrop'
import TarjetaTransaccion from '../../components/TarjetaTransaccion/TarjetaTransaccion'
import { fetchTransPendientesClientes, resetErrorTransPendClientes } from '../../ducks/transacciones/PendientesClientes';
import { fetchPaises } from '../../ducks/Paises';
import { fetchEstados } from '../../ducks/Estados';

const PendientesClientes = props => {
    const dispatch = useDispatch()

    const transacciones = useSelector(state => state.TransaccionesPendientesClientes)
    const error = useSelector(state => state.TransaccionesPendientesClientes.error)
    const loading = useSelector(state => state.TransaccionesRealizadasClientes.fetching || state.Paises.fetching || state.Estados.fetching)

    useEffect(() => {
        const fetchData = () => {
            if(transacciones.data.length === 0){
                dispatch(fetchTransPendientesClientes({ state:"Por Aprobar", state2:"Pendiente"}))
            }
            dispatch(fetchEstados())
            dispatch(fetchPaises())
        }
        fetchData()
    }
    ,[])

    return(
        <Layout menu>
            <Grid container justify="space-between" alignItems="center" >
                <Grid item>
                    <Typography variant="h5">Mis Transacciones Pendientes</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => dispatch(fetchTransPendientesClientes({ state:"Por Aprobar", state2:"Pendiente"}))}>
                            <LoopIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Hidden smDown>
                <MenuClientes />
            </Hidden>
            
            <Box mb={2}>
                <TarjetaNueva 
                    title="Nueva Transacción"
                    descripcion="Click aquí para realizar una transacción"
                    handleClick={() => history.push('/clientes/agregar')}
                />
            </Box>

            {transacciones.data.map(transaccion => 
                <Box key={transaccion.id} mb={2}>
                    <TarjetaTransaccion  transaccion={transaccion} />    
                </Box>
            )}

            
        <Backdrop open={loading} />
        <Snackbar 
            open={error && error.length > 0}
            handleClose={() => dispatch(resetErrorTransPendClientes()) }
            message={error && error.map( er => er)}
        />
        </Layout>

    )
}

export default PendientesClientes