import React from 'react'
import Navegacion from '../Navegacion/Navegacion'
import Footer from '../Footer/Footer'
import CopyRight from '../CopyRight/CopyRight'
import Scroll from '../Scroll/Scroll'

const HomeLayout = props => {
    return(
        <>
            <Navegacion />
            {props.children}
            <Footer />
            <CopyRight />
            <Scroll />
        </>
    )
}

export default HomeLayout