import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import TextField from './TextField';
import SelectSearch from './SelectSearch2';

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'descripcion',
    'minimo',
    'maximo',
    'country_id'
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if (values.nombre && values.nombre.length < 3 ){
    errors.nombre = "Nombre debe tener menos de caracteres"
  }

  if (values.descripcion && values.descripcion.length < 3 ){
    errors.descripcion = "Descripción debe tener mas caracteres"
  }

  if (values.minimo && values.maximo && (parseInt(values.minimo) > parseInt(values.maximo) ) ){
    errors.minimo = "valor minimo no puede ser mayor que el maximo"
    errors.maximo = "valor minimo no puede ser mayor que el maximo"
  }

  return errors
}

const currency = value => {
    if(typeof(value) === "number"){
      value = value.toString()
    }
    let stringValue = value && value.replace(/\./g, '').replace(/\,/g, '');
    let valor = stringValue && parseFloat(stringValue);
    valor = valor && valor.toLocaleString('en-US').replace(/\,/g, '.');
    return valor ? valor : '';
  };
  
const numero = num => {
  let value = num && num.toLocaleString().replace(/\./g,"").replace(/\,/g, '')
  let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? "" : parseInt(value)) : value
  return valor
}  

let FormRangos = props => {
    const { handleSubmit, paises, initialValues, change, rangoSeleccionado } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                required
                type="text"
                name="nombre"
                label="Nombre"
                component={TextField}/>

            <Field
                required
                type="text"
                name="descripcion"
                label="Descripción"
                component={TextField}/>

            <Field
                required
                type="text"
                name="minimo"
                label="Mínimo"
                format={currency}
                normalize={numero}
                component={TextField}/>

            <Field
                required
                type="text"
                name="maximo"
                label="Máximo"
                format={currency}
                normalize={numero}
                component={TextField}/>

            <Field
                required
                style={{ width: '100%', padding: '10px 0px 10px 0px '}}
                name="country_id"
                disableClearable={true}
                label="País"
                options={paises ? paises.data.filter(x => x.active) : []}
                component={SelectSearch}/>
                
            <Grid item xs={12}>
                <Grid container justify="center" alignItems="center"  >
                    {rangoSeleccionado ? 
                    <Button variant="contained" color="primary" type="submit">
                        Editar Rango
                    </Button>
                    :
                    <Button variant="contained" color="primary" type="submit">
                        Agregar Rango
                    </Button>}
                </Grid>
            </Grid>
        </form>
    )
}

FormRangos = reduxForm({
  form: 'rangos',
  enableReinitialize : true,
  validate,
})(FormRangos);

export default FormRangos
