import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../img/logo.png'
import { Typography, Container, Grid } from '@material-ui/core';
import { history } from '../index'
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        zIndex: 2

    },
    z2: {
        zIndex: 2
    }
});


function Page(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <Container fixed>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>

                    <div className={classes.root}>
                        <a style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
                            <img style={{
                                height: 250, display: 'flex',
                                alignItems: 'center', marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '100px'
                            }} src={logo} alt="logo"/>
                        </a>
                        <Container >
                            <Grid container justify="center" alignItems="center"  >
                                <Typography variant="h6">Estamos trabajando para ti, pronto tendrás acceso a esta sección</Typography>
                            </Grid>
                        </Container>
                    </div>

                </div>
            </Container>
        </Fragment>
    );
}

export default Page;
