import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, resetErrorHOR, resetHOR } from '../hors.js'
import { getRequest } from '../../services/api'

const t = makeTypes('transacciones-realizadas-clientes')
const FETCH = t('fetch').async()
const RESET = t('reset').single()
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
export const resetTransRealClientes = mac(RESET)
export const resetErrorTransRealClientes = mac(RESETERROR)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))

export default reduceReducers(fetchReducer, resetErrorReducer, resetReducer)

export const fetchTransRealizadasClientes = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/clpbstransactions/transactions-users?state=${values.state}&state2=${values.state2}&fecha_comienzo=${values.fecha_comienzo ? values.fecha_comienzo : ''}&fecha_final=${values.fecha_final ? values.fecha_final : ''}` )
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(["Ocurrió un error, intente de nuevo o contacte al Administrador"]))
    }
}