import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { IconButton, Typography } from '@material-ui/core'
import Tabla from '../components/Tabla'
import BotonMenu from '../components/BotonMenu'
import CheckIcon from '@material-ui/icons/Check';
import FormMonto from '../components/FormMonto';
import * as beneficiariosDuck from '../ducks/Beneficiarios'
import * as pasosDuck from '../ducks/Pasos'
import * as modalesDuck from '../ducks/Modales'
import { Banco } from '../services/utils'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import * as beneficiariosSeleccionadosDuck from '../ducks/BeneficiariosSeleccionados';
import { currency } from '../services/utils'

class TablaBeneficiarios extends React.Component{   

    handleClick = values => {
        const { asignarBeneficiario, pasoActivo, asignarPaso, suma } = this.props
        asignarBeneficiario([values]) 
        asignarPaso( pasoActivo.data +1 )
    }

    render(){
        const { cerrarModal, 
            detalle,
            beneficiarios, 
            modales, 
            agregarBeneficiarioSeleccionado, 
            seleccionarBeneficario, 
            anadir, 
            tasas, 
            paises, 
            borrarCuenta, 
            editCuentas, 
            quitarDeCliente, 
            quitarDeUsuario, 
            agregar,
            agregarCuenta, 
            desvincularUsuario, 
            desvincularCliente, 
            beneficiariosAgregados,
            seleccionar, 
            acciones, 
            beneficiariosSeleccionados,
            pais,
            activo,
            suma,
            agregarBeneficiarioUsuario,
            abrirModal } = this.props
        return(
            <Tabla
                loading={beneficiarios.fetching}
                agregar={agregar && ( () => {console.log("asd") })}
                columnas={[
                    ... agregarCuenta ? [{ title: 'Agregar', field: 'agregar', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ... seleccionar ? [{ title: 'Seleccionar', field: 'seleccionar', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ... acciones ? [{ title: 'Acción', field: 'accion', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ... desvincularUsuario ? [{ title: 'Desvincular', field: 'quitarUsuario', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ... desvincularCliente ? [{ title: 'Borrar', field: 'quitarCliente', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    { title: 'Nombre', field: 'nombre', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Cedula', field: 'cedula',
                        cellStyle: {
                        },headerStyle: {
                    }},
                    { title: 'Banco', field: 'banco', 
                        cellStyle: {
                        },headerStyle: {
                        
                    }},
                    { title: 'Cuenta', field: 'cuenta', 
                        cellStyle: {
                        },headerStyle: {
                    }},
                    ... pais ? [{ title: 'País', field: 'pais', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ... activo ? [{ title: 'Activo', field: 'active', 
                        cellStyle: {
                            width: '10px',
                        },headerStyle: {
                            width: '10px',
                    }}] : [],
                    ]}
                    data={beneficiarios.data.filter((value,index,array) => index === array.findIndex( x => (x.cuenta === value.cuenta))).map(x => ({
                        ...x,
                        banco: Banco(x.banco),
                        cedula: x.idTipo ? (x.idTipo + "-"+ x.idNumero) : "",
                        cuenta: x.tipoCuenta + "-" + x.cuenta,
                        agregar: <IconButton onClick={ () => {
                            if(beneficiariosAgregados.data.find(y => y.cuenta === x.cuenta)){
                                abrirModal({title:"Notificación", body: `Ya este beneficiario se encuentra asociado a su cuenta`, close:true})
                            }else{
                                agregarBeneficiarioUsuario(x.id)
                            }
                        }}><AddIcon /></IconButton>,
                        anadir: <IconButton onClick={ () => {
                            if(beneficiariosSeleccionados.data.find(y => y.cuenta === x.cuenta)){
                                abrirModal({title:"Notificación", body: `Ya agregó una transacción a esta cuenta, debe elegir una distinta`, close:true})
                            }else{
                                if(detalle.data.origen/(beneficiariosSeleccionados.data.length+1) >= 10000){
                                    seleccionarBeneficario(x.id);
                                    abrirModal({title:"Añadir Cambio", body:<div><Typography variant="body1">{` Ingresa cuanto deseas enviar a ${x.nombre} - ${Banco(x.banco)}`}</Typography><Typography variant="h6"><strong>{`(Saldo Disponible: ${currency(detalle.data.origen - suma)}):`}</strong></Typography> <FormMonto 
                                    onSubmit={values => {agregarBeneficiarioSeleccionado({ ...x, ...values})}} tasas={tasas}/></div>,close:true})
                                }else{
                                    abrirModal({title:"Notificación", body: `No puede agregar mas de ${beneficiariosSeleccionados.data.length} transacciones por el cambio de ${detalle.data.origen}. Verifica el carro de compras`, close:true})
                                }   
                            }
                        }}><AddIcon /></IconButton>,
                        quitarUsuario: <IconButton onClick={() => {abrirModal({title:"Confirmación", body:"¿Estás seguro que deseas desvincular esta cuenta?", close:true, action:() => quitarDeUsuario(x.id)})}}><DeleteIcon /></IconButton>,
                        quitarCliente: <IconButton onClick={() => {abrirModal({title:"Confirmación", body:"¿Estás seguro que deseas desvincular esta cuenta?", close:true, action:() => quitarDeCliente(x.id)})}}><DeleteIcon /></IconButton>,
                        seleccionar: <IconButton onClick={ () => this.handleClick(x) }><CheckIcon /></IconButton>,
                        active: x.active ? "SI" : "NO",
                        pais: paises.data.find(y => y.id === x.country_id) ? paises.data.find(y => y.id === x.country_id).nombre : "",
                        accion: 
                            <BotonMenu 
                                editar={true}
                                borrar={true}
                                activar={true}
                                //handleEditar={() => {selectRango(x.id); abrirModal({title:"Formulario Rango", body:<FormularioRangos />, close: true })}} 
                                handleActivar={() => {editCuentas({ ...x, active: !x.active })}} registro={x} 
                                handleBorrar={() => {abrirModal({ title: "Confirmación", body:"¿Está seguro que desea borrar el registro?", action: () => {borrarCuenta(x.id)}, close: true})}}/>,
                }))} 
            />
        )
    }
}

const mapStateToProps = state => {
    const suma = state.BeneficiariosSeleccionados.data.map(x => x.origen).length > 0 ? state.BeneficiariosSeleccionados.data.map(x => x.origen).reduce((a,b) => a+b) : 0
    return {
        beneficiariosSeleccionados: state.BeneficiariosSeleccionados,
        beneficiarios: state.BeneficiariosSugeridos,
        beneficiariosAgregados: state.Beneficiarios,
        pasoActivo: state.Pasos,
        paises: state.Paises,
        tasas: state.Tasas,
        modales: state.Modales,
        detalle: state.DetalleCambio,
        suma,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...beneficiariosDuck,
    ...pasosDuck,
    ...modalesDuck,
    ...beneficiariosSeleccionadosDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TablaBeneficiarios)
