import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Grid } from '@material-ui/core'
import TextField from './TextField';

const validate = (values = {}) => {
    const errors = {}
    const requiredFields = [
        'nombre',
        'descripcion',
    ]
    
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = true
        }
    })

    if (values.nombre && values.nombre.length <= 3 ){
        errors.nombre = "Nombre inválido"
    }

    return errors
}

let FormPerfiles = props => {
    const { handleSubmit, perfilSeleccionado } = props
    return(
        <form noValidate onSubmit={handleSubmit} >
            <Field
                type="text"
                name="nombre"
                label="Nombre"
                component={TextField}
            />

            <Field
                type="text"
                name="descripcion"
                label="Descripción"
                component={TextField}
            />

            <Grid item xs={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center"  >
                    {perfilSeleccionado ? 
                    <Button variant="contained" color="primary" type="submit">
                        Editar Perfil
                    </Button>
                    :
                    <Button variant="contained" color="primary" type="submit">
                        Agregar Perfil
                    </Button>}
                </Grid>
            </Grid>
        </form>
    )
}

FormPerfiles = reduxForm({
  form: 'perfiles',
  enableReinitialize : true,
  validate,
})(FormPerfiles);

export default FormPerfiles
