import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const perfil = useSelector(state => state.Usuario.data[0]);
    const url = useSelector(state => state.Url.data)
    return (
        <Route {...rest} render={(props) => (
                perfil && perfil.profile && (restricted || perfil.profile.nombre === "Mayorista") ?
                    (perfil.profile.nombre === "Mayorista" ? 
                        (perfil.nombre ?
                        <Redirect to="/mayorista/pagadores" />
                        : 
                        <Redirect to="/configuracion/perfil" />) 
                    :
                    <Redirect to={url === window.location.pathname ? "/" : url}/>)
                : 
                <Component {...props} />
        )} />
    );
}

export default PublicRoute;