import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import TarjetaUsuario from '../../components/TarjetaUsuario'
import FormUsuario from '../../components/FormUsuario'
import FormEmpresa from '../../components/FormEmpresa'
import TarjetaNueva from '../../components/TarjetaNueva'
import Modal from '../../components/Notificacion2'
import Snackbar from '../../components/Snackbar'
import Backdrop from '../../components/Backdrop'
import { paises } from '../../services/utils';
import TiposId from '../../constants/TiposId';
import Sexos from '../../constants/Sexos';
import TiposCuenta from '../../constants/TiposCuenta';
import Actividades from '../../constants/Actividades';
import Profesiones from '../../constants/Profesiones';
import { fetchPagadoresUsuarios, agregarUsuario, resetErrorUsuarios, editarUsuario, crearEmpresa, editEmpresa } from '../../ducks/Usuarios'
import { fetchProfiles } from '../../ducks/Profiles'
import { resetMensaje } from '../../ducks/Mensajes'

const useStyles = makeStyles({
    container: {
        margin: 10,
    },
});

const Pagadores = props => {
    const classes = useStyles();

    const [ modalDetalle, setModalDetalle ] = useState(false) 
    const [ modalDetalleEmpresa, setModalDetalleEmpresa ] = useState(false) 
    const [ crear, setCrear ] = useState(false) 
    const [ editar, setEditar ] = useState(false) 
    const [ borrar, setBorrar ] = useState(false) 
    const [ empresa, setEmpresa ] = useState(false) 
    const [ editarEmpresa, setEditarEmpresa ] = useState(false) 
    const [ usuarioSeleccionado, setUsuarioSeleccionado ] = useState(null) 
    const usuario = useSelector(state => state.Usuario.data[0])
    const usuarios = useSelector(state => state.Usuarios)
    const perfiles = useSelector(state => state.Profiles)
    const mensajes = useSelector( state => state.Mensajes)
    const errores = useSelector(state => state.Usuarios.error)
    const loading = useSelector(state => state.Usuarios.fetching || state.Usuarios.adding || state.Usuarios.editing)
    const dispatch = useDispatch()

    console.log(perfiles)

    const fetchData = () => {
        dispatch(fetchPagadoresUsuarios(usuario.id))
        dispatch(fetchProfiles())
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <Box mt={15} mb={5} ml={5} mr={5}>
            <Grid container justify="center" alignItems="center" >
                <Typography variant="h5">Usuarios Registrados</Typography>
            </Grid>
            <Navbar />
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Grid container className={classes.container}>
                <TarjetaNueva 
                    title="Nuevo Pagador"
                    descripcion="Click aquí para crear un nuevo pagador"
                    handleClick={() => setCrear(true)}
                />

            </Grid>

            {usuarios.data.map(x => 
                <Grid key={x.id} container className={classes.container}>
                    <TarjetaUsuario 
                        usuario={x}
                        acciones={true}
                        editar={true}
                        borrar={true}
                        empresa={true}
                        handleEditar={() => {setUsuarioSeleccionado(x);setEditar(true)}}
                        handleBorrar={() => {setUsuarioSeleccionado(x);setBorrar(true)}}
                        handleEditarEmpresa={() => {setUsuarioSeleccionado(x); setEditarEmpresa(true)}}
                        handleDetalle={() => {setUsuarioSeleccionado(x);setModalDetalle(true)}}
                        handleDetalleEmpresa={() => {
                            setUsuarioSeleccionado(x)
                            if(x.company){
                                setModalDetalleEmpresa(true)
                            }else{
                                setEmpresa(true)
                            }
                        }}
                    />
                </Grid>
            )}
            
            <Modal
                open={crear}
                title="Crear Usuario"
                handleClose={() => setCrear(false)}
                loading={loading}
                close={true}
            >
                <FormUsuario 
                    onSubmit={async values => {
                        await dispatch(agregarUsuario({
                            ...values,
                            sexo: values.sexo ? values.sexo.id : null,
                            tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                            password: Math.random().toString(36),
                            nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            profesion: values.profesion ? values.profesion.id : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            profile_id: perfiles.data.find(perfil => perfil.nombre === "Mayorista").id,
                            friend: true,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        }))
                        setCrear(false)
                        
                    }}
                />
            </Modal>

            <Modal
                open={editar}
                title="Editar Usuario"
                handleClose={() => setEditar(false)}
                loading={loading}
                close={true}
            >
                <FormUsuario 
                    verificado={usuarioSeleccionado && usuarioSeleccionado.verified}
                    usuarioSeleccionado={usuarioSeleccionado}
                    initialValues={{
                        ...usuarioSeleccionado,
                        sexo: Sexos.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.sexo ) ),
                        pais: paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.pais) ),
                        nacionalidad:  paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.nacionalidad) ),
                        tipo_cuenta:  TiposCuenta.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.tipo_cuenta) ),
                        tipo_id: TiposId.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.tipo_id) ),
                        profesion: Profesiones.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.profesion) ),
                        profile_id: perfiles.data.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.profile_id) ),
                        codigo: paises.find(y => y.phone === (usuarioSeleccionado && usuarioSeleccionado.codigo) ),
                    }}
                    onSubmit={async values => {
                        await dispatch(editarUsuario({
                            ...values,
                            sexo: values.sexo ? values.sexo.id : null,
                            tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                            nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            profesion: values.profesion ? values.profesion.id : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            profile_id: values.profile_id ? values.profile_id.id : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        }))
                        setEditar(false)
                    }}
                        
                />

            </Modal>
            <Modal
                open={borrar}
                title="Borrar Usuario"
                handleClose={() => setBorrar(false)}
                close={true}
            >
                ¿Estas seguro de desvincular a este usuario? (Este registro no puede ser borrado)

            </Modal>
            <Modal
                open={empresa}
                title="Crear Empresa"
                handleClose={() => setEmpresa(false)}
                close={true}
            >
                <FormEmpresa 
                    onSubmit={async values => {
                        await dispatch(crearEmpresa({
                            ...values,
                            usuario: usuarioSeleccionado ? usuarioSeleccionado.id : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        })); setEmpresa(false)
                    }}
                />
            </Modal>
            <Modal
                open={editarEmpresa}
                title="Editar Empresa"
                handleClose={() => setEditarEmpresa(false)}
                close={true}
            >
                <FormEmpresa 
                    verificado={usuarioSeleccionado && usuarioSeleccionado.verified}
                    usuarioSeleccionado={usuarioSeleccionado}
                    initialValues={{
                        ... usuarioSeleccionado ? usuarioSeleccionado.company : [],
                        pais: paises.find(y => y.label === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.pais) ),
                        tipo_id: TiposId.find(y => y.id === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.tipo_id) ),
                        actividad: Actividades.find(y => y.nombre === (usuarioSeleccionado && usuarioSeleccionado.company && usuarioSeleccionado.company.actividad) ),
                    }}
                    onSubmit={async values => {
                        await dispatch(editEmpresa({
                            ...values,
                            usuario: usuarioSeleccionado ? usuarioSeleccionado.id : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        }));setEditarEmpresa(false)
                    }}
                />
            </Modal>

            <Modal
                open={modalDetalle}
                title="Detalle Usuario"
                handleClose={() => setModalDetalle(false)}
                close={true}
            >
                <Typography color="textSecondary" gutterBottom><strong>Tipo Cuenta:</strong> { usuarioSeleccionado && usuarioSeleccionado.tipo_cuenta ? usuarioSeleccionado.tipo_cuenta : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nombre:</strong> { usuarioSeleccionado && usuarioSeleccionado.nombre ? usuarioSeleccionado.nombre : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Apellido:</strong> { usuarioSeleccionado && usuarioSeleccionado.apellido ? usuarioSeleccionado.apellido : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Identificación:</strong> { usuarioSeleccionado && usuarioSeleccionado.tipo_id && usuarioSeleccionado.rut ? usuarioSeleccionado.tipo_id+"-"+usuarioSeleccionado.rut : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Email:</strong> { usuarioSeleccionado && usuarioSeleccionado.email ? usuarioSeleccionado.email : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Telefono:</strong> { usuarioSeleccionado && usuarioSeleccionado.codigo && usuarioSeleccionado.telefono ? "+"+usuarioSeleccionado.codigo+"-"+usuarioSeleccionado.telefono : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nacionalidad:</strong> { usuarioSeleccionado && usuarioSeleccionado.nacionalidad ? usuarioSeleccionado.nacionalidad : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Pais de Residencia: </strong>{ usuarioSeleccionado && usuarioSeleccionado.pais ? usuarioSeleccionado.pais : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Dirección:</strong> { usuarioSeleccionado && usuarioSeleccionado.direccion ? usuarioSeleccionado.direccion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Profesion:</strong> { usuarioSeleccionado && usuarioSeleccionado.profesion ? usuarioSeleccionado.profesion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Verificado:</strong> { usuarioSeleccionado && usuarioSeleccionado.verified ? "SI": "NO"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>PEP:</strong> { usuarioSeleccionado && usuarioSeleccionado.pep ? "SI": "NO"}</Typography>
                {/* {usuarioSeleccionado && usuarioSeleccionado.UserFile.length > 0 && usuarioSeleccionado.UserFile.map((x,index) =>
                    <div key={index}>
                        <a target="_blank" href={x.archivo}>Archivo {index+1}</a>
                    </div>
                )}
                {usuarioSeleccionado && usuarioSeleccionado.images.length > 0 && usuarioSeleccionado.images.map((x,index) =>
                    <div key={index}>
                        <a target="_blank" href={x}>Archivo {index+1}</a>
                    </div>
                )} */}
            </Modal>

            <Modal
                open={modalDetalleEmpresa}
                title="Detalle Empresa"
                handleClose={() => setModalDetalleEmpresa(false)}
                close={true}
            >
                <Typography color="textSecondary" gutterBottom><strong>Nombre:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.nombre : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Nombre de Fantasía:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.nombre_fantasia : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Identificación:</strong> {usuarioSeleccionado && usuarioSeleccionado.company? usuarioSeleccionado && usuarioSeleccionado.company.tipo_id+"-"+usuarioSeleccionado && usuarioSeleccionado.company.numero_id : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Pais:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.pais : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Ciudad:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.ciudad : "-"}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Dirección:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.direccion : ""}</Typography>
                <Typography color="textSecondary" gutterBottom><strong>Actividad:</strong> {usuarioSeleccionado && usuarioSeleccionado.company ? usuarioSeleccionado && usuarioSeleccionado.company.actividad : ""}</Typography>
            </Modal>

            <Backdrop open={usuarios.fetching}/>

            <Snackbar 
                open={mensajes.data.length > 0 ? true : false}
                handleClose={() => dispatch(resetMensaje())}
                message={mensajes.data}
            />

            <Modal
                open={errores && errores.length > 0 ? true : false}
                title="Notificación"
                handleClose={() => dispatch(resetErrorUsuarios())}
                close={true}
            >
                {errores && errores.length > 0 ? errores.map((x,index) => <ul key={index}><li>{x}</li></ul>) : "Ocurrió un error, intente nuevamente"}
            </Modal> 
        </Box>
    
    )
}

export default Pagadores