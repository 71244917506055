import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import logo from '../../img/logo.png';
import * as menuDuck from '../../ducks/Menu';
import * as modalesDuck from '../../ducks/Modales';
import * as usuarioDuck from '../../ducks/Usuario';
import { history } from '../../index'

const useStyles = makeStyles(theme => ({
  root: {
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  name:{
    flexGrow: 10,
    textAlign: 'right',
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 3,
    background: 'linear-gradient(to left, #003520 20%, #FBF1BA 100%)',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

function MenuAppBar(props) {
    const { cerrarUsuario, abrirModal, usuario } = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawer = () => {
        const { abrirMenu } = props
        abrirMenu(true)
    }

    return (
        <AppBar position="fixed" className={classes.appBar} >
            <Toolbar>
            <IconButton onClick={handleDrawer} edge="start" className={classes.menuButton} color="primary" aria-label="menu">
                <MenuIcon />
            </IconButton>
            <a className={classes.title} style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
                <img src={logo} style={{ width: '100px' }}  />
            </a>
            <Grid>
                <Typography variant="body1" color="inherit">
                <strong>Bienvenido</strong>
                </Typography>
                <Typography variant="body2" color="inherit">
                    {usuario.data[0] ? usuario.data[0].nombre : ""}
                </Typography>
            </Grid>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit">
                <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem onClick={e => history.push('/configuracion/perfil')}>Perfil</MenuItem>
                    <MenuItem onClick={e => abrirModal({title:"Confirmación", body:"¿Está seguro que desea cerrar sesión?", close:true, action: e => cerrarUsuario() })}>Cerrar Sesión</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

const mapStateToProps = state => {
  return {
    usuario: state.Usuario
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...modalesDuck,
  ...usuarioDuck,
  ...menuDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar)
