import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, CardContent, Paper, Hidden, Typography, ButtonBase} from '@material-ui/core';
import PropTypes from 'prop-types';

import MenuButton from '../MenuButton/MenuButton'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Colors from '../../constants/Colors'
import Modal from '../Notificacion2'
import { Banco, currency8decimal } from '../../services/utils';
import moment from "moment";

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    contentLeft:{
		flex:0.6,
        overflow: 'hidden',
        cursor: 'pointer',
        flexDirection: 'column'
    },
    contentRight:{
		flex: 0.4,
        overflow: 'hidden',
        flexDirection: 'column',
        textAlign: 'end'
    },
    icon: {
        fontSize: 70,
    },
    iconContainer:{
        alignSelf: 'center'
    }
});

const TarjetaTransaccion = props => {
	const { buttons, handleClick, transaccion } = props
	const [ detail, setDetail ] = useState(false)
	const paises = useSelector(state => state.Paises.data)
	const estados = useSelector(state => state.Estados.data)
	const estado = estados.find(est => est.id === transaccion?.state_id )?.nombre
	const paisOrigen = paises.find(est => est.id === transaccion?.exchange_rate.origen_id)?.nombre
	const paisDestino = paises.find(est => est.id === transaccion?.exchange_rate.destino_id)?.nombre
	const classes = useStyles();

	const handleDetail = () => {
		setDetail(true)
	}

	return (
		<>
			<Card className={classes.root}>
				<Grid container direction="row">
					<Hidden smDown>
						<CardContent className={classes.iconContainer}>
							<Paper>
								<SwapHorizIcon style={{ color: Colors.primary }} className={classes.icon}/>
							</Paper>
						</CardContent>
					</Hidden>
					<CardContent className={classes.contentLeft} onClick={handleClick ? handleClick : handleDetail }>
						<Typography variant='overline' gutterBottom>
							<strong>
								{transaccion?.account.nombre + " - " + Banco(transaccion?.account.banco)}
							</strong>
						</Typography>
						<Typography variant='body2' gutterBottom>
							{transaccion?.account.idTipo+" - 	"+transaccion?.account.idNumero}
						</Typography>
						<Typography variant='body2' gutterBottom>
							Envía: {currency8decimal(transaccion?.cantidadPesos)}
						</Typography>
						<Typography variant='body2' gutterBottom>
							Recibe: {currency8decimal(transaccion?.bolivares)}
						</Typography>
					</CardContent>
					<CardContent className={classes.contentRight}>
						<Typography variant='body2' gutterBottom>
							{paisOrigen+"-"+paisDestino}
						</Typography>
						<Typography variant='body2' gutterBottom>
							{estado}
						</Typography>
						<Typography variant='body2' gutterBottom>
							{moment(transaccion.created_at).format('DD-MM-YYYY')}
						</Typography>
						{buttons && buttons.length > 0 && <MenuButton buttons={buttons}/>}
					</CardContent>
				</Grid>
			</Card>
			<Modal
				open={detail}
				title="Detalle Transacción"
				close={true}
				handleClose={() => setDetail(false)}
			>
				<Typography variant='body2' gutterBottom>Beneficiario: {transaccion?.account.nombre + "  " + transaccion?.account.idTipo+ "-" +transaccion?.account.idNumero }</Typography>
				<Typography variant='body2' gutterBottom>Banco: {Banco(transaccion?.account.banco)}</Typography>
				<Typography variant='body2' gutterBottom>Cuenta: {transaccion?.account.cuenta}</Typography>
				<Typography variant='body2' gutterBottom>País Origen/País Destino: {paisOrigen+"/"+paisDestino}</Typography>
				<Typography variant='body2' gutterBottom>Envía: {currency8decimal(transaccion?.cantidadPesos)}</Typography>
				<Typography variant='body2' gutterBottom>Recibe: {currency8decimal(transaccion?.bolivares)}</Typography>
				<Typography variant='body2' gutterBottom>Estado de la Transacción: {estado}</Typography>
        	</Modal>
		</>
	);
}

TarjetaTransaccion.propTypes = {
	buttons: PropTypes.array,
	handleClick: PropTypes.func
}

export default TarjetaTransaccion
