import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Paper, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Navbar from '../Navbar/Navbar'
import MenuDetalle from '../../components/Drawer'
import ContenidoMenu from '../../components/ContenidoMenu'
import Modal from '../../components/Notificacion2'
import FormUsuario from '../../components/FormUsuario'
import FormEmpresa from '../../components/FormEmpresa'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Backdrop from '../../components/Backdrop'
import { fetchProfiles } from '../../ducks/Profiles'
import TiposId from '../../constants/TiposId'
import TiposCuenta from '../../constants/TiposCuenta'
import Sexos from '../../constants/Sexos'
import Profesiones from '../../constants/Profesiones'
import Actividades from '../../constants/Actividades'
import { paises, countryToFlag } from '../../services/utils'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import HomeIcon from '@material-ui/icons/Home'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import BusinessIcon from '@material-ui/icons/Business';
import moment from "moment"
import { asociarEmpresa, editarEmpresaAsociada, editUsuario, getUsuario } from '../../ducks/Usuario';

const useStyles = makeStyles(theme => ({
    perfil: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    contenedor: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    button: {
        margin: '10px',
    }
}));

function Perfil(props) {
    const classes = useStyles();
    const [ modalEditar, setModalEditar ] = useState(false)
    const [ modalEmpresa, setModalEmpresa ] = useState(false)
    const [ modalEditarEmpresa, setModalEditarEmpresa ] = useState(false)
    const usuario = useSelector(state => state.Usuario.data[0])
    const loading = useSelector(state => state.Usuario.fetching || state.Profiles.fetching)
    const perfiles = useSelector(state => state.Profiles)

    const dispatch = useDispatch()

    async function fetchData(){
        await dispatch(getUsuario())
        await dispatch(fetchProfiles())
    }
    
    React.useEffect( () => {
        fetchData()
    }
    , [])

    return (
        <Box mt={15} mb={5} ml={5} mr={5}>
            <Navbar />
            <Paper>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <span><AccountCircleIcon style={{ color: '#003520', fontSize: '60px', margin: '20px 0px 0px 0px' }} /></span>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center" >
                    <Typography variant="h5">Mi Perfil - {usuario.tipo_cuenta === "empresa" ? "Empresa" : "Persona"}</Typography>
                </Grid>
            </Paper>
            <MenuDetalle>
                <ContenidoMenu />
            </MenuDetalle>
            <Grid container>
                <Grid item xs={12} lg={6} xl={6} >
                    <Grid container direction="column">
                        <Paper style={{ margin: 10, padding: 20 }}>
                            <Grid container justify="center" alignItems="center"  >
                                <PermIdentityIcon fontSize="large"/>
                            </Grid>
                            <Typography variant='body1'><strong>Nombre(s):</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.nombre ? usuario.nombre : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Apellido(s):</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.apellido ? usuario.apellido : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Identificación:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.tipo_id && usuario.rut ? usuario.tipo_id+"-"+usuario.rut : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Fecha de Nacimiento:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.fecha_nacimiento ? moment(usuario.fecha_nacimiento).format('DD-MM-YYYY') : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Sexo(s):</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.sexo ? usuario.sexo : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Profesión:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.profesion ? usuario.profesion : "Dato faltante"}</Typography>
                            </Grid>
                        </Paper>
                        <Paper style={{ margin: 10, padding: 20  }}>
                            <Grid container justify="center" alignItems="center"  >
                                <ContactPhoneIcon fontSize="large"/>
                            </Grid>
                            <Typography variant='body1'><strong>Email:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.email ? usuario.email : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Telefono:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.codigo && usuario.telefono ? "+"+usuario.codigo+"-"+ usuario.telefono : "Dato faltante"}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={6} >
                    <Grid container direction="column">
                        <Paper style={{ margin: 10, padding: 20  }}>
                            <Grid container justify="center" alignItems="center"  >
                                <HomeIcon fontSize="large"/>
                            </Grid>
                            <Typography variant='body1'><strong>Nacionalidad:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.nacionalidad ? countryToFlag(paises.find(x => x.label === usuario.nacionalidad).code) +"  "+usuario.nacionalidad : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Pais de residencia:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.pais ?  countryToFlag(paises.find(x => x.label === usuario.pais).code) +"  "+ usuario.pais : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Ciudad:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.ciudad ? usuario.ciudad : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Dirección:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.direccion ? usuario.direccion : "Dato faltante"}</Typography>
                            </Grid>
                        </Paper>
                        {usuario.tipo_cuenta === "empresa" && <Paper style={{ margin: 10, padding: 20 }}>
                            <Grid container justify="center" alignItems="center"  >
                                <BusinessIcon fontSize="large"/>
                            </Grid>
                            <Typography variant='body1'><strong>Nombre de Empresa:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? usuario.company.nombre : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Nombre de Fantasía:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? usuario.company.nombre_fantasia : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Identificación de Empresa:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company && usuario.company.tipo_id && usuario.company.numero_id ? usuario.company.tipo_id+"-"+ usuario.company.numero_id : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>País Empresa:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? countryToFlag(paises.find(x => x.label === usuario.company.pais).code)+"  "+ usuario.company.pais: "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Ciudad Empresa:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? usuario.company.ciudad : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Dirección Empresa:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? usuario.company.direccion : "Dato faltante"}</Typography>
                            </Grid>
                            <Typography variant='body1'><strong>Actividad:</strong></Typography>
                            <Grid container style={{ margin: 10}}>
                                <Typography variant='body1'>{usuario.company ? usuario.company.actividad : "Dato faltante"}</Typography>
                            </Grid>
                        </Paper>}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center"  >
                        <Button 
                            className={classes.button} 
                            variant="contained" color="primary" 
                            onClick={() => setModalEditar(true)}
                        >
                            Editar perfil
                        </Button>
                        {usuario.tipo_cuenta === "empresa" && (usuario.company ? <Button 
                            className={classes.button} 
                            variant="contained" color="primary" 
                            onClick={() => setModalEditarEmpresa(true)}
                        >
                            Editar empresa
                        </Button> :
                        <Button
                            className={classes.button} 
                            variant="contained" color="primary" 
                            onClick={() => setModalEmpresa(true)}

                        >
                            Agregar Empresa
                        </Button>)}
                    </Grid>
                </Grid>
            </Grid>
            

            
            <Modal
                open={modalEditar}
                title="Editar Perfil"
                handleClose={() => setModalEditar(false)}
                close={true}
            >
                <FormUsuario 
                    verificado={usuario.verified}
                    usuarioSeleccionado={usuario}
                    initialValues={{
                        ...usuario,
                        sexo: Sexos.find(y => y.id === (usuario && usuario.sexo) ),
                        pais: paises.find(y => y.label === (usuario && usuario.pais) ),
                        nacionalidad:  paises.find(y => y.label === (usuario && usuario.nacionalidad) ),
                        tipo_cuenta:  TiposCuenta.find(y => y.id === (usuario && usuario.tipo_cuenta) ),
                        tipo_id: TiposId.find(y => y.id === (usuario && usuario.tipo_id) ),
                        profesion: Profesiones.find(y => y.id === (usuario && usuario.profesion) ),
                        profile_id: perfiles.data.find(y => y.id === (usuario && usuario.profile_id) ),
                        codigo: paises.find(y => y.phone === (usuario && usuario.codigo) ),
                    }}
                    onSubmit={values => {dispatch(editUsuario({
                        ...values,
                        sexo: values.sexo ? values.sexo.id : null,
                        tipo_cuenta: values.tipo_cuenta ? values.tipo_cuenta.id : null,
                        nacionalidad: values.nacionalidad ? values.nacionalidad.label : null,
                        pais: values.pais ? values.pais.label : null,
                        tipo_id: values.tipo_id ? values.tipo_id.id : null,
                        profesion: values.profesion ? values.profesion.id : null,
                        codigo: values.codigo ? values.codigo.phone : null,
                        profile_id: values.profile_id ? values.profile_id.id : null,
                        images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                    })); setModalEditar(false)}}
                />

            </Modal>
            <Modal
                open={modalEmpresa}
                title={"Agregar Empresa"}
                handleClose={() => setModalEmpresa(false)}
                close={true}
            >
                <FormEmpresa 
                    onSubmit={async values => {
                        await dispatch(asociarEmpresa({
                            ...values,
                            usuario: usuario ? usuario.id : null,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        })); setModalEmpresa(false)
                    }}
                />

            </Modal>
            <Modal
                open={modalEditarEmpresa}
                title={"Editar Empresa"}
                handleClose={() => setModalEditarEmpresa(false)}
                close={true}
            >
                <FormEmpresa 
                    verificado={usuario.verified}
                    usuarioSeleccionado={usuario}
                    initialValues={{
                        ...usuario.company,
                        pais: paises.find(y => y.label === (usuario && usuario.company && usuario.company.pais) ),
                        tipo_id: TiposId.find(y => y.id === (usuario && usuario.company && usuario.company.tipo_id) ),
                        actividad: Actividades.find(y => y.nombre === (usuario && usuario.company && usuario.company.actividad) ),
                    }}
                    onSubmit={async values => {
                        await dispatch(editarEmpresaAsociada({
                            ...values,
                            pais: values.pais ? values.pais.label : null,
                            tipo_id: values.tipo_id ? values.tipo_id.id : null,
                            actividad: values.actividad ? values.actividad.nombre : null,
                            codigo: values.codigo ? values.codigo.phone : null,
                            images: values.imagen1 ? (values.imagen2 ? [values.imagen1, values.imagen2] : [values.imagen1]) :  (values.imagen2 ? [values.imagen2] : []),
                        })); setModalEditarEmpresa(false)
                    }}
                />

            </Modal>
            <Backdrop open={loading}/>
        </Box>        
    );
}

export default Perfil;
