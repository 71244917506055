import React from 'react'
import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, selectHOR, addFetch, delFetch, resetStoreHOR, resetErrorHOR } from './hors.js'
import { getRequest, putRequest, postRequest, deleteRequest, postImageRequest } from '../services/api'
import { cerrarModal, abrirModal } from './Modales'
import { asignarMensaje } from './Mensajes'
import { editarEmpresa } from './Empresas'

const t = makeTypes('usuarios')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const ADD = t('add').async()
const DEL = t('del').async()
const SELECT = t('select').single()
const RESETSTORE = 'reset'
const RESETERROR = t('reset-error').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
const deleteActions = asyncMac(DEL)
export const resetErrorUsuarios = mac(RESETERROR)
export const selectUsuario = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const deleteReducer = createReducer(initialState, delFetch( DEL ))
const selectReducer = createReducer(initialState, selectHOR({SELECT}))
const resetErrorReducer = createReducer(initialState, resetErrorHOR({ RESETERROR }))
const resetStoreReducer = createReducer(initialState, resetStoreHOR({ RESETSTORE, initialState } ))

export default reduceReducers(fetchReducer, resetErrorReducer, editReducer, addReducer, deleteReducer, selectReducer, resetStoreReducer)

export const fetchUsuarios = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await getRequest('/users')
        dispatch(fetchActions.success(response.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const buscarUsuario = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await getRequest(`/users/search?id=${values}`)
        dispatch(fetchActions.success([response.data]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const buscarUsuarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const response = await postRequest('/users/advance-search', values)
        dispatch(fetchActions.success(response.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

/*export const editUsuario = values => async (dispatch,getState) => {
    const state = getState()    
    const usuario = state.Usuario.data[0]
    if(usuario.nombre === values.nombre && usuario.telefono === values.telefono && usuario.tipo_id === values.tipo_id && (usuario.rut === values.rut || usuario.rut === values.pasaporte)){

    }else{
        dispatch(editActions.start())
        try {
            const data = await putRequest(`/users`, values.id, { ...values, rut: values.tipo_id === "Pasaporte" ? values.pasaporte : values.rut})
            dispatch(editActions.success(data.data))
            //dispatch(editarUsuarioActual({ ...usuario, ...data.data}))
            //dispatch(selectUsuario(data.data.id))
        } catch (error) {
            dispatch(editActions.error(error))
        }
    } 
}*/

export const editUsuarios = values => async (dispatch, getState) => {
    const state = getState()    
    dispatch(editActions.start())
    try {
        const data = await putRequest(`/users`, values.id, { ...values, rut: values.tipo_id === "Pasaporte" ? values.pasaporte : values.rut})
        dispatch(editActions.success(data.data))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        dispatch(editActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const crearUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    const state = getState()
    try {
        const data = await postRequest(`/users`, { ...values, rut: values.tipo_id === "Pasaporte" ? values.pasaporte : values.rut} )
        dispatch(addActions.success(data.data))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        dispatch(addActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const crearEmpresa = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const response = await postImageRequest(`/companies`, { data: values, image: values.images })
        dispatch(editActions.success(response.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        throw error
    }
}

export const editEmpresa = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const response = await dispatch(editarEmpresa(values))
        dispatch(editActions.success(response.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        throw error
    }
} 

export const agregarUsuario = values => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const response = await postImageRequest(`/users/add-user`, { data: values, image: values.images } )
        dispatch(addActions.success(response.data))
    } catch (error) {
        dispatch(addActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}

export const editarUsuario = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const response = await postImageRequest(`/users/edit-user`, { data: values, image: values.images } )
        dispatch(editActions.success(response.data))
    } catch (error) {
        dispatch(editActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
        throw error
    }
}

export const borrarUsuario = id => async (dispatch, getState) => {
    dispatch(deleteActions.start())
    const state = getState()
    try {
        const data = await deleteRequest(`/users`, id)
        dispatch(deleteActions.success(data.data.id))
        state.Modales.data.map(x => dispatch(cerrarModal(x.id)))
    } catch (error) {
        dispatch(deleteActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const verificarUsuario = values => async (dispatch,getState) => {
    dispatch(fetchActions.start())
    const state = getState()
    try {
        await putRequest(`/users`, values, { verified: true })
        dispatch(fetchActions.success([]))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? error.data.errors : error))
        dispatch(abrirModal({ 
            title:"Notificación", 
            body: error.data ? 
            <ul>
                {error.data.errors.map(x => 
                <li key={x}>
                    {x}
                </li>)}
            </ul>
            : 
            "Ocurrió un error contacte al admistrador", 
            close: true }))
    }
}

export const fetchPagadoresUsuarios = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await getRequest(`/users/get-friends?id=${values}`)
        dispatch(fetchActions.success(data.data))
    } catch (error) {
        dispatch(fetchActions.error(error.data ? (error.data.errors ? error.data.errors : ["Ocurrió un error, contacte al admin"]) : ["Ocurrió un error, contacte al admin"]))
    }
}
