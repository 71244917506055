import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Fab } from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex:6000,
        position: 'fixed',
        bottom: theme.spacing(10),
        right: theme.spacing(0)
    }
}));

const SocialNetwork = props => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction="column" >
                <Fab href="https://www.instagram.com/aguacatecambios/" target="_blank" style={{ width: '48px', height: '48px' }} color="primary" aria-label="add">
                    <InstagramIcon />
                </Fab>
                <Fab href="https://www.facebook.com/aguacatecambios/" target="_blank" style={{ width: '48px', height: '48px' }} color="primary" aria-label="add">
                    <FacebookIcon />
                </Fab>
                <Fab href="https://twitter.com/aguacatecambios" target="_blank" style={{ width: '48px', height: '48px' }} color="primary" aria-label="add">
                    <TwitterIcon />
                </Fab>
            </Grid>
        </div>
    )
}

export default SocialNetwork