import Cuentas from './Cuentas'
import Transacciones from './Transacciones'
import Clientes from './Clientes'
import Beneficiarios from './Beneficiarios'
import BeneficiariosSugeridos from './BeneficiariosSugeridos'
import BeneficiariosSeleccionados from './BeneficiariosSeleccionados'
import Mensajes from './Mensajes'
import Modales from './Modales'
import TotalDeposito from './TotalDeposito'
import Menu from './Menu'
import Usuario from './Usuario'
import Usuarios from './Usuarios'
import Pasos from './Pasos'
import Tasas from './Tasas'
import DetalleCambio from './DetallesCambio'
import Profiles from './Profiles'
import Paises from './Paises'
import Rangos from './Rangos'
import BotonMenu from './BotonMenu'
import MenuDerecha from './MenuDerecha'
import Estados from './Estados'
import Workingaccounts from './Workingaccounts'
import Comprobante from './Comprobante'
import Url from './Url'
import Archivos from './Archivos'
import Balances from './Balances'
import Estadisticas from './Estadisticas'
import Friends from './Friends'
import Proveedores from './Proveedores'
import Empresas from './Empresas'
import Taxes from './Taxes'
import TransaccionesPendientesClientes from './transacciones/PendientesClientes'
import TransaccionesRealizadasClientes from './transacciones/RealizadasClientes'
import SolicitudesAbiertas from './transacciones/SolicitudesAbiertas'
import BeneficiariosClientes from './beneficiarios/BeneficiariosClientes'
import { reducer as formReducer } from 'redux-form'

const ducks = {
    Cuentas,
    Transacciones,
    Clientes, 
    Beneficiarios,
    BeneficiariosSugeridos,
    BeneficiariosSeleccionados,
    Mensajes,
    Modales,
    TotalDeposito,
    Menu,
    Usuario,
    Usuarios,
    Pasos,
    Tasas,
    DetalleCambio,
    Profiles,
    Paises,
    Rangos,
    BotonMenu,
    MenuDerecha,
    Estados,
    Workingaccounts,
    Comprobante,
    Url,
    Archivos,
    Balances,
    Estadisticas,
    Friends,
    Proveedores,
    Empresas,
    Taxes,
    TransaccionesPendientesClientes,
    TransaccionesRealizadasClientes,
    SolicitudesAbiertas,
    BeneficiariosClientes,
    BeneficiariosClientes,
    form: formReducer,
}

export default ducks